/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import i18n from '../../i18n';
import './Header.scss';
import LogoImage from '../../assets/images/logo.svg';
import { Link, useHistory } from 'react-router-dom';
import NotificationCount from './notification-count';
import { useLanguage } from '../../LanguageContext'; // Import useLanguage hook
import { useSelector } from 'react-redux';

interface CustomProps {
  innerHeader?: boolean;
  noBorder?: boolean;
}

const HeaderCanvas: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const history = useHistory();
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  const { setSelectedLanguage } = useLanguage();
  const [lang, setLang] = React.useState<'en' | 'ar'>(selectedLanguage);

  const changeLanguage = (lng: 'en' | 'ar') => {
    // Change the language in i18n and state
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    setLang(lng);
  
    // Get the current pathname and search params
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
  
    // Replace the language segment in the pathname
    const newPath = currentPath.replace(/^\/(en|ar)/, `/${lng}`);
  
    // Construct the new URL with the updated pathname and existing search params
    const newUrl = `${newPath}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
  
    // Push the new URL to history
    history.push(newUrl);
  };
  useEffect(() => {
    const urlLanguage = window.location.pathname.split('/')[1];
    if (urlLanguage && (urlLanguage === 'en' || urlLanguage === 'ar')) {
      changeLanguage(urlLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const dir = lang === 'en' ? 'ltr' : 'rtl';
    const language = lang === 'en' ? 'en' : 'ar';
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.setAttribute('dir', dir);
      htmlElement.setAttribute('lang', language);
    }
  }, [lang]);

  return (
    <header
      className={`relative bg-white px-4 lg:px-10 py-3.5 ${
        props?.noBorder ? 'shadow' : 'border-b border-[#E8E8E8]'
      }`}
    >
      <Link to="/" className="cursor-pointer flex">
        <span className="cursor-pointer flex h-16 lg:h-auto w-auto">
          <img src={LogoImage} alt="" />
        </span>
      </Link>
    </header>
  );
};

export default HeaderCanvas;
