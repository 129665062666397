// core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { actions as userActions } from '../../store/reducers/authUser';
import { actions as registrationActions } from '../../store/reducers/registration';

// components
import {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  DateInput,
  SelectPhoneInput,
} from '../../components/form-group';
import { CrudDetailsRequestModel } from '../../api/models';
// helpers
import { validateFields, serializeFields, validators } from '../helpers';
import Button from '../../components/button';
import SelectInput from '../../components/form-group/SelectInput/SelectInput';

import countryCodes from '../../models/country-dialcodes.json';
import prepareData from './prepareData';
import { Dict } from '../../models/interfaces';
import { State } from '../../store/interfaces';
import ClickLink from '../../components/click-link';
import { useGTagConversionTracker } from '../../hooks/gtag';
import { GtagSendTo } from '../../utils/gtag';
import { RouteKeys } from '../route-keys';
import loaderImg from '../../assets/images/loader-white.svg';
import Modal from '../../components/modal';
import moment from 'moment';
import { CloseIcon } from '../../components/icons/close';
import { ErrorResponse } from '../../store/reducers/error';
import SelectMultipleInput from '../../components/form-group/SelectMultipleInput';
const UAECountryName = 'United Arab Emirates';
const noVisaCountries = [
  'Saudi Arabia',
  'Kuwait',
  'United Arab Emirates',
  'Qatar',
  'Bahrain',
  'Oman',
];

const countryCodeList: Array<{ label: string; value: string }> = [];
const countryCodesList = countryCodes as { [key: string]: string };
Object.keys(countryCodes).forEach((codeKey) =>
  countryCodeList.push({
    label: countryCodesList[codeKey],
    value: countryCodesList[codeKey],
  })
);

const StepForm: React.SFC = () => {
  const location = useLocation();
  useGTagConversionTracker({
    allow_custom_scripts: true,
    u1: location.pathname,
    send_to: GtagSendTo.Signup,
  });

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onSubmit = (data: any) =>
    dispatch(
      registrationActions.updatePreregistrationDetails(data, () =>
        dispatch(userActions.fetchProfile())
      )
    );
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  const { isLoading, user } = useSelector((state: State) => state.authUser);
  const [options, setOptions] = useState([
    {
      label: t('sign_up.gear_type_options.label_automatic'),
      value: 'automatic',
    },
    { label: t('sign_up.gear_type_options.label_manual'), value: 'manual' },
  ]);
  const [genderOptions, setGenderOptions] = useState([
    { label: t('sign_up.gender_options.label_male'), value: 'male' },
    { label: t('sign_up.gender_options.label_female'), value: 'female' },
  ]);
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEidDetailsModalOpen, setIsEidDetailsModalOpen] = useState(false);
  const [isUploadEidModalOpen, setIsUploadEidModalOpen] = useState(false);
  const actionModalPopup = (open: boolean) => {
    setIsModalOpen(true);
    setIsUploadEidModalOpen(true);
    document.body.classList.toggle('overflow-hidden');
    document.body.classList.toggle('lg:pr-[17px]');
  };
  const [nationalityValue, setNationality] = useState<any>(null);
  const [readEIDLoading, setReadEIDLoading] = useState<boolean>(false);
  const [genderValue, setGender] = useState<any>(null);
  const [nameValue, setNameValue] = useState<any>(null);
  const [dateOfBirth, setDateOfBirth] = useState<any>(null);
  const [eidNumber, setEidNumber] = useState<any>(null);
  const closeModalPopup = () => {
    setNationality(null);
    setGender(null);
    setNameValue(null);
    setDateOfBirth(null);
    setNameValue(null);
    setIsModalOpen(false);
    setIsEidDetailsModalOpen(false);
    setIsUploadEidModalOpen(false);
    document.body.classList.toggle('overflow-hidden');
    document.body.classList.toggle('lg:pr-[17px]');
  };

  const handleImageUpload = async (event: any) => {
    try {
      setReadEIDLoading(true);
      const file = event.target.files[0];
      const isFileValid = isImage(file);

      if (!isFileValid) {
        throw new Error('Use an image file');
      }

      const request: CrudDetailsRequestModel = {
        resource: 'read-emirate-id',
        resourceId: '',
      };

      const response = await api.media.uploadFile(file, request);

      extractDataFromEIDText(response.data);
    } catch (error) {
      if (error) {
        const str = '2 UNKNOWN:';
        const typedError = error as ErrorResponse;

        let message = typedError?.response?.data?.message
          ? typedError?.response?.data?.message
          : typedError.message;

        const isUnKnowError = `${message}`.startsWith(str);

        if (isUnKnowError) {
          message = message.replace(str, '');
          toastr.error(message, '');
        } else {
          toastr.error(message, '');
        }
        resetFields();
      }

      setReadEIDLoading(false);
      // nothing
    } finally {
      setReadEIDLoading(false);
    }
  };

  async function resetFields() {
    setEidNumber(null);
    setFields({
      ...fields,
      name: {
        ...fields.name,
        value: user?.name || '',
      },
      dob: {
        ...fields.dob,
        value: '',
      },
      nationality: {
        ...fields.nationality,
        value: '',
      },
      gender: {
        ...fields.gender,
        value: '',
      },
    });
  }

  function isImage(file: any) {
    const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const typeProperty = 'type';
    return file && acceptedImageTypes.includes(file[typeProperty]);
  }

  const extractDataFromEIDText = (extractedData: any) => {
    if (extractedData) {
      setIsUploadEidModalOpen(false);
      setIsEidDetailsModalOpen(true);

      for (const [key, value] of Object.entries(extractedData)) {
        if (key === 'nationality') {
          setNationality(value ? `${value}` : '');
        } else if (key === 'gender') {
          setGender(value ? (value === 'M' ? 'male' : 'female') : '');
        } else if (key === 'name') {
          setNameValue(value ? `${value}` : '');
        } else if (key === 'dob') {
          setDateOfBirth(value ? `${value}` : '');
        } else if (key === 'eidNumber') {
          setEidNumber(value ? `${value}` : '');
        }
      }
    }
    if (
      !extractedData?.dob &&
      !extractedData?.name &&
      !extractedData?.nationality &&
      !extractedData?.gender
    ) {
      toastr.error('Invalid Id ,', `Id can't be read , please try again`);

      closeModalPopup();
    }
  };

  const onProceed = () => {
    try {
      const datedob = new Date(`${dateOfBirth}`).toString();

      setFields({
        ...fields,
        name: {
          ...fields.name,
          value: nameValue,
        },
        dob: {
          ...fields.dob,
          value:
            datedob !== 'Invalid Date' && dateOfBirth
              ? new Date(moment(dateOfBirth).format('DD/MM/YYYY'))
              : '',
        },
        nationality: {
          ...fields.nationality,
          value: nationalityValue,
        },
        gender: {
          ...fields.gender,
          value: genderValue,
        },
      });

      closeModalPopup();
    } catch (error) {
      toastr.error('error occured ,', `please try again`);
    }
  };

  const [fields, setFields] = useState<Dict>({
    name: {
      value: user?.name || '',
      name: 'Full Name',
      label: 'Full Name *',
      error: '',
      placeholder: 'Enter full name',
      validators: [
        validators.required,
        validators.regexPattern(/^[a-zA-Z ]+$/),
      ],
    },
    countryCode: {
      value: user?.phones?.firstNumber?.countryCode || '971',
    },
    startingCode: {
      value: user?.phones?.firstNumber?.startingCode || '50',
    },
    number: {
      value: user?.phones?.firstNumber?.number || '',
      name: 'Phone Number',
      label: 'Phone Number *',
      error: '',
      placeholder: 'Enter your phone number',
      validators: [validators.required],
    },
    email: {
      value: user?.email || '',
      name: 'Email Address',
      label: 'Email Address *',
      error: '',
      placeholder: 'Enter your email',
      validators: [validators.required, validators.email],
    },
    licenseTypeId: {
      value: user?.studentDetails?.requiredLicenseId || '',
      name: 'Driving License Type',
      label: 'Driving License Type *',
      placeholder: 'Select License Type',
      error: '',
      validators: [validators.required],
    },
    licenseTypeCategory: {
      value: user?.studentDetails?.requiredLicenseCategory || '',
    },
    gearType: {
      value: '',
      name: 'Gear Type',
      label: 'Gear Type *',
      error: '',
      validators: [validators.required],
    },
    gender: {
      value: 'male',
      name: 'Gender',
      label: 'Gender *',
      error: '',
      validators: [validators.required],
    },

    dob: {
      value: '',
      name: 'Date Of Birth',
      label: 'Date Of Birth *',
      error: '',
      placeholder: 'DD / MM / YYYY',
      validators: [validators.required, validators.validDOB],
    },
    language: {
      value: '',
      name: 'Language',
      label: 'Language *',
      error: '',
      placeholder: 'Select your language',
      validators: [validators.required],
    },
    hasResidencyVisa: {
      value: undefined,
      name: 'Residency Visa Status',
      label: 'Do you have a residency visa in UAE? *',
      error: '',
      validators: [validators.required],
    },
    nationality: {
      value: '',
      name: 'Nationality',
      label: 'Nationality *',
      error: '',
      placeholder: 'Select your nationality',
      validators: [validators.required],
    },
    trainingBranch: {
      value: '',
      name: 'Training Branch',
      label: 'Training Branch *',
      error: '',
      placeholder: 'Select your Training Branch',
      validators: [validators.required],
    },
  });
  const [queryValues, setQueryValues] = useState<{
    code: string;
    startingCode: string;
    number: string;
  }>();

  const queryNumber = useLocation<any>();

  useEffect(() => {
    if (queryNumber) {
      const valAr: any = {};
      queryNumber.search
        .replace('?', '')
        .split('&')
        .map((queries) => {
          const query = queries.split('=');
          return (valAr[query[0]] = query[1]);
        });
      if (valAr) {
        setQueryValues(valAr);
      }
    }
  }, [queryNumber]);

  const getInput = (inputField: string, value: any) => {
    if (inputField === 'nationality' && value === UAECountryName) {
      const fieldName = 'hasResidencyVisa';
      setFields({
        ...fields,
        [fieldName]: {
          ...fields.hasResidencyVisa,
          value: false,
        },
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    } else {
      setFields({
        ...fields,
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    }
  };

  const loadGearType = async (licenseTypeId: any) => {
    const request: CrudDetailsRequestModel = {
      resource: 'entity/v1/license-type',
      resourceId: licenseTypeId,
    };
    const gearTypeoptionsOptions: any = [];
    const { data } = await api.crud.get(request);
    if (data && data.gearTypes) {
      data.gearTypes.forEach((item: string) => {
        gearTypeoptionsOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setOptions(gearTypeoptionsOptions);
    }
    const genderTypeOptions: any = [];
    if (data && data.genders) {
      data.genders.forEach((item: string) => {
        genderTypeOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setGenderOptions(genderTypeOptions);
    }
  };

  const validateCustomFields = (customeFields: Dict) => {
    let areCustomFieldsValid = true;
    if (
      !customeFields.number.value ||
      !customeFields.countryCode.value ||
      !customeFields.startingCode.value
    ) {
      return { areCustomFieldsValid: false, updatedCustomFields: {} };
    }

    const validatorMessage = validators.phone(
      'number',
      customeFields.countryCode.value +
        customeFields.startingCode.value +
        customeFields.number.value,
      customeFields.number.name || customeFields.number.label
    );
    customeFields.number.error = validatorMessage;

    if (validatorMessage.length) {
      areCustomFieldsValid = false;
    }

    return { areCustomFieldsValid, updatedCustomFields: customeFields };
  };

  const validateHasResidencyVisaField = (customeFields: Dict) => {
    const { hasResidencyVisa, nationality } = customeFields;
    if (
      hasResidencyVisa.value === false &&
      !noVisaCountries.includes(nationality.value)
    ) {
      return 'You need to have residency Visa in UAE to register!';
    }
    return undefined;
  };

  const submitForm = async (readyFields: Dict) => {
    const data: any = prepareData(readyFields);

    if (eidNumber) {
      data.studentDetails.emiratesId = eidNumber;
      data.studentDetails.emiratesIdDetails = { id: eidNumber };
    }
    try {
      await onSubmit(data);
      history.replace(`/${selectedLanguage}${RouteKeys.SignupOnboard}`);
    } catch (error) {
      const typedError = error as ErrorResponse;

      if (typedError && typedError.response && typedError.response.data) {
        if (typedError.response.data.message === 'Emirates Id already exists') {
          resetFields();
        }
        formatErrors(typedError?.response?.data?.errors);
      }
    }
  };

  const formatErrors = (errors: any) => {
    const errorFields: Dict = {};
    if (Array.isArray(errors)) {
      for (const error of errors) {
        const field = error?.context?.key;
        const formField = field === 'phone' ? 'number' : field;
        const message = `Invalid ${fields[formField]?.name ||
          fields[formField].label}`;
        if (fields[formField]) {
          errorFields[formField] = {
            ...fields[formField],
            error: message,
          };
        } else {
          toastr.error(message, '');
        }
      }
    } else {
      if (errors) {
        const errorkeys = Object.keys(errors);
        for (const field of errorkeys) {
          const formField = field === 'phone' ? 'number' : field;

          const message =
            errors[field].message ||
            errors[field].errorMessage ||
            `Invalid ${fields[formField].name || fields[formField].label}`;
          if (fields[formField]) {
            errorFields[formField] = {
              ...fields[formField],
              error: message,
            };
          } else {
            toastr.error(message, '');
          }
        }
      }
    }

    setFields({
      ...fields,
      ...errorFields,
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const validateOptions = {
      fieldsToValidate: fields,
    };
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    const validatedFields = { ...fields, ...updatedFields };
    if (!areFieldsValid) {
      setFields(validatedFields);
      return;
    }
    const { areCustomFieldsValid, updatedCustomFields } = validateCustomFields(
      validatedFields
    );
    // show error messages (if any)
    const updatedValidatedFields = {
      ...validatedFields,
      ...updatedCustomFields,
    };
    if (!areCustomFieldsValid) {
      setFields(updatedValidatedFields);
      return;
    }

    const residencyVisaErrorMessage = validateHasResidencyVisaField(
      updatedValidatedFields
    );
    if (residencyVisaErrorMessage) {
      setFields({
        ...updatedValidatedFields,
        hasResidencyVisa: {
          ...updatedValidatedFields.hasResidencyVisa,
          error: residencyVisaErrorMessage,
        },
      });
      return;
    }
    const readyFields = serializeFields(fields);
    submitForm(readyFields);
  };

  const renderFieldGroupOne = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/license-type"
            label={t('sign_up.license_type.label')}
            placeholder={t('sign_up.license_type.placeholder')}
            optionLabel="name"
            optionValue="_id"
            onChange={(value, selectedOption) => {
              setFields({
                ...fields,
                licenseTypeId: {
                  ...fields.licenseTypeId,
                  value,
                },
                licenseTypeCategory: {
                  ...fields.licenseTypeCategory,
                  value: selectedOption.name,
                },
                gearType: {
                  ...fields.gearType,
                  value: '',
                },
              });
              loadGearType(value);
            }}
            error={fields.licenseTypeId.error}
            isSearchable={false}
            default={fields.licenseTypeId.value}
            className="form-control-select"
          />
        </div>
        {/* {fields.licenseTypeId.value && ( */}
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            name="gearType"
            key="gearType"
            label={t('sign_up.gear_type.label')}
            options={options}
            onChange={(value) => getInput('gearType', value)}
            error={fields.gearType.error}
            default={fields.gearType.value}
          />
        </div>
        {/* )} */}
      </>
    );
  };

  const renderFieldGroupTwo = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <TextInput
            label={t('sign_up.name.label')}
            default={fields.name.value}
            onChange={(value) => {
              getInput('name', value);
            }}
            placeholder={t('sign_up.name.placeholder')}
            error={fields.name.error}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <TextInput
            label={t('sign_up.email.label')}
            onChange={(value) => getInput('email', value)}
            placeholder={t('sign_up.email.placeholder')}
            error={fields.email.error}
            default={fields.email.value}
            disabled
          />
        </div>
      </>
    );
  };

  const renderFieldGroupThree = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            name="gender"
            key="gender"
            label={t('sign_up.gender.label')}
            options={genderOptions}
            onChange={(value) => getInput('gender', value)}
            error={fields.gender.error}
            default={fields.gender.value}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <SelectPhoneInput
            type="number"
            label={t('sign_up.number.label')}
            data={countryCodeList}
            defaultCode={fields.countryCode.value}
            defaultSelected={
              queryValues?.startingCode || fields.startingCode.value
            }
            defaultEntered={queryValues?.number || fields.number.value}
            onChange={(value) => {
              setFields({
                ...fields,
                countryCode: {
                  ...fields.countryCode,
                  value: value.code,
                },
                startingCode: {
                  ...fields.startingCode,
                  value: value.selected,
                },
                number: {
                  ...fields.number,
                  value: value.entered,
                },
              });
            }}
            placeholder={t('sign_up.number.placeholder')}
            error={fields.number.error}
            enableSelectSearch
            disableNumberScrolls
            disableSelected
          />
        </div>
      </>
    );
  };

  const renderFieldGroupFour = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <DateInput
            label={t('sign_up.dob.label')}
            onChange={(value) => getInput('dob', value)}
            placeholder={fields.dob.placeholder}
            error={fields.dob.error}
            default={fields.dob.value}
            maxDate={new Date()}
            className="form-control"
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <SelectMultipleInput
            api="entity/v1/languages"
            label={t('sign_up.language.label')}
            placeholder={t('sign_up.language.placeholder')}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('language', value)}
            error={fields.language.error}
            className="form-control-select"
          />
        </div>
      </>
    );
  };

  const renderFieldGroupFive = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/countries"
            label={t('sign_up.nationality.label')}
            placeholder={t('sign_up.nationality.placeholder')}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('nationality', value)}
            error={fields.nationality.error}
            className="form-control-select"
            default={fields.nationality.value}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            type="boolean"
            name="hasResidencyVisa"
            key="hasResidencyVisa"
            label={t('sign_up.has_residency_visa.label')}
            options={[
              {
                label: t('sign_up.has_residency_visa_options.yes'),
                value: true,
              },
              {
                label: t('sign_up.has_residency_visa_options.no'),
                value: false,
              },
            ]}
            onChange={(value) => getInput('hasResidencyVisa', value)}
            error={fields.hasResidencyVisa.error}
            default={fields.hasResidencyVisa.value}
          />
        </div>
      </>
    );
  };

  const renderFieldGroupSeven = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/training-branch"
            label={fields.trainingBranch.label}
            placeholder={fields.trainingBranch.placeholder}
            optionLabel="name"
            optionValue="_id"
            onChange={(value) => getInput('trainingBranch', value)}
            error={fields.trainingBranch.error}
            className="form-control-select"
            default={fields.trainingBranch.value}
            filterFunc={(item) =>
              item.type !== 'main_branch' || item.allowTrainingSchedule
            }
          />
        </div>
        <div className="col-span-2 md:col-span-1 hidden md:block"></div>
      </>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="col-span-2 pt-2 pb-3">
        <div className="grid grid-cols-2 gap-x-4 gap-y-5 lg:gap-x-10 lg:gap-y-7">
          <div className="col-span-2 md:col-span-1">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary w-full"
            >
              <span className="relative">
                {isLoading && (
                  <span className="absolute top-[-3px] ltr:-left-7 rtl:-right-7">
                    <img src={loaderImg} width={20} />
                  </span>
                )}
                {t('sign_up.next')}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white py-11 lg:py-24">
      <div className="container">
        <div className="text-center pb-8">
          <h1 className="text-3xl md:text-4xl leading-tight text-center font-semibold">
            {t('sign_up.Quick_register_now')}
          </h1>
        </div>
        <form onSubmit={submitHandler}>
          <div className="max-w-[920px] mx-auto">
            <div>
              {isModalOpen && (
                <>
                  {isUploadEidModalOpen && !readEIDLoading && (
                    <div className="max-w-[95%] sm:max-w-[450px] w-full bg-white rounded-2xl fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-50">
                      <div className="bg-primary rounded-tr-2xl rounded-tl-2xl text-center p-4 font-bold text-white text-lg leading-4 relative">
                        {t('sign_up.upload_eid')}
                        <div
                          className="absolute top-3 right-5 p-[6px] "
                          onClick={() => closeModalPopup()}
                        >
                          <CloseIcon width={12} height={12} stroke="white" />
                        </div>
                      </div>
                      <div className="px-8 pt-8 pb-12 sm:mb-5">
                        <div className="grid grid-cols-2 gap-y-16 sm:gap-y-0 sm:gap-x-16 relative">
                          <div
                            className="col-span-2 sm:col-span-1"
                            aria-disabled
                          >
                            <p className="text-sm	" style={{ opacity: 0.4 }}>
                              {t('sign_up.for_mobile_user')}
                            </p>
                            <div
                              className="border-primary rounded-md flex items-center justify-center p-3 mt-2 border-2 opacity-50 cursor-not-allowed"
                              style={{ opacity: 0.4 }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                className="mx-2"
                              >
                                <path
                                  d="M15 3H21V8H19V5H15V3ZM9 3V5H5V8H3V3H9ZM15 21V19H19V16H21V21H15ZM9 21H3V16H5V19H9V21ZM3 11H21V13H3V11Z"
                                  fill="#151817"
                                />
                              </svg>
                              {t('sign_up.scan')}
                              <input
                                type={'file'}
                                capture={'camera'}
                                accept="image/*"
                                onChange={handleImageUpload}
                                className="absolute bottom-0 left-0 top-0 w-full z-40 opacity-0 "
                                // disabled={readEIDLoading}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-span-2 sm:col-span-1">
                            <p className="text-sm	">
                              {t('sign_up.upload_from_computer')}
                            </p>
                            <div className="border-primary rounded-md flex items-center justify-center p-3 mt-2 border-2 relative text-sm font-medium">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                className="mx-2"
                              >
                                <g clip-path="url(#clip0_11834_61704)">
                                  <path
                                    d="M16 16L12 12L8 16"
                                    stroke="#151817"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 12V21"
                                    stroke="#151817"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M20.39 18.3905C21.3653 17.8587 22.1358 17.0174 22.5798 15.9991C23.0239 14.9808 23.1162 13.8437 22.8422 12.7672C22.5682 11.6906 21.9434 10.736 21.0666 10.0539C20.1898 9.37185 19.1108 9.00121 18 9.00047H16.74C16.4373 7.82971 15.8731 6.74281 15.0899 5.82147C14.3067 4.90012 13.3248 4.16832 12.2181 3.68108C11.1113 3.19384 9.90851 2.96383 8.70008 3.00835C7.49164 3.05288 6.30903 3.37077 5.24114 3.93814C4.17325 4.5055 3.24787 5.30758 2.53458 6.28405C1.82129 7.26053 1.33865 8.38601 1.12294 9.57587C0.90723 10.7657 0.964065 11.989 1.28917 13.1537C1.61428 14.3185 2.1992 15.3943 2.99996 16.3005"
                                    stroke="#151817"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M16 16L12 12L8 16"
                                    stroke="#151817"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_11834_61704">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              {t('sign_up.upload')}
                              <input
                                type={'file'}
                                accept="image/*"
                                onChange={handleImageUpload}
                                className="absolute bottom-0 left-0 top-0 w-full z-40 opacity-0 "
                                disabled={readEIDLoading}
                              />
                            </div>
                          </div>
                          <div className="absolute left-1/2 -translate-y-2/4 -translate-x-2/4 text-xs	bg-white text-[#B7B7B7] p-1 z-10 sm:mt-3 top-1/2">
                            {t('sign_up.or')}
                          </div>
                          <span className="w-[100%] h-[1px] sm:w-[1px] sm:h-[100%] bg-[#B7B7B7] absolute sm:left-1/2	top-1/2 sm:top-0"></span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* file upload */}
                  {readEIDLoading && (
                    <div className="fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-50">
                      <img src={loaderImg} width={45} className="mr-2" />
                    </div>
                  )}
                  {/* details */}
                  {isEidDetailsModalOpen && !readEIDLoading && (
                    <div className="max-w-[95%] sm:max-w-[410px] w-full bg-white rounded-3xl fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-50">
                      <div className="bg-primary rounded-tr-3xl  rounded-tl-3xl text-center p-4 font-bold text-white text-lg leading-4">
                        {t('sign_up.details_from_EID')}
                      </div>
                      <div className="px-12 pt-8">
                        <div className="grid grid-cols-2 gap-y-3 sm:gap-x-12 relative text-sm">
                          <div className="col-span-2 sm:col-span-1">
                            <label className="text-[#8A8C8B]">Name:</label>
                            <p>{nameValue}</p>
                          </div>
                          <div className="col-span-2 sm:col-span-1">
                            <label className="text-[#8A8C8B]">Gender:</label>
                            <p>{genderValue}</p>
                          </div>
                          <div className="col-span-2 sm:col-span-1">
                            <label className="text-[#8A8C8B]">
                              Date of Birth:
                            </label>
                            <p>{dateOfBirth}</p>
                          </div>
                          <div className="col-span-2 sm:col-span-1">
                            <label className="text-[#8A8C8B]">
                              Nationality:
                            </label>
                            <p>{nationalityValue}</p>
                          </div>
                          <div className="col-span-2 sm:col-span-1">
                            <label className="text-[#8A8C8B]">
                              Emirate Id:
                            </label>
                            <p>{eidNumber}</p>
                          </div>
                        </div>
                        <div className="gap-3 grid grid-cols-2 mt-2">
                          <div className="col-span-1 mt-3">
                            <button
                              onClick={() => closeModalPopup()}
                              type="button"
                              className="btn btn-outline-primary w-full "
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-span-1 mt-3">
                            <button
                              type="button"
                              onClick={() => onProceed()}
                              className="btn btn-primary w-full "
                            >
                              Proceed
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex text-sm px-4 pt-4 pb-5">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            className="px-[6px] w-[24px]"
                          >
                            <path
                              d="M7 4.6V7.6M7 13C10.3 13 13 10.3 13 7C13 3.7 10.3 1 7 1C3.7 1 1 3.7 1 7C1 10.3 3.7 13 7 13Z"
                              stroke="#6F7171"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <p className="text-[#6F7171] text-xs">
                          In the event of any discrepancies or incorrect data,
                          you may manually make the necessary corrections.
                        </p>
                      </div>
                    </div>
                  )}
                  {/* details */}
                  <span
                    className="fixed bg-black/60 z-40 top-0 left-0 right-0 bottom-0"
                    // onClick={() => closeModalPopup()}
                  ></span>
                </>
              )}
              <div
                className="upload bg-slate-50 rounded px-4 lg:px-6 py-3.5 cursor-pointer mb-9"
                onClick={() => actionModalPopup(true)}
              >
                <div className="flex items-center -mx-2.5">
                  <div className="px-2.5">
                    <h4 className="text-lg font-bold mb-1">
                      {t('sign_up.upload_eid')}
                    </h4>
                    <p className="text-sm text-muted">
                      {t('sign_up.click_on')}
                    </p>
                  </div>
                  <div className="px-2.5 ltr:ml-auto rtl:mr-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1025_57082)">
                        <path
                          d="M16 16L12 12L8 16"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 12V21"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.39 18.3914C21.3653 17.8597 22.1358 17.0183 22.5798 16.0001C23.0239 14.9818 23.1162 13.8447 22.8422 12.7681C22.5682 11.6916 21.9434 10.737 21.0666 10.0549C20.1898 9.37283 19.1108 9.00218 18 9.00145H16.74C16.4373 7.83069 15.8731 6.74378 15.0899 5.82244C14.3067 4.9011 13.3248 4.1693 12.2181 3.68206C11.1113 3.19481 9.90851 2.96481 8.70008 3.00933C7.49164 3.05385 6.30903 3.37175 5.24114 3.93911C4.17325 4.50648 3.24787 5.30855 2.53458 6.28503C1.82129 7.26151 1.33865 8.38699 1.12294 9.57684C0.90723 10.7667 0.964065 11.99 1.28917 13.1547C1.61428 14.3194 2.1992 15.3953 2.99996 16.3014"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 16L12 12L8 16"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1025_57082">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4 lg:gap-x-10 md:gap-y-7 gap-5">
              {renderFieldGroupOne()}
              {renderFieldGroupTwo()}
              {renderFieldGroupThree()}
              {renderFieldGroupFour()}
              {renderFieldGroupFive()}
              {renderFieldGroupSeven()}
              {renderSubmitButton()}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepForm;
