import React from 'react';

export const TickRoundedIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <circle cx="10" cy="10" r="10" />
      <path
        d="M13.8891 7.08984L8.54188 12.4371L6.11133 10.0065"
        stroke="white"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
