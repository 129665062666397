import React from 'react';

export const ThankyouIcon = (props: any) => {
  return (
    <svg
      width="77"
      height="77"
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M69.6483 45.8519C73.7085 28.6491 63.0544 11.4121 45.8517 7.35189C28.6489 3.29168 11.4119 13.9458 7.35167 31.1485C3.29146 48.3513 13.9456 65.5883 31.1483 69.6485C48.3511 73.7088 65.5881 63.0546 69.6483 45.8519Z"
        fill="#00B159"
      />
      <mask
        id="mask0_6586_8156"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="65"
        height="65"
      >
        <path
          d="M69.6483 45.8519C73.7085 28.6491 63.0544 11.4121 45.8517 7.35189C28.6489 3.29168 11.4119 13.9458 7.35167 31.1485C3.29146 48.3513 13.9456 65.5883 31.1483 69.6485C48.3511 73.7088 65.5881 63.0546 69.6483 45.8519Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6586_8156)">
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
          <path
            d="M74.1283 66.6095C74.2019 65.0161 72.9271 63.7045 71.3459 63.7045H71.8117C73.3316 63.7045 74.7044 62.5646 74.7657 61.0446C74.8393 59.4512 73.5645 58.1396 71.9833 58.1396H59.8484C59.8484 58.1396 59.8116 58.1151 59.8362 58.0906C62.5573 55.6391 64.2488 54.4011 64.3101 49.3511C64.3346 47.586 63.2682 45.3184 61.4664 45.3184C58.684 45.3184 58.5736 47.4634 58.5491 49.3511C56.9066 54.7811 49.307 54.7198 49.307 63.5084V75.4962C49.307 78.1928 51.4889 80.3746 54.1855 80.3746H69.875C71.3459 80.3746 72.7677 79.1856 72.829 77.7147C72.9026 76.1213 71.6278 74.8097 70.0466 74.8097H70.5124C71.9833 74.8097 73.4051 73.6208 73.4664 72.1499C73.54 70.5564 72.2652 69.2449 70.684 69.2449H71.1498C72.6207 69.2449 74.0425 68.0559 74.1038 66.585L74.1283 66.6095ZM44.3918 58.1396H41.8177C40.261 58.1396 38.9985 59.4021 38.9985 60.9466V77.5677C38.9985 79.1244 40.261 80.3746 41.8177 80.3746H44.3918C45.9485 80.3746 47.1988 79.1121 47.1988 77.5677V60.9466C47.1988 59.3899 45.9362 58.1396 44.3918 58.1396Z"
            fill="white"
          />
          <path
            d="M74.0551 66.4738C74.1287 64.8803 72.8539 63.5688 71.2727 63.5688H71.7385C73.2584 63.5688 74.6312 62.4288 74.6925 60.9089C74.7661 59.3154 73.4913 58.0039 71.9101 58.0039H59.7752C59.7752 58.0039 59.7385 57.9794 59.763 57.9549C62.4841 55.5034 64.1756 54.2654 64.2369 49.2153C64.2614 47.4502 63.1951 45.1826 61.3932 45.1826C58.6108 45.1826 58.5005 47.3277 58.4759 49.2153C56.8334 54.6454 49.2338 54.5841 49.2338 63.3726V75.3604C49.2338 78.057 51.4157 80.2389 54.1123 80.2389H69.8018C71.2727 80.2389 72.6946 79.0499 72.7558 77.579C72.8294 75.9855 71.5546 74.674 69.9734 74.674H70.4392C71.9101 74.674 73.3319 73.485 73.3932 72.0141C73.4668 70.4207 72.192 69.1091 70.6108 69.1091H71.0766C72.5475 69.1091 73.9693 67.9202 74.0306 66.4493L74.0551 66.4738ZM44.3064 58.0161H41.7323C40.1756 58.0161 38.9131 59.2787 38.9131 60.8231V77.4442C38.9131 79.0009 40.1756 80.2511 41.7323 80.2511H44.3064C45.863 80.2511 47.1133 78.9886 47.1133 77.4442V60.8231C47.1133 59.2664 45.8508 58.0161 44.3064 58.0161Z"
            fill="#FEFEFE"
          />
          <path
            d="M73.9687 66.3517C74.0423 64.7582 72.7675 63.4467 71.1863 63.4467H71.6521C73.172 63.4467 74.5448 62.3068 74.6061 60.7868C74.6796 59.1934 73.4049 57.8818 71.8237 57.8818H59.6888C59.6888 57.8818 59.652 57.8573 59.6765 57.8328C62.3977 55.3813 64.0892 54.1433 64.1505 49.0932C64.175 47.3282 63.1086 45.0605 61.3068 45.0605C58.5243 45.0605 58.414 47.2056 58.3895 49.0932C56.747 54.5233 49.1474 54.462 49.1474 63.2506V75.2383C49.1474 77.935 51.3292 80.1168 54.0259 80.1168H69.7154C71.1863 80.1168 72.6081 78.9278 72.6694 77.4569C72.743 75.8635 71.4682 74.5519 69.887 74.5519H70.3528C71.8237 74.5519 73.2455 73.3629 73.3068 71.8921C73.3804 70.2986 72.1056 68.9871 70.5244 68.9871H70.9902C72.461 68.9871 73.8829 67.7981 73.9442 66.3272L73.9687 66.3517ZM44.2199 57.8818H41.6459C40.0892 57.8818 38.8267 59.1443 38.8267 60.6888V77.3098C38.8267 78.8665 40.0892 80.1168 41.6459 80.1168H44.2199C45.7766 80.1168 47.0269 78.8543 47.0269 77.3098V60.6888C47.0269 59.1321 45.7644 57.8818 44.2199 57.8818Z"
            fill="#FDFDFD"
          />
          <path
            d="M73.8832 66.2169C73.9567 64.6235 72.6819 63.3119 71.1007 63.3119H71.5665C73.0864 63.3119 74.4593 62.172 74.5205 60.6521C74.5941 59.0586 73.3193 57.7471 71.7381 57.7471H59.6033C59.6033 57.7471 59.5665 57.7225 59.591 57.698C62.3121 55.2465 64.0037 54.0085 64.065 48.9585C64.0895 47.1934 63.0231 44.9258 61.2212 44.9258C58.4388 44.9258 58.3285 47.0708 58.304 48.9585C56.6615 54.3885 49.0619 54.3272 49.0619 63.1158V75.1036C49.0619 77.8002 51.2437 79.982 53.9403 79.982H69.6298C71.1007 79.982 72.5226 78.7931 72.5839 77.3222C72.6574 75.7287 71.3826 74.4172 69.8014 74.4172H70.2672C71.7381 74.4172 73.16 73.2282 73.2213 71.7573C73.2948 70.1638 72.02 68.8523 70.4388 68.8523H70.9046C72.3755 68.8523 73.7974 67.6633 73.8587 66.1924L73.8832 66.2169ZM44.1466 57.7471H41.5726C40.0159 57.7471 38.7656 59.0096 38.7656 60.554V77.1751C38.7656 78.7318 40.0281 79.982 41.5726 79.982H44.1466C45.7033 79.982 46.9536 78.7195 46.9536 77.1751V60.554C46.9536 58.9973 45.6911 57.7471 44.1466 57.7471Z"
            fill="#FCFCFC"
          />
          <path
            d="M73.81 66.0822C73.8835 64.4887 72.6087 63.1772 71.0275 63.1772H71.4933C73.0132 63.1772 74.3861 62.0372 74.4474 60.5173C74.5209 58.9238 73.2461 57.6123 71.6649 57.6123H59.5301C59.5301 57.6123 59.4933 57.5878 59.5178 57.5633C62.239 55.1118 63.9305 53.8738 63.9918 48.8237C64.0163 47.0586 62.9499 44.791 61.148 44.791C58.3656 44.791 58.2553 46.9361 58.2308 48.8237C56.5883 54.2538 48.9887 54.1925 48.9887 62.981V74.9688C48.9887 77.6654 51.1705 79.8473 53.8671 79.8473H69.5566C71.0275 79.8473 72.4494 78.6583 72.5107 77.1874C72.5842 75.5939 71.3095 74.2824 69.7282 74.2824H70.194C71.6649 74.2824 73.0868 73.0934 73.1481 71.6225C73.2216 70.0291 71.9468 68.7175 70.3656 68.7175H70.8314C72.3023 68.7175 73.7242 67.5286 73.7854 66.0577L73.81 66.0822ZM44.0612 57.6245H41.4871C39.9304 57.6245 38.6802 58.8871 38.6802 60.4315V77.0526C38.6802 78.6093 39.9427 79.8595 41.4871 79.8595H44.0612C45.6179 79.8595 46.8681 78.597 46.8681 77.0526V60.4315C46.8681 58.8748 45.6056 57.6245 44.0612 57.6245Z"
            fill="#FBFBFB"
          />
          <path
            d="M73.7235 65.9591C73.7971 64.3657 72.5223 63.0541 70.9411 63.0541H71.4069C72.9268 63.0541 74.2996 61.9142 74.3609 60.3943C74.4345 58.8008 73.1597 57.4892 71.5785 57.4892H59.4436C59.4436 57.4892 59.4069 57.4647 59.4314 57.4402C62.1525 54.9887 63.8441 53.7507 63.9053 48.7007C63.9299 46.9356 62.8635 44.668 61.0616 44.668C58.2792 44.668 58.1689 46.813 58.1443 48.7007C56.5019 54.1307 48.9022 54.0694 48.9022 62.858V74.8458C48.9022 77.5424 51.0841 79.7242 53.7807 79.7242H69.4702C70.9411 79.7242 72.363 78.5352 72.4243 77.0644C72.4978 75.4709 71.223 74.1593 69.6418 74.1593H70.1076C71.5785 74.1593 73.0004 72.9704 73.0616 71.4995C73.1352 69.906 71.8604 68.5945 70.2792 68.5945H70.745C72.2159 68.5945 73.6377 67.4055 73.699 65.9346L73.7235 65.9591ZM43.9748 57.4892H41.4007C39.844 57.4892 38.5938 58.7518 38.5938 60.2962V76.9173C38.5938 78.474 39.8563 79.7242 41.4007 79.7242H43.9748C45.5315 79.7242 46.7817 78.4617 46.7817 76.9173V60.2962C46.7817 58.7395 45.5192 57.4892 43.9748 57.4892Z"
            fill="#FAFAFA"
          />
          <path
            d="M73.6376 65.8253C73.7111 64.2319 72.4363 62.9203 70.8551 62.9203H71.3209C72.8408 62.9203 74.2136 61.7804 74.2749 60.2605C74.3485 58.667 73.0737 57.3554 71.4925 57.3554H59.3576C59.3576 57.3554 59.3209 57.3309 59.3454 57.3064C62.0665 54.8549 63.7581 53.6169 63.8194 48.5669C63.8439 46.8018 62.7775 44.5342 60.9756 44.5342C58.1932 44.5342 58.0829 46.6792 58.0584 48.5669C56.4159 53.9969 48.8163 53.9356 48.8163 62.7242V74.712C48.8163 77.4086 50.9981 79.5904 53.6947 79.5904H69.3842C70.8551 79.5904 72.277 78.4015 72.3383 76.9306C72.4118 75.3371 71.137 74.0256 69.5558 74.0256H70.0216C71.4925 74.0256 72.9144 72.8366 72.9757 71.3657C73.0492 69.7722 71.7744 68.4607 70.1932 68.4607H70.659C72.1299 68.4607 73.5518 67.2717 73.613 65.8008L73.6376 65.8253ZM43.901 57.3677H41.327C39.7703 57.3677 38.52 58.6302 38.52 60.1747V76.7957C38.52 78.3524 39.7825 79.6027 41.327 79.6027H43.901C45.4577 79.6027 46.708 78.3402 46.708 76.7957V60.1747C46.708 58.618 45.4455 57.3677 43.901 57.3677Z"
            fill="#F9F9F9"
          />
          <path
            d="M73.5521 65.7023C73.6257 64.1088 72.3509 62.7973 70.7697 62.7973H71.2354C72.7554 62.7973 74.1282 61.6573 74.1895 60.1374C74.263 58.544 72.9883 57.2324 71.4071 57.2324H59.2722C59.2722 57.2324 59.2354 57.2079 59.2599 57.1834C61.9811 54.7319 63.6726 53.4939 63.7339 48.4438C63.7584 46.6788 62.692 44.4111 60.8902 44.4111C58.1077 44.4111 57.9974 46.5562 57.9729 48.4438C56.3304 53.8739 48.7308 53.8126 48.7308 62.6012V74.5889C48.7308 77.2856 50.9126 79.4674 53.6093 79.4674H69.2988C70.7697 79.4674 72.1915 78.2784 72.2528 76.8075C72.3264 75.2141 71.0516 73.9025 69.4704 73.9025H69.9362C71.4071 73.9025 72.8289 72.7135 72.8902 71.2426C72.9637 69.6492 71.689 68.3376 70.1078 68.3376H70.5736C72.0444 68.3376 73.4663 67.1487 73.5276 65.6778L73.5521 65.7023ZM43.8156 57.2324H41.2415C39.6848 57.2324 38.4346 58.4949 38.4346 60.0394V76.6604C38.4346 78.2171 39.6971 79.4674 41.2415 79.4674H43.8156C45.3723 79.4674 46.6225 78.2049 46.6225 76.6604V60.0394C46.6225 58.4827 45.36 57.2324 43.8156 57.2324Z"
            fill="#F8F8F8"
          />
          <path
            d="M73.4789 65.5675C73.5525 63.9741 72.2777 62.6625 70.6965 62.6625H71.1623C72.6822 62.6625 74.055 61.5226 74.1163 60.0026C74.1898 58.4092 72.9151 57.0976 71.3339 57.0976H59.199C59.199 57.0976 59.1622 57.0731 59.1868 57.0486C61.9079 54.5971 63.5994 53.3591 63.6607 48.3091C63.6852 46.544 62.6188 44.2764 60.817 44.2764C58.0346 44.2764 57.9242 46.4214 57.8997 48.3091C56.2572 53.7391 48.6576 53.6778 48.6576 62.4664V74.4542C48.6576 77.1508 50.8394 79.3326 53.5361 79.3326H69.2256C70.6965 79.3326 72.1183 78.1436 72.1796 76.6727C72.2532 75.0793 70.9784 73.7677 69.3972 73.7677H69.863C71.3339 73.7677 72.7557 72.5788 72.817 71.1079C72.8906 69.5144 71.6158 68.2029 70.0346 68.2029H70.5004C71.9712 68.2029 73.3931 67.0139 73.4544 65.543L73.4789 65.5675ZM43.7301 57.0976H41.1561C39.5994 57.0976 38.3491 58.3602 38.3491 59.9046V76.5257C38.3491 78.0824 39.6116 79.3326 41.1561 79.3326H43.7301C45.2868 79.3326 46.5493 78.0701 46.5493 76.5257V59.9046C46.5493 58.3479 45.2868 57.0976 43.7301 57.0976Z"
            fill="#F7F7F7"
          />
          <path
            d="M73.3929 65.4445C73.4665 63.851 72.1917 62.5395 70.6105 62.5395H71.0763C72.5962 62.5395 73.969 61.3995 74.0303 59.8796C74.1039 58.2861 72.8291 56.9746 71.2479 56.9746H59.113C59.113 56.9746 59.0762 56.9501 59.1008 56.9256C61.8219 54.4741 63.5134 53.2361 63.5747 48.186C63.5992 46.4209 62.5328 44.1533 60.731 44.1533C57.9486 44.1533 57.8382 46.2984 57.8137 48.186C56.1712 53.6161 48.5716 53.5548 48.5716 62.3433V74.3311C48.5716 77.0277 50.7535 79.2096 53.4501 79.2096H69.1396C70.6105 79.2096 72.0324 78.0206 72.0936 76.5497C72.1672 74.9562 70.8924 73.6447 69.3112 73.6447H69.777C71.2479 73.6447 72.6697 72.4557 72.731 70.9848C72.8046 69.3914 71.5298 68.0798 69.9486 68.0798H70.4144C71.8853 68.0798 73.3071 66.8908 73.3684 65.42L73.3929 65.4445ZM43.6564 56.9746H41.0823C39.5256 56.9746 38.2754 58.2371 38.2754 59.7815V76.4026C38.2754 77.9593 39.5379 79.2096 41.0823 79.2096H43.6564C45.2131 79.2096 46.4756 77.947 46.4756 76.4026V59.7815C46.4756 58.2248 45.2131 56.9746 43.6564 56.9746Z"
            fill="#F6F6F6"
          />
          <path
            d="M73.307 65.3107C73.3805 63.7172 72.1058 62.4057 70.5246 62.4057H70.9903C72.5103 62.4057 73.8831 61.2657 73.9444 59.7458C74.0179 58.1523 72.7431 56.8408 71.1619 56.8408H59.0271C59.0271 56.8408 58.9903 56.8163 59.0148 56.7918C61.736 54.3403 63.4275 53.1023 63.4888 48.0522C63.5133 46.2872 62.4469 44.0195 60.6451 44.0195C57.8626 44.0195 57.7523 46.1646 57.7278 48.0522C56.0853 53.4823 48.4857 53.421 48.4857 62.2096V74.1973C48.4857 76.894 50.6675 79.0758 53.3641 79.0758H69.0537C70.5245 79.0758 71.9464 77.8868 72.0077 76.4159C72.0812 74.8224 70.8065 73.5109 69.2253 73.5109H69.691C71.1619 73.5109 72.5838 72.3219 72.6451 70.851C72.7186 69.2576 71.4439 67.946 69.8626 67.946H70.3284C71.7993 67.946 73.2212 66.7571 73.2825 65.2862L73.307 65.3107ZM43.5705 56.8408H40.9964C39.4397 56.8408 38.1895 58.1033 38.1895 59.6478V76.2688C38.1895 77.8255 39.452 79.0758 40.9964 79.0758H43.5705C45.1272 79.0758 46.3897 77.8133 46.3897 76.2688V59.6478C46.3897 58.0911 45.1272 56.8408 43.5705 56.8408Z"
            fill="#F5F5F5"
          />
          <path
            d="M73.2333 65.1749C73.3069 63.5815 72.0321 62.2699 70.4509 62.2699H70.9167C72.4366 62.2699 73.8094 61.13 73.8707 59.6101C73.9442 58.0166 72.6695 56.7051 71.0883 56.7051H58.9534C58.9534 56.7051 58.9166 56.6805 58.9411 56.656C61.6623 54.2045 63.3538 52.9665 63.4151 47.9165C63.4396 46.1514 62.3732 43.8838 60.5714 43.8838C57.7889 43.8838 57.6786 46.0288 57.6541 47.9165C56.0116 53.3465 48.412 53.2852 48.412 62.0738V74.0616C48.412 76.7582 50.5938 78.94 53.2905 78.94H68.98C70.4509 78.94 71.8727 77.7511 71.934 76.2802C72.0076 74.6867 70.7328 73.3752 69.1516 73.3752H69.6174C71.0882 73.3752 72.5101 72.1862 72.5714 70.7153C72.6449 69.1218 71.3702 67.8103 69.789 67.8103H70.2547C71.7256 67.8103 73.1475 66.6213 73.2088 65.1504L73.2333 65.1749ZM43.4845 56.7173H40.9105C39.3538 56.7173 38.1035 57.9798 38.1035 59.5243V76.1453C38.1035 77.702 39.366 78.9523 40.9105 78.9523H43.4845C45.0412 78.9523 46.3037 77.6898 46.3037 76.1453V59.5243C46.3037 57.9676 45.0412 56.7173 43.4845 56.7173Z"
            fill="#F4F4F4"
          />
          <path
            d="M73.1479 65.0529C73.2214 63.4594 71.9466 62.1479 70.3654 62.1479H70.8312C72.3511 62.1479 73.7239 61.0079 73.7852 59.488C73.8588 57.8945 72.584 56.583 71.0028 56.583H58.868C58.868 56.583 58.8312 56.5585 58.8557 56.534C61.5768 54.0825 63.2684 52.8445 63.3297 47.7944C63.3542 46.0293 62.2878 43.7617 60.4859 43.7617C57.7035 43.7617 57.5932 45.9068 57.5687 47.7944C55.9262 53.2245 48.3266 53.1632 48.3266 61.9517V73.9395C48.3266 76.6361 50.5084 78.818 53.205 78.818H68.8945C70.3654 78.818 71.7873 77.629 71.8486 76.1581C71.9221 74.5646 70.6473 73.2531 69.0661 73.2531H69.5319C71.0028 73.2531 72.4247 72.0641 72.486 70.5932C72.5595 68.9998 71.2847 67.6882 69.7035 67.6882H70.1693C71.6402 67.6882 73.062 66.4992 73.1233 65.0284L73.1479 65.0529ZM43.3991 56.583H40.825C39.2683 56.583 38.0181 57.8455 38.0181 59.3899V76.011C38.0181 77.5677 39.2806 78.8302 40.825 78.8302H43.3991C44.9558 78.8302 46.2183 77.5677 46.2183 76.011V59.3899C46.2183 57.8332 44.9558 56.583 43.3991 56.583Z"
            fill="#F3F3F3"
          />
          <path
            d="M73.0619 64.9181C73.1354 63.3246 71.8606 62.0131 70.2794 62.0131H70.7452C72.2651 62.0131 73.638 60.8732 73.6993 59.3532C73.7728 57.7598 72.498 56.4482 70.9168 56.4482H58.782C58.782 56.4482 58.7452 56.4237 58.7697 56.3992C61.4909 53.9477 63.1824 52.7097 63.2437 47.6596C63.2682 45.8946 62.2018 43.627 60.3999 43.627C57.6175 43.627 57.5072 45.772 57.4827 47.6596C55.8402 53.0897 48.2406 53.0284 48.2406 61.817V73.8047C48.2406 76.5014 50.4224 78.6832 53.119 78.6832H68.8085C70.2794 78.6832 71.7013 77.4942 71.7626 76.0233C71.8361 74.4299 70.5614 73.1183 68.9801 73.1183H69.4459C70.9168 73.1183 72.3387 71.9294 72.4 70.4585C72.4735 68.865 71.1987 67.5534 69.6175 67.5534H70.0833C71.5542 67.5534 72.9761 66.3645 73.0374 64.8936L73.0619 64.9181ZM43.3253 56.4482H40.7513C39.1946 56.4482 37.9443 57.7107 37.9443 59.2552V75.8762C37.9443 77.4329 39.2069 78.6832 40.7513 78.6832H43.3253C44.882 78.6832 46.1446 77.4207 46.1446 75.8762V59.2552C46.1446 57.6985 44.882 56.4482 43.3253 56.4482Z"
            fill="#F2F2F2"
          />
          <path
            d="M72.9882 64.796C73.0617 63.2026 71.787 61.891 70.2057 61.891H70.6715C72.1915 61.891 73.5643 60.7511 73.6256 59.2312C73.6991 57.6377 72.4243 56.3262 70.8431 56.3262H58.7083C58.7083 56.3262 58.6715 56.3016 58.696 56.2771C61.4172 53.8256 63.1087 52.5876 63.17 47.5376C63.1945 45.7725 62.1281 43.5049 60.3263 43.5049C57.5438 43.5049 57.4335 45.6499 57.409 47.5376C55.7665 52.9676 48.1669 52.9063 48.1669 61.6949V73.6827C48.1669 76.3793 50.3487 78.5611 53.0454 78.5611H68.7349C70.2058 78.5611 71.6276 77.3722 71.6889 75.9013C71.7624 74.3078 70.4877 72.9963 68.9065 72.9963H69.3722C70.8431 72.9963 72.265 71.8073 72.3263 70.3364C72.3998 68.7429 71.1251 67.4314 69.5438 67.4314H70.0096C71.4805 67.4314 72.9024 66.2424 72.9637 64.7715L72.9882 64.796ZM43.2394 56.3262H40.6654C39.1087 56.3262 37.8584 57.5887 37.8584 59.1331V75.7542C37.8584 77.3109 39.1209 78.5611 40.6654 78.5611H43.2394C44.7961 78.5611 46.0586 77.2986 46.0586 75.7542V59.1331C46.0586 57.5764 44.7961 56.3262 43.2394 56.3262Z"
            fill="#F1F1F1"
          />
          <path
            d="M72.9022 64.6603C72.9758 63.0668 71.701 61.7553 70.1198 61.7553H70.5856C72.1055 61.7553 73.4783 60.6153 73.5396 59.0954C73.6132 57.502 72.3384 56.1904 70.7572 56.1904H58.6223C58.6223 56.1904 58.5856 56.1659 58.6101 56.1414C61.3312 53.6899 63.0228 52.4519 63.0841 47.4018C63.1086 45.6368 62.0422 43.3691 60.2403 43.3691C57.4579 43.3691 57.3476 45.5142 57.3231 47.4018C55.6806 52.8319 48.081 52.7706 48.081 61.5592V73.5469C48.081 76.2436 50.2628 78.4254 52.9594 78.4254H68.6489C70.1198 78.4254 71.5417 77.2364 71.603 75.7655C71.6765 74.1721 70.4017 72.8605 68.8205 72.8605H69.2863C70.7572 72.8605 72.1791 71.6715 72.2404 70.2006C72.3139 68.6072 71.0391 67.2956 69.4579 67.2956H69.9237C71.3946 67.2956 72.8164 66.1067 72.8777 64.6358L72.9022 64.6603ZM43.1535 56.1904H40.5794C39.0227 56.1904 37.7725 57.4529 37.7725 59.0096V75.6307C37.7725 77.1874 39.035 78.4376 40.5794 78.4376H43.1535C44.7102 78.4376 45.9727 77.1751 45.9727 75.6307V59.0096C45.9727 57.4529 44.7102 56.1904 43.1535 56.1904Z"
            fill="#F0F0F0"
          />
          <path
            d="M72.8167 64.5255C72.8903 62.9321 71.6155 61.6205 70.0343 61.6205H70.5001C72.02 61.6205 73.3929 60.4806 73.4541 58.9607C73.5277 57.3672 72.2529 56.0556 70.6717 56.0556H58.5368C58.5368 56.0556 58.5001 56.0311 58.5246 56.0066C61.2457 53.5551 62.9373 52.3171 62.9986 47.2671C63.0231 45.502 61.9567 43.2344 60.1548 43.2344C57.3724 43.2344 57.2621 45.3794 57.2376 47.2671C55.5951 52.6971 47.9955 52.6358 47.9955 61.4244V73.4122C47.9955 76.1088 50.1773 78.2906 52.8739 78.2906H68.5634C70.0343 78.2906 71.4562 77.1017 71.5175 75.6308C71.591 74.0373 70.3162 72.7257 68.735 72.7257H69.2008C70.6717 72.7257 72.0936 71.5368 72.1549 70.0659C72.2284 68.4724 70.9536 67.1609 69.3724 67.1609H69.8382C71.3091 67.1609 72.731 65.9719 72.7922 64.501L72.8167 64.5255ZM43.0802 56.0679H40.5062C38.9495 56.0679 37.6992 57.3304 37.6992 58.8749V75.4959C37.6992 77.0526 38.9617 78.3029 40.5062 78.3029H43.0802C44.6369 78.3029 45.8994 77.0404 45.8994 75.4959V58.8749C45.8994 57.3182 44.6369 56.0679 43.0802 56.0679Z"
            fill="#EFEFEF"
          />
          <path
            d="M72.7308 64.4035C72.8044 62.81 71.5296 61.4984 69.9484 61.4984H70.4142C71.9341 61.4984 73.3069 60.3585 73.3682 58.8386C73.4417 57.2451 72.167 55.9336 70.5858 55.9336H58.4509C58.4509 55.9336 58.4141 55.9091 58.4387 55.8845C61.1598 53.4331 62.8513 52.1951 62.9126 47.145C62.9371 45.3799 61.8707 43.1123 60.0689 43.1123C57.2865 43.1123 57.1761 45.2574 57.1516 47.145C55.5091 52.575 47.9095 52.5137 47.9095 61.3023V73.2901C47.9095 75.9867 50.0913 78.1685 52.788 78.1685H68.4775C69.9484 78.1685 71.3702 76.9796 71.4315 75.5087C71.5051 73.9152 70.2303 72.6037 68.6491 72.6037H69.1149C70.5858 72.6037 72.0076 71.4147 72.0689 69.9438C72.1425 68.3503 70.8677 67.0388 69.2865 67.0388H69.7523C71.2231 67.0388 72.645 65.8498 72.7063 64.3789L72.7308 64.4035ZM42.9943 55.9336H40.4202C38.8635 55.9336 37.6133 57.1961 37.6133 58.7405V75.3616C37.6133 76.9183 38.8758 78.1685 40.4202 78.1685H42.9943C44.551 78.1685 45.8135 76.906 45.8135 75.3616V58.7405C45.8135 57.1838 44.551 55.9336 42.9943 55.9336Z"
            fill="#EEEEEE"
          />
          <path
            d="M72.6571 64.2677C72.7307 62.6742 71.4559 61.3627 69.8747 61.3627H70.3405C71.8604 61.3627 73.2332 60.2228 73.2945 58.7028C73.3681 57.1094 72.0933 55.7978 70.5121 55.7978H58.3772C58.3772 55.7978 58.3405 55.7733 58.365 55.7488C61.0861 53.2973 62.7776 52.0593 62.8389 47.0093C62.8634 45.2442 61.797 42.9766 59.9952 42.9766C57.2128 42.9766 57.1025 45.1216 57.0779 47.0093C55.4354 52.4393 47.8358 52.378 47.8358 61.1666V73.1543C47.8358 75.851 50.0177 78.0328 52.7143 78.0328H68.4038C69.8747 78.0328 71.2966 76.8438 71.3578 75.3729C71.4314 73.7795 70.1566 72.4679 68.5754 72.4679H69.0412C70.5121 72.4679 71.9339 71.279 71.9952 69.8081C72.0688 68.2146 70.794 66.9031 69.2128 66.9031H69.6786C71.1495 66.9031 72.5713 65.7141 72.6326 64.2432L72.6571 64.2677ZM42.9084 55.8101H40.3343C38.7776 55.8101 37.5273 57.0726 37.5273 58.617V75.2381C37.5273 76.7948 38.7899 78.0451 40.3343 78.0451H42.9084C44.465 78.0451 45.7276 76.7826 45.7276 75.2381V58.617C45.7276 57.0604 44.465 55.8101 42.9084 55.8101Z"
            fill="#EDEDED"
          />
          <path
            d="M72.5716 64.1456C72.6452 62.5522 71.3704 61.2406 69.7892 61.2406H70.255C71.7749 61.2406 73.1477 60.1007 73.209 58.5808C73.2826 56.9873 72.0078 55.6758 70.4266 55.6758H58.2917C58.2917 55.6758 58.255 55.6512 58.2795 55.6267C61.0006 53.1752 62.6921 51.9372 62.7534 46.8872C62.778 45.1221 61.7116 42.8545 59.9097 42.8545C57.1273 42.8545 57.017 44.9995 56.9924 46.8872C55.35 52.3172 47.7503 52.2559 47.7503 61.0445V73.0323C47.7503 75.7289 49.9322 77.9107 52.6288 77.9107H68.3183C69.7892 77.9107 71.2111 76.7218 71.2724 75.2509C71.3459 73.6574 70.0711 72.3459 68.4899 72.3459H68.9557C70.4266 72.3459 71.8484 71.1569 71.9097 69.686C71.9833 68.0925 70.7085 66.781 69.1273 66.781H69.5931C71.064 66.781 72.4858 65.592 72.5471 64.1211L72.5716 64.1456ZM42.8351 55.6758H40.2611C38.7044 55.6758 37.4541 56.9383 37.4541 58.4827V75.1038C37.4541 76.6605 38.7166 77.923 40.2611 77.923H42.8351C44.3918 77.923 45.6543 76.6605 45.6543 75.1038V58.4827C45.6543 56.926 44.3918 55.6758 42.8351 55.6758Z"
            fill="#ECECEC"
          />
          <path
            d="M72.4857 64.0109C72.5592 62.4174 71.2845 61.1059 69.7033 61.1059H70.169C71.689 61.1059 73.0618 59.9659 73.1231 58.446C73.1966 56.8525 71.9219 55.541 70.3407 55.541H58.2058C58.2058 55.541 58.169 55.5165 58.1935 55.492C60.9147 53.0405 62.6062 51.8025 62.6675 46.7524C62.692 44.9874 61.6256 42.7197 59.8238 42.7197C57.0413 42.7197 56.931 44.8648 56.9065 46.7524C55.264 52.1825 47.6644 52.1212 47.6644 60.9097V72.8975C47.6644 75.5941 49.8462 77.776 52.5429 77.776H68.2324C69.7033 77.776 71.1251 76.587 71.1864 75.1161C71.26 73.5226 69.9852 72.2111 68.404 72.2111H68.8698C70.3407 72.2111 71.7625 71.0221 71.8238 69.5512C71.8973 67.9578 70.6226 66.6462 69.0414 66.6462H69.5071C70.978 66.6462 72.3999 65.4573 72.4612 63.9864L72.4857 64.0109ZM42.7492 55.541H40.1751C38.6184 55.541 37.3682 56.8035 37.3682 58.3479V74.969C37.3682 76.5257 38.6307 77.776 40.1751 77.776H42.7492C44.3059 77.776 45.5684 76.5135 45.5684 74.969V58.3479C45.5684 56.7913 44.3059 55.541 42.7492 55.541Z"
            fill="#EBEBEB"
          />
          <path
            d="M72.4125 63.8888C72.4861 62.2953 71.2113 60.9838 69.6301 60.9838H70.0959C71.6158 60.9838 72.9886 59.8439 73.0499 58.3239C73.1234 56.7305 71.8487 55.4189 70.2675 55.4189H58.1326C58.1326 55.4189 58.0958 55.3944 58.1203 55.3699C60.8415 52.9184 62.533 51.6804 62.5943 46.6304C62.6188 44.8653 61.5524 42.5977 59.7506 42.5977C56.9681 42.5977 56.8578 44.7427 56.8333 46.6304C55.1908 52.0604 47.5912 51.9991 47.5912 60.7877V72.7754C47.5912 75.4721 49.773 77.6539 52.4697 77.6539H68.1592C69.6301 77.6539 71.0519 76.4649 71.1132 74.994C71.1868 73.4006 69.912 72.089 68.3308 72.089H68.7966C70.2675 72.089 71.6893 70.9001 71.7506 69.4292C71.8242 67.8357 70.5494 66.5242 68.9682 66.5242H69.434C70.9048 66.5242 72.3267 65.3352 72.388 63.8643L72.4125 63.8888ZM42.6637 55.4189H40.0897C38.533 55.4189 37.2827 56.6814 37.2827 58.2259V74.847C37.2827 76.4037 38.5452 77.6539 40.0897 77.6539H42.6637C44.2204 77.6539 45.4829 76.3914 45.4829 74.847V58.2259C45.4829 56.6692 44.2204 55.4189 42.6637 55.4189Z"
            fill="#EAEAEA"
          />
          <path
            d="M72.3261 63.7531C72.3996 62.1596 71.1249 60.8481 69.5436 60.8481H70.0094C71.5293 60.8481 72.9022 59.7081 72.9635 58.1882C73.037 56.5947 71.7622 55.2832 70.181 55.2832H58.0462C58.0462 55.2832 58.0094 55.2587 58.0339 55.2342C60.7551 52.7827 62.4466 51.5447 62.5079 46.4946C62.5324 44.7295 61.466 42.4619 59.6642 42.4619C56.8817 42.4619 56.7714 44.607 56.7469 46.4946C55.1044 51.9246 47.5048 51.8634 47.5048 60.6519V72.6397C47.5048 75.3363 49.6866 77.5182 52.3832 77.5182H68.0728C69.5436 77.5182 70.9655 76.3292 71.0268 74.8583C71.1003 73.2648 69.8256 71.9533 68.2444 71.9533H68.7101C70.181 71.9533 71.6029 70.7643 71.6642 69.2934C71.7377 67.7 70.4629 66.3884 68.8817 66.3884H69.3475C70.8184 66.3884 72.2403 65.1994 72.3016 63.7286L72.3261 63.7531ZM42.5773 55.2832H40.0032C38.4465 55.2832 37.1963 56.5457 37.1963 58.1024V74.7235C37.1963 76.2802 38.4588 77.5304 40.0032 77.5304H42.5773C44.134 77.5304 45.3965 76.2679 45.3965 74.7235V58.1024C45.3965 56.5457 44.134 55.2832 42.5773 55.2832Z"
            fill="#E9E9E9"
          />
          <path
            d="M72.2411 63.6183C72.3146 62.0248 71.0398 60.7133 69.4586 60.7133H69.9244C71.4443 60.7133 72.8172 59.5733 72.8785 58.0534C72.952 56.46 71.6772 55.1484 70.096 55.1484H57.9612C57.9612 55.1484 57.9244 55.1239 57.9489 55.0994C60.6701 52.6479 62.3616 51.4099 62.4229 46.3598C62.4474 44.5948 61.381 42.3271 59.5791 42.3271C56.7967 42.3271 56.6864 44.4722 56.6619 46.3598C55.0194 51.7899 47.4198 51.7286 47.4198 60.5172V72.5049C47.4198 75.2016 49.6016 77.3834 52.2982 77.3834H67.9877C69.4586 77.3834 70.8805 76.1944 70.9418 74.7235C71.0153 73.1301 69.7406 71.8185 68.1594 71.8185H68.6251C70.096 71.8185 71.5179 70.6295 71.5792 69.1587C71.6527 67.5652 70.3779 66.2536 68.7967 66.2536H69.2625C70.7334 66.2536 72.1553 65.0647 72.2166 63.5938L72.2411 63.6183ZM42.5046 55.1607H39.9305C38.3738 55.1607 37.1235 56.4232 37.1235 57.9676V74.5887C37.1235 76.1454 38.3861 77.3957 39.9305 77.3957H42.5046C44.0612 77.3957 45.3238 76.1331 45.3238 74.5887V57.9676C45.3238 56.4109 44.0612 55.1607 42.5046 55.1607Z"
            fill="#E8E8E8"
          />
          <path
            d="M72.1669 63.4962C72.2404 61.9028 70.9657 60.5912 69.3845 60.5912H69.8502C71.3702 60.5912 72.743 59.4513 72.8043 57.9314C72.8778 56.3379 71.6031 55.0264 70.0219 55.0264H57.887C57.887 55.0264 57.8502 55.0018 57.8747 54.9773C60.5959 52.5258 62.2874 51.2878 62.3487 46.2378C62.3732 44.4727 61.3068 42.2051 59.505 42.2051C56.7225 42.2051 56.6122 44.3501 56.5877 46.2378C54.9452 51.6678 47.3456 51.6065 47.3456 60.3951V72.3829C47.3456 75.0795 49.5274 77.2613 52.2241 77.2613H67.9136C69.3845 77.2613 70.8063 76.0724 70.8676 74.6015C70.9412 73.008 69.6664 71.6965 68.0852 71.6965H68.551C70.0219 71.6965 71.4437 70.5075 71.505 69.0366C71.5785 67.4431 70.3038 66.1316 68.7226 66.1316H69.1883C70.6592 66.1316 72.0811 64.9426 72.1424 63.4717L72.1669 63.4962ZM42.4181 55.0264H39.8441C38.2874 55.0264 37.0371 56.2889 37.0371 57.8333V74.4544C37.0371 76.0111 38.2996 77.2613 39.8441 77.2613H42.4181C43.9748 77.2613 45.2373 75.9988 45.2373 74.4544V57.8333C45.2373 56.2766 43.9748 55.0264 42.4181 55.0264Z"
            fill="#E7E7E7"
          />
          <path
            d="M72.081 63.3605C72.1546 61.767 70.8798 60.4555 69.2986 60.4555H69.7644C71.2843 60.4555 72.6571 59.3155 72.7184 57.7956C72.7919 56.2021 71.5172 54.8906 69.936 54.8906H57.8011C57.8011 54.8906 57.7643 54.8661 57.7888 54.8416C60.51 52.3901 62.2015 51.1521 62.2628 46.102C62.2873 44.337 61.2209 42.0693 59.4191 42.0693C56.6367 42.0693 56.5263 44.2144 56.5018 46.102C54.8593 51.5321 47.2597 51.4708 47.2597 60.2594V72.2471C47.2597 74.9438 49.4415 77.1256 52.1382 77.1256H67.8277C69.2986 77.1256 70.7204 75.9366 70.7817 74.4657C70.8553 72.8722 69.5805 71.5607 67.9993 71.5607H68.4651C69.936 71.5607 71.3578 70.3717 71.4191 68.9008C71.4927 67.3074 70.2179 65.9958 68.6367 65.9958H69.1025C70.5733 65.9958 71.9952 64.8069 72.0565 63.336L72.081 63.3605ZM42.3322 54.8906H39.7582C38.2015 54.8906 36.939 56.1531 36.939 57.6976V74.3186C36.939 75.8753 38.2015 77.1256 39.7582 77.1256H42.3322C43.8889 77.1256 45.1514 75.8631 45.1514 74.3186V57.6976C45.1514 56.1409 43.8889 54.8906 42.3322 54.8906Z"
            fill="#E6E6E6"
          />
          <path
            d="M71.995 63.2384C72.0686 61.645 70.7938 60.3334 69.2126 60.3334H69.6784C71.1983 60.3334 72.5711 59.1935 72.6324 57.6735C72.706 56.0801 71.4312 54.7685 69.85 54.7685H57.7151C57.7151 54.7685 57.6783 54.744 57.7029 54.7195C60.424 52.268 62.1155 51.03 62.1768 45.98C62.2013 44.2149 61.1349 41.9473 59.3331 41.9473C56.5507 41.9473 56.4403 44.0923 56.4158 45.98C54.7733 51.41 47.1737 51.3487 47.1737 60.1373V72.1251C47.1737 74.8217 49.3556 77.0035 52.0522 77.0035H67.7417C69.2126 77.0035 70.6345 75.8145 70.6957 74.3437C70.7693 72.7502 69.4945 71.4386 67.9133 71.4386H68.3791C69.85 71.4386 71.2718 70.2497 71.3331 68.7788C71.4067 67.1853 70.1319 65.8738 68.5507 65.8738H69.0165C70.4874 65.8738 71.9092 64.6848 71.9705 63.2139L71.995 63.2384ZM42.2585 54.7685H39.6844C38.1277 54.7685 36.8652 56.0311 36.8652 57.5755V74.1966C36.8652 75.7533 38.1277 77.0035 39.6844 77.0035H42.2585C43.8152 77.0035 45.0777 75.741 45.0777 74.1966V57.5755C45.0777 56.0188 43.8152 54.7685 42.2585 54.7685Z"
            fill="#E5E5E5"
          />
          <path
            d="M71.9096 63.1037C71.9831 61.5102 70.7084 60.1986 69.1271 60.1986H69.5929C71.1128 60.1986 72.4857 59.0587 72.547 57.5388C72.6205 55.9453 71.3457 54.6338 69.7645 54.6338H57.6297C57.6297 54.6338 57.5929 54.6093 57.6174 54.5847C60.3386 52.1333 62.0301 50.8953 62.0914 45.8452C62.1159 44.0801 61.0495 41.8125 59.2477 41.8125C56.4652 41.8125 56.3549 43.9576 56.3304 45.8452C54.6879 51.2752 47.0883 51.214 47.0883 60.0025V71.9903C47.0883 74.6869 49.2701 76.8688 51.9667 76.8688H67.6562C69.1271 76.8688 70.549 75.6798 70.6103 74.2089C70.6838 72.6154 69.4091 71.3039 67.8279 71.3039H68.2936C69.7645 71.3039 71.1864 70.1149 71.2477 68.644C71.3212 67.0505 70.0465 65.739 68.4652 65.739H68.931C70.4019 65.739 71.8238 64.55 71.8851 63.0791L71.9096 63.1037ZM42.1731 54.6338H39.599C38.0423 54.6338 36.7798 55.8963 36.7798 57.4407V74.0618C36.7798 75.6185 38.0423 76.8688 39.599 76.8688H42.1731C43.7297 76.8688 44.98 75.6062 44.98 74.0618V57.4407C44.98 55.884 43.7175 54.6338 42.1731 54.6338Z"
            fill="#E4E4E4"
          />
          <path
            d="M71.8364 62.9689C71.9099 61.3754 70.6352 60.0639 69.0539 60.0639H69.5197C71.0397 60.0639 72.4125 58.9239 72.4738 57.404C72.5473 55.8106 71.2725 54.499 69.6913 54.499H57.5565C57.5565 54.499 57.5197 54.4745 57.5442 54.45C60.2654 51.9985 61.9569 50.7605 62.0182 45.7104C62.0427 43.9454 60.9763 41.6777 59.1745 41.6777C56.392 41.6777 56.2817 43.8228 56.2572 45.7104C54.6147 51.1405 47.0151 51.0792 47.0151 59.8678V71.8555C47.0151 74.5522 49.1969 76.734 51.8935 76.734H67.5831C69.054 76.734 70.4758 75.545 70.5371 74.0741C70.6106 72.4807 69.3359 71.1691 67.7547 71.1691H68.2204C69.6913 71.1691 71.1132 69.9801 71.1745 68.5093C71.248 66.9158 69.9733 65.6042 68.392 65.6042H68.8578C70.3287 65.6042 71.7506 64.4153 71.8119 62.9444L71.8364 62.9689ZM42.0876 54.5113H39.5135C37.9569 54.5113 36.6943 55.7738 36.6943 57.3182V73.9393C36.6943 75.496 37.9569 76.7462 39.5135 76.7462H42.0876C43.6443 76.7462 44.8946 75.4837 44.8946 73.9393V57.3182C44.8946 55.7615 43.632 54.5113 42.0876 54.5113Z"
            fill="#E3E3E3"
          />
          <path
            d="M71.7504 62.8458C71.8239 61.2524 70.5492 59.9408 68.968 59.9408H69.4337C70.9537 59.9408 72.3265 58.8009 72.3878 57.281C72.4613 55.6875 71.1866 54.376 69.6054 54.376H57.4705C57.4705 54.376 57.4337 54.3514 57.4582 54.3269C60.1794 51.8754 61.8709 50.6374 61.9322 45.5874C61.9567 43.8223 60.8903 41.5547 59.0885 41.5547C56.306 41.5547 56.1957 43.6997 56.1712 45.5874C54.5287 51.0174 46.9291 50.9561 46.9291 59.7447V71.7325C46.9291 74.4291 49.1109 76.6109 51.8076 76.6109H67.4971C68.968 76.6109 70.3898 75.422 70.4511 73.9511C70.5247 72.3576 69.2499 71.0461 67.6687 71.0461H68.1345C69.6053 71.0461 71.0272 69.8571 71.0885 68.3862C71.162 66.7927 69.8873 65.4812 68.3061 65.4812H68.7718C70.2427 65.4812 71.6646 64.2922 71.7259 62.8213L71.7504 62.8458ZM42.0139 54.376H39.4398C37.8831 54.376 36.6206 55.6385 36.6206 57.1829V73.804C36.6206 75.3607 37.8831 76.6109 39.4398 76.6109H42.0139C43.5706 76.6109 44.8208 75.3484 44.8208 73.804V57.1829C44.8208 55.6262 43.5583 54.376 42.0139 54.376Z"
            fill="#E3E2E2"
          />
          <path
            d="M71.665 62.7121C71.7385 61.1186 70.4637 59.807 68.8825 59.807H69.3483C70.8682 59.807 72.241 58.6671 72.3023 57.1472C72.3759 55.5537 71.1011 54.2422 69.5199 54.2422H57.385C57.385 54.2422 57.3483 54.2177 57.3728 54.1931C60.0939 51.7417 61.7855 50.5037 61.8467 45.4536C61.8713 43.6885 60.8049 41.4209 59.003 41.4209C56.2206 41.4209 56.1103 43.5659 56.0858 45.4536C54.4433 50.8836 46.8437 50.8224 46.8437 59.6109V71.5987C46.8437 74.2953 49.0255 76.4771 51.7221 76.4771H67.4116C68.8825 76.4771 70.3044 75.2882 70.3657 73.8173C70.4392 72.2238 69.1644 70.9123 67.5832 70.9123H68.049C69.5199 70.9123 70.9418 69.7233 71.003 68.2524C71.0766 66.6589 69.8018 65.3474 68.2206 65.3474H68.6864C70.1573 65.3474 71.5791 64.1584 71.6404 62.6875L71.665 62.7121ZM41.9284 54.2544H39.3544C37.7977 54.2544 36.5352 55.5169 36.5352 57.0614V73.6824C36.5352 75.2391 37.7977 76.4894 39.3544 76.4894H41.9284C43.4851 76.4894 44.7354 75.2269 44.7354 73.6824V57.0614C44.7354 55.5047 43.4729 54.2544 41.9284 54.2544Z"
            fill="#E2E1E1"
          />
          <path
            d="M71.5908 62.589C71.6643 60.9955 70.3895 59.684 68.8083 59.684H69.2741C70.794 59.684 72.1669 58.5441 72.2282 57.0241C72.3017 55.4307 71.0269 54.1191 69.4457 54.1191H57.3109C57.3109 54.1191 57.2741 54.0946 57.2986 54.0701C60.0198 51.6186 61.7113 50.3806 61.7726 45.3305C61.7971 43.5655 60.7307 41.2979 58.9289 41.2979C56.1464 41.2979 56.0361 43.4429 56.0116 45.3305C54.3691 50.7606 46.7695 50.6993 46.7695 59.4879V71.4756C46.7695 74.1723 48.9513 76.3541 51.6479 76.3541H67.3375C68.8083 76.3541 70.2302 75.1651 70.2915 73.6942C70.365 72.1008 69.0903 70.7892 67.5091 70.7892H67.9748C69.4457 70.7892 70.8676 69.6003 70.9289 68.1294C71.0024 66.5359 69.7276 65.2244 68.1464 65.2244H68.6122C70.0831 65.2244 71.505 64.0354 71.5663 62.5645L71.5908 62.589ZM41.842 54.1191H39.2679C37.7112 54.1191 36.4487 55.3816 36.4487 56.9261V73.5471C36.4487 75.1038 37.7112 76.3541 39.2679 76.3541H41.842C43.3987 76.3541 44.649 75.0916 44.649 73.5471V56.9261C44.649 55.3694 43.3864 54.1191 41.842 54.1191Z"
            fill="#E1E0E0"
          />
          <path
            d="M71.5048 62.4542C71.5783 60.8608 70.3036 59.5492 68.7224 59.5492H69.1881C70.7081 59.5492 72.0809 58.4093 72.1422 56.8894C72.2157 55.2959 70.9409 53.9844 69.3597 53.9844H57.2249C57.2249 53.9844 57.1881 53.9598 57.2126 53.9353C59.9338 51.4838 61.6253 50.2458 61.6866 45.1958C61.7111 43.4307 60.6447 41.1631 58.8429 41.1631C56.0604 41.1631 55.9501 43.3081 55.9256 45.1958C54.2831 50.6258 46.6835 50.5645 46.6835 59.3531V71.3409C46.6835 74.0375 48.8653 76.2193 51.562 76.2193H67.2515C68.7223 76.2193 70.1442 75.0304 70.2055 73.5595C70.279 71.966 69.0043 70.6545 67.4231 70.6545H67.8888C69.3597 70.6545 70.7816 69.4655 70.8429 67.9946C70.9164 66.4011 69.6417 65.0896 68.0604 65.0896H68.5262C69.9971 65.0896 71.419 63.9006 71.4803 62.4297L71.5048 62.4542ZM41.7683 53.9844H39.1942C37.6375 53.9844 36.375 55.2469 36.375 56.7913V73.4124C36.375 74.9691 37.6375 76.2193 39.1942 76.2193H41.7683C43.325 76.2193 44.5752 74.9568 44.5752 73.4124V56.7913C44.5752 55.2346 43.3127 53.9844 41.7683 53.9844Z"
            fill="#E0DFDF"
          />
          <path
            d="M71.4193 62.3312C71.4929 60.7377 70.2181 59.4262 68.6369 59.4262H69.1027C70.6226 59.4262 71.9954 58.2862 72.0567 56.7663C72.1303 55.1729 70.8555 53.8613 69.2743 53.8613H57.1394C57.1394 53.8613 57.1027 53.8368 57.1272 53.8123C59.8483 51.3608 61.5399 50.1228 61.6011 45.0727C61.6257 43.3077 60.5593 41.04 58.7574 41.04C55.975 41.04 55.8647 43.1851 55.8401 45.0727C54.1976 50.5028 46.598 50.4415 46.598 59.2301V71.2178C46.598 73.9145 48.7799 76.0963 51.4765 76.0963H67.166C68.6369 76.0963 70.0588 74.9073 70.1201 73.4364C70.1936 71.843 68.9188 70.5314 67.3376 70.5314H67.8034C69.2743 70.5314 70.6961 69.3424 70.7574 67.8715C70.831 66.2781 69.5562 64.9665 67.975 64.9665H68.4408C69.9117 64.9665 71.3335 63.7776 71.3948 62.3067L71.4193 62.3312ZM41.6828 53.8613H39.1088C37.5521 53.8613 36.2896 55.1238 36.2896 56.6683V73.2893C36.2896 74.846 37.5521 76.0963 39.1088 76.0963H41.6828C43.2395 76.0963 44.4898 74.8338 44.4898 73.2893V56.6683C44.4898 55.1116 43.2273 53.8613 41.6828 53.8613Z"
            fill="#DFDEDE"
          />
          <path
            d="M71.3462 62.1974C71.4197 60.6039 70.1449 59.2924 68.5637 59.2924H69.0295C70.5494 59.2924 71.9222 58.1525 71.9835 56.6325C72.0571 55.0391 70.7823 53.7275 69.2011 53.7275H57.0662C57.0662 53.7275 57.0295 53.703 57.054 53.6785C59.7751 51.227 61.4667 49.989 61.528 44.9389C61.5525 43.1739 60.4861 40.9062 58.6842 40.9062C55.9018 40.9062 55.7915 43.0513 55.767 44.9389C54.1245 50.369 46.5249 50.3077 46.5249 59.0963V71.084C46.5249 73.7807 48.7067 75.9625 51.4033 75.9625H67.0928C68.5637 75.9625 69.9856 74.7735 70.0469 73.3026C70.1204 71.7092 68.8456 70.3976 67.2644 70.3976H67.7302C69.2011 70.3976 70.623 69.2087 70.6842 67.7378C70.7578 66.1443 69.483 64.8328 67.9018 64.8328H68.3676C69.8385 64.8328 71.2604 63.6438 71.3216 62.1729L71.3462 62.1974ZM41.5974 53.7275H39.0233C37.4666 53.7275 36.2041 54.99 36.2041 56.5345V73.1555C36.2041 74.7122 37.4666 75.9625 39.0233 75.9625H41.5974C43.1541 75.9625 44.4043 74.7 44.4043 73.1555V56.5345C44.4043 54.9778 43.1418 53.7275 41.5974 53.7275Z"
            fill="#DEDDDD"
          />
          <path
            d="M71.2597 62.0617C71.3333 60.4682 70.0585 59.1567 68.4773 59.1567H68.9431C70.463 59.1567 71.8358 58.0167 71.8971 56.4968C71.9707 54.9033 70.6959 53.5918 69.1147 53.5918H56.9798C56.9798 53.5918 56.9431 53.5673 56.9676 53.5427C59.6887 51.0913 61.3802 49.8533 61.4415 44.8032C61.466 43.0381 60.3996 40.7705 58.5978 40.7705C55.8154 40.7705 55.7051 42.9156 55.6805 44.8032C54.038 50.2332 46.4384 50.172 46.4384 58.9605V70.9483C46.4384 73.6449 48.6203 75.8268 51.3169 75.8268H67.0064C68.4773 75.8268 69.8992 74.6378 69.9604 73.1669C70.034 71.5734 68.7592 70.2619 67.178 70.2619H67.6438C69.1147 70.2619 70.5365 69.0729 70.5978 67.602C70.6714 66.0086 69.3966 64.697 67.8154 64.697H68.2812C69.7521 64.697 71.1739 63.508 71.2352 62.0371L71.2597 62.0617ZM41.5109 53.604H38.9369C37.3802 53.604 36.1177 54.8666 36.1177 56.411V73.0321C36.1177 74.5888 37.3802 75.8513 38.9369 75.8513H41.5109C43.0676 75.8513 44.3179 74.5888 44.3179 73.0321V56.411C44.3179 54.8543 43.0554 53.604 41.5109 53.604Z"
            fill="#DDDCDC"
          />
          <path
            d="M71.1742 61.9396C71.2478 60.3461 69.973 59.0346 68.3918 59.0346H68.8576C70.3775 59.0346 71.7503 57.8946 71.8116 56.3747C71.8852 54.7813 70.6104 53.4697 69.0292 53.4697H56.8943C56.8943 53.4697 56.8575 53.4452 56.8821 53.4207C59.6032 50.9692 61.2947 49.7312 61.356 44.6811C61.3805 42.9161 60.3141 40.6484 58.5123 40.6484C55.7299 40.6484 55.6195 42.7935 55.595 44.6811C53.9525 50.1112 46.3529 50.0499 46.3529 58.8385V70.8262C46.3529 73.5229 48.5348 75.7047 51.2314 75.7047H66.9209C68.3918 75.7047 69.8137 74.5157 69.8749 73.0448C69.9485 71.4514 68.6737 70.1398 67.0925 70.1398H67.5583C69.0292 70.1398 70.451 68.9508 70.5123 67.4799C70.5859 65.8865 69.3111 64.5749 67.7299 64.5749H68.1957C69.6666 64.5749 71.0884 63.386 71.1497 61.9151L71.1742 61.9396ZM41.4377 53.4697H38.8636C37.3069 53.4697 36.0444 54.7322 36.0444 56.2767V72.8977C36.0444 74.4544 37.3069 75.7047 38.8636 75.7047H41.4377C42.9944 75.7047 44.2447 74.4422 44.2447 72.8977V56.2767C44.2447 54.72 42.9821 53.4697 41.4377 53.4697Z"
            fill="#DCDBDB"
          />
          <path
            d="M71.0883 61.8048C71.1618 60.2114 69.8871 58.8998 68.3058 58.8998H68.7716C70.2916 58.8998 71.6644 57.7599 71.7257 56.24C71.7992 54.6465 70.5244 53.3349 68.9432 53.3349H56.8084C56.8084 53.3349 56.7716 53.3104 56.7961 53.2859C59.5173 50.8344 61.2088 49.5964 61.2701 44.5464C61.2946 42.7813 60.2282 40.5137 58.4264 40.5137C55.6439 40.5137 55.5336 42.6587 55.5091 44.5464C53.8666 49.9764 46.267 49.9151 46.267 58.7037V70.6915C46.267 73.3881 48.4488 75.5699 51.1454 75.5699H66.835C68.3058 75.5699 69.7277 74.3809 69.789 72.9101C69.8625 71.3166 68.5878 70.005 67.0066 70.005H67.4723C68.9432 70.005 70.3651 68.8161 70.4264 67.3452C70.4999 65.7517 69.2252 64.4402 67.644 64.4402H68.1097C69.5806 64.4402 71.0025 63.2512 71.0638 61.7803L71.0883 61.8048ZM41.3518 53.3349H38.7777C37.221 53.3349 35.9585 54.5975 35.9585 56.1419V72.763C35.9585 74.3197 37.221 75.5699 38.7777 75.5699H41.3518C42.9085 75.5699 44.1587 74.3074 44.1587 72.763V56.1419C44.1587 54.5852 42.8962 53.3349 41.3518 53.3349Z"
            fill="#DBDADA"
          />
          <path
            d="M71.0151 61.6828C71.0886 60.0893 69.8139 58.7777 68.2327 58.7777H68.6984C70.2184 58.7777 71.5912 57.6378 71.6525 56.1179C71.726 54.5244 70.4513 53.2129 68.87 53.2129H56.7352C56.7352 53.2129 56.6984 53.1884 56.7229 53.1638C59.4441 50.7124 61.1356 49.4744 61.1969 44.4243C61.2214 42.6592 60.155 40.3916 58.3532 40.3916C55.5707 40.3916 55.4604 42.5367 55.4359 44.4243C53.7934 49.8543 46.1938 49.793 46.1938 58.5816V70.5694C46.1938 73.266 48.3756 75.4478 51.0723 75.4478H66.7618C68.2327 75.4478 69.6545 74.2589 69.7158 72.788C69.7893 71.1945 68.5146 69.883 66.9334 69.883H67.3992C68.87 69.883 70.2919 68.694 70.3532 67.2231C70.4267 65.6296 69.152 64.3181 67.5708 64.3181H68.0365C69.5074 64.3181 70.9293 63.1291 70.9906 61.6582L71.0151 61.6828ZM41.2663 53.2129H38.6923C37.1356 53.2129 35.873 54.4754 35.873 56.0321V72.6532C35.873 74.2098 37.1356 75.4601 38.6923 75.4601H41.2663C42.823 75.4601 44.0733 74.1976 44.0733 72.6532V56.0321C44.0733 54.4754 42.8107 53.2129 41.2663 53.2129Z"
            fill="#DAD9D9"
          />
          <path
            d="M70.9291 61.547C71.0026 59.9536 69.7279 58.642 68.1467 58.642H68.6124C70.1324 58.642 71.5052 57.5021 71.5665 55.9821C71.64 54.3887 70.3653 53.0771 68.784 53.0771H56.6492C56.6492 53.0771 56.6124 53.0526 56.6369 53.0281C59.3581 50.5766 61.0496 49.3386 61.1109 44.2886C61.1354 42.5235 60.069 40.2559 58.2672 40.2559C55.4847 40.2559 55.3744 42.4009 55.3499 44.2886C53.7074 49.7186 46.1078 49.6573 46.1078 58.4459V70.4337C46.1078 73.1303 48.2896 75.3121 50.9863 75.3121H66.6758C68.1467 75.3121 69.5685 74.1231 69.6298 72.6522C69.7034 71.0588 68.4286 69.7472 66.8474 69.7472H67.3132C68.7841 69.7472 70.2059 68.5583 70.2672 67.0874C70.3408 65.4939 69.066 64.1824 67.4848 64.1824H67.9506C69.4214 64.1824 70.8433 62.9934 70.9046 61.5225L70.9291 61.547ZM41.1926 53.0771H38.6185C37.0618 53.0771 35.7993 54.3396 35.7993 55.8841V72.5051C35.7993 74.0618 37.0618 75.3121 38.6185 75.3121H41.1926C42.7493 75.3121 43.9995 74.0496 43.9995 72.5051V55.8841C43.9995 54.3274 42.737 53.0771 41.1926 53.0771Z"
            fill="#D9D8D8"
          />
          <path
            d="M70.8432 61.4123C70.9167 59.8188 69.6419 58.5072 68.0607 58.5072H68.5265C70.0464 58.5072 71.4193 57.3673 71.4806 55.8474C71.5541 54.2539 70.2793 52.9424 68.6981 52.9424H56.5633C56.5633 52.9424 56.5265 52.9178 56.551 52.8933C59.2722 50.4418 60.9637 49.2038 61.025 44.1538C61.0495 42.3887 59.9831 40.1211 58.1812 40.1211C55.3988 40.1211 55.2885 42.2661 55.264 44.1538C53.6215 49.5838 46.0219 49.5225 46.0219 58.3111V70.2989C46.0219 72.9955 48.2037 75.1773 50.9003 75.1773H66.5898C68.0607 75.1773 69.4826 73.9884 69.5439 72.5175C69.6174 70.924 68.3427 69.6125 66.7614 69.6125H67.2272C68.6981 69.6125 70.12 68.4235 70.1813 66.9526C70.2548 65.3591 68.98 64.0476 67.3988 64.0476H67.8646C69.3355 64.0476 70.7574 62.8586 70.8187 61.3877L70.8432 61.4123ZM41.1066 52.9546H38.5326C36.9759 52.9546 35.7134 54.2171 35.7134 55.7616V72.3826C35.7134 73.9393 36.9759 75.1896 38.5326 75.1896H41.1066C42.6633 75.1896 43.9136 73.9271 43.9136 72.3826V55.7616C43.9136 54.2049 42.6511 52.9546 41.1066 52.9546Z"
            fill="#D8D7D7"
          />
          <path
            d="M70.7695 61.2902C70.843 59.6967 69.5683 58.3852 67.9871 58.3852H68.4528C69.9728 58.3852 71.3456 57.2452 71.4069 55.7253C71.4804 54.1318 70.2056 52.8203 68.6244 52.8203H56.4896C56.4896 52.8203 56.4528 52.7958 56.4773 52.7713C59.1985 50.3198 60.89 49.0818 60.9513 44.0317C60.9758 42.2666 59.9094 39.999 58.1076 39.999C55.3251 39.999 55.2148 42.1441 55.1903 44.0317C53.5478 49.4618 45.9482 49.4005 45.9482 58.189V70.1768C45.9482 72.8734 48.13 75.0553 50.8266 75.0553H66.5162C67.987 75.0553 69.4089 73.8663 69.4702 72.3954C69.5437 70.8019 68.269 69.4904 66.6878 69.4904H67.1535C68.6244 69.4904 70.0463 68.3014 70.1076 66.8305C70.1811 65.2371 68.9064 63.9255 67.3252 63.9255H67.7909C69.2618 63.9255 70.6837 62.7366 70.745 61.2657L70.7695 61.2902ZM41.0207 52.8203H38.4466C36.89 52.8203 35.6274 54.0828 35.6274 55.6272V72.2483C35.6274 73.805 36.89 75.0553 38.4466 75.0553H41.0207C42.5774 75.0553 43.8277 73.7928 43.8277 72.2483V55.6272C43.8277 54.0706 42.5651 52.8203 41.0207 52.8203Z"
            fill="#D7D6D6"
          />
          <path
            d="M70.684 61.1544C70.7575 59.561 69.4828 58.2494 67.9016 58.2494H68.3673C69.8873 58.2494 71.2601 57.1095 71.3214 55.5896C71.3949 53.9961 70.1202 52.6846 68.5389 52.6846H56.4041C56.4041 52.6846 56.3673 52.66 56.3918 52.6355C59.113 50.184 60.8045 48.946 60.8658 43.896C60.8903 42.1309 59.8239 39.8633 58.0221 39.8633C55.2396 39.8633 55.1293 42.0083 55.1048 43.896C53.4623 49.326 45.8627 49.2647 45.8627 58.0533V70.0411C45.8627 72.7377 48.0445 74.9195 50.7412 74.9195H66.4307C67.9016 74.9195 69.3234 73.7306 69.3847 72.2597C69.4583 70.6662 68.1835 69.3547 66.6023 69.3547H67.0681C68.5389 69.3547 69.9608 68.1657 70.0221 66.6948C70.0956 65.1013 68.8209 63.7898 67.2397 63.7898H67.7054C69.1763 63.7898 70.5982 62.6008 70.6595 61.1299L70.684 61.1544ZM40.9475 52.6968H38.3734C36.8167 52.6968 35.5542 53.9593 35.5542 55.5038V72.1248C35.5542 73.6815 36.8167 74.944 38.3734 74.944H40.9475C42.5042 74.944 43.7544 73.6815 43.7544 72.1248V55.5038C43.7544 53.9471 42.4919 52.6968 40.9475 52.6968Z"
            fill="#D6D5D5"
          />
          <path
            d="M70.598 61.0324C70.6716 59.4389 69.3968 58.1274 67.8156 58.1274H68.2814C69.8013 58.1274 71.1741 56.9874 71.2354 55.4675C71.309 53.874 70.0342 52.5625 68.453 52.5625H56.3181C56.3181 52.5625 56.2814 52.538 56.3059 52.5134C59.027 50.062 60.7186 48.824 60.7799 43.7739C60.8044 42.0088 59.738 39.7412 57.9361 39.7412C55.1537 39.7412 55.0434 41.8863 55.0189 43.7739C53.3764 49.2039 45.7768 49.1427 45.7768 57.9312V69.919C45.7768 72.6156 47.9586 74.7975 50.6552 74.7975H66.3447C67.8156 74.7975 69.2375 73.6085 69.2988 72.1376C69.3723 70.5441 68.0975 69.2326 66.5163 69.2326H66.9821C68.453 69.2326 69.8749 68.0436 69.9361 66.5727C70.0097 64.9793 68.7349 63.6677 67.1537 63.6677H67.6195C69.0904 63.6677 70.5123 62.4787 70.5735 61.0078L70.598 61.0324ZM40.8615 52.5625H38.2875C36.7308 52.5625 35.4683 53.825 35.4683 55.3694V71.9905C35.4683 73.5472 36.7308 74.7975 38.2875 74.7975H40.8615C42.4182 74.7975 43.6685 73.5349 43.6685 71.9905V55.3694C43.6685 53.8127 42.406 52.5625 40.8615 52.5625Z"
            fill="#D5D4D4"
          />
          <path
            d="M70.5248 60.8976C70.5984 59.3041 69.3236 57.9926 67.7424 57.9926H68.2082C69.7281 57.9926 71.1009 56.8526 71.1622 55.3327C71.2357 53.7393 69.961 52.4277 68.3798 52.4277H56.2449C56.2449 52.4277 56.2081 52.4032 56.2326 52.3787C58.9538 49.9272 60.6453 48.6892 60.7066 43.6391C60.7311 41.8741 59.6647 39.6064 57.8629 39.6064C55.0805 39.6064 54.9701 41.7515 54.9456 43.6391C53.3031 49.0692 45.7035 49.0079 45.7035 57.7965V69.7842C45.7035 72.4809 47.8853 74.6627 50.582 74.6627H66.2715C67.7424 74.6627 69.1642 73.4737 69.2255 72.0028C69.2991 70.4094 68.0243 69.0978 66.4431 69.0978H66.9089C68.3798 69.0978 69.8016 67.9088 69.8629 66.438C69.9365 64.8445 68.6617 63.5329 67.0805 63.5329H67.5463C69.0172 63.5329 70.439 62.344 70.5003 60.8731L70.5248 60.8976ZM40.776 52.4277H38.202C36.6453 52.4277 35.395 53.6902 35.395 55.2347V71.8557C35.395 73.4124 36.6575 74.6627 38.202 74.6627H40.776C42.3327 74.6627 43.583 73.4002 43.583 71.8557V55.2347C43.583 53.678 42.3205 52.4277 40.776 52.4277Z"
            fill="#D4D3D3"
          />
          <path
            d="M70.4389 60.7755C70.5124 59.1821 69.2376 57.8705 67.6564 57.8705H68.1222C69.6421 57.8705 71.015 56.7306 71.0763 55.2106C71.1498 53.6172 69.875 52.3056 68.2938 52.3056H56.159C56.159 52.3056 56.1222 52.2811 56.1467 52.2566C58.8679 49.8051 60.5594 48.5671 60.6207 43.5171C60.6452 41.752 59.5788 39.4844 57.777 39.4844C54.9945 39.4844 54.8842 41.6294 54.8597 43.5171C53.2172 48.9471 45.6176 48.8858 45.6176 57.6744V69.6622C45.6176 72.3588 47.7994 74.5406 50.496 74.5406H66.1855C67.6564 74.5406 69.0783 73.3517 69.1396 71.8808C69.2131 70.2873 67.9384 68.9757 66.3571 68.9757H66.8229C68.2938 68.9757 69.7157 67.7868 69.777 66.3159C69.8505 64.7224 68.5757 63.4109 66.9945 63.4109H67.4603C68.9312 63.4109 70.3531 62.2219 70.4144 60.751L70.4389 60.7755ZM40.6901 52.3056H38.116C36.5593 52.3056 35.3091 53.5682 35.3091 55.1126V71.7337C35.3091 73.2904 36.5716 74.5406 38.116 74.5406H40.6901C42.2468 74.5406 43.497 73.2781 43.497 71.7337V55.1126C43.497 53.5559 42.2345 52.3056 40.6901 52.3056Z"
            fill="#D3D2D2"
          />
          <path
            d="M70.3534 60.6398C70.4269 59.0463 69.1521 57.7348 67.5709 57.7348H68.0367C69.5566 57.7348 70.9295 56.5948 70.9908 55.0749C71.0643 53.4814 69.7895 52.1699 68.2083 52.1699H56.0735C56.0735 52.1699 56.0367 52.1454 56.0612 52.1209C58.7824 49.6694 60.4739 48.4314 60.5352 43.3813C60.5597 41.6163 59.4933 39.3486 57.6915 39.3486C54.909 39.3486 54.7987 41.4937 54.7742 43.3813C53.1317 48.8114 45.5321 48.7501 45.5321 57.5387V69.5264C45.5321 72.223 47.7139 74.4049 50.4105 74.4049H66.1C67.5709 74.4049 68.9928 73.2159 69.0541 71.745C69.1276 70.1516 67.8529 68.84 66.2717 68.84H66.7374C68.2083 68.84 69.6302 67.651 69.6915 66.1801C69.765 64.5867 68.4902 63.2751 66.909 63.2751H67.3748C68.8457 63.2751 70.2676 62.0862 70.3289 60.6153L70.3534 60.6398ZM40.6169 52.1699H38.0428C36.4861 52.1699 35.2358 53.4324 35.2358 54.9769V71.5979C35.2358 73.1546 36.4984 74.4049 38.0428 74.4049H40.6169C42.1735 74.4049 43.4238 73.1424 43.4238 71.5979V54.9769C43.4238 53.4202 42.1613 52.1699 40.6169 52.1699Z"
            fill="#D2D1D1"
          />
          <path
            d="M70.267 60.506C70.3405 58.9125 69.0657 57.601 67.4845 57.601H67.9503C69.4702 57.601 70.843 56.461 70.9043 54.9411C70.9779 53.3477 69.7031 52.0361 68.1219 52.0361H55.987C55.987 52.0361 55.9503 52.0116 55.9748 51.9871C58.6959 49.5356 60.3875 48.2976 60.4487 43.2475C60.4733 41.4825 59.4069 39.2148 57.605 39.2148C54.8226 39.2148 54.7123 41.3599 54.6878 43.2475C53.0453 48.6776 45.4457 48.6163 45.4457 57.4049V69.3926C45.4457 72.0893 47.6275 74.2711 50.3241 74.2711H66.0136C67.4845 74.2711 68.9064 73.0821 68.9677 71.6112C69.0412 70.0178 67.7664 68.7062 66.1852 68.7062H66.651C68.1219 68.7062 69.5438 67.5172 69.605 66.0464C69.6786 64.4529 68.4038 63.1413 66.8226 63.1413H67.2884C68.7593 63.1413 70.1811 61.9524 70.2424 60.4815L70.267 60.506ZM40.5304 52.0484H37.9564C36.3997 52.0484 35.1494 53.3109 35.1494 54.8553V71.4764C35.1494 73.0331 36.4119 74.2833 37.9564 74.2833H40.5304C42.0871 74.2833 43.3374 73.0208 43.3374 71.4764V54.8553C43.3374 53.2986 42.0749 52.0484 40.5304 52.0484Z"
            fill="#D1D0D0"
          />
          <path
            d="M70.1937 60.382C70.2673 58.7885 68.9925 57.477 67.4113 57.477H67.8771C69.397 57.477 70.7699 56.337 70.8311 54.8171C70.9047 53.2236 69.6299 51.9121 68.0487 51.9121H55.9138C55.9138 51.9121 55.8771 51.8876 55.9016 51.8631C58.6227 49.4116 60.3143 48.1736 60.3756 43.1235C60.4001 41.3584 59.3337 39.0908 57.5318 39.0908C54.7494 39.0908 54.6391 41.2359 54.6146 43.1235C52.9721 48.5535 45.3725 48.4923 45.3725 57.2808V69.2686C45.3725 71.9652 47.5543 74.1471 50.2509 74.1471H65.9404C67.4113 74.1471 68.8332 72.9581 68.8945 71.4872C68.968 69.8937 67.6932 68.5822 66.112 68.5822H66.5778C68.0487 68.5822 69.4706 67.3932 69.5318 65.9223C69.6054 64.3289 68.3306 63.0173 66.7494 63.0173H67.2152C68.6861 63.0173 70.108 61.8283 70.1692 60.3575L70.1937 60.382ZM40.445 51.9121H37.8709C36.3142 51.9121 35.064 53.1746 35.064 54.719V71.3401C35.064 72.8968 36.3265 74.1471 37.8709 74.1471H40.445C42.0017 74.1471 43.2519 72.8845 43.2519 71.3401V54.719C43.2519 53.1623 41.9894 51.9121 40.445 51.9121Z"
            fill="#D0CFCF"
          />
          <path
            d="M70.1078 60.2472C70.1813 58.6537 68.9065 57.3422 67.3253 57.3422H67.7911C69.311 57.3422 70.6839 56.2022 70.7452 54.6823C70.8187 53.0889 69.5439 51.7773 67.9627 51.7773H55.8279C55.8279 51.7773 55.7911 51.7528 55.8156 51.7283C58.5368 49.2768 60.2283 48.0388 60.2896 42.9887C60.3141 41.2237 59.2477 38.9561 57.4458 38.9561C54.6634 38.9561 54.5531 41.1011 54.5286 42.9887C52.8861 48.4188 45.2865 48.3575 45.2865 57.1461V69.1338C45.2865 71.8305 47.4683 74.0123 50.1649 74.0123H65.8544C67.3253 74.0123 68.7472 72.8233 68.8085 71.3524C68.882 69.759 67.6073 68.4474 66.026 68.4474H66.4918C67.9627 68.4474 69.3846 67.2585 69.4459 65.7876C69.5194 64.1941 68.2446 62.8826 66.6634 62.8826H67.1292C68.6001 62.8826 70.022 61.6936 70.0833 60.2227L70.1078 60.2472ZM40.3712 51.7773H37.7972C36.2405 51.7773 34.9902 53.0398 34.9902 54.5843V71.2053C34.9902 72.762 36.2528 74.0123 37.7972 74.0123H40.3712C41.9279 74.0123 43.1905 72.7498 43.1905 71.2053V54.5843C43.1905 53.0276 41.9279 51.7773 40.3712 51.7773Z"
            fill="#CFCECE"
          />
          <path
            d="M70.0223 60.1251C70.0959 58.5317 68.8211 57.2201 67.2399 57.2201H67.7057C69.2256 57.2201 70.5984 56.0802 70.6597 54.5603C70.7333 52.9668 69.4585 51.6553 67.8773 51.6553H55.7424C55.7424 51.6553 55.7056 51.6307 55.7302 51.6062C58.4513 49.1547 60.1428 47.9167 60.2041 42.8667C60.2286 41.1016 59.1622 38.834 57.3604 38.834C54.578 38.834 54.4676 40.979 54.4431 42.8667C52.8006 48.2967 45.201 48.2354 45.201 57.024V69.0118C45.201 71.7084 47.3828 73.8902 50.0795 73.8902H65.769C67.2399 73.8902 68.6617 72.7013 68.723 71.2304C68.7966 69.6369 67.5218 68.3254 65.9406 68.3254H66.4064C67.8773 68.3254 69.2991 67.1364 69.3604 65.6655C69.434 64.072 68.1592 62.7605 66.578 62.7605H67.0438C68.5147 62.7605 69.9365 61.5715 69.9978 60.1006L70.0223 60.1251ZM40.2858 51.6553H37.7117C36.155 51.6553 34.9048 52.9178 34.9048 54.4622V71.0833C34.9048 72.64 36.1673 73.8902 37.7117 73.8902H40.2858C41.8425 73.8902 43.105 72.6277 43.105 71.0833V54.4622C43.105 52.9055 41.8425 51.6553 40.2858 51.6553Z"
            fill="#CECDCD"
          />
          <path
            d="M69.9481 59.9894C70.0217 58.3959 68.7469 57.0844 67.1657 57.0844H67.6315C69.1514 57.0844 70.5242 55.9444 70.5855 54.4245C70.6591 52.831 69.3843 51.5195 67.8031 51.5195H55.6682C55.6682 51.5195 55.6315 51.495 55.656 51.4705C58.3771 49.019 60.0687 47.781 60.1299 42.7309C60.1545 40.9659 59.0881 38.6982 57.2862 38.6982C54.5038 38.6982 54.3935 40.8433 54.369 42.7309C52.7265 48.161 45.1269 48.0997 45.1269 56.8883V68.876C45.1269 71.5727 47.3087 73.7545 50.0053 73.7545H65.6948C67.1657 73.7545 68.5876 72.5655 68.6489 71.0946C68.7224 69.5012 67.4476 68.1896 65.8664 68.1896H66.3322C67.8031 68.1896 69.225 67.0006 69.2862 65.5298C69.3598 63.9363 68.085 62.6247 66.5038 62.6247H66.9696C68.4405 62.6247 69.8623 61.4358 69.9236 59.9649L69.9481 59.9894ZM40.1994 51.5195H37.6253C36.0686 51.5195 34.8184 52.782 34.8184 54.3265V70.9475C34.8184 72.5042 36.0809 73.7545 37.6253 73.7545H40.1994C41.7561 73.7545 43.0186 72.492 43.0186 70.9475V54.3265C43.0186 52.7698 41.7561 51.5195 40.1994 51.5195Z"
            fill="#CDCCCC"
          />
          <path
            d="M69.8627 59.8556C69.9362 58.2621 68.6615 56.9506 67.0803 56.9506H67.546C69.066 56.9506 70.4388 55.8106 70.5001 54.2907C70.5736 52.6973 69.2989 51.3857 67.7176 51.3857H55.5828C55.5828 51.3857 55.546 51.3612 55.5705 51.3367C58.2917 48.8852 59.9832 47.6472 60.0445 42.5971C60.069 40.8321 59.0026 38.5645 57.2008 38.5645C54.4183 38.5645 54.308 40.7095 54.2835 42.5971C52.641 48.0272 45.0414 47.9659 45.0414 56.7545V68.7422C45.0414 71.4389 47.2232 73.6207 49.9199 73.6207H65.6094C67.0803 73.6207 68.5021 72.4317 68.5634 70.9608C68.637 69.3674 67.3622 68.0558 65.781 68.0558H66.2468C67.7176 68.0558 69.1395 66.8669 69.2008 65.396C69.2743 63.8025 67.9996 62.4909 66.4184 62.4909H66.8841C68.355 62.4909 69.7769 61.302 69.8382 59.8311L69.8627 59.8556ZM40.1139 51.398H37.5399C35.9832 51.398 34.7329 52.6605 34.7329 54.2049V70.826C34.7329 72.3827 35.9954 73.633 37.5399 73.633H40.1139C41.6706 73.633 42.9331 72.3704 42.9331 70.826V54.2049C42.9331 52.6482 41.6706 51.398 40.1139 51.398Z"
            fill="#CCCBCB"
          />
          <path
            d="M69.7767 59.7326C69.8503 58.1391 68.5755 56.8276 66.9943 56.8276H67.4601C68.98 56.8276 70.3528 55.6876 70.4141 54.1677C70.4876 52.5742 69.2129 51.2627 67.6317 51.2627H55.4968C55.4968 51.2627 55.46 51.2382 55.4846 51.2137C58.2057 48.7622 59.8972 47.5242 59.9585 42.4741C59.983 40.709 58.9166 38.4414 57.1148 38.4414C54.3324 38.4414 54.222 40.5865 54.1975 42.4741C52.555 47.9041 44.9554 47.8429 44.9554 56.6314V68.6192C44.9554 71.3158 47.1372 73.4977 49.8339 73.4977H65.5234C66.9943 73.4977 68.4161 72.3087 68.4774 70.8378C68.551 69.2443 67.2762 67.9328 65.695 67.9328H66.1608C67.6317 67.9328 69.0535 66.7438 69.1148 65.2729C69.1884 63.6795 67.9136 62.3679 66.3324 62.3679H66.7982C68.2691 62.3679 69.6909 61.1789 69.7522 59.7081L69.7767 59.7326ZM40.0402 51.2627H37.4661C35.9094 51.2627 34.6592 52.5252 34.6592 54.0696V70.6907C34.6592 72.2474 35.9217 73.4977 37.4661 73.4977H40.0402C41.5969 73.4977 42.8594 72.2351 42.8594 70.6907V54.0696C42.8594 52.5129 41.5969 51.2627 40.0402 51.2627Z"
            fill="#CBCACA"
          />
          <path
            d="M69.7035 59.5988C69.7771 58.0053 68.5023 56.6938 66.9211 56.6938H67.3869C68.9068 56.6938 70.2796 55.5538 70.3409 54.0339C70.4145 52.4404 69.1397 51.1289 67.5585 51.1289H55.4236C55.4236 51.1289 55.3868 51.1044 55.4114 51.0799C58.1325 48.6284 59.824 47.3904 59.8853 42.3403C59.9098 40.5752 58.8434 38.3076 57.0416 38.3076C54.2592 38.3076 54.1488 40.4527 54.1243 42.3403C52.4818 47.7704 44.8822 47.7091 44.8822 56.4976V68.4854C44.8822 71.182 47.064 73.3639 49.7607 73.3639H65.4502C66.9211 73.3639 68.3429 72.1749 68.4042 70.704C68.4778 69.1105 67.203 67.799 65.6218 67.799H66.0876C67.5585 67.799 68.9803 66.61 69.0416 65.1391C69.1152 63.5457 67.8404 62.2341 66.2592 62.2341H66.725C68.1959 62.2341 69.6177 61.0452 69.679 59.5743L69.7035 59.5988ZM39.9547 51.1411H37.3807C35.824 51.1411 34.5737 52.4037 34.5737 53.9481V70.5692C34.5737 72.1259 35.8362 73.3761 37.3807 73.3761H39.9547C41.5114 73.3761 42.7739 72.1136 42.7739 70.5692V53.9481C42.7739 52.3914 41.5114 51.1411 39.9547 51.1411Z"
            fill="#CAC9C9"
          />
          <path
            d="M69.6171 59.4748C69.6906 57.8813 68.4159 56.5697 66.8347 56.5697H67.3004C68.8204 56.5697 70.1932 55.4298 70.2545 53.9099C70.328 52.3164 69.0532 51.0049 67.472 51.0049H55.3372C55.3372 51.0049 55.3004 50.9804 55.3249 50.9558C58.0461 48.5044 59.7376 47.2663 59.7989 42.2163C59.8234 40.4512 58.757 38.1836 56.9552 38.1836C54.1727 38.1836 54.0624 40.3286 54.0379 42.2163C52.3954 47.6463 44.7958 47.585 44.7958 56.3736V68.3614C44.7958 71.058 46.9776 73.2398 49.6743 73.2398H65.3638C66.8347 73.2398 68.2565 72.0509 68.3178 70.58C68.3913 68.9865 67.1166 67.675 65.5354 67.675H66.0012C67.472 67.675 68.8939 66.486 68.9552 65.0151C69.0287 63.4216 67.754 62.1101 66.1728 62.1101H66.6385C68.1094 62.1101 69.5313 60.9211 69.5926 59.4502L69.6171 59.4748ZM39.8683 51.0049H37.2943C35.7376 51.0049 34.4873 52.2674 34.4873 53.8118V70.4329C34.4873 71.9896 35.7498 73.2398 37.2943 73.2398H39.8683C41.425 73.2398 42.6875 71.9773 42.6875 70.4329V53.8118C42.6875 52.2551 41.425 51.0049 39.8683 51.0049Z"
            fill="#C9C8C8"
          />
          <path
            d="M69.5316 59.341C69.6051 57.7475 68.3304 56.4359 66.7492 56.4359H67.2149C68.7349 56.4359 70.1077 55.296 70.169 53.7761C70.2425 52.1826 68.9678 50.8711 67.3865 50.8711H55.2517C55.2517 50.8711 55.2149 50.8466 55.2394 50.8221C57.9606 48.3706 59.6521 47.1326 59.7134 42.0825C59.7379 40.3174 58.6715 38.0498 56.8697 38.0498C54.0872 38.0498 53.9769 40.1949 53.9524 42.0825C52.3099 47.5125 44.7103 47.4513 44.7103 56.2398V68.2276C44.7103 70.9242 46.8921 73.1061 49.5888 73.1061H65.2783C66.7492 73.1061 68.171 71.9171 68.2323 70.4462C68.3059 68.8527 67.0311 67.5412 65.4499 67.5412H65.9157C67.3865 67.5412 68.8084 66.3522 68.8697 64.8813C68.9432 63.2879 67.6685 61.9763 66.0873 61.9763H66.553C68.0239 61.9763 69.4458 60.7873 69.5071 59.3165L69.5316 59.341ZM39.7951 50.8711H37.221C35.6643 50.8711 34.4141 52.1336 34.4141 53.678V70.2991C34.4141 71.8558 35.6766 73.1061 37.221 73.1061H39.7951C41.3518 73.1061 42.6143 71.8435 42.6143 70.2991V53.678C42.6143 52.1213 41.3518 50.8711 39.7951 50.8711Z"
            fill="#C8C7C7"
          />
          <path
            d="M69.4457 59.2062C69.5192 57.6127 68.2444 56.3012 66.6632 56.3012H67.129C68.6489 56.3012 70.0218 55.1612 70.083 53.6413C70.1566 52.0479 68.8818 50.7363 67.3006 50.7363H55.1658C55.1658 50.7363 55.129 50.7118 55.1535 50.6873C57.8746 48.2358 59.5662 46.9978 59.6275 41.9477C59.652 40.1827 58.5856 37.915 56.7837 37.915C54.0013 37.915 53.891 40.0601 53.8665 41.9477C52.224 47.3778 44.6244 47.3165 44.6244 56.1051V68.0928C44.6244 70.7895 46.8062 72.9713 49.5028 72.9713H65.1923C66.6632 72.9713 68.0851 71.7823 68.1464 70.3114C68.2199 68.718 66.9451 67.4064 65.3639 67.4064H65.8297C67.3006 67.4064 68.7225 66.2174 68.7838 64.7466C68.8573 63.1531 67.5825 61.8415 66.0013 61.8415H66.4671C67.938 61.8415 69.3599 60.6526 69.4211 59.1817L69.4457 59.2062ZM39.7091 50.7486H37.1351C35.5784 50.7486 34.3281 52.0111 34.3281 53.5555V70.1766C34.3281 71.7333 35.5906 72.9835 37.1351 72.9835H39.7091C41.2658 72.9835 42.5283 71.721 42.5283 70.1766V53.5555C42.5283 51.9988 41.2658 50.7486 39.7091 50.7486Z"
            fill="#C7C6C6"
          />
          <path
            d="M69.3725 59.0841C69.446 57.4907 68.1712 56.1791 66.59 56.1791H67.0558C68.5757 56.1791 69.9486 55.0392 70.0098 53.5193C70.0834 51.9258 68.8086 50.6142 67.2274 50.6142H55.0926C55.0926 50.6142 55.0558 50.5897 55.0803 50.5652C57.8015 48.1137 59.493 46.8757 59.5543 41.8257C59.5788 40.0606 58.5124 37.793 56.7105 37.793C53.9281 37.793 53.8178 39.938 53.7933 41.8257C52.1508 47.2557 44.5512 47.1944 44.5512 55.983V67.9708C44.5512 70.6674 46.733 72.8492 49.4296 72.8492H65.1191C66.59 72.8492 68.0119 71.6602 68.0732 70.1894C68.1467 68.5959 66.8719 67.2843 65.2907 67.2843H65.7565C67.2274 67.2843 68.6493 66.0954 68.7106 64.6245C68.7841 63.031 67.5093 61.7195 65.9281 61.7195H66.3939C67.8648 61.7195 69.2867 60.5305 69.348 59.0596L69.3725 59.0841ZM39.6237 50.6142H37.0496C35.4929 50.6142 34.2427 51.8768 34.2427 53.4212V70.0423C34.2427 71.599 35.5052 72.8615 37.0496 72.8615H39.6237C41.1804 72.8615 42.4429 71.599 42.4429 70.0423V53.4212C42.4429 51.8645 41.1804 50.6142 39.6237 50.6142Z"
            fill="#C6C5C5"
          />
          <path
            d="M69.2865 58.9484C69.36 57.3549 68.0853 56.0434 66.5041 56.0434H66.9698C68.4897 56.0434 69.8626 54.9034 69.9239 53.3835C69.9974 51.79 68.7226 50.4785 67.1414 50.4785H55.0066C55.0066 50.4785 54.9698 50.454 54.9943 50.4295C57.7155 47.978 59.407 46.74 59.4683 41.6899C59.4928 39.9249 58.4264 37.6572 56.6246 37.6572C53.8421 37.6572 53.7318 39.8023 53.7073 41.6899C52.0648 47.12 44.4652 47.0587 44.4652 55.8473V67.835C44.4652 70.5317 46.647 72.7135 49.3436 72.7135H65.0332C66.504 72.7135 67.9259 71.5245 67.9872 70.0536C68.0607 68.4601 66.786 67.1486 65.2048 67.1486H65.6705C67.1414 67.1486 68.5633 65.9596 68.6246 64.4887C68.6981 62.8953 67.4234 61.5837 65.8421 61.5837H66.3079C67.7788 61.5837 69.2007 60.3948 69.262 58.9239L69.2865 58.9484ZM39.55 50.4908H36.9759C35.4192 50.4908 34.1689 51.7533 34.1689 53.2977V69.9188C34.1689 71.4755 35.4315 72.7257 36.9759 72.7257H39.55C41.1067 72.7257 42.3692 71.4632 42.3692 69.9188V53.2977C42.3692 51.741 41.1067 50.4908 39.55 50.4908Z"
            fill="#C5C4C4"
          />
          <path
            d="M69.201 58.8263C69.2746 57.2328 67.9998 55.9213 66.4186 55.9213H66.8844C68.4043 55.9213 69.7771 54.7814 69.8384 53.2614C69.912 51.668 68.6372 50.3564 67.056 50.3564H54.9211C54.9211 50.3564 54.8844 50.3319 54.9089 50.3074C57.63 47.8559 59.3215 46.6179 59.3828 41.5679C59.4073 39.8028 58.3409 37.5352 56.5391 37.5352C53.7567 37.5352 53.6464 39.6802 53.6218 41.5679C51.9793 46.9979 44.3797 46.9366 44.3797 55.7252V67.7129C44.3797 70.4096 46.5616 72.5914 49.2582 72.5914H64.9477C66.4186 72.5914 67.8405 71.4024 67.9017 69.9315C67.9753 68.3381 66.7005 67.0265 65.1193 67.0265H65.5851C67.056 67.0265 68.4778 65.8376 68.5391 64.3667C68.6127 62.7732 67.3379 61.4617 65.7567 61.4617H66.2225C67.6934 61.4617 69.1152 60.2727 69.1765 58.8018L69.201 58.8263ZM39.4645 50.3564H36.8904C35.3338 50.3564 34.0835 51.6189 34.0835 53.1634V69.7844C34.0835 71.3411 35.346 72.5914 36.8904 72.5914H39.4645C41.0212 72.5914 42.2837 71.3289 42.2837 69.7844V53.1634C42.2837 51.6067 41.0212 50.3564 39.4645 50.3564Z"
            fill="#C4C3C3"
          />
          <path
            d="M69.1274 58.6915C69.2009 57.0981 67.9261 55.7865 66.3449 55.7865H66.8107C68.3306 55.7865 69.7034 54.6466 69.7647 53.1267C69.8383 51.5332 68.5635 50.2217 66.9823 50.2217H54.8474C54.8474 50.2217 54.8107 50.1971 54.8352 50.1726C57.5563 47.7211 59.2479 46.4831 59.3091 41.4331C59.3337 39.668 58.2673 37.4004 56.4654 37.4004C53.683 37.4004 53.5727 39.5454 53.5482 41.4331C51.9057 46.8631 44.3061 46.8018 44.3061 55.5904V67.5782C44.3061 70.2748 46.4879 72.4566 49.1845 72.4566H64.874C66.3449 72.4566 67.7668 71.2677 67.8281 69.7968C67.9016 68.2033 66.6268 66.8918 65.0456 66.8918H65.5114C66.9823 66.8918 68.4042 65.7028 68.4655 64.2319C68.539 62.6384 67.2642 61.3269 65.683 61.3269H66.1488C67.6197 61.3269 69.0415 60.1379 69.1028 58.667L69.1274 58.6915ZM39.3786 50.2217H36.8045C35.2478 50.2217 33.9976 51.4842 33.9976 53.0409V69.6619C33.9976 71.2186 35.2601 72.4689 36.8045 72.4689H39.3786C40.9353 72.4689 42.1978 71.2064 42.1978 69.6619V53.0409C42.1978 51.4842 40.9353 50.2217 39.3786 50.2217Z"
            fill="#C3C2C2"
          />
          <path
            d="M69.0414 58.5685C69.115 56.975 67.8402 55.6635 66.259 55.6635H66.7248C68.2447 55.6635 69.6175 54.5235 69.6788 53.0036C69.7523 51.4102 68.4776 50.0986 66.8964 50.0986H54.7615C54.7615 50.0986 54.7247 50.0741 54.7492 50.0496C57.4704 47.5981 59.1619 46.3601 59.2232 41.31C59.2477 39.545 58.1813 37.2773 56.3795 37.2773C53.5971 37.2773 53.4867 39.4224 53.4622 41.31C51.8197 46.7401 44.2201 46.6788 44.2201 55.4674V67.4551C44.2201 70.1518 46.4019 72.3336 49.0986 72.3336H64.7881C66.259 72.3336 67.6808 71.1446 67.7421 69.6737C67.8157 68.0803 66.5409 66.7687 64.9597 66.7687H65.4255C66.8964 66.7687 68.3182 65.5797 68.3795 64.1089C68.4531 62.5154 67.1783 61.2038 65.5971 61.2038H66.0629C67.5338 61.2038 68.9556 60.0149 69.0169 58.544L69.0414 58.5685ZM39.2926 50.0986H36.7186C35.1619 50.0986 33.9116 51.3611 33.9116 52.9056V69.5266C33.9116 71.0833 35.1741 72.3336 36.7186 72.3336H39.2926C40.8493 72.3336 42.1118 71.0711 42.1118 69.5266V52.9056C42.1118 51.3489 40.8493 50.0986 39.2926 50.0986Z"
            fill="#C2C1C1"
          />
          <path
            d="M68.9554 58.4337C69.029 56.8403 67.7542 55.5287 66.173 55.5287H66.6388C68.1587 55.5287 69.5315 54.3888 69.5928 52.8689C69.6664 51.2754 68.3916 49.9638 66.8104 49.9638H54.6755C54.6755 49.9638 54.6387 49.9393 54.6633 49.9148C57.3844 47.4633 59.0759 46.2253 59.1372 41.1753C59.1617 39.4102 58.0953 37.1426 56.2935 37.1426C53.5111 37.1426 53.4007 39.2876 53.3762 41.1753C51.7337 46.6053 44.1341 46.544 44.1341 55.3326V67.3204C44.1341 70.017 46.316 72.1988 49.0126 72.1988H64.7021C66.173 72.1988 67.5948 71.0099 67.6561 69.539C67.7297 67.9455 66.4549 66.6339 64.8737 66.6339H65.3395C66.8104 66.6339 68.2322 65.445 68.2935 63.9741C68.3671 62.3806 67.0923 61.0691 65.5111 61.0691H65.9769C67.4478 61.0691 68.8696 59.8801 68.9309 58.4092L68.9554 58.4337ZM39.2189 49.9638H36.6448C35.0881 49.9638 33.8379 51.2264 33.8379 52.7708V69.3919C33.8379 70.9486 35.1004 72.1988 36.6448 72.1988H39.2189C40.7756 72.1988 42.0381 70.9363 42.0381 69.3919V52.7708C42.0381 51.2141 40.7756 49.9638 39.2189 49.9638Z"
            fill="#C1C0C0"
          />
          <path
            d="M68.8822 58.298C68.9558 56.7045 67.681 55.393 66.0998 55.393H66.5656C68.0855 55.393 69.4583 54.253 69.5196 52.7331C69.5932 51.1397 68.3184 49.8281 66.7372 49.8281H54.6023C54.6023 49.8281 54.5656 49.8036 54.5901 49.7791C57.3112 47.3276 59.0027 46.0896 59.064 41.0395C59.0886 39.2745 58.0222 37.0068 56.2203 37.0068C53.4379 37.0068 53.3276 39.1519 53.303 41.0395C51.6605 46.4696 44.0609 46.4083 44.0609 55.1969V67.1846C44.0609 69.8813 46.2428 72.0631 48.9394 72.0631H64.6289C66.0998 72.0631 67.5217 70.8741 67.583 69.4032C67.6565 67.8098 66.3817 66.4982 64.8005 66.4982H65.2663C66.7372 66.4982 68.159 65.3092 68.2203 63.8383C68.2939 62.2449 67.0191 60.9333 65.4379 60.9333H65.9037C67.3746 60.9333 68.7964 59.7444 68.8577 58.2735L68.8822 58.298ZM39.1335 49.8404H36.5594C35.0027 49.8404 33.7524 51.1029 33.7524 52.6473V69.2684C33.7524 70.8251 35.015 72.0753 36.5594 72.0753H39.1335C40.6902 72.0753 41.9527 70.8128 41.9527 69.2684V52.6473C41.9527 51.0906 40.6902 49.8404 39.1335 49.8404Z"
            fill="#C0BFBF"
          />
          <path
            d="M68.7963 58.1759C68.8698 56.5825 67.5951 55.2709 66.0139 55.2709H66.4796C67.9996 55.2709 69.3724 54.131 69.4337 52.611C69.5072 51.0176 68.2325 49.706 66.6512 49.706H54.5164C54.5164 49.706 54.4796 49.6815 54.5041 49.657C57.2253 47.2055 58.9168 45.9675 58.9781 40.9175C59.0026 39.1524 57.9362 36.8848 56.1344 36.8848C53.3519 36.8848 53.2416 39.0298 53.2171 40.9175C51.5746 46.3475 43.975 46.2862 43.975 55.0748V67.0626C43.975 69.7592 46.1568 71.941 48.8535 71.941H64.543C66.0139 71.941 67.4357 70.752 67.497 69.2811C67.5706 67.6877 66.2958 66.3761 64.7146 66.3761H65.1804C66.6512 66.3761 68.0731 65.1872 68.1344 63.7163C68.2079 62.1228 66.9332 60.8113 65.352 60.8113H65.8177C67.2886 60.8113 68.7105 59.6223 68.7718 58.1514L68.7963 58.1759ZM39.0475 49.706H36.4735C34.9168 49.706 33.6665 50.9686 33.6665 52.513V69.1341C33.6665 70.6908 34.929 71.941 36.4735 71.941H39.0475C40.6042 71.941 41.8667 70.6785 41.8667 69.1341V52.513C41.8667 50.9563 40.6042 49.706 39.0475 49.706Z"
            fill="#BFBEBE"
          />
          <path
            d="M68.7104 58.0412C68.7839 56.4477 67.5091 55.1361 65.9279 55.1361H66.3937C67.9136 55.1361 69.2865 53.9962 69.3478 52.4763C69.4213 50.8828 68.1465 49.5713 66.5653 49.5713H54.4305C54.4305 49.5713 54.3937 49.5468 54.4182 49.5222C57.1393 47.0708 58.8309 45.8328 58.8922 40.7827C58.9167 39.0176 57.8503 36.75 56.0484 36.75C53.266 36.75 53.1557 38.8951 53.1312 40.7827C51.4887 46.2127 43.8891 46.1515 43.8891 54.94V66.9278C43.8891 69.6244 46.0709 71.8062 48.7675 71.8062H64.457C65.9279 71.8062 67.3498 70.6173 67.4111 69.1464C67.4846 67.5529 66.2098 66.2414 64.6286 66.2414H65.0944C66.5653 66.2414 67.9872 65.0524 68.0485 63.5815C68.122 61.988 66.8472 60.6765 65.266 60.6765H65.7318C67.2027 60.6765 68.6246 59.4875 68.6858 58.0166L68.7104 58.0412ZM38.9738 49.5835H36.3998C34.8431 49.5835 33.5806 50.846 33.5806 52.3905V69.0116C33.5806 70.5682 34.8431 71.8185 36.3998 71.8185H38.9738C40.5305 71.8185 41.793 70.556 41.793 69.0116V52.3905C41.793 50.8338 40.5305 49.5835 38.9738 49.5835Z"
            fill="#BEBDBD"
          />
          <path
            d="M68.6244 57.9191C68.698 56.3256 67.4232 55.0141 65.842 55.0141H66.3078C67.8277 55.0141 69.2005 53.8741 69.2618 52.3542C69.3354 50.7607 68.0606 49.4492 66.4794 49.4492H54.3445C54.3445 49.4492 54.3077 49.4247 54.3323 49.4002C57.0534 46.9487 58.7449 45.7107 58.8062 40.6606C58.8307 38.8956 57.7643 36.6279 55.9625 36.6279C53.1801 36.6279 53.0697 38.773 53.0452 40.6606C51.4027 46.0907 43.8031 46.0294 43.8031 54.8179V66.8057C43.8031 69.5023 45.9849 71.6842 48.6816 71.6842H64.3711C65.842 71.6842 67.2639 70.4952 67.3251 69.0243C67.3987 67.4308 66.1239 66.1193 64.5427 66.1193H65.0085C66.4794 66.1193 67.9012 64.9303 67.9625 63.4594C68.0361 61.866 66.7613 60.5544 65.1801 60.5544H65.6459C67.1168 60.5544 68.5386 59.3655 68.5999 57.8946L68.6244 57.9191ZM38.8879 49.4492H36.3138C34.7571 49.4492 33.4946 50.7117 33.4946 52.2562V68.8772C33.4946 70.4339 34.7571 71.6842 36.3138 71.6842H38.8879C40.4446 71.6842 41.7071 70.4217 41.7071 68.8772V52.2562C41.7071 50.6995 40.4446 49.4492 38.8879 49.4492Z"
            fill="#BDBCBC"
          />
          <path
            d="M68.5512 57.7833C68.6248 56.1899 67.35 54.8783 65.7688 54.8783H66.2346C67.7545 54.8783 69.1273 53.7384 69.1886 52.2185C69.2622 50.625 67.9874 49.3135 66.4062 49.3135H54.2713C54.2713 49.3135 54.2346 49.2889 54.2591 49.2644C56.9802 46.8129 58.6717 45.5749 58.733 40.5249C58.7575 38.7598 57.6911 36.4922 55.8893 36.4922C53.1069 36.4922 52.9966 38.6372 52.972 40.5249C51.3295 45.9549 43.7299 45.8936 43.7299 54.6822V66.67C43.7299 69.3666 45.9118 71.5484 48.6084 71.5484H64.2979C65.7688 71.5484 67.1907 70.3595 67.2519 68.8886C67.3255 67.2951 66.0507 65.9836 64.4695 65.9836H64.9353C66.4062 65.9836 67.828 64.7946 67.8893 63.3237C67.9629 61.7302 66.6881 60.4187 65.1069 60.4187H65.5727C67.0436 60.4187 68.4654 59.2297 68.5267 57.7588L68.5512 57.7833ZM38.8025 49.3135H36.2284C34.6717 49.3135 33.4092 50.576 33.4092 52.1204V68.7415C33.4092 70.2982 34.6717 71.5484 36.2284 71.5484H38.8025C40.3591 71.5484 41.6094 70.2859 41.6094 68.7415V52.1204C41.6094 50.5637 40.3469 49.3135 38.8025 49.3135Z"
            fill="#BCBBBB"
          />
          <path
            d="M68.4652 57.6496C68.5388 56.0561 67.264 54.7445 65.6828 54.7445H66.1486C67.6685 54.7445 69.0413 53.6046 69.1026 52.0847C69.1762 50.4912 67.9014 49.1797 66.3202 49.1797H54.1853C54.1853 49.1797 54.1486 49.1552 54.1731 49.1306C56.8942 46.6792 58.5858 45.4412 58.647 40.3911C58.6716 38.626 57.6052 36.3584 55.8033 36.3584C53.0209 36.3584 52.9106 38.5034 52.886 40.3911C51.2436 45.8211 43.644 45.7598 43.644 54.5484V66.5362C43.644 69.2328 45.8258 71.4146 48.5224 71.4146H64.2119C65.6828 71.4146 67.1047 70.2257 67.166 68.7548C67.2395 67.1613 65.9647 65.8498 64.3835 65.8498H64.8493C66.3202 65.8498 67.7421 64.6608 67.8033 63.1899C67.8769 61.5964 66.6021 60.2849 65.0209 60.2849H65.4867C66.9576 60.2849 68.3794 59.0959 68.4407 57.625L68.4652 57.6496ZM38.7287 49.1919H36.1547C34.598 49.1919 33.3354 50.4544 33.3354 51.9989V68.6199C33.3354 70.1766 34.598 71.4269 36.1547 71.4269H38.7287C40.2854 71.4269 41.5357 70.1644 41.5357 68.6199V51.9989C41.5357 50.4422 40.2732 49.1919 38.7287 49.1919Z"
            fill="#BBBABA"
          />
          <path
            d="M68.3798 57.5265C68.4533 55.933 67.1786 54.6215 65.5974 54.6215H66.0631C67.5831 54.6215 68.9559 53.4816 69.0172 51.9616C69.0907 50.3682 67.816 49.0566 66.2347 49.0566H54.0999C54.0999 49.0566 54.0631 49.0321 54.0876 49.0076C56.8088 46.5561 58.5003 45.3181 58.5616 40.268C58.5861 38.503 57.5197 36.2354 55.7179 36.2354C52.9354 36.2354 52.8251 38.3804 52.8006 40.268C51.1581 45.6981 43.5585 45.6368 43.5585 54.4254V66.4131C43.5585 69.1098 45.7403 71.2916 48.437 71.2916H64.1265C65.5974 71.2916 67.0192 70.1026 67.0805 68.6317C67.154 67.0383 65.8793 65.7267 64.2981 65.7267H64.7638C66.2347 65.7267 67.6566 64.5378 67.7179 63.0669C67.7914 61.4734 66.5167 60.1618 64.9354 60.1618H65.4012C66.8721 60.1618 68.294 58.9729 68.3553 57.502L68.3798 57.5265ZM38.6433 49.0566H36.0692C34.5125 49.0566 33.25 50.3191 33.25 51.8636V68.4846C33.25 70.0413 34.5125 71.2916 36.0692 71.2916H38.6433C40.2 71.2916 41.4502 70.0291 41.4502 68.4846V51.8636C41.4502 50.3069 40.1877 49.0566 38.6433 49.0566Z"
            fill="#BAB9B9"
          />
          <path
            d="M68.3061 57.3927C68.3797 55.7993 67.1049 54.4877 65.5237 54.4877H65.9895C67.5094 54.4877 68.8822 53.3478 68.9435 51.8278C69.017 50.2344 67.7423 48.9228 66.1611 48.9228H54.0262C54.0262 48.9228 53.9894 48.8983 54.014 48.8738C56.7351 46.4223 58.4266 45.1843 58.4879 40.1343C58.5124 38.3692 57.446 36.1016 55.6442 36.1016C52.8617 36.1016 52.7514 38.2466 52.7269 40.1343C51.0844 45.5643 43.4848 45.503 43.4848 54.2916V66.2794C43.4848 68.976 45.6666 71.1578 48.3633 71.1578H64.0528C65.5237 71.1578 66.9455 69.9688 67.0068 68.4979C67.0804 66.9045 65.8056 65.5929 64.2244 65.5929H64.6902C66.1611 65.5929 67.5829 64.404 67.6442 62.9331C67.7178 61.3396 66.443 60.0281 64.8618 60.0281H65.3276C66.7984 60.0281 68.2203 58.8391 68.2816 57.3682L68.3061 57.3927ZM38.5573 48.9351H35.9833C34.4266 48.9351 33.1641 50.1976 33.1641 51.742V68.3631C33.1641 69.9198 34.4266 71.1701 35.9833 71.1701H38.5573C40.114 71.1701 41.3643 69.9076 41.3643 68.3631V51.742C41.3643 50.1854 40.1018 48.9351 38.5573 48.9351Z"
            fill="#B9B8B8"
          />
          <path
            d="M68.2206 57.2687C68.2941 55.6752 67.0194 54.3637 65.4382 54.3637H65.9039C67.4239 54.3637 68.7967 53.2237 68.858 51.7038C68.9315 50.1103 67.6568 48.7988 66.0756 48.7988H53.9407C53.9407 48.7988 53.9039 48.7743 53.9284 48.7498C56.6496 46.2983 58.3411 45.0603 58.4024 40.0102C58.4269 38.2452 57.3605 35.9775 55.5587 35.9775C52.7762 35.9775 52.6659 38.1226 52.6414 40.0102C50.9989 45.4403 43.3993 45.379 43.3993 54.1676V66.1553C43.3993 68.852 45.5811 71.0338 48.2778 71.0338H63.9673C65.4382 71.0338 66.86 69.8448 66.9213 68.3739C66.9949 66.7805 65.7201 65.4689 64.1389 65.4689H64.6047C66.0756 65.4689 67.4974 64.2799 67.5587 62.809C67.6322 61.2156 66.3575 59.904 64.7763 59.904H65.2421C66.7129 59.904 68.1348 58.7151 68.1961 57.2442L68.2206 57.2687ZM38.4841 48.7988H35.91C34.3533 48.7988 33.0908 50.0613 33.0908 51.6058V68.2268C33.0908 69.7835 34.3533 71.0338 35.91 71.0338H38.4841C40.0408 71.0338 41.291 69.7713 41.291 68.2268V51.6058C41.291 50.0491 40.0285 48.7988 38.4841 48.7988Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.1342 57.1349C68.2077 55.5414 66.933 54.2299 65.3517 54.2299H65.8175C67.3374 54.2299 68.7103 53.09 68.7716 51.57C68.8451 49.9766 67.5703 48.665 65.9891 48.665H53.8543C53.8543 48.665 53.8175 48.6405 53.842 48.616C56.5632 46.1645 58.2547 44.9265 58.316 39.8764C58.3405 38.1114 57.2741 35.8438 55.4723 35.8438C52.6898 35.8438 52.5795 37.9888 52.555 39.8764C50.9125 45.3065 43.3129 45.2452 43.3129 54.0338V66.0215C43.3129 68.7182 45.4947 70.9 48.1913 70.9H63.8808C65.3517 70.9 66.7736 69.711 66.8349 68.2401C66.9084 66.6467 65.6337 65.3351 64.0525 65.3351H64.5182C65.9891 65.3351 67.411 64.1462 67.4723 62.6753C67.5458 61.0818 66.2711 59.7702 64.6898 59.7702H65.1556C66.6265 59.7702 68.0484 58.5813 68.1097 57.1104L68.1342 57.1349ZM38.3977 48.665H35.8236C34.2669 48.665 33.0044 49.9275 33.0044 51.472V68.093C33.0044 69.6497 34.2669 70.9 35.8236 70.9H38.3977C39.9544 70.9 41.2046 69.6375 41.2046 68.093V51.472C41.2046 49.9153 39.9421 48.665 38.3977 48.665Z"
            fill="#B7B6B6"
          />
          <path
            d="M68.061 57.0119C68.1345 55.4184 66.8598 54.1068 65.2786 54.1068H65.7443C67.2643 54.1068 68.6371 52.9669 68.6984 51.447C68.7719 49.8535 67.4972 48.542 65.9159 48.542H53.7811C53.7811 48.542 53.7443 48.5175 53.7688 48.4929C56.49 46.0415 58.1815 44.8034 58.2428 39.7534C58.2673 37.9883 57.2009 35.7207 55.3991 35.7207C52.6166 35.7207 52.5063 37.8657 52.4818 39.7534C50.8393 45.1834 43.2397 45.1221 43.2397 53.9107V65.8985C43.2397 68.5951 45.4215 70.7769 48.1182 70.7769H63.8077C65.2786 70.7769 66.7004 69.588 66.7617 68.1171C66.8353 66.5236 65.5605 65.2121 63.9793 65.2121H64.4451C65.9159 65.2121 67.3378 64.0231 67.3991 62.5522C67.4726 60.9587 66.1979 59.6472 64.6167 59.6472H65.0824C66.5533 59.6472 67.9752 58.4582 68.0365 56.9873L68.061 57.0119ZM38.3122 48.542H35.7382C34.1815 48.542 32.9189 49.8045 32.9189 51.3489V67.97C32.9189 69.5267 34.1815 70.7769 35.7382 70.7769H38.3122C39.8689 70.7769 41.1192 69.5144 41.1192 67.97V51.3489C41.1192 49.7922 39.8566 48.542 38.3122 48.542Z"
            fill="#B6B5B5"
          />
          <path
            d="M67.9751 56.8761C68.0486 55.2826 66.7738 53.9711 65.1926 53.9711H65.6584C67.1783 53.9711 68.5512 52.8312 68.6124 51.3112C68.686 49.7178 67.4112 48.4062 65.83 48.4062H53.6952C53.6952 48.4062 53.6584 48.3817 53.6829 48.3572C56.404 45.9057 58.0956 44.6677 58.1569 39.6177C58.1814 37.8526 57.115 35.585 55.3131 35.585C52.5307 35.585 52.4204 37.73 52.3959 39.6177C50.7534 45.0477 43.1538 44.9864 43.1538 53.775V65.7627C43.1538 68.4594 45.3356 70.6412 48.0322 70.6412H63.7217C65.1926 70.6412 66.6145 69.4522 66.6758 67.9813C66.7493 66.3879 65.4745 65.0763 63.8933 65.0763H64.3591C65.83 65.0763 67.2519 63.8874 67.3132 62.4165C67.3867 60.823 66.1119 59.5115 64.5307 59.5115H64.9965C66.4674 59.5115 67.8893 58.3225 67.9506 56.8516L67.9751 56.8761ZM38.2263 48.4062H35.6522C34.0955 48.4062 32.833 49.6687 32.833 51.2132V67.8343C32.833 69.3909 34.0955 70.6412 35.6522 70.6412H38.2263C39.783 70.6412 41.0332 69.3787 41.0332 67.8343V51.2132C41.0332 49.6565 39.7707 48.4062 38.2263 48.4062Z"
            fill="#B5B4B4"
          />
          <path
            d="M67.8896 56.7423C67.9631 55.1489 66.6883 53.8373 65.1071 53.8373H65.5729C67.0928 53.8373 68.4656 52.6974 68.5269 51.1774C68.6005 49.584 67.3257 48.2724 65.7445 48.2724H53.6096C53.6096 48.2724 53.5729 48.2479 53.5974 48.2234C56.3185 45.7719 58.0101 44.5339 58.0714 39.4839C58.0959 37.7188 57.0295 35.4512 55.2276 35.4512C52.4452 35.4512 52.3349 37.5962 52.3104 39.4839C50.6679 44.9139 43.0683 44.8526 43.0683 53.6412V65.629C43.0683 68.3256 45.2501 70.5074 47.9467 70.5074H63.6362C65.1071 70.5074 66.529 69.3184 66.5903 67.8476C66.6638 66.2541 65.389 64.9425 63.8078 64.9425H64.2736C65.7445 64.9425 67.1664 63.7536 67.2277 62.2827C67.3012 60.6892 66.0264 59.3777 64.4452 59.3777H64.911C66.3819 59.3777 67.8037 58.1887 67.865 56.7178L67.8896 56.7423ZM38.153 48.2847H35.579C34.0223 48.2847 32.7598 49.5472 32.7598 51.0916V67.7127C32.7598 69.2694 34.0223 70.5197 35.579 70.5197H38.153C39.7097 70.5197 40.96 69.2572 40.96 67.7127V51.0916C40.96 49.535 39.6975 48.2847 38.153 48.2847Z"
            fill="#B4B3B3"
          />
          <path
            d="M67.8031 56.6193C67.8767 55.0258 66.6019 53.7143 65.0207 53.7143H65.4865C67.0064 53.7143 68.3792 52.5743 68.4405 51.0544C68.5141 49.4609 67.2393 48.1494 65.6581 48.1494H53.5232C53.5232 48.1494 53.4865 48.1249 53.511 48.1004C56.2321 45.6489 57.9236 44.4109 57.9849 39.3608C58.0094 37.5957 56.943 35.3281 55.1412 35.3281C52.3588 35.3281 52.2485 37.4732 52.2239 39.3608C50.5814 44.7909 42.9818 44.7296 42.9818 53.5181V65.5059C42.9818 68.2025 45.1637 70.3844 47.8603 70.3844H63.5498C65.0207 70.3844 66.4426 69.1954 66.5038 67.7245C66.5774 66.131 65.3026 64.8195 63.7214 64.8195H64.1872C65.6581 64.8195 67.0799 63.6305 67.1412 62.1596C67.2148 60.5662 65.94 59.2546 64.3588 59.2546H64.8246C66.2955 59.2546 67.7173 58.0657 67.7786 56.5948L67.8031 56.6193ZM38.0666 48.1494H35.4925C33.9359 48.1494 32.6733 49.4119 32.6733 50.9686V67.5897C32.6733 69.1464 33.9359 70.3966 35.4925 70.3966H38.0666C39.6233 70.3966 40.8736 69.1341 40.8736 67.5897V50.9686C40.8736 49.4119 39.611 48.1494 38.0666 48.1494Z"
            fill="#B3B2B2"
          />
          <path
            d="M67.7299 56.4845C67.8035 54.891 66.5287 53.5795 64.9475 53.5795H65.4133C66.9332 53.5795 68.306 52.4396 68.3673 50.9196C68.4409 49.3262 67.1661 48.0146 65.5849 48.0146H53.45C53.45 48.0146 53.4133 47.9901 53.4378 47.9656C56.1589 45.5141 57.8505 44.2761 57.9117 39.226C57.9363 37.461 56.8699 35.1934 55.068 35.1934C52.2856 35.1934 52.1753 37.3384 52.1507 39.226C50.5083 44.6561 42.9086 44.5948 42.9086 53.3834V65.3711C42.9086 68.0678 45.0905 70.2496 47.7871 70.2496H63.4766C64.9475 70.2496 66.3694 69.0606 66.4306 67.5897C66.5042 65.9963 65.2294 64.6847 63.6482 64.6847H64.114C65.5849 64.6847 67.0068 63.4958 67.068 62.0249C67.1416 60.4314 65.8668 59.1199 64.2856 59.1199H64.7514C66.2223 59.1199 67.6441 57.9309 67.7054 56.46L67.7299 56.4845ZM37.9812 48.0269H35.4071C33.8504 48.0269 32.5879 49.2894 32.5879 50.8338V67.4549C32.5879 69.0116 33.8504 70.2619 35.4071 70.2619H37.9812C39.5379 70.2619 40.7881 68.9993 40.7881 67.4549V50.8338C40.7881 49.2771 39.5256 48.0269 37.9812 48.0269Z"
            fill="#B2B1B1"
          />
          <path
            d="M67.644 56.3615C67.7175 54.768 66.4427 53.4565 64.8615 53.4565H65.3273C66.8472 53.4565 68.22 52.3165 68.2813 50.7966C68.3549 49.2031 67.0801 47.8916 65.4989 47.8916H53.364C53.364 47.8916 53.3273 47.8671 53.3518 47.8426C56.0729 45.3911 57.7645 44.1531 57.8258 39.103C57.8503 37.3379 56.7839 35.0703 54.982 35.0703C52.1996 35.0703 52.0893 37.2154 52.0648 39.103C50.4223 44.533 42.8227 44.4718 42.8227 53.2603V65.2481C42.8227 67.9447 45.0045 70.1266 47.7011 70.1266H63.3906C64.8615 70.1266 66.2834 68.9376 66.3447 67.4667C66.4182 65.8732 65.1434 64.5617 63.5622 64.5617H64.028C65.4989 64.5617 66.9208 63.3727 66.9821 61.9018C67.0556 60.3084 65.7808 58.9968 64.1996 58.9968H64.6654C66.1363 58.9968 67.5581 57.8079 67.6194 56.337L67.644 56.3615ZM37.9074 47.8916H35.3334C33.7767 47.8916 32.5142 49.1541 32.5142 50.6985V67.3196C32.5142 68.8763 33.7767 70.1266 35.3334 70.1266H37.9074C39.4641 70.1266 40.7144 68.8641 40.7144 67.3196V50.6985C40.7144 49.1418 39.4519 47.8916 37.9074 47.8916Z"
            fill="#B1B0B0"
          />
          <path
            d="M67.5585 56.2277C67.632 54.6342 66.3573 53.3227 64.7761 53.3227H65.2418C66.7618 53.3227 68.1346 52.1827 68.1959 50.6628C68.2694 49.0693 66.9947 47.7578 65.4134 47.7578H53.2786C53.2786 47.7578 53.2418 47.7333 53.2663 47.7088C55.9875 45.2573 57.679 44.0193 57.7403 38.9692C57.7648 37.2041 56.6984 34.9365 54.8966 34.9365C52.1141 34.9365 52.0038 37.0816 51.9793 38.9692C50.3368 44.3993 42.7372 44.338 42.7372 53.1266V65.1143C42.7372 67.811 44.919 69.9928 47.6157 69.9928H63.3052C64.7761 69.9928 66.1979 68.8038 66.2592 67.3329C66.3328 65.7394 65.058 64.4279 63.4768 64.4279H63.9426C65.4134 64.4279 66.8353 63.2389 66.8966 61.768C66.9701 60.1746 65.6954 58.863 64.1142 58.863H64.5799C66.0508 58.863 67.4727 57.6741 67.534 56.2032L67.5585 56.2277ZM37.822 47.7578H35.2479C33.6912 47.7578 32.4287 49.0203 32.4287 50.5647V67.1858C32.4287 68.7425 33.6912 69.9928 35.2479 69.9928H37.822C39.3787 69.9928 40.6289 68.7303 40.6289 67.1858V50.5647C40.6289 49.0081 39.3664 47.7578 37.822 47.7578Z"
            fill="#B0AFAF"
          />
          <path
            d="M67.4843 56.0919C67.5579 54.4985 66.2831 53.1869 64.7019 53.1869H65.1677C66.6876 53.1869 68.0604 52.047 68.1217 50.5271C68.1953 48.9336 66.9205 47.6221 65.3393 47.6221H53.2044C53.2044 47.6221 53.1677 47.5975 53.1922 47.573C55.9133 45.1215 57.6048 43.8835 57.6661 38.8335C57.6906 37.0684 56.6243 34.8008 54.8224 34.8008C52.04 34.8008 51.9297 36.9458 51.9051 38.8335C50.2626 44.2635 42.663 44.2022 42.663 52.9908V64.9786C42.663 67.6752 44.8449 69.857 47.5415 69.857H63.231C64.7019 69.857 66.1238 68.6681 66.185 67.1972C66.2586 65.6037 64.9838 64.2922 63.4026 64.2922H63.8684C65.3393 64.2922 66.7611 63.1032 66.8224 61.6323C66.896 60.0388 65.6212 58.7273 64.04 58.7273H64.5058C65.9767 58.7273 67.3985 57.5383 67.4598 56.0674L67.4843 56.0919ZM37.7356 47.6343H35.1615C33.6048 47.6343 32.3423 48.8968 32.3423 50.4413V67.0623C32.3423 68.619 33.6048 69.8815 35.1615 69.8815H37.7356C39.2922 69.8815 40.5425 68.619 40.5425 67.0623V50.4413C40.5425 48.8846 39.28 47.6343 37.7356 47.6343Z"
            fill="#AFAEAE"
          />
          <path
            d="M67.3988 55.9699C67.4724 54.3764 66.1976 53.0649 64.6164 53.0649H65.0822C66.6021 53.0649 67.9749 51.9249 68.0362 50.405C68.1098 48.8115 66.835 47.5 65.2538 47.5H53.1189C53.1189 47.5 53.0822 47.4755 53.1067 47.451C55.8278 44.9995 57.5194 43.7615 57.5806 38.7114C57.6052 36.9463 56.5388 34.6787 54.7369 34.6787C51.9545 34.6787 51.8442 36.8238 51.8196 38.7114C50.1771 44.1414 42.5775 44.0802 42.5775 52.8687V64.8565C42.5775 67.5531 44.7594 69.735 47.456 69.735H63.1455C64.6164 69.735 66.0383 68.546 66.0996 67.0751C66.1731 65.4816 64.8983 64.1701 63.3171 64.1701H63.7829C65.2538 64.1701 66.6756 62.9811 66.7369 61.5102C66.8105 59.9168 65.5357 58.6052 63.9545 58.6052H64.4203C65.8912 58.6052 67.313 57.4163 67.3743 55.9454L67.3988 55.9699ZM37.6623 47.5H35.0883C33.5316 47.5 32.269 48.7625 32.269 50.3069V66.928C32.269 68.4847 33.5316 69.735 35.0883 69.735H37.6623C39.219 69.735 40.4693 68.4725 40.4693 66.928V50.3069C40.4693 48.7502 39.2067 47.5 37.6623 47.5Z"
            fill="#AEADAD"
          />
          <path
            d="M67.3129 55.8351C67.3864 54.2416 66.1117 52.9301 64.5305 52.9301H64.9962C66.5162 52.9301 67.889 51.7901 67.9503 50.2702C68.0238 48.6768 66.7491 47.3652 65.1678 47.3652H53.033C53.033 47.3652 52.9962 47.3407 53.0207 47.3162C55.7419 44.8647 57.4334 43.6267 57.4947 38.5766C57.5192 36.8116 56.4528 34.5439 54.651 34.5439C51.8685 34.5439 51.7582 36.689 51.7337 38.5766C50.0912 44.0067 42.4916 43.9454 42.4916 52.734V64.7217C42.4916 67.4184 44.6734 69.6002 47.3701 69.6002H63.0596C64.5305 69.6002 65.9523 68.4112 66.0136 66.9403C66.0872 65.3469 64.8124 64.0353 63.2312 64.0353H63.697C65.1679 64.0353 66.5897 62.8464 66.651 61.3755C66.7245 59.782 65.4498 58.4704 63.8686 58.4704H64.3343C65.8052 58.4704 67.2271 57.2815 67.2884 55.8106L67.3129 55.8351ZM37.5764 47.3775H35.0023C33.4456 47.3775 32.1831 48.64 32.1831 50.1844V66.8055C32.1831 68.3622 33.4456 69.6124 35.0023 69.6124H37.5764C39.1331 69.6124 40.3833 68.3499 40.3833 66.8055V50.1844C40.3833 48.6277 39.1208 47.3775 37.5764 47.3775Z"
            fill="#ADACAC"
          />
          <path
            d="M67.2397 55.713C67.3133 54.1196 66.0385 52.808 64.4573 52.808H64.9231C66.443 52.808 67.8158 51.6681 67.8771 50.1482C67.9506 48.5547 66.6759 47.2431 65.0947 47.2431H52.9598C52.9598 47.2431 52.923 47.2186 52.9475 47.1941C55.6687 44.7426 57.3602 43.5046 57.4215 38.4546C57.446 36.6895 56.3796 34.4219 54.5778 34.4219C51.7953 34.4219 51.685 36.5669 51.6605 38.4546C50.018 43.8846 42.4184 43.8233 42.4184 52.6119V64.5997C42.4184 67.2963 44.6002 69.4781 47.2969 69.4781H62.9864C64.4573 69.4781 65.8791 68.2892 65.9404 66.8183C66.014 65.2248 64.7392 63.9133 63.158 63.9133H63.6238C65.0947 63.9133 66.5165 62.7243 66.5778 61.2534C66.6514 59.6599 65.3766 58.3484 63.7954 58.3484H64.2612C65.732 58.3484 67.1539 57.1594 67.2152 55.6885L67.2397 55.713ZM37.4909 47.2431H34.9169C33.3602 47.2431 32.0977 48.5057 32.0977 50.0624V66.6834C32.0977 68.2401 33.3602 69.4904 34.9169 69.4904H37.4909C39.0476 69.4904 40.2979 68.2279 40.2979 66.6834V50.0624C40.2979 48.5057 39.0354 47.2431 37.4909 47.2431Z"
            fill="#ACABAB"
          />
          <path
            d="M67.1537 55.5773C67.2273 53.9838 65.9525 52.6723 64.3713 52.6723H64.8371C66.357 52.6723 67.7298 51.5323 67.7911 50.0124C67.8646 48.419 66.5899 47.1074 65.0087 47.1074H52.8738C52.8738 47.1074 52.837 47.0829 52.8616 47.0584C55.5827 44.6069 57.2742 43.3689 57.3355 38.3188C57.36 36.5538 56.2936 34.2861 54.4918 34.2861C51.7094 34.2861 51.599 36.4312 51.5745 38.3188C49.932 43.7489 42.3324 43.6876 42.3324 52.4762V64.4639C42.3324 67.1606 44.5142 69.3424 47.2109 69.3424H62.9004C64.3713 69.3424 65.7931 68.1534 65.8544 66.6825C65.928 65.0891 64.6532 63.7775 63.072 63.7775H63.5378C65.0087 63.7775 66.4305 62.5885 66.4918 61.1176C66.5654 59.5242 65.2906 58.2126 63.7094 58.2126H64.1752C65.6461 58.2126 67.0679 57.0237 67.1292 55.5528L67.1537 55.5773ZM37.4049 47.1074H34.8309C33.2742 47.1074 32.0239 48.3699 32.0239 49.9144V66.5354C32.0239 68.0921 33.2864 69.3424 34.8309 69.3424H37.4049C38.9616 69.3424 40.2119 68.0799 40.2119 66.5354V49.9144C40.2119 48.3577 38.9494 47.1074 37.4049 47.1074Z"
            fill="#ACAAAA"
          />
          <path
            d="M67.0677 55.4552C67.1413 53.8618 65.8665 52.5502 64.2853 52.5502H64.7511C66.271 52.5502 67.6438 51.4103 67.7051 49.8903C67.7787 48.2969 66.5039 46.9853 64.9227 46.9853H52.7878C52.7878 46.9853 52.7511 46.9608 52.7756 46.9363C55.4967 44.4848 57.1882 43.2468 57.2495 38.1968C57.274 36.4317 56.2076 34.1641 54.4058 34.1641C51.6234 34.1641 51.5131 36.3091 51.4885 38.1968C49.846 43.6268 42.2464 43.5655 42.2464 52.3541V64.3419C42.2464 67.0385 44.4283 69.2203 47.1249 69.2203H62.8144C64.2853 69.2203 65.7071 68.0313 65.7684 66.5604C65.842 64.967 64.5672 63.6554 62.986 63.6554H63.4518C64.9227 63.6554 66.3445 62.4665 66.4058 60.9956C66.4794 59.4021 65.2046 58.0906 63.6234 58.0906H64.0892C65.5601 58.0906 66.9819 56.9016 67.0432 55.4307L67.0677 55.4552ZM37.3312 46.9853H34.7571C33.2005 46.9853 31.9502 48.2478 31.9502 49.7923V66.4134C31.9502 67.97 33.2127 69.2203 34.7571 69.2203H37.3312C38.8879 69.2203 40.1382 67.9578 40.1382 66.4134V49.7923C40.1382 48.2356 38.8756 46.9853 37.3312 46.9853Z"
            fill="#ABA9A9"
          />
          <path
            d="M66.9823 55.3205C67.0558 53.727 65.781 52.4154 64.1998 52.4154H64.6656C66.1856 52.4154 67.5584 51.2755 67.6197 49.7556C67.6932 48.1621 66.4184 46.8506 64.8372 46.8506H52.7024C52.7024 46.8506 52.6656 46.826 52.6901 46.8015C55.4113 44.35 57.1028 43.1121 57.1641 38.062C57.1886 36.2969 56.1222 34.0293 54.3204 34.0293C51.5379 34.0293 51.4276 36.1743 51.4031 38.062C49.7606 43.492 42.161 43.4307 42.161 52.2193V64.2071C42.161 66.9037 44.3428 69.0855 47.0394 69.0855H62.729C64.1998 69.0855 65.6217 67.8966 65.683 66.4257C65.7565 64.8322 64.4818 63.5207 62.9006 63.5207H63.3663C64.8372 63.5207 66.2591 62.3317 66.3204 60.8608C66.3939 59.2673 65.1191 57.9558 63.5379 57.9558H64.0037C65.4746 57.9558 66.8965 56.7668 66.9578 55.2959L66.9823 55.3205ZM37.2458 46.8506H34.6717C33.115 46.8506 31.8647 48.1131 31.8647 49.6575V66.2786C31.8647 67.8353 33.1273 69.0855 34.6717 69.0855H37.2458C38.8025 69.0855 40.0527 67.823 40.0527 66.2786V49.6575C40.0527 48.1008 38.7902 46.8506 37.2458 46.8506Z"
            fill="#AAA8A8"
          />
          <path
            d="M66.9086 55.1847C66.9821 53.5912 65.7074 52.2797 64.1262 52.2797H64.5919C66.1119 52.2797 67.4847 51.1398 67.546 49.6198C67.6195 48.0264 66.3447 46.7148 64.7635 46.7148H52.6287C52.6287 46.7148 52.5919 46.6903 52.6164 46.6658C55.3376 44.2143 57.0291 42.9763 57.0904 37.9262C57.1149 36.1612 56.0485 33.8936 54.2467 33.8936C51.4642 33.8936 51.3539 36.0386 51.3294 37.9262C49.6869 43.3563 42.0873 43.295 42.0873 52.0836V64.0713C42.0873 66.768 44.2691 68.9498 46.9658 68.9498H62.6553C64.1262 68.9498 65.548 67.7608 65.6093 66.2899C65.6829 64.6965 64.4081 63.3849 62.8269 63.3849H63.2927C64.7635 63.3849 66.1854 62.196 66.2467 60.7251C66.3202 59.1316 65.0455 57.8201 63.4643 57.8201H63.93C65.4009 57.8201 66.8228 56.6311 66.8841 55.1602L66.9086 55.1847ZM37.1598 46.7271H34.5858C33.0291 46.7271 31.7788 47.9896 31.7788 49.534V66.1551C31.7788 67.7118 33.0413 68.9621 34.5858 68.9621H37.1598C38.7165 68.9621 39.9668 67.6995 39.9668 66.1551V49.534C39.9668 47.9773 38.7043 46.7271 37.1598 46.7271Z"
            fill="#A9A7A7"
          />
          <path
            d="M66.8226 55.0626C66.8962 53.4692 65.6214 52.1576 64.0402 52.1576H64.506C66.0259 52.1576 67.3987 51.0177 67.46 49.4978C67.5335 47.9043 66.2588 46.5928 64.6776 46.5928H52.5427C52.5427 46.5928 52.5059 46.5682 52.5305 46.5437C55.2516 44.0922 56.9431 42.8542 57.0044 37.8042C57.0289 36.0391 55.9625 33.7715 54.1607 33.7715C51.3783 33.7715 51.2679 35.9165 51.2434 37.8042C49.6009 43.2342 42.0013 43.1729 42.0013 51.9615V63.9493C42.0013 66.6459 44.1831 68.8277 46.8798 68.8277H62.5693C64.0402 68.8277 65.462 67.6388 65.5233 66.1679C65.5969 64.5744 64.3221 63.2629 62.7409 63.2629H63.2067C64.6776 63.2629 66.0994 62.0739 66.1607 60.603C66.2343 59.0095 64.9595 57.698 63.3783 57.698H63.8441C65.3149 57.698 66.7368 56.509 66.7981 55.0381L66.8226 55.0626ZM37.0861 46.5928H34.512C32.9553 46.5928 31.7051 47.8553 31.7051 49.3997V66.0208C31.7051 67.5775 32.9676 68.8277 34.512 68.8277H37.0861C38.6428 68.8277 39.893 67.5652 39.893 66.0208V49.3997C39.893 47.843 38.6305 46.5928 37.0861 46.5928Z"
            fill="#A8A6A6"
          />
          <path
            d="M66.7372 54.9279C66.8107 53.3344 65.5359 52.0229 63.9547 52.0229H64.4205C65.9404 52.0229 67.3133 50.8829 67.3746 49.363C67.4481 47.7695 66.1733 46.458 64.5921 46.458H52.4573C52.4573 46.458 52.4205 46.4335 52.445 46.409C55.1661 43.9575 56.8577 42.7195 56.919 37.6694C56.9435 35.9043 55.8771 33.6367 54.0752 33.6367C51.2928 33.6367 51.1825 35.7818 51.158 37.6694C49.5155 43.0995 41.9159 43.0382 41.9159 51.8267V63.8145C41.9159 66.5111 44.0977 68.693 46.7943 68.693H62.4838C63.9547 68.693 65.3766 67.504 65.4379 66.0331C65.5114 64.4396 64.2366 63.1281 62.6554 63.1281H63.1212C64.5921 63.1281 66.014 61.9391 66.0753 60.4682C66.1488 58.8748 64.874 57.5632 63.2928 57.5632H63.7586C65.2295 57.5632 66.6514 56.3742 66.7127 54.9034L66.7372 54.9279ZM37.0006 46.458H34.4266C32.8699 46.458 31.6196 47.7205 31.6196 49.2649V65.886C31.6196 67.4427 32.8821 68.693 34.4266 68.693H37.0006C38.5573 68.693 39.8076 67.4304 39.8076 65.886V49.2649C39.8076 47.7082 38.5451 46.458 37.0006 46.458Z"
            fill="#A7A5A5"
          />
          <path
            d="M66.6635 54.8058C66.737 53.2123 65.4622 51.9008 63.881 51.9008H64.3468C65.8667 51.9008 67.2396 50.7609 67.3009 49.2409C67.3744 47.6475 66.0996 46.3359 64.5184 46.3359H52.3836C52.3836 46.3359 52.3468 46.3114 52.3713 46.2869C55.0925 43.8354 56.784 42.5974 56.8453 37.5473C56.8698 35.7823 55.8034 33.5146 54.0016 33.5146C51.2191 33.5146 51.1088 35.6597 51.0843 37.5473C49.4418 42.9774 41.8422 42.9161 41.8422 51.7047V63.6924C41.8422 66.3891 44.024 68.5709 46.7206 68.5709H62.4102C63.881 68.5709 65.3029 67.3819 65.3642 65.911C65.4377 64.3176 64.163 63.006 62.5818 63.006H63.0475C64.5184 63.006 65.9403 61.817 66.0016 60.3462C66.0751 58.7527 64.8004 57.4411 63.2191 57.4411H63.6849C65.1558 57.4411 66.5777 56.2522 66.639 54.7813L66.6635 54.8058ZM36.9147 46.3359H34.3406C32.784 46.3359 31.5337 47.5984 31.5337 49.1429V65.7639C31.5337 67.3206 32.7962 68.5709 34.3406 68.5709H36.9147C38.4714 68.5709 39.7339 67.3084 39.7339 65.7639V49.1429C39.7339 47.5862 38.4714 46.3359 36.9147 46.3359Z"
            fill="#A6A4A4"
          />
          <path
            d="M66.578 54.6701C66.6515 53.0766 65.3768 51.765 63.7955 51.765H64.2613C65.7813 51.765 67.1541 50.6251 67.2154 49.1052C67.2889 47.5117 66.0141 46.2002 64.4329 46.2002H52.2981C52.2981 46.2002 52.2613 46.1757 52.2858 46.1511C55.007 43.6997 56.6985 42.4617 56.7598 37.4116C56.7843 35.6465 55.7179 33.3789 53.9161 33.3789C51.1336 33.3789 51.0233 35.524 50.9988 37.4116C49.3563 42.8416 41.7567 42.7804 41.7567 51.5689V63.5567C41.7567 66.2533 43.9385 68.4352 46.6351 68.4352H62.3247C63.7955 68.4352 65.2174 67.2462 65.2787 65.7753C65.3522 64.1818 64.0775 62.8703 62.4963 62.8703H62.962C64.4329 62.8703 65.8548 61.6813 65.9161 60.2104C65.9896 58.617 64.7149 57.3054 63.1337 57.3054H63.5994C65.0703 57.3054 66.4922 56.1164 66.5535 54.6455L66.578 54.6701ZM36.8415 46.2002H34.2674C32.7107 46.2002 31.4604 47.4627 31.4604 49.0071V65.6282C31.4604 67.1849 32.723 68.4352 34.2674 68.4352H36.8415C38.3982 68.4352 39.6607 67.1726 39.6607 65.6282V49.0071C39.6607 47.4504 38.3982 46.2002 36.8415 46.2002Z"
            fill="#A5A3A3"
          />
          <path
            d="M66.4916 54.5363C66.5651 52.9428 65.2903 51.6313 63.7091 51.6313H64.1749C65.6948 51.6313 67.0677 50.4913 67.1289 48.9714C67.2025 47.3779 65.9277 46.0664 64.3465 46.0664H52.2117C52.2117 46.0664 52.1749 46.0419 52.1994 46.0174C54.9205 43.5659 56.6121 42.3279 56.6734 37.2778C56.6979 35.5127 55.6315 33.2451 53.8296 33.2451C51.0472 33.2451 50.9369 35.3902 50.9124 37.2778C49.2699 42.7079 41.6703 42.6466 41.6703 51.4351V63.4229C41.6703 66.1195 43.8521 68.3014 46.5487 68.3014H62.2382C63.7091 68.3014 65.131 67.1124 65.1923 65.6415C65.2658 64.048 63.991 62.7365 62.4098 62.7365H62.8756C64.3465 62.7365 65.7684 61.5475 65.8297 60.0766C65.9032 58.4832 64.6284 57.1716 63.0472 57.1716H63.513C64.9839 57.1716 66.4058 55.9826 66.467 54.5118L66.4916 54.5363ZM36.755 46.0786H34.181C32.6243 46.0786 31.374 47.3412 31.374 48.8856V65.5067C31.374 67.0634 32.6365 68.3136 34.181 68.3136H36.755C38.3117 68.3136 39.5742 67.0511 39.5742 65.5067V48.8856C39.5742 47.3289 38.3117 46.0786 36.755 46.0786Z"
            fill="#A4A2A2"
          />
          <path
            d="M66.4184 54.4132C66.4919 52.8198 65.2171 51.5082 63.6359 51.5082H64.1017C65.6216 51.5082 66.9945 50.3683 67.0558 48.8484C67.1293 47.2549 65.8545 45.9433 64.2733 45.9433H52.1385C52.1385 45.9433 52.1017 45.9188 52.1262 45.8943C54.8474 43.4428 56.5389 42.2048 56.6002 37.1548C56.6247 35.3897 55.5583 33.1221 53.7564 33.1221C50.974 33.1221 50.8637 35.2671 50.8392 37.1548C49.1967 42.5848 41.5971 42.5235 41.5971 51.3121V63.2999C41.5971 65.9965 43.7789 68.1783 46.4755 68.1783H62.165C63.6359 68.1783 65.0578 66.9893 65.1191 65.5185C65.1926 63.925 63.9179 62.6134 62.3366 62.6134H62.8024C64.2733 62.6134 65.6952 61.4245 65.7565 59.9536C65.83 58.3601 64.5552 57.0486 62.974 57.0486H63.4398C64.9107 57.0486 66.3326 55.8596 66.3938 54.3887L66.4184 54.4132ZM36.6696 45.9433H34.0955C32.5388 45.9433 31.2886 47.2059 31.2886 48.7503V65.3714C31.2886 66.9281 32.5511 68.1783 34.0955 68.1783H36.6696C38.2263 68.1783 39.4888 66.9158 39.4888 65.3714V48.7503C39.4888 47.1936 38.2263 45.9433 36.6696 45.9433Z"
            fill="#A3A1A1"
          />
          <path
            d="M66.3329 54.2785C66.4065 52.685 65.1317 51.3734 63.5505 51.3734H64.0163C65.5362 51.3734 66.909 50.2335 66.9703 48.7136C67.0438 47.1201 65.7691 45.8086 64.1879 45.8086H52.053C52.053 45.8086 52.0162 45.7841 52.0408 45.7595C54.7619 43.3081 56.4534 42.0701 56.5147 37.02C56.5392 35.2549 55.4728 32.9873 53.671 32.9873C50.8886 32.9873 50.7782 35.1324 50.7537 37.02C49.1112 42.45 41.5116 42.3887 41.5116 51.1773V63.1651C41.5116 65.8617 43.6934 68.0435 46.3901 68.0435H62.0796C63.5505 68.0435 64.9723 66.8546 65.0336 65.3837C65.1072 63.7902 63.8324 62.4787 62.2512 62.4787H62.717C64.1879 62.4787 65.6097 61.2897 65.671 59.8188C65.7446 58.2253 64.4698 56.9138 62.8886 56.9138H63.3544C64.8253 56.9138 66.2471 55.7248 66.3084 54.2539L66.3329 54.2785ZM36.5841 45.8208H34.0101C32.4534 45.8208 31.2031 47.0834 31.2031 48.6278V65.2489C31.2031 66.8055 32.4656 68.0558 34.0101 68.0558H36.5841C38.1408 68.0558 39.4033 66.7933 39.4033 65.2489V48.6278C39.4033 47.0711 38.1408 45.8208 36.5841 45.8208Z"
            fill="#A2A0A0"
          />
          <path
            d="M66.2469 54.1554C66.3205 52.5619 65.0457 51.2504 63.4645 51.2504H63.9303C65.4502 51.2504 66.823 50.1105 66.8843 48.5905C66.9579 46.9971 65.6831 45.6855 64.1019 45.6855H51.967C51.967 45.6855 51.9303 45.661 51.9548 45.6365C54.6759 43.185 56.3674 41.947 56.4287 36.897C56.4532 35.1319 55.3868 32.8643 53.585 32.8643C50.8026 32.8643 50.6923 35.0093 50.6677 36.897C49.0252 42.327 41.4256 42.2657 41.4256 51.0543V63.042C41.4256 65.7387 43.6075 67.9205 46.3041 67.9205H61.9936C63.4645 67.9205 64.8864 66.7315 64.9476 65.2606C65.0212 63.6672 63.7464 62.3556 62.1652 62.3556H62.631C64.1019 62.3556 65.5237 61.1667 65.585 59.6958C65.6586 58.1023 64.3838 56.7908 62.8026 56.7908H63.2684C64.7393 56.7908 66.1611 55.6018 66.2224 54.1309L66.2469 54.1554ZM36.5104 45.6855H33.9363C32.3797 45.6855 31.1294 46.948 31.1294 48.4925V65.1135C31.1294 66.6702 32.3919 67.9205 33.9363 67.9205H36.5104C38.0671 67.9205 39.3296 66.658 39.3296 65.1135V48.4925C39.3296 46.9358 38.0671 45.6855 36.5104 45.6855Z"
            fill="#A19F9F"
          />
          <path
            d="M66.1605 54.0216C66.234 52.4282 64.9593 51.1166 63.3781 51.1166H63.8438C65.3638 51.1166 66.7366 49.9767 66.7979 48.4568C66.8714 46.8633 65.5967 45.5517 64.0154 45.5517H51.8806C51.8806 45.5517 51.8438 45.5272 51.8683 45.5027C54.5895 43.0512 56.281 41.8132 56.3423 36.7632C56.3668 34.9981 55.3004 32.7305 53.4986 32.7305C50.7161 32.7305 50.6058 34.8755 50.5813 36.7632C48.9388 42.1932 41.3392 42.1319 41.3392 50.9205V62.9083C41.3392 65.6049 43.521 67.7867 46.2177 67.7867H61.9072C63.3781 67.7867 64.7999 66.5977 64.8612 65.1269C64.9348 63.5334 63.66 62.2218 62.0788 62.2218H62.5446C64.0155 62.2218 65.4373 61.0329 65.4986 59.562C65.5722 57.9685 64.2974 56.657 62.7162 56.657H63.1819C64.6528 56.657 66.0747 55.468 66.136 53.9971L66.1605 54.0216ZM36.424 45.5517H33.8499C32.2932 45.5517 31.043 46.8143 31.043 48.3587V64.9798C31.043 66.5365 32.3055 67.7867 33.8499 67.7867H36.424C37.9807 67.7867 39.2432 66.5242 39.2432 64.9798V48.3587C39.2432 46.802 37.9807 45.5517 36.424 45.5517Z"
            fill="#A09E9E"
          />
          <path
            d="M66.0873 53.8986C66.1609 52.3051 64.8861 50.9936 63.3049 50.9936H63.7707C65.2906 50.9936 66.6634 49.8536 66.7247 48.3337C66.7982 46.7402 65.5235 45.4287 63.9423 45.4287H51.8074C51.8074 45.4287 51.7706 45.4042 51.7951 45.3797C54.5163 42.9282 56.2078 41.6902 56.2691 36.6401C56.2936 34.875 55.2272 32.6074 53.4254 32.6074C50.643 32.6074 50.5326 34.7525 50.5081 36.6401C48.8656 42.0702 41.266 42.0089 41.266 50.7974V62.7852C41.266 65.4818 43.4478 67.6637 46.1445 67.6637H61.834C63.3049 67.6637 64.7267 66.4747 64.788 65.0038C64.8616 63.4103 63.5868 62.0988 62.0056 62.0988H62.4714C63.9423 62.0988 65.3641 60.9098 65.4254 59.4389C65.499 57.8455 64.2242 56.5339 62.643 56.5339H63.1088C64.5796 56.5339 66.0015 55.3449 66.0628 53.8741L66.0873 53.8986ZM36.3385 45.4287H33.7645C32.2078 45.4287 30.9575 46.6912 30.9575 48.2356V64.8567C30.9575 66.4134 32.22 67.6637 33.7645 67.6637H36.3385C37.8952 67.6637 39.1577 66.4011 39.1577 64.8567V48.2356C39.1577 46.6789 37.8952 45.4287 36.3385 45.4287Z"
            fill="#9F9D9D"
          />
          <path
            d="M66.0013 53.7638C66.0749 52.1703 64.8001 50.8588 63.2189 50.8588H63.6847C65.2046 50.8588 66.5774 49.7189 66.6387 48.1989C66.7123 46.6055 65.4375 45.2939 63.8563 45.2939H51.7214C51.7214 45.2939 51.6846 45.2694 51.7092 45.2449C54.4303 42.7934 56.1218 41.5554 56.1831 36.5054C56.2076 34.7403 55.1412 32.4727 53.3394 32.4727C50.557 32.4727 50.4466 34.6177 50.4221 36.5054C48.7796 41.9354 41.18 41.8741 41.18 50.6627V62.6504C41.18 65.3471 43.3619 67.5289 46.0585 67.5289H61.748C63.2189 67.5289 64.6408 66.3399 64.702 64.869C64.7756 63.2756 63.5008 61.964 61.9196 61.964H62.3854C63.8563 61.964 65.2781 60.7751 65.3394 59.3042C65.413 57.7107 64.1382 56.3992 62.557 56.3992H63.0228C64.4937 56.3992 65.9155 55.2102 65.9768 53.7393L66.0013 53.7638ZM36.2648 45.2939H33.6907C32.134 45.2939 30.8838 46.5564 30.8838 48.1009V64.7219C30.8838 66.2786 32.1463 67.5289 33.6907 67.5289H36.2648C37.8215 67.5289 39.084 66.2664 39.084 64.7219V48.1009C39.084 46.5442 37.8215 45.2939 36.2648 45.2939Z"
            fill="#9E9C9C"
          />
          <path
            d="M65.9159 53.629C65.9894 52.0356 64.7147 50.724 63.1334 50.724H63.5992C65.1191 50.724 66.492 49.5841 66.5533 48.0642C66.6268 46.4707 65.352 45.1592 63.7708 45.1592H51.636C51.636 45.1592 51.5992 45.1346 51.6237 45.1101C54.3449 42.6586 56.0364 41.4206 56.0977 36.3706C56.1222 34.6055 55.0558 32.3379 53.254 32.3379C50.4715 32.3379 50.3612 34.4829 50.3367 36.3706C48.6942 41.8006 41.0946 41.7393 41.0946 50.5279V62.5157C41.0946 65.2123 43.2764 67.3941 45.973 67.3941H61.6625C63.1334 67.3941 64.5553 66.2052 64.6166 64.7343C64.6901 63.1408 63.4154 61.8293 61.8342 61.8293H62.2999C63.7708 61.8293 65.1927 60.6403 65.254 59.1694C65.3275 57.5759 64.0527 56.2644 62.4715 56.2644H62.9373C64.4082 56.2644 65.8301 55.0754 65.8914 53.6045L65.9159 53.629ZM36.1794 45.1714H33.6053C32.0486 45.1714 30.7983 46.4339 30.7983 47.9906V64.6117C30.7983 66.1684 32.0609 67.4186 33.6053 67.4186H36.1794C37.736 67.4186 38.9986 66.1561 38.9986 64.6117V47.9906C38.9986 46.4339 37.736 45.1714 36.1794 45.1714Z"
            fill="#9D9B9B"
          />
          <path
            d="M65.8427 53.506C65.9162 51.9125 64.6415 50.601 63.0602 50.601H63.526C65.046 50.601 66.4188 49.461 66.4801 47.9411C66.5536 46.3477 65.2788 45.0361 63.6976 45.0361H51.5628C51.5628 45.0361 51.526 45.0116 51.5505 44.9871C54.2717 42.5356 55.9632 41.2976 56.0245 36.2475C56.049 34.4825 54.9826 32.2148 53.1808 32.2148C50.3983 32.2148 50.288 34.3599 50.2635 36.2475C48.621 41.6776 41.0214 41.6163 41.0214 50.4049V62.3926C41.0214 65.0893 43.2032 67.2711 45.8998 67.2711H61.5894C63.0602 67.2711 64.4821 66.0821 64.5434 64.6112C64.6169 63.0178 63.3422 61.7062 61.761 61.7062H62.2267C63.6976 61.7062 65.1195 60.5172 65.1808 59.0464C65.2543 57.4529 63.9795 56.1413 62.3983 56.1413H62.8641C64.335 56.1413 65.7569 54.9524 65.8182 53.4815L65.8427 53.506ZM36.0939 45.0361H33.5198C31.9631 45.0361 30.7129 46.2986 30.7129 47.8431V64.4641C30.7129 66.0208 31.9754 67.2711 33.5198 67.2711H36.0939C37.6506 67.2711 38.9131 66.0086 38.9131 64.4641V47.8431C38.9131 46.2864 37.6506 45.0361 36.0939 45.0361Z"
            fill="#9C9A9A"
          />
          <path
            d="M65.7567 53.3712C65.8302 51.7778 64.5555 50.4662 62.9743 50.4662H63.44C64.96 50.4662 66.3328 49.3263 66.3941 47.8064C66.4676 46.2129 65.1929 44.9013 63.6116 44.9013H51.4768C51.4768 44.9013 51.44 44.8768 51.4645 44.8523C54.1857 42.4008 55.8772 41.1628 55.9385 36.1128C55.963 34.3477 54.8966 32.0801 53.0948 32.0801C50.3123 32.0801 50.202 34.2251 50.1775 36.1128C48.535 41.5428 40.9354 41.4815 40.9354 50.2701V62.2579C40.9354 64.9545 43.1172 67.1363 45.8139 67.1363H61.5034C62.9743 67.1363 64.3961 65.9474 64.4574 64.4765C64.5309 62.883 63.2562 61.5714 61.675 61.5714H62.1407C63.6116 61.5714 65.0335 60.3825 65.0948 58.9116C65.1683 57.3181 63.8936 56.0066 62.3124 56.0066H62.7781C64.249 56.0066 65.6709 54.8176 65.7322 53.3467L65.7567 53.3712ZM36.0202 44.9013H33.4461C31.8894 44.9013 30.6392 46.1639 30.6392 47.7083V64.3294C30.6392 65.8861 31.9017 67.1363 33.4461 67.1363H36.0202C37.5769 67.1363 38.8394 65.8738 38.8394 64.3294V47.7083C38.8394 46.1516 37.5769 44.9013 36.0202 44.9013Z"
            fill="#9B9999"
          />
          <path
            d="M65.6703 53.2482C65.7438 51.6547 64.469 50.3432 62.8878 50.3432H63.3536C64.8735 50.3432 66.2464 49.2032 66.3077 47.6833C66.3812 46.0898 65.1064 44.7783 63.5252 44.7783H51.3904C51.3904 44.7783 51.3536 44.7538 51.3781 44.7293C54.0993 42.2778 55.7908 41.0398 55.8521 35.9897C55.8766 34.2246 54.8102 31.957 53.0083 31.957C50.2259 31.957 50.1156 34.1021 50.0911 35.9897C48.4486 41.4198 40.849 41.3585 40.849 50.1471V62.1348C40.849 64.8314 43.0308 67.0133 45.7274 67.0133H61.4169C62.8878 67.0133 64.3097 65.8243 64.371 64.3534C64.4445 62.7599 63.1698 61.4484 61.5885 61.4484H62.0543C63.5252 61.4484 64.9471 60.2594 65.0084 58.7885C65.0819 57.1951 63.8071 55.8835 62.2259 55.8835H62.6917C64.1626 55.8835 65.5845 54.6946 65.6458 53.2237L65.6703 53.2482ZM35.9337 44.7783H33.3597C31.803 44.7783 30.5527 46.0408 30.5527 47.5853V64.2063C30.5527 65.763 31.8152 67.0133 33.3597 67.0133H35.9337C37.4904 67.0133 38.753 65.7508 38.753 64.2063V47.5853C38.753 46.0286 37.4904 44.7783 35.9337 44.7783Z"
            fill="#9A9898"
          />
          <path
            d="M65.5971 53.1144C65.6706 51.5209 64.3958 50.2094 62.8146 50.2094H63.2804C64.8003 50.2094 66.1732 49.0694 66.2345 47.5495C66.308 45.956 65.0332 44.6445 63.452 44.6445H51.3172C51.3172 44.6445 51.2804 44.62 51.3049 44.5955C54.0261 42.144 55.7176 40.906 55.7789 35.8559C55.8034 34.0909 54.737 31.8232 52.9351 31.8232C50.1527 31.8232 50.0424 33.9683 50.0179 35.8559C48.3754 41.286 40.7758 41.2247 40.7758 50.0133V62.001C40.7758 64.6977 42.9576 66.8795 45.6542 66.8795H61.3437C62.8146 66.8795 64.2365 65.6905 64.2978 64.2196C64.3713 62.6262 63.0966 61.3146 61.5153 61.3146H61.9811C63.452 61.3146 64.8739 60.1256 64.9352 58.6548C65.0087 57.0613 63.7339 55.7497 62.1527 55.7497H62.6185C64.0894 55.7497 65.5113 54.5608 65.5726 53.0899L65.5971 53.1144ZM35.8483 44.6445H33.2742C31.7175 44.6445 30.4673 45.907 30.4673 47.4515V64.0725C30.4673 65.6292 31.7298 66.8917 33.2742 66.8917H35.8483C37.405 66.8917 38.6675 65.6292 38.6675 64.0725V47.4515C38.6675 45.8948 37.405 44.6445 35.8483 44.6445Z"
            fill="#999797"
          />
          <path
            d="M65.5116 52.9786C65.5852 51.3852 64.3104 50.0736 62.7292 50.0736H63.195C64.7149 50.0736 66.0877 48.9337 66.149 47.4138C66.2226 45.8203 64.9478 44.5088 63.3666 44.5088H51.2317C51.2317 44.5088 51.195 44.4843 51.2195 44.4597C53.9406 42.0083 55.6321 40.7703 55.6934 35.7202C55.7179 33.9551 54.6515 31.6875 52.8497 31.6875C50.0673 31.6875 49.957 33.8326 49.9324 35.7202C48.2899 41.1502 40.6903 41.0889 40.6903 49.8775V61.8653C40.6903 64.5619 42.8722 66.7437 45.5688 66.7437H61.2583C62.7292 66.7437 64.1511 65.5548 64.2123 64.0839C64.2859 62.4904 63.0111 61.1789 61.4299 61.1789H61.8957C63.3666 61.1789 64.7884 59.9899 64.8497 58.519C64.9233 56.9255 63.6485 55.614 62.0673 55.614H62.5331C64.004 55.614 65.4258 54.425 65.4871 52.9541L65.5116 52.9786ZM35.7629 44.521H33.1888C31.6321 44.521 30.3818 45.7835 30.3818 47.328V63.9491C30.3818 65.5057 31.6444 66.756 33.1888 66.756H35.7629C37.3195 66.756 38.5821 65.4935 38.5821 63.9491V47.328C38.5821 45.7713 37.3195 44.521 35.7629 44.521Z"
            fill="#989696"
          />
          <path
            d="M65.4256 52.8566C65.4992 51.2631 64.2244 49.9516 62.6432 49.9516H63.109C64.6289 49.9516 66.0017 48.8116 66.063 47.2917C66.1366 45.6982 64.8618 44.3867 63.2806 44.3867H51.1457C51.1457 44.3867 51.109 44.3622 51.1335 44.3377C53.8546 41.8862 55.5462 40.6482 55.6074 35.5981C55.632 33.8331 54.5656 31.5654 52.7637 31.5654C49.9813 31.5654 49.871 33.7105 49.8464 35.5981C48.204 41.0282 40.6043 40.9669 40.6043 49.7555V61.7432C40.6043 64.4399 42.7862 66.6217 45.4828 66.6217H61.1723C62.6432 66.6217 64.0651 65.4327 64.1264 63.9618C64.1999 62.3684 62.9251 61.0568 61.3439 61.0568H61.8097C63.2806 61.0568 64.7024 59.8678 64.7637 58.397C64.8373 56.8035 63.5625 55.4919 61.9813 55.4919H62.4471C63.918 55.4919 65.3398 54.303 65.4011 52.8321L65.4256 52.8566ZM35.6891 44.3867H33.1151C31.5584 44.3867 30.3081 45.6492 30.3081 47.1937V63.8147C30.3081 65.3714 31.5706 66.6217 33.1151 66.6217H35.6891C37.2458 66.6217 38.5083 65.3592 38.5083 63.8147V47.1937C38.5083 45.637 37.2458 44.3867 35.6891 44.3867Z"
            fill="#979595"
          />
          <path
            d="M65.3397 52.7218C65.4132 51.1283 64.1385 49.8168 62.5573 49.8168H63.023C64.543 49.8168 65.9158 48.6769 65.9771 47.1569C66.0506 45.5635 64.7759 44.2519 63.1946 44.2519H51.0598C51.0598 44.2519 51.023 44.2274 51.0475 44.2029C53.7687 41.7514 55.4602 40.5134 55.5215 35.4634C55.546 33.6983 54.4796 31.4307 52.6778 31.4307C49.8953 31.4307 49.785 33.5757 49.7605 35.4634C48.118 40.8934 40.5184 40.8321 40.5184 49.6207V61.6084C40.5184 64.3051 42.7002 66.4869 45.3969 66.4869H61.0864C62.5573 66.4869 63.9791 65.2979 64.0404 63.827C64.114 62.2336 62.8392 60.922 61.258 60.922H61.7238C63.1947 60.922 64.6165 59.7331 64.6778 58.2622C64.7513 56.6687 63.4766 55.3572 61.8954 55.3572H62.3611C63.832 55.3572 65.2539 54.1682 65.3152 52.6973L65.3397 52.7218ZM35.6032 44.2642H33.0291C31.4724 44.2642 30.2222 45.5267 30.2222 47.0834V63.7045C30.2222 65.2612 31.4847 66.5114 33.0291 66.5114H35.6032C37.1599 66.5114 38.4224 65.2489 38.4224 63.7045V47.0834C38.4224 45.5267 37.1599 44.2642 35.6032 44.2642Z"
            fill="#969494"
          />
          <path
            d="M65.2661 52.5998C65.3396 51.0063 64.0648 49.6947 62.4836 49.6947H62.9494C64.4693 49.6947 65.8422 48.5548 65.9035 47.0349C65.977 45.4414 64.7022 44.1299 63.121 44.1299H50.9862C50.9862 44.1299 50.9494 44.1054 50.9739 44.0808C53.6951 41.6294 55.3866 40.3913 55.4479 35.3413C55.4724 33.5762 54.406 31.3086 52.6041 31.3086C49.8217 31.3086 49.7114 33.4536 49.6869 35.3413C48.0444 40.7713 40.4448 40.71 40.4448 49.4986V61.4864C40.4448 64.183 42.6266 66.3648 45.3232 66.3648H61.0127C62.4836 66.3648 63.9055 65.1759 63.9668 63.705C64.0403 62.1115 62.7656 60.8 61.1843 60.8H61.6501C63.121 60.8 64.5429 59.611 64.6042 58.1401C64.6777 56.5466 63.4029 55.2351 61.8217 55.2351H62.2875C63.7584 55.2351 65.1803 54.0461 65.2416 52.5752L65.2661 52.5998ZM35.5173 44.1299H32.9432C31.3865 44.1299 30.124 45.3924 30.124 46.9368V63.5579C30.124 65.1146 31.3865 66.3648 32.9432 66.3648H35.5173C37.074 66.3648 38.3365 65.1023 38.3365 63.5579V46.9368C38.3365 45.3801 37.074 44.1299 35.5173 44.1299Z"
            fill="#959393"
          />
          <path
            d="M65.1806 52.464C65.2541 50.8705 63.9793 49.559 62.3981 49.559H62.8639C64.3838 49.559 65.7567 48.4191 65.818 46.8991C65.8915 45.3057 64.6167 43.9941 63.0355 43.9941H50.9007C50.9007 43.9941 50.8639 43.9696 50.8884 43.9451C53.6096 41.4936 55.3011 40.2556 55.3624 35.2055C55.3869 33.4405 54.3205 31.1729 52.5186 31.1729C49.7362 31.1729 49.6259 33.3179 49.6014 35.2055C47.9589 40.6356 40.3593 40.5743 40.3593 49.3629V61.3506C40.3593 64.0473 42.5411 66.2291 45.2377 66.2291H60.9272C62.3981 66.2291 63.82 65.0401 63.8813 63.5692C63.9548 61.9758 62.6801 60.6642 61.0988 60.6642H61.5646C63.0355 60.6642 64.4574 59.4753 64.5187 58.0044C64.5922 56.4109 63.3174 55.0994 61.7362 55.0994H62.202C63.6729 55.0994 65.0948 53.9104 65.1561 52.4395L65.1806 52.464ZM35.444 43.9941H32.87C31.3133 43.9941 30.0508 45.2566 30.0508 46.8011V63.4222C30.0508 64.9788 31.3133 66.2291 32.87 66.2291H35.444C37.0007 66.2291 38.2633 64.9666 38.2633 63.4222V46.8011C38.2633 45.2444 37.0007 43.9941 35.444 43.9941Z"
            fill="#949292"
          />
          <path
            d="M65.0942 52.3419C65.1677 50.7485 63.8929 49.4369 62.3117 49.4369H62.7775C64.2974 49.4369 65.6702 48.297 65.7315 46.7771C65.8051 45.1836 64.5303 43.8721 62.9491 43.8721H50.8142C50.8142 43.8721 50.7775 43.8475 50.802 43.823C53.5231 41.3715 55.2147 40.1335 55.2759 35.0835C55.3005 33.3184 54.2341 31.0508 52.4322 31.0508C49.6498 31.0508 49.5395 33.1958 49.515 35.0835C47.8725 40.5135 40.2729 40.4522 40.2729 49.2408V61.2286C40.2729 63.9252 42.4547 66.107 45.1513 66.107H60.8408C62.3117 66.107 63.7336 64.9181 63.7949 63.4472C63.8684 61.8537 62.5936 60.5422 61.0124 60.5422H61.4782C62.9491 60.5422 64.371 59.3532 64.4322 57.8823C64.5058 56.2888 63.231 54.9773 61.6498 54.9773H62.1156C63.5865 54.9773 65.0083 53.7883 65.0696 52.3174L65.0942 52.3419ZM35.3576 43.8721H32.7836C31.2269 43.8721 29.9644 45.1346 29.9644 46.679V63.3001C29.9644 64.8568 31.2269 66.107 32.7836 66.107H35.3576C36.9143 66.107 38.1646 64.8445 38.1646 63.3001V46.679C38.1646 45.1223 36.9021 43.8721 35.3576 43.8721Z"
            fill="#939191"
          />
          <path
            d="M65.021 52.2072C65.0945 50.6137 63.8197 49.3022 62.2385 49.3022H62.7043C64.2242 49.3022 65.5971 48.1622 65.6583 46.6423C65.7319 45.0488 64.4571 43.7373 62.8759 43.7373H50.741C50.741 43.7373 50.7043 43.7128 50.7288 43.6883C53.4499 41.2368 55.1415 39.9988 55.2028 34.9487C55.2273 33.1836 54.1609 30.916 52.359 30.916C49.5766 30.916 49.4663 33.0611 49.4418 34.9487C47.7993 40.3788 40.1997 40.3175 40.1997 49.106V61.0938C40.1997 63.7904 42.3815 65.9723 45.0781 65.9723H60.7676C62.2385 65.9723 63.6604 64.7833 63.7217 63.3124C63.7952 61.7189 62.5204 60.4074 60.9392 60.4074H61.405C62.8759 60.4074 64.2978 59.2184 64.3591 57.7475C64.4326 56.1541 63.1578 54.8425 61.5766 54.8425H62.0424C63.5133 54.8425 64.9352 53.6536 64.9964 52.1827L65.021 52.2072ZM35.2722 43.7373H32.6981C31.1414 43.7373 29.8789 44.9998 29.8789 46.5442V63.1653C29.8789 64.722 31.1414 65.9723 32.6981 65.9723H35.2722C36.8289 65.9723 38.0791 64.7098 38.0791 63.1653V46.5442C38.0791 44.9875 36.8166 43.7373 35.2722 43.7373Z"
            fill="#929090"
          />
          <path
            d="M64.935 52.0714C65.0085 50.478 63.7337 49.1664 62.1525 49.1664H62.6183C64.1382 49.1664 65.5111 48.0265 65.5724 46.5066C65.6459 44.9131 64.3711 43.6016 62.7899 43.6016H50.6551C50.6551 43.6016 50.6183 43.577 50.6428 43.5525C53.364 41.101 55.0555 39.863 55.1168 34.813C55.1413 33.0479 54.0749 30.7803 52.273 30.7803C49.4906 30.7803 49.3803 32.9253 49.3558 34.813C47.7133 40.243 40.1137 40.1817 40.1137 48.9703V60.9581C40.1137 63.6547 42.2955 65.8365 44.9921 65.8365H60.6816C62.1525 65.8365 63.5744 64.6475 63.6357 63.1767C63.7092 61.5832 62.4344 60.2717 60.8532 60.2717H61.319C62.7899 60.2717 64.2118 59.0827 64.2731 57.6118C64.3466 56.0183 63.0718 54.7068 61.4906 54.7068H61.9564C63.4273 54.7068 64.8492 53.5178 64.9104 52.0469L64.935 52.0714ZM35.1984 43.6138H32.6244C31.0677 43.6138 29.8052 44.8763 29.8052 46.4208V63.0418C29.8052 64.5985 31.0677 65.8488 32.6244 65.8488H35.1984C36.7551 65.8488 38.0054 64.5863 38.0054 63.0418V46.4208C38.0054 44.8641 36.7429 43.6138 35.1984 43.6138Z"
            fill="#918F90"
          />
          <path
            d="M64.8495 51.9494C64.9231 50.3559 63.6483 49.0443 62.0671 49.0443H62.5329C64.0528 49.0443 65.4256 47.9044 65.4869 46.3845C65.5604 44.791 64.2857 43.4795 62.7045 43.4795H50.5696C50.5696 43.4795 50.5328 43.455 50.5574 43.4304C53.2785 40.979 54.97 39.741 55.0313 34.6909C55.0558 32.9258 53.9894 30.6582 52.1876 30.6582C49.4052 30.6582 49.2948 32.8033 49.2703 34.6909C47.6278 40.1209 40.0282 40.0597 40.0282 48.8482V60.836C40.0282 63.5326 42.21 65.7144 44.9067 65.7144H60.5962C62.0671 65.7144 63.4889 64.5255 63.5502 63.0546C63.6238 61.4611 62.349 60.1496 60.7678 60.1496H61.2336C62.7045 60.1496 64.1263 58.9606 64.1876 57.4897C64.2612 55.8962 62.9864 54.5847 61.4052 54.5847H61.871C63.3419 54.5847 64.7637 53.3957 64.825 51.9248L64.8495 51.9494ZM35.113 43.4795H32.5389C30.9822 43.4795 29.7197 44.742 29.7197 46.2864V62.9075C29.7197 64.4642 30.9822 65.7144 32.5389 65.7144H35.113C36.6697 65.7144 37.9199 64.4519 37.9199 62.9075V46.2864C37.9199 44.7297 36.6574 43.4795 35.113 43.4795Z"
            fill="#908E8F"
          />
          <path
            d="M64.7758 51.8146C64.8494 50.2211 63.5746 48.9096 61.9934 48.9096H62.4592C63.9791 48.9096 65.3519 47.7696 65.4132 46.2497C65.4868 44.6563 64.212 43.3447 62.6308 43.3447H50.4959C50.4959 43.3447 50.4592 43.3202 50.4837 43.2957C53.2048 40.8442 54.8964 39.6062 54.9576 34.5561C54.9822 32.7911 53.9158 30.5234 52.1139 30.5234C49.3315 30.5234 49.2212 32.6685 49.1966 34.5561C47.5541 39.9862 39.9545 39.9249 39.9545 48.7135V60.7012C39.9545 63.3979 42.1364 65.5797 44.833 65.5797H60.5225C61.9934 65.5797 63.4153 64.3907 63.4766 62.9198C63.5501 61.3264 62.2753 60.0148 60.6941 60.0148H61.1599C62.6308 60.0148 64.0526 58.8258 64.1139 57.3549C64.1875 55.7615 62.9127 54.4499 61.3315 54.4499H61.7973C63.2682 54.4499 64.69 53.261 64.7513 51.7901L64.7758 51.8146ZM35.0271 43.3447H32.453C30.8963 43.3447 29.6338 44.6072 29.6338 46.1517V62.7727C29.6338 64.3294 30.8963 65.5797 32.453 65.5797H35.0271C36.5837 65.5797 37.834 64.3172 37.834 62.7727V46.1517C37.834 44.595 36.5715 43.3447 35.0271 43.3447Z"
            fill="#8F8D8E"
          />
          <path
            d="M64.6898 51.6925C64.7634 50.0991 63.4886 48.7875 61.9074 48.7875H62.3732C63.8931 48.7875 65.2659 47.6476 65.3272 46.1276C65.4008 44.5342 64.126 43.2226 62.5448 43.2226H50.4099C50.4099 43.2226 50.3732 43.1981 50.3977 43.1736C53.1188 40.7221 54.8104 39.4841 54.8716 34.4341C54.8962 32.669 53.8298 30.4014 52.0279 30.4014C49.2455 30.4014 49.1352 32.5464 49.1107 34.4341C47.4682 39.8641 39.8686 39.8028 39.8686 48.5914V60.5792C39.8686 63.2758 42.0504 65.4576 44.747 65.4576H60.4365C61.9074 65.4576 63.3293 64.2686 63.3906 62.7977C63.4641 61.2043 62.1893 59.8927 60.6081 59.8927H61.0739C62.5448 59.8927 63.9667 58.7038 64.0279 57.2329C64.1015 55.6394 62.8267 54.3279 61.2455 54.3279H61.7113C63.1822 54.3279 64.604 53.1389 64.6653 51.668L64.6898 51.6925ZM34.9411 43.2226H32.367C30.8103 43.2226 29.5601 44.4852 29.5601 46.0296V62.6507C29.5601 64.2074 30.8226 65.4576 32.367 65.4576H34.9411C36.4978 65.4576 37.748 64.1951 37.748 62.6507V46.0296C37.748 44.4729 36.4855 43.2226 34.9411 43.2226Z"
            fill="#8E8C8D"
          />
          <path
            d="M64.6044 51.5568C64.6779 49.9633 63.4031 48.6518 61.8219 48.6518H62.2877C63.8076 48.6518 65.1804 47.5118 65.2417 45.9919C65.3153 44.3984 64.0405 43.0869 62.4593 43.0869H50.3244C50.3244 43.0869 50.2877 43.0624 50.3122 43.0379C53.0333 40.5864 54.7249 39.3484 54.7861 34.2983C54.8107 32.5333 53.7443 30.2656 51.9424 30.2656C49.16 30.2656 49.0497 32.4107 49.0252 34.2983C47.3827 39.7284 39.7831 39.6671 39.7831 48.4557V60.4434C39.7831 63.14 41.9649 65.3219 44.6615 65.3219H60.351C61.8219 65.3219 63.2438 64.1329 63.3051 62.662C63.3786 61.0685 62.1038 59.757 60.5226 59.757H60.9884C62.4593 59.757 63.8812 58.568 63.9425 57.0971C64.016 55.5037 62.7412 54.1921 61.16 54.1921H61.6258C63.0967 54.1921 64.5186 53.0032 64.5798 51.5323L64.6044 51.5568ZM34.8678 43.0869H32.2938C30.7371 43.0869 29.4868 44.3494 29.4868 45.8938V62.5149C29.4868 64.0716 30.7493 65.3219 32.2938 65.3219H34.8678C36.4245 65.3219 37.6748 64.0594 37.6748 62.5149V45.8938C37.6748 44.3372 36.4123 43.0869 34.8678 43.0869Z"
            fill="#8D8B8C"
          />
          <path
            d="M64.5184 51.423C64.592 49.8295 63.3172 48.518 61.736 48.518H62.2018C63.7217 48.518 65.0945 47.378 65.1558 45.8581C65.2293 44.2647 63.9546 42.9531 62.3734 42.9531H50.2385C50.2385 42.9531 50.2017 42.9286 50.2263 42.9041C52.9474 40.4526 54.6389 39.2146 54.7002 34.1645C54.7247 32.3995 53.6583 30.1318 51.8565 30.1318C49.0741 30.1318 48.9637 32.2769 48.9392 34.1645C47.2967 39.5946 39.6971 39.5333 39.6971 48.3219V60.3096C39.6971 63.0063 41.8789 65.1881 44.5756 65.1881H60.2651C61.736 65.1881 63.1578 63.9991 63.2191 62.5282C63.2927 60.9348 62.0179 59.6232 60.4367 59.6232H60.9025C62.3734 59.6232 63.7952 58.4342 63.8565 56.9633C63.9301 55.3699 62.6553 54.0583 61.0741 54.0583H61.5399C63.0108 54.0583 64.4326 52.8694 64.4939 51.3985L64.5184 51.423ZM34.7819 42.9654H32.2078C30.6511 42.9654 29.4009 44.2279 29.4009 45.7723V62.3934C29.4009 63.9501 30.6634 65.2003 32.2078 65.2003H34.7819C36.3386 65.2003 37.5888 63.9378 37.5888 62.3934V45.7723C37.5888 44.2156 36.3263 42.9654 34.7819 42.9654Z"
            fill="#8C8A8B"
          />
          <path
            d="M64.4452 51.2999C64.5188 49.7065 63.244 48.3949 61.6628 48.3949H62.1286C63.6485 48.3949 65.0213 47.255 65.0826 45.7351C65.1562 44.1416 63.8814 42.8301 62.3002 42.8301H50.1653C50.1653 42.8301 50.1285 42.8055 50.1531 42.781C52.8742 40.3295 54.5657 39.0915 54.627 34.0415C54.6515 32.2764 53.5851 30.0088 51.7833 30.0088C49.0009 30.0088 48.8905 32.1538 48.866 34.0415C47.2235 39.4715 39.6239 39.4102 39.6239 48.1988V60.1866C39.6239 62.8832 41.8057 65.065 44.5024 65.065H60.1919C61.6628 65.065 63.0846 63.8761 63.1459 62.4052C63.2195 60.8117 61.9447 59.5002 60.3635 59.5002H60.8293C62.3002 59.5002 63.722 58.3112 63.7833 56.8403C63.8569 55.2468 62.5821 53.9353 61.0009 53.9353H61.4667C62.9376 53.9353 64.3594 52.7463 64.4207 51.2754L64.4452 51.2999ZM34.6964 42.8301H32.1224C30.5657 42.8301 29.3154 44.0926 29.3154 45.637V62.2581C29.3154 63.8148 30.5779 65.065 32.1224 65.065H34.6964C36.2531 65.065 37.5034 63.8025 37.5034 62.2581V45.637C37.5034 44.0803 36.2409 42.8301 34.6964 42.8301Z"
            fill="#8B898A"
          />
          <path
            d="M64.3592 51.1652C64.4328 49.5717 63.158 48.2602 61.5768 48.2602H62.0426C63.5625 48.2602 64.9353 47.1202 64.9966 45.6003C65.0702 44.0068 63.7954 42.6953 62.2142 42.6953H50.0793C50.0793 42.6953 50.0426 42.6708 50.0671 42.6463C52.7882 40.1948 54.4797 38.9568 54.541 33.9067C54.5655 32.1416 53.4992 29.874 51.6973 29.874C48.9149 29.874 48.8046 32.0191 48.78 33.9067C47.1375 39.3368 39.5379 39.2755 39.5379 48.0641V60.0518C39.5379 62.7484 41.7198 64.9303 44.4164 64.9303H60.1059C61.5768 64.9303 62.9987 63.7413 63.0599 62.2704C63.1335 60.6769 61.8587 59.3654 60.2775 59.3654H60.7433C62.2142 59.3654 63.636 58.1764 63.6973 56.7055C63.7709 55.1121 62.4961 53.8005 60.9149 53.8005H61.3807C62.8516 53.8005 64.2734 52.6116 64.3347 51.1407L64.3592 51.1652ZM34.6227 42.7076H32.0486C30.492 42.7076 29.2417 43.9701 29.2417 45.5145V62.1356C29.2417 63.6923 30.5042 64.9425 32.0486 64.9425H34.6227C36.1794 64.9425 37.4297 63.68 37.4297 62.1356V45.5145C37.4297 43.9578 36.1671 42.7076 34.6227 42.7076Z"
            fill="#8A8889"
          />
          <path
            d="M64.2733 51.0421C64.3468 49.4487 63.0721 48.1371 61.4909 48.1371H61.9566C63.4766 48.1371 64.8494 46.9972 64.9107 45.4773C64.9842 43.8838 63.7095 42.5722 62.1283 42.5722H49.9934C49.9934 42.5722 49.9566 42.5477 49.9811 42.5232C52.7023 40.0717 54.3938 38.8337 54.4551 33.7837C54.4796 32.0186 53.4132 29.751 51.6114 29.751C48.8289 29.751 48.7186 31.896 48.6941 33.7837C47.0516 39.2137 39.452 39.1524 39.452 47.941V59.9288C39.452 62.6254 41.6338 64.8072 44.3305 64.8072H60.02C61.4909 64.8072 62.9127 63.6183 62.974 62.1474C63.0476 60.5539 61.7728 59.2423 60.1916 59.2423H60.6574C62.1282 59.2423 63.5501 58.0534 63.6114 56.5825C63.6849 54.989 62.4102 53.6775 60.829 53.6775H61.2947C62.7656 53.6775 64.1875 52.4885 64.2488 51.0176L64.2733 51.0421ZM34.5368 42.5722H31.9627C30.406 42.5722 29.1558 43.8348 29.1558 45.3792V62.0003C29.1558 63.557 30.4183 64.8195 31.9627 64.8195H34.5368C36.0935 64.8195 37.3437 63.557 37.3437 62.0003V45.3792C37.3437 43.8225 36.0812 42.5722 34.5368 42.5722Z"
            fill="#898788"
          />
          <path
            d="M64.1996 50.9083C64.2732 49.3149 62.9984 48.0033 61.4172 48.0033H61.883C63.4029 48.0033 64.7757 46.8634 64.837 45.3435C64.9106 43.75 63.6358 42.4385 62.0546 42.4385H49.9197C49.9197 42.4385 49.8829 42.4139 49.9075 42.3894C52.6286 39.9379 54.3201 38.6999 54.3814 33.6499C54.4059 31.8848 53.3395 29.6172 51.5377 29.6172C48.7553 29.6172 48.6449 31.7622 48.6204 33.6499C46.9779 39.0799 39.3783 39.0186 39.3783 47.8072V59.795C39.3783 62.4916 41.5601 64.6734 44.2568 64.6734H59.9463C61.4172 64.6734 62.839 63.4845 62.9003 62.0136C62.9739 60.4201 61.6991 59.1086 60.1179 59.1086H60.5837C62.0546 59.1086 63.4764 57.9196 63.5377 56.4487C63.6113 54.8552 62.3365 53.5437 60.7553 53.5437H61.2211C62.692 53.5437 64.1138 52.3547 64.1751 50.8838L64.1996 50.9083ZM34.4508 42.4385H31.8768C30.3201 42.4385 29.0698 43.701 29.0698 45.2454V61.8665C29.0698 63.4232 30.3323 64.6734 31.8768 64.6734H34.4508C36.0075 64.6734 37.2578 63.4109 37.2578 61.8665V45.2454C37.2578 43.6887 35.9953 42.4385 34.4508 42.4385Z"
            fill="#888687"
          />
          <path
            d="M64.1136 50.7843C64.1872 49.1908 62.9124 47.8793 61.3312 47.8793H61.797C63.3169 47.8793 64.6897 46.7394 64.751 45.2194C64.8246 43.626 63.5498 42.3144 61.9686 42.3144H49.8337C49.8337 42.3144 49.7969 42.2899 49.8215 42.2654C52.5426 39.8139 54.2341 38.5759 54.2954 33.5259C54.3199 31.7608 53.2535 29.4932 51.4517 29.4932C48.6693 29.4932 48.5589 31.6382 48.5344 33.5259C46.8919 38.9559 39.2923 38.8946 39.2923 47.6832V59.6709C39.2923 62.3676 41.4742 64.5494 44.1708 64.5494H59.8603C61.3312 64.5494 62.7531 63.3604 62.8143 61.8895C62.8879 60.2961 61.6131 58.9845 60.0319 58.9845H60.4977C61.9686 58.9845 63.3904 57.7956 63.4517 56.3247C63.5253 54.7312 62.2505 53.4197 60.6693 53.4197H61.1351C62.606 53.4197 64.0278 52.2307 64.0891 50.7598L64.1136 50.7843ZM34.3771 42.3144H31.803C30.2463 42.3144 28.9961 43.5769 28.9961 45.1214V61.7425C28.9961 63.2991 30.2586 64.5494 31.803 64.5494H34.3771C35.9338 64.5494 37.1841 63.2869 37.1841 61.7425V45.1214C37.1841 43.5647 35.9215 42.3144 34.3771 42.3144Z"
            fill="#878586"
          />
          <path
            d="M64.0282 50.6505C64.1017 49.0571 62.8269 47.7455 61.2457 47.7455H61.7115C63.2314 47.7455 64.6043 46.6056 64.6656 45.0857C64.7391 43.4922 63.4643 42.1806 61.8831 42.1806H49.7483C49.7483 42.1806 49.7115 42.1561 49.736 42.1316C52.4572 39.6801 54.1487 38.4421 54.21 33.3921C54.2345 31.627 53.1681 29.3594 51.3663 29.3594C48.5838 29.3594 48.4735 31.5044 48.449 33.3921C46.8065 38.8221 39.2069 38.7608 39.2069 47.5494V59.5372C39.2069 62.2338 41.3887 64.4156 44.0853 64.4156H59.7749C61.2457 64.4156 62.6676 63.2267 62.7289 61.7558C62.8024 60.1623 61.5277 58.8507 59.9465 58.8507H60.4122C61.8831 58.8507 63.305 57.6618 63.3663 56.1909C63.4398 54.5974 62.1651 53.2859 60.5838 53.2859H61.0496C62.5205 53.2859 63.9424 52.0969 64.0037 50.626L64.0282 50.6505ZM34.2917 42.1806H31.7176C30.1609 42.1806 28.9106 43.4432 28.9106 44.9999V61.6209C28.9106 63.1776 30.1732 64.4279 31.7176 64.4279H34.2917C35.8483 64.4279 37.0986 63.1654 37.0986 61.6209V44.9999C37.0986 43.4432 35.8361 42.1806 34.2917 42.1806Z"
            fill="#868485"
          />
          <path
            d="M63.955 50.5158C64.0285 48.9223 62.7538 47.6108 61.1725 47.6108H61.6383C63.1582 47.6108 64.5311 46.4708 64.5924 44.9509C64.6659 43.3574 63.3911 42.0459 61.8099 42.0459H49.6751C49.6751 42.0459 49.6383 42.0214 49.6628 41.9968C52.384 39.5454 54.0755 38.3074 54.1368 33.2573C54.1613 31.4922 53.0949 29.2246 51.2931 29.2246C48.5106 29.2246 48.4003 31.3697 48.3758 33.2573C46.7333 38.6873 39.1337 38.6261 39.1337 47.4146V59.4024C39.1337 62.099 41.3155 64.2809 44.0121 64.2809H59.7017C61.1725 64.2809 62.5944 63.0919 62.6557 61.621C62.7292 60.0275 61.4545 58.716 59.8733 58.716H60.339C61.8099 58.716 63.2318 57.527 63.2931 56.0561C63.3666 54.4627 62.0919 53.1511 60.5106 53.1511H60.9764C62.4473 53.1511 63.8692 51.9621 63.9305 50.4912L63.955 50.5158ZM34.2062 42.0581H31.6321C30.0754 42.0581 28.8252 43.3207 28.8252 44.8651V61.4862C28.8252 63.0429 30.0877 64.2931 31.6321 64.2931H34.2062C35.7629 64.2931 37.0132 63.0306 37.0132 61.4862V44.8651C37.0132 43.3084 35.7506 42.0581 34.2062 42.0581Z"
            fill="#858384"
          />
          <path
            d="M63.8691 50.3937C63.9426 48.8002 62.6679 47.4887 61.0867 47.4887H61.5524C63.0724 47.4887 64.4452 46.3487 64.5065 44.8288C64.58 43.2354 63.3053 41.9238 61.7241 41.9238H49.5892C49.5892 41.9238 49.5524 41.8993 49.5769 41.8748C52.2981 39.4233 53.9896 38.1853 54.0509 33.1352C54.0754 31.3702 53.009 29.1025 51.2072 29.1025C48.4247 29.1025 48.3144 31.2476 48.2899 33.1352C46.6474 38.5653 39.0478 38.504 39.0478 47.2926V59.2803C39.0478 61.977 41.2296 64.1588 43.9263 64.1588H59.6158C61.0867 64.1588 62.5085 62.9698 62.5698 61.4989C62.6434 59.9055 61.3686 58.5939 59.7874 58.5939H60.2532C61.7241 58.5939 63.1459 57.4049 63.2072 55.934C63.2807 54.3406 62.006 53.029 60.4248 53.029H60.8905C62.3614 53.029 63.7833 51.8401 63.8446 50.3692L63.8691 50.3937ZM34.1203 41.9238H31.5463C29.9896 41.9238 28.7271 43.1863 28.7271 44.7308V61.3518C28.7271 62.9085 29.9896 64.1588 31.5463 64.1588H34.1203C35.677 64.1588 36.9273 62.8963 36.9273 61.3518V44.7308C36.9273 43.1741 35.6648 41.9238 34.1203 41.9238Z"
            fill="#848283"
          />
          <path
            d="M63.7831 50.2579C63.8567 48.6645 62.5819 47.3529 61.0007 47.3529H61.4665C62.9864 47.3529 64.3592 46.213 64.4205 44.6931C64.494 43.0996 63.2193 41.7881 61.6381 41.7881H49.5032C49.5032 41.7881 49.4664 41.7635 49.4909 41.739C52.2121 39.2875 53.9036 38.0496 53.9649 32.9995C53.9894 31.2344 52.923 28.9668 51.1212 28.9668C48.3388 28.9668 48.2284 31.1118 48.2039 32.9995C46.5614 38.4295 38.9618 38.3682 38.9618 47.1568V59.1446C38.9618 61.8412 41.1436 64.023 43.8403 64.023H59.5298C61.0007 64.023 62.4225 62.8341 62.4838 61.3632C62.5574 59.7697 61.2826 58.4582 59.7014 58.4582H60.1672C61.6381 58.4582 63.0599 57.2692 63.1212 55.7983C63.1948 54.2048 61.92 52.8933 60.3388 52.8933H60.8046C62.2755 52.8933 63.6973 51.7043 63.7586 50.2334L63.7831 50.2579ZM34.0466 41.7881H31.4725C29.9158 41.7881 28.6533 43.0506 28.6533 44.595V61.2161C28.6533 62.7728 29.9158 64.023 31.4725 64.023H34.0466C35.6033 64.023 36.8535 62.7605 36.8535 61.2161V44.595C36.8535 43.0383 35.591 41.7881 34.0466 41.7881Z"
            fill="#838182"
          />
          <path
            d="M63.6971 50.1349C63.7707 48.5414 62.4959 47.2299 60.9147 47.2299H61.3805C62.9004 47.2299 64.2732 46.0899 64.3345 44.57C64.4081 42.9766 63.1333 41.665 61.5521 41.665H49.4172C49.4172 41.665 49.3804 41.6405 49.405 41.616C52.1261 39.1645 53.8176 37.9265 53.8789 32.8764C53.9034 31.1114 52.837 28.8438 51.0352 28.8438C48.2528 28.8438 48.1424 30.9888 48.1179 32.8764C46.4754 38.3065 38.8758 38.2452 38.8758 47.0338V59.0215C38.8758 61.7182 41.0576 63.9 43.7543 63.9H59.4438C60.9147 63.9 62.3365 62.711 62.3978 61.2401C62.4714 59.6467 61.1966 58.3351 59.6154 58.3351H60.0812C61.5521 58.3351 62.9739 57.1462 63.0352 55.6753C63.1088 54.0818 61.834 52.7702 60.2528 52.7702H60.7186C62.1895 52.7702 63.6113 51.5813 63.6726 50.1104L63.6971 50.1349ZM33.9606 41.665H31.3865C29.8298 41.665 28.5796 42.9275 28.5796 44.472V61.093C28.5796 62.6497 29.8421 63.9123 31.3865 63.9123H33.9606C35.5173 63.9123 36.7676 62.6497 36.7676 61.093V44.472C36.7676 42.9153 35.505 41.665 33.9606 41.665Z"
            fill="#828081"
          />
          <path
            d="M63.6239 50.0011C63.6975 48.4076 62.4227 47.0961 60.8415 47.0961H61.3073C62.8272 47.0961 64.2 45.9562 64.2613 44.4362C64.3349 42.8428 63.0601 41.5312 61.4789 41.5312H49.344C49.344 41.5312 49.3073 41.5067 49.3318 41.4822C52.0529 39.0307 53.7444 37.7927 53.8057 32.7427C53.8302 30.9776 52.7638 28.71 50.962 28.71C48.1796 28.71 48.0693 30.855 48.0447 32.7427C46.4022 38.1727 38.8026 38.1114 38.8026 46.9V58.8877C38.8026 61.5844 40.9845 63.7662 43.6811 63.7662H59.3706C60.8415 63.7662 62.2634 62.5772 62.3246 61.1063C62.3982 59.5129 61.1234 58.2013 59.5422 58.2013H60.008C61.4789 58.2013 62.9007 57.0124 62.962 55.5415C63.0356 53.948 61.7608 52.6365 60.1796 52.6365H60.6454C62.1163 52.6365 63.5381 51.4475 63.5994 49.9766L63.6239 50.0011ZM33.8752 41.5312H31.3011C29.7444 41.5312 28.4941 42.7937 28.4941 44.3382V60.9593C28.4941 62.5159 29.7567 63.7662 31.3011 63.7662H33.8752C35.4318 63.7662 36.6821 62.5037 36.6821 60.9593V44.3382C36.6821 42.7815 35.4196 41.5312 33.8752 41.5312Z"
            fill="#817F80"
          />
          <path
            d="M63.5379 49.8654C63.6115 48.2719 62.3367 46.9604 60.7555 46.9604H61.2213C62.7412 46.9604 64.114 45.8204 64.1753 44.3005C64.2489 42.707 62.9741 41.3955 61.3929 41.3955H49.258C49.258 41.3955 49.2213 41.371 49.2458 41.3465C51.9669 38.895 53.6585 37.657 53.7197 32.6069C53.7443 30.8418 52.6779 28.5742 50.876 28.5742C48.0936 28.5742 47.9833 30.7193 47.9588 32.6069C46.3163 38.037 38.7167 37.9757 38.7167 46.7642V58.752C38.7167 61.4486 40.8985 63.6305 43.5951 63.6305H59.2846C60.7555 63.6305 62.1774 62.4415 62.2387 60.9706C62.3122 59.3771 61.0374 58.0656 59.4562 58.0656H59.922C61.3929 58.0656 62.8148 56.8766 62.876 55.4057C62.9496 53.8123 61.6748 52.5007 60.0936 52.5007H60.5594C62.0303 52.5007 63.4521 51.3117 63.5134 49.8409L63.5379 49.8654ZM33.8014 41.4077H31.2274C29.6707 41.4077 28.4204 42.6703 28.4204 44.2147V60.8358C28.4204 62.3925 29.6829 63.6427 31.2274 63.6427H33.8014C35.3581 63.6427 36.6084 62.3802 36.6084 60.8358V44.2147C36.6084 42.658 35.3459 41.4077 33.8014 41.4077Z"
            fill="#807E7F"
          />
          <path
            d="M63.452 49.7433C63.5256 48.1498 62.2508 46.8383 60.6696 46.8383H61.1354C62.6553 46.8383 64.0281 45.6983 64.0894 44.1784C64.1629 42.585 62.8882 41.2734 61.307 41.2734H49.1721C49.1721 41.2734 49.1353 41.2489 49.1598 41.2244C51.881 38.7729 53.5725 37.5349 53.6338 32.4848C53.6583 30.7198 52.5919 28.4521 50.7901 28.4521C48.0076 28.4521 47.8973 30.5972 47.8728 32.4848C46.2303 37.9149 38.6307 37.8536 38.6307 46.6422V58.6299C38.6307 61.3266 40.8125 63.5084 43.5092 63.5084H59.1987C60.6696 63.5084 62.0914 62.3194 62.1527 60.8485C62.2263 59.2551 60.9515 57.9435 59.3703 57.9435H59.8361C61.307 57.9435 62.7288 56.7545 62.7901 55.2837C62.8636 53.6902 61.5889 52.3786 60.0077 52.3786H60.4735C61.9443 52.3786 63.3662 51.1897 63.4275 49.7188L63.452 49.7433ZM33.7155 41.2734H31.1414C29.5847 41.2734 28.3345 42.5359 28.3345 44.0926V60.7137C28.3345 62.2704 29.597 63.5207 31.1414 63.5207H33.7155C35.2722 63.5207 36.5224 62.2581 36.5224 60.7137V44.0926C36.5224 42.5359 35.2599 41.2734 33.7155 41.2734Z"
            fill="#7F7D7E"
          />
          <path
            d="M63.3783 49.6085C63.4519 48.0151 62.1771 46.7035 60.5959 46.7035H61.0617C62.5816 46.7035 63.9544 45.5636 64.0157 44.0437C64.0893 42.4502 62.8145 41.1387 61.2333 41.1387H49.0984C49.0984 41.1387 49.0616 41.1141 49.0862 41.0896C51.8073 38.6381 53.4988 37.4001 53.5601 32.3501C53.5846 30.585 52.5182 28.3174 50.7164 28.3174C47.934 28.3174 47.8236 30.4624 47.7991 32.3501C46.1566 37.7801 38.557 37.7188 38.557 46.5074V58.4952C38.557 61.1918 40.7389 63.3736 43.4355 63.3736H59.125C60.5959 63.3736 62.0178 62.1847 62.079 60.7138C62.1526 59.1203 60.8778 57.8088 59.2966 57.8088H59.7624C61.2333 57.8088 62.6551 56.6198 62.7164 55.1489C62.79 53.5554 61.5152 52.2439 59.934 52.2439H60.3998C61.8707 52.2439 63.2925 51.0549 63.3538 49.584L63.3783 49.6085ZM33.6295 41.1509H31.0555C29.4988 41.1509 28.2485 42.4134 28.2485 43.9579V60.5789C28.2485 62.1356 29.5111 63.3859 31.0555 63.3859H33.6295C35.1862 63.3859 36.4365 62.1234 36.4365 60.5789V43.9579C36.4365 42.4012 35.174 41.1509 33.6295 41.1509Z"
            fill="#7E7C7D"
          />
          <path
            d="M63.2923 49.4865C63.3659 47.893 62.0911 46.5815 60.5099 46.5815H60.9757C62.4956 46.5815 63.8684 45.4415 63.9297 43.9216C64.0033 42.3281 62.7285 41.0166 61.1473 41.0166H49.0124C49.0124 41.0166 48.9757 40.9921 49.0002 40.9676C51.7213 38.5161 53.4129 37.2781 53.4741 32.228C53.4987 30.4629 52.4323 28.1953 50.6304 28.1953C47.848 28.1953 47.7377 30.3404 47.7132 32.228C46.0707 37.658 38.471 37.5968 38.471 46.3853V58.3731C38.471 61.0697 40.6529 63.2516 43.3495 63.2516H59.039C60.5099 63.2516 61.9318 62.0626 61.9931 60.5917C62.0666 58.9982 60.7918 57.6867 59.2106 57.6867H59.6764C61.1473 57.6867 62.5692 56.4977 62.6304 55.0268C62.704 53.4334 61.4292 52.1218 59.848 52.1218H60.3138C61.7847 52.1218 63.2065 50.9329 63.2678 49.462L63.2923 49.4865ZM33.5558 41.0166H30.9818C29.4251 41.0166 28.1748 42.2791 28.1748 43.8235V60.4446C28.1748 62.0013 29.4373 63.2516 30.9818 63.2516H33.5558C35.1125 63.2516 36.375 61.9891 36.375 60.4446V43.8235C36.375 42.2668 35.1125 41.0166 33.5558 41.0166Z"
            fill="#7D7B7C"
          />
          <path
            d="M63.2069 49.3507C63.2804 47.7573 62.0057 46.4457 60.4245 46.4457H60.8902C62.4102 46.4457 63.783 45.3058 63.8443 43.7858C63.9178 42.1924 62.6431 40.8808 61.0618 40.8808H48.927C48.927 40.8808 48.8902 40.8563 48.9147 40.8318C51.6359 38.3803 53.3274 37.1423 53.3887 32.0923C53.4132 30.3272 52.3468 28.0596 50.545 28.0596C47.7625 28.0596 47.6522 30.2046 47.6277 32.0923C45.9852 37.5223 38.3856 37.461 38.3856 46.2496V58.2374C38.3856 60.934 40.5674 63.1158 43.2641 63.1158H58.9536C60.4245 63.1158 61.8463 61.9268 61.9076 60.456C61.9811 58.8625 60.7064 57.5509 59.1252 57.5509H59.591C61.0618 57.5509 62.4837 56.362 62.545 54.8911C62.6185 53.2976 61.3438 51.9861 59.7626 51.9861H60.2283C61.6992 51.9861 63.1211 50.7971 63.1824 49.3262L63.2069 49.3507ZM33.4704 40.8808H30.8963C29.3396 40.8808 28.0894 42.1434 28.0894 43.6878V60.3089C28.0894 61.8656 29.3519 63.1158 30.8963 63.1158H33.4704C35.0271 63.1158 36.2896 61.8533 36.2896 60.3089V43.6878C36.2896 42.1311 35.0271 40.8808 33.4704 40.8808Z"
            fill="#7C7A7B"
          />
          <path
            d="M63.1332 49.2287C63.2068 47.6352 61.932 46.3236 60.3508 46.3236H60.8166C62.3365 46.3236 63.7093 45.1837 63.7706 43.6638C63.8441 42.0703 62.5694 40.7588 60.9882 40.7588H48.8533C48.8533 40.7588 48.8165 40.7343 48.841 40.7097C51.5622 38.2583 53.2537 37.0203 53.315 31.9702C53.3395 30.2051 52.2731 27.9375 50.4713 27.9375C47.6889 27.9375 47.5785 30.0826 47.554 31.9702C45.9115 37.4002 38.3119 37.339 38.3119 46.1275V58.1153C38.3119 60.8119 40.4937 62.9938 43.1904 62.9938H58.8799C60.3508 62.9938 61.7726 61.8048 61.8339 60.3339C61.9075 58.7404 60.6327 57.4289 59.0515 57.4289H59.5173C60.9882 57.4289 62.41 56.2399 62.4713 54.769C62.5449 53.1755 61.2701 51.864 59.6889 51.864H60.1547C61.6255 51.864 63.0474 50.675 63.1087 49.2041L63.1332 49.2287ZM33.3844 40.7588H30.8104C29.2537 40.7588 28.0034 42.0213 28.0034 43.5657V60.1868C28.0034 61.7435 29.2659 62.9938 30.8104 62.9938H33.3844C34.9411 62.9938 36.2036 61.7312 36.2036 60.1868V43.5657C36.2036 42.009 34.9411 40.7588 33.3844 40.7588Z"
            fill="#7B797A"
          />
          <path
            d="M63.0473 49.0939C63.1208 47.5004 61.846 46.1889 60.2648 46.1889H60.7306C62.2505 46.1889 63.6234 45.0489 63.6847 43.529C63.7582 41.9356 62.4834 40.624 60.9022 40.624H48.7674C48.7674 40.624 48.7306 40.5995 48.7551 40.575C51.4763 38.1235 53.1678 36.8855 53.2291 31.8354C53.2536 30.0704 52.1872 27.8027 50.3853 27.8027C47.6029 27.8027 47.4926 29.9478 47.4681 31.8354C45.8256 37.2655 38.226 37.2042 38.226 45.9928V57.9805C38.226 60.6772 40.4078 62.859 43.1044 62.859H58.7939C60.2648 62.859 61.6867 61.67 61.748 60.1991C61.8215 58.6057 60.5468 57.2941 58.9655 57.2941H59.4313C60.9022 57.2941 62.3241 56.1051 62.3854 54.6343C62.4589 53.0408 61.1841 51.7292 59.6029 51.7292H60.0687C61.5396 51.7292 62.9615 50.5403 63.0228 49.0694L63.0473 49.0939ZM33.2985 40.624H30.7244C29.1677 40.624 27.9175 41.8865 27.9175 43.431V60.052C27.9175 61.6087 29.18 62.859 30.7244 62.859H33.2985C34.8552 62.859 36.1177 61.5965 36.1177 60.052V43.431C36.1177 41.8743 34.8552 40.624 33.2985 40.624Z"
            fill="#7A7879"
          />
          <path
            d="M62.9618 48.9581C63.0353 47.3647 61.7605 46.0531 60.1793 46.0531H60.6451C62.165 46.0531 63.5379 44.9132 63.5992 43.3933C63.6727 41.7998 62.3979 40.4883 60.8167 40.4883H48.6819C48.6819 40.4883 48.6451 40.4637 48.6696 40.4392C51.3908 37.9877 53.0823 36.7497 53.1436 31.6997C53.1681 29.9346 52.1017 27.667 50.2998 27.667C47.5174 27.667 47.4071 29.812 47.3826 31.6997C45.7401 37.1297 38.1405 37.0684 38.1405 45.857V57.8448C38.1405 60.5414 40.3223 62.7232 43.0189 62.7232H58.7084C60.1793 62.7232 61.6012 61.5343 61.6625 60.0634C61.736 58.4699 60.4613 57.1584 58.88 57.1584H59.3458C60.8167 57.1584 62.2386 55.9694 62.2999 54.4985C62.3734 52.905 61.0986 51.5935 59.5174 51.5935H59.9832C61.4541 51.5935 62.876 50.4045 62.9373 48.9336L62.9618 48.9581ZM33.2253 40.5005H30.6512C29.0945 40.5005 27.8442 41.763 27.8442 43.3075V59.9285C27.8442 61.4852 29.1068 62.7355 30.6512 62.7355H33.2253C34.7819 62.7355 36.0445 61.473 36.0445 59.9285V43.3075C36.0445 41.7508 34.7819 40.5005 33.2253 40.5005Z"
            fill="#797778"
          />
          <path
            d="M62.8758 48.8361C62.9494 47.2426 61.6746 45.9311 60.0934 45.9311H60.5592C62.0791 45.9311 63.4519 44.7911 63.5132 43.2712C63.5868 41.6777 62.312 40.3662 60.7308 40.3662H48.5959C48.5959 40.3662 48.5592 40.3417 48.5837 40.3172C51.3048 37.8657 52.9963 36.6277 53.0576 31.5776C53.0821 29.8125 52.0158 27.5449 50.2139 27.5449C47.4315 27.5449 47.3212 29.69 47.2966 31.5776C45.6542 37.0077 38.0545 36.9464 38.0545 45.735V57.7227C38.0545 60.4193 40.2364 62.6012 42.933 62.6012H58.6225C60.0934 62.6012 61.5153 61.4122 61.5765 59.9413C61.6501 58.3478 60.3753 57.0363 58.7941 57.0363H59.2599C60.7308 57.0363 62.1527 55.8473 62.2139 54.3764C62.2875 52.783 61.0127 51.4714 59.4315 51.4714H59.8973C61.3682 51.4714 62.79 50.2825 62.8513 48.8116L62.8758 48.8361ZM33.1393 40.3662H30.5653C29.0086 40.3662 27.7583 41.6287 27.7583 43.1731V59.7942C27.7583 61.3509 29.0208 62.6012 30.5653 62.6012H33.1393C34.696 62.6012 35.9585 61.3387 35.9585 59.7942V43.1731C35.9585 41.6165 34.696 40.3662 33.1393 40.3662Z"
            fill="#787677"
          />
          <path
            d="M62.8022 48.7003C62.8757 47.1069 61.6009 45.7953 60.0197 45.7953H60.4855C62.0054 45.7953 63.3783 44.6554 63.4395 43.1355C63.5131 41.542 62.2383 40.2305 60.6571 40.2305H48.5223C48.5223 40.2305 48.4855 40.2059 48.51 40.1814C51.2311 37.7299 52.9227 36.4919 52.984 31.4419C53.0085 29.6768 51.9421 27.4092 50.1402 27.4092C47.3578 27.4092 47.2475 29.5542 47.223 31.4419C45.5805 36.8719 37.9809 36.8106 37.9809 45.5992V57.587C37.9809 60.2836 40.1627 62.4654 42.8593 62.4654H58.5488C60.0197 62.4654 61.4416 61.2765 61.5029 59.8056C61.5764 58.2121 60.3016 56.9006 58.7204 56.9006H59.1862C60.6571 56.9006 62.079 55.7116 62.1403 54.2407C62.2138 52.6472 60.939 51.3357 59.3578 51.3357H59.8236C61.2945 51.3357 62.7164 50.1467 62.7776 48.6758L62.8022 48.7003ZM33.0534 40.2305H30.4793C28.9226 40.2305 27.6724 41.493 27.6724 43.0374V59.6585C27.6724 61.2152 28.9349 62.4654 30.4793 62.4654H33.0534C34.6101 62.4654 35.8726 61.2029 35.8726 59.6585V43.0374C35.8726 41.4807 34.6101 40.2305 33.0534 40.2305Z"
            fill="#777576"
          />
          <path
            d="M62.7167 48.5783C62.7902 46.9848 61.5154 45.6733 59.9342 45.6733H60.4C61.9199 45.6733 63.2928 44.5333 63.354 43.0134C63.4276 41.4199 62.1528 40.1084 60.5716 40.1084H48.4368C48.4368 40.1084 48.4 40.0839 48.4245 40.0593C51.1456 37.6079 52.8372 36.3699 52.8985 31.3198C52.923 29.5547 51.8566 27.2871 50.0547 27.2871C47.2723 27.2871 47.162 29.4322 47.1375 31.3198C45.495 36.7498 37.8954 36.6886 37.8954 45.4771V57.4649C37.8954 60.1615 40.0772 62.3434 42.7738 62.3434H58.4633C59.9342 62.3434 61.3561 61.1544 61.4174 59.6835C61.4909 58.09 60.2161 56.7785 58.6349 56.7785H59.1007C60.5716 56.7785 61.9935 55.5895 62.0548 54.1186C62.1283 52.5252 60.8535 51.2136 59.2723 51.2136H59.7381C61.209 51.2136 62.6309 50.0246 62.6921 48.5537L62.7167 48.5783ZM32.9801 40.1084H30.4061C28.8494 40.1084 27.5991 41.3709 27.5991 42.9153V59.5364C27.5991 61.0931 28.8616 62.3434 30.4061 62.3434H32.9801C34.5368 62.3434 35.7993 61.0808 35.7993 59.5364V42.9153C35.7993 41.3586 34.5368 40.1084 32.9801 40.1084Z"
            fill="#767475"
          />
          <path
            d="M62.6307 48.4435C62.7043 46.85 61.4295 45.5385 59.8483 45.5385H60.3141C61.834 45.5385 63.2068 44.3985 63.2681 42.8786C63.3416 41.2852 62.0669 39.9736 60.4857 39.9736H48.3508C48.3508 39.9736 48.314 39.9491 48.3386 39.9246C51.0597 37.4731 52.7512 36.2351 52.8125 31.185C52.837 29.42 51.7706 27.1523 49.9688 27.1523C47.1864 27.1523 47.076 29.2974 47.0515 31.185C45.409 36.6151 37.8094 36.5538 37.8094 45.3424V57.3301C37.8094 60.0268 39.9912 62.2086 42.6879 62.2086H58.3774C59.8483 62.2086 61.2701 61.0196 61.3314 59.5487C61.405 57.9553 60.1302 56.6437 58.549 56.6437H59.0148C60.4857 56.6437 61.9075 55.4547 61.9688 53.9839C62.0424 52.3904 60.7676 51.0788 59.1864 51.0788H59.6522C61.1231 51.0788 62.5449 49.8899 62.6062 48.419L62.6307 48.4435ZM32.8942 39.9736H30.3201C28.7634 39.9736 27.5132 41.2361 27.5132 42.7806V59.4016C27.5132 60.9583 28.7757 62.2086 30.3201 62.2086H32.8942C34.4509 62.2086 35.7134 60.9461 35.7134 59.4016V42.7806C35.7134 41.2239 34.4509 39.9736 32.8942 39.9736Z"
            fill="#767374"
          />
          <path
            d="M62.5575 48.3097C62.6311 46.7162 61.3563 45.4047 59.7751 45.4047H60.2409C61.7608 45.4047 63.1336 44.2648 63.1949 42.7448C63.2685 41.1514 61.9937 39.8398 60.4125 39.8398H48.2776C48.2776 39.8398 48.2408 39.8153 48.2654 39.7908C50.9865 37.3393 52.678 36.1013 52.7393 31.0512C52.7638 29.2862 51.6974 27.0186 49.8956 27.0186C47.1132 27.0186 47.0028 29.1636 46.9783 31.0512C45.3358 36.4813 37.7362 36.42 37.7362 45.2086V57.1963C37.7362 59.893 39.9181 62.0748 42.6147 62.0748H58.3042C59.7751 62.0748 61.197 60.8858 61.2582 59.4149C61.3318 57.8215 60.057 56.5099 58.4758 56.5099H58.9416C60.4125 56.5099 61.8343 55.321 61.8956 53.8501C61.9692 52.2566 60.6944 50.9451 59.1132 50.9451H59.579C61.0499 50.9451 62.4717 49.7561 62.533 48.2852L62.5575 48.3097ZM32.8087 39.8521H30.2347C28.678 39.8521 27.4277 41.1146 27.4277 42.659V59.2801C27.4277 60.8368 28.6903 62.0871 30.2347 62.0871H32.8087C34.3654 62.0871 35.628 60.8245 35.628 59.2801V42.659C35.628 41.1023 34.3654 39.8521 32.8087 39.8521Z"
            fill="#757273"
          />
          <path
            d="M62.4715 48.1857C62.5451 46.5922 61.2703 45.2807 59.6891 45.2807H60.1549C61.6748 45.2807 63.0476 44.1407 63.1089 42.6208C63.1825 41.0273 61.9077 39.7158 60.3265 39.7158H48.1916C48.1916 39.7158 48.1549 39.6913 48.1794 39.6668C50.9005 37.2153 52.5921 35.9773 52.6533 30.9272C52.6779 29.1622 51.6115 26.8945 49.8096 26.8945C47.0272 26.8945 46.9169 29.0396 46.8923 30.9272C45.2498 36.3573 37.6502 36.296 37.6502 45.0846V57.0723C37.6502 59.769 39.8321 61.9508 42.5287 61.9508H58.2182C59.6891 61.9508 61.111 60.7618 61.1723 59.2909C61.2458 57.6974 59.971 56.3859 58.3898 56.3859H58.8556C60.3265 56.3859 61.7484 55.1969 61.8096 53.726C61.8832 52.1326 60.6084 50.821 59.0272 50.821H59.493C60.9639 50.821 62.3857 49.6321 62.447 48.1612L62.4715 48.1857ZM32.735 39.7158H30.161C28.6043 39.7158 27.354 40.9783 27.354 42.5228V59.1438C27.354 60.7005 28.6165 61.9508 30.161 61.9508H32.735C34.2917 61.9508 35.5542 60.6883 35.5542 59.1438V42.5228C35.5542 40.9661 34.2917 39.7158 32.735 39.7158Z"
            fill="#747172"
          />
          <path
            d="M62.3856 48.0519C62.4591 46.4584 61.1844 45.1469 59.6032 45.1469H60.0689C61.5889 45.1469 62.9617 44.0069 63.023 42.487C63.0965 40.8936 61.8218 39.582 60.2405 39.582H48.1057C48.1057 39.582 48.0689 39.5575 48.0934 39.533C50.8146 37.0815 52.5061 35.8435 52.5674 30.7934C52.5919 29.0284 51.5255 26.7607 49.7237 26.7607C46.9412 26.7607 46.8309 28.9058 46.8064 30.7934C45.1639 36.2235 37.5643 36.1622 37.5643 44.9508V56.9385C37.5643 59.6352 39.7461 61.817 42.4428 61.817H58.1323C59.6032 61.817 61.025 60.628 61.0863 59.1571C61.1599 57.5637 59.8851 56.2521 58.3039 56.2521H58.7697C60.2405 56.2521 61.6624 55.0631 61.7237 53.5923C61.7972 51.9988 60.5225 50.6873 58.9413 50.6873H59.407C60.8779 50.6873 62.2998 49.4983 62.3611 48.0274L62.3856 48.0519ZM32.6491 39.5943H30.075C28.5183 39.5943 27.2681 40.8568 27.2681 42.4012V59.0223C27.2681 60.579 28.5306 61.8415 30.075 61.8415H32.6491C34.2058 61.8415 35.4683 60.579 35.4683 59.0223V42.4012C35.4683 40.8445 34.2058 39.5943 32.6491 39.5943Z"
            fill="#737071"
          />
          <path
            d="M62.3119 47.9289C62.3855 46.3354 61.1107 45.0238 59.5295 45.0238H59.9953C61.5152 45.0238 62.888 43.8839 62.9493 42.364C63.0229 40.7705 61.7481 39.459 60.1669 39.459H48.032C48.032 39.459 47.9952 39.4344 48.0198 39.4099C50.7409 36.9584 52.4324 35.7205 52.4937 30.6704C52.5182 28.9053 51.4518 26.6377 49.65 26.6377C46.8676 26.6377 46.7572 28.7827 46.7327 30.6704C45.0902 36.1004 37.4906 36.0391 37.4906 44.8277V56.8155C37.4906 59.5121 39.6724 61.6939 42.3691 61.6939H58.0586C59.5295 61.6939 60.9513 60.505 61.0126 59.0341C61.0862 57.4406 59.8114 56.1291 58.2302 56.1291H58.696C60.1669 56.1291 61.5887 54.9401 61.65 53.4692C61.7236 51.8757 60.4488 50.5642 58.8676 50.5642H59.3334C60.8043 50.5642 62.2261 49.3752 62.2874 47.9043L62.3119 47.9289ZM32.5631 39.459H29.9891C28.4324 39.459 27.1821 40.7215 27.1821 42.2659V58.887C27.1821 60.4437 28.4446 61.6939 29.9891 61.6939H32.5631C34.1198 61.6939 35.3823 60.4314 35.3823 58.887V42.2659C35.3823 40.7092 34.1198 39.459 32.5631 39.459Z"
            fill="#726F70"
          />
          <path
            d="M62.2265 47.7951C62.3 46.2016 61.0252 44.89 59.444 44.89H59.9098C61.4297 44.89 62.8026 43.7501 62.8639 42.2302C62.9374 40.6367 61.6626 39.3252 60.0814 39.3252H47.9466C47.9466 39.3252 47.9098 39.3007 47.9343 39.2761C50.6555 36.8247 52.347 35.5867 52.4083 30.5366C52.4328 28.7715 51.3664 26.5039 49.5645 26.5039C46.7821 26.5039 46.6718 28.649 46.6473 30.5366C45.0048 35.9666 37.4052 35.9054 37.4052 44.6939V56.6817C37.4052 59.3783 39.587 61.5602 42.2836 61.5602H57.9731C59.444 61.5602 60.8659 60.3712 60.9272 58.9003C61.0007 57.3068 59.726 55.9953 58.1447 55.9953H58.6105C60.0814 55.9953 61.5033 54.8063 61.5646 53.3354C61.6381 51.742 60.3633 50.4304 58.7821 50.4304H59.2479C60.7188 50.4304 62.1407 49.2414 62.202 47.7705L62.2265 47.7951ZM32.4777 39.3252H29.9036C28.3469 39.3252 27.0967 40.5877 27.0967 42.1321V58.7532C27.0967 60.3099 28.3592 61.5602 29.9036 61.5602H32.4777C34.0344 61.5602 35.2969 60.2976 35.2969 58.7532V42.1321C35.2969 40.5754 34.0344 39.3252 32.4777 39.3252Z"
            fill="#716E6F"
          />
          <path
            d="M62.1405 47.6593C62.214 46.0659 60.9393 44.7543 59.358 44.7543H59.8238C61.3438 44.7543 62.7166 43.6144 62.7779 42.0944C62.8514 40.501 61.5766 39.1894 59.9954 39.1894H47.8606C47.8606 39.1894 47.8238 39.1649 47.8483 39.1404C50.5695 36.6889 52.261 35.4509 52.3223 30.4009C52.3468 28.6358 51.2804 26.3682 49.4786 26.3682C46.6961 26.3682 46.5858 28.5132 46.5613 30.4009C44.9188 35.8309 37.3192 35.7696 37.3192 44.5582V56.546C37.3192 59.2426 39.501 61.4244 42.1976 61.4244H57.8872C59.358 61.4244 60.7799 60.2354 60.8412 58.7645C60.9147 57.1711 59.64 55.8595 58.0588 55.8595H58.5245C59.9954 55.8595 61.4173 54.6706 61.4786 53.1997C61.5521 51.6062 60.2774 50.2947 58.6961 50.2947H59.1619C60.6328 50.2947 62.0547 49.1057 62.116 47.6348L62.1405 47.6593ZM32.404 39.2017H29.8299C28.2732 39.2017 27.0229 40.4642 27.0229 42.0209V58.642C27.0229 60.1987 28.2855 61.4489 29.8299 61.4489H32.404C33.9607 61.4489 35.2232 60.1864 35.2232 58.642V42.0209C35.2232 40.4642 33.9607 39.2017 32.404 39.2017Z"
            fill="#706D6E"
          />
          <path
            d="M62.055 47.5373C62.1286 45.9438 60.8538 44.6322 59.2726 44.6322H59.7384C61.2583 44.6322 62.6311 43.4923 62.6924 41.9724C62.766 40.3789 61.4912 39.0674 59.91 39.0674H47.7751C47.7751 39.0674 47.7384 39.0428 47.7629 39.0183C50.484 36.5668 52.1755 35.3288 52.2368 30.2788C52.2613 28.5137 51.195 26.2461 49.3931 26.2461C46.6107 26.2461 46.5004 28.3911 46.4758 30.2788C44.8333 35.7088 37.2337 35.6475 37.2337 44.4361V56.4239C37.2337 59.1205 39.4156 61.3023 42.1122 61.3023H57.8017C59.2726 61.3023 60.6945 60.1134 60.7557 58.6425C60.8293 57.049 59.5545 55.7375 57.9733 55.7375H58.4391C59.91 55.7375 61.3318 54.5485 61.3931 53.0776C61.4667 51.4841 60.1919 50.1726 58.6107 50.1726H59.0765C60.5474 50.1726 61.9692 48.9836 62.0305 47.5127L62.055 47.5373ZM32.3185 39.0674H29.7445C28.1878 39.0674 26.9375 40.3299 26.9375 41.8743V58.4954C26.9375 60.0521 28.2 61.3023 29.7445 61.3023H32.3185C33.8752 61.3023 35.1377 60.0398 35.1377 58.4954V41.8743C35.1377 40.3176 33.8752 39.0674 32.3185 39.0674Z"
            fill="#6F6C6D"
          />
          <path
            d="M61.9809 47.4025C62.0544 45.809 60.7796 44.4975 59.1984 44.4975H59.6642C61.1841 44.4975 62.557 43.3575 62.6182 41.8376C62.6918 40.2441 61.417 38.9326 59.8358 38.9326H47.701C47.701 38.9326 47.6642 38.9081 47.6887 38.8836C50.4099 36.4321 52.1014 35.1941 52.1627 30.144C52.1872 28.379 51.1208 26.1113 49.3189 26.1113C46.5365 26.1113 46.4262 28.2564 46.4017 30.144C44.7592 35.5741 37.1596 35.5128 37.1596 44.3014V56.2891C37.1596 58.9858 39.3414 61.1676 42.038 61.1676H57.7275C59.1984 61.1676 60.6203 59.9786 60.6816 58.5077C60.7551 56.9142 59.4803 55.6027 57.8991 55.6027H58.3649C59.8358 55.6027 61.2577 54.4137 61.319 52.9428C61.3925 51.3494 60.1177 50.0378 58.5365 50.0378H59.0023C60.4732 50.0378 61.8951 48.8489 61.9563 47.378L61.9809 47.4025ZM32.2321 38.9449H29.658C28.1013 38.9449 26.8511 40.2074 26.8511 41.7518V58.3729C26.8511 59.9296 28.1136 61.1798 29.658 61.1798H32.2321C33.7888 61.1798 35.0513 59.9173 35.0513 58.3729V41.7518C35.0513 40.1951 33.7888 38.9449 32.2321 38.9449Z"
            fill="#6E6B6C"
          />
          <path
            d="M61.8949 47.2804C61.9684 45.6869 60.6937 44.3754 59.1124 44.3754H59.5782C61.0981 44.3754 62.471 43.2355 62.5323 41.7155C62.6058 40.1221 61.331 38.8105 59.7498 38.8105H47.615C47.615 38.8105 47.5782 38.786 47.6027 38.7615C50.3239 36.31 52.0154 35.072 52.0767 30.022C52.1012 28.2569 51.0348 25.9893 49.233 25.9893C46.4505 25.9893 46.3402 28.1343 46.3157 30.022C44.6732 35.452 37.0736 35.3907 37.0736 44.1793V56.167C37.0736 58.8637 39.2554 61.0455 41.952 61.0455H57.6416C59.1124 61.0455 60.5343 59.8565 60.5956 58.3856C60.6691 56.7922 59.3944 55.4806 57.8132 55.4806H58.2789C59.7498 55.4806 61.1717 54.2917 61.233 52.8208C61.3065 51.2273 60.0317 49.9158 58.4505 49.9158H58.9163C60.3872 49.9158 61.8091 48.7268 61.8704 47.2559L61.8949 47.2804ZM32.1584 38.8105H29.5843C28.0276 38.8105 26.7773 40.073 26.7773 41.6175V58.2385C26.7773 59.7952 28.0399 61.0455 29.5843 61.0455H32.1584C33.715 61.0455 34.9776 59.783 34.9776 58.2385V41.6175C34.9776 40.0608 33.715 38.8105 32.1584 38.8105Z"
            fill="#6D6A6B"
          />
          <path
            d="M61.8094 47.1447C61.883 45.5512 60.6082 44.2397 59.027 44.2397H59.4928C61.0127 44.2397 62.3855 43.0997 62.4468 41.5798C62.5204 39.9863 61.2456 38.6748 59.6644 38.6748H47.5295C47.5295 38.6748 47.4928 38.6503 47.5173 38.6258C50.2384 36.1743 51.9299 34.9363 51.9912 29.8862C52.0157 28.1211 50.9493 25.8535 49.1475 25.8535C46.3651 25.8535 46.2547 27.9986 46.2302 29.8862C44.5877 35.3163 36.9881 35.255 36.9881 44.0435V56.0313C36.9881 58.7279 39.17 60.9098 41.8666 60.9098H57.5561C59.027 60.9098 60.4489 59.7208 60.5101 58.2499C60.5837 56.6564 59.3089 55.3449 57.7277 55.3449H58.1935C59.6644 55.3449 61.0862 54.1559 61.1475 52.685C61.2211 51.0916 59.9463 49.78 58.3651 49.78H58.8309C60.3018 49.78 61.7236 48.591 61.7849 47.1202L61.8094 47.1447ZM32.0729 38.6748H29.4988C27.9422 38.6748 26.6919 39.9373 26.6919 41.4817V58.1028C26.6919 59.6595 27.9544 60.922 29.4988 60.922H32.0729C33.6296 60.922 34.8921 59.6595 34.8921 58.1028V41.4817C34.8921 39.925 33.6296 38.6748 32.0729 38.6748Z"
            fill="#6C696A"
          />
          <path
            d="M61.7362 47.0226C61.8098 45.4291 60.535 44.1176 58.9538 44.1176H59.4196C60.9395 44.1176 62.3123 42.9776 62.3736 41.4577C62.4472 39.8643 61.1724 38.5527 59.5912 38.5527H47.4563C47.4563 38.5527 47.4196 38.5282 47.4441 38.5037C50.1652 36.0522 51.8567 34.8142 51.918 29.7641C51.9426 27.9991 50.8762 25.7314 49.0743 25.7314C46.2919 25.7314 46.1816 27.8765 46.157 29.7641C44.5145 35.1942 36.9149 35.1329 36.9149 43.9215V55.9092C36.9149 58.6059 39.0968 60.7877 41.7934 60.7877H57.4829C58.9538 60.7877 60.3757 59.5987 60.4369 58.1278C60.5105 56.5344 59.2357 55.2228 57.6545 55.2228H58.1203C59.5912 55.2228 61.013 54.0338 61.0743 52.563C61.1479 50.9695 59.8731 49.6579 58.2919 49.6579H58.7577C60.2286 49.6579 61.6504 48.469 61.7117 46.9981L61.7362 47.0226ZM31.9875 38.5527H29.4134C27.8567 38.5527 26.6064 39.8152 26.6064 41.3597V57.9807C26.6064 59.5374 27.869 60.7877 29.4134 60.7877H31.9875C33.5441 60.7877 34.7944 59.5252 34.7944 57.9807V41.3597C34.7944 39.803 33.5319 38.5527 31.9875 38.5527Z"
            fill="#6B6869"
          />
          <path
            d="M61.6498 46.8869C61.7234 45.2934 60.4486 43.9818 58.8674 43.9818H59.3332C60.8531 43.9818 62.2259 42.8419 62.2872 41.322C62.3607 39.7285 61.086 38.417 59.5048 38.417H47.3699C47.3699 38.417 47.3331 38.3925 47.3576 38.3679C50.0788 35.9165 51.7703 34.6785 51.8316 29.6284C51.8561 27.8633 50.7897 25.5957 48.9879 25.5957C46.2054 25.5957 46.0951 27.7408 46.0706 29.6284C44.4281 35.0584 36.8285 34.9971 36.8285 43.7857V55.7735C36.8285 58.4701 39.0103 60.6519 41.707 60.6519H57.3965C58.8674 60.6519 60.2892 59.463 60.3505 57.9921C60.4241 56.3986 59.1493 55.0871 57.5681 55.0871H58.0339C59.5048 55.0871 60.9266 53.8981 60.9879 52.4272C61.0615 50.8337 59.7867 49.5222 58.2055 49.5222H58.6713C60.1421 49.5222 61.564 48.3332 61.6253 46.8623L61.6498 46.8869ZM31.9133 38.417H29.3392C27.7825 38.417 26.52 39.6795 26.52 41.2239V57.845C26.52 59.4017 27.7825 60.6519 29.3392 60.6519H31.9133C33.47 60.6519 34.7202 59.3894 34.7202 57.845V41.2239C34.7202 39.6672 33.4577 38.417 31.9133 38.417Z"
            fill="#6A6768"
          />
          <path
            d="M61.5644 46.7521C61.6379 45.1586 60.3631 43.8471 58.7819 43.8471H59.2477C60.7676 43.8471 62.1405 42.7071 62.2017 41.1872C62.2753 39.5938 61.0005 38.2822 59.4193 38.2822H47.2845C47.2845 38.2822 47.2477 38.2577 47.2722 38.2332C49.9933 35.7817 51.6849 34.5437 51.7462 29.4936C51.7707 27.7286 50.7043 25.4609 48.9024 25.4609C46.12 25.4609 46.0097 27.606 45.9852 29.4936C44.3427 34.9237 36.7431 34.8624 36.7431 43.651V55.6387C36.7431 58.3354 38.9249 60.5172 41.6215 60.5172H57.311C58.7819 60.5172 60.2038 59.3282 60.2651 57.8573C60.3386 56.2639 59.0638 54.9523 57.4826 54.9523H57.9484C59.4193 54.9523 60.8412 53.7633 60.9025 52.2924C60.976 50.699 59.7012 49.3874 58.12 49.3874H58.5858C60.0567 49.3874 61.4786 48.1985 61.5398 46.7276L61.5644 46.7521ZM31.8278 38.2945H29.2538C27.6971 38.2945 26.4346 39.557 26.4346 41.1014V57.7225C26.4346 59.2792 27.6971 60.5294 29.2538 60.5294H31.8278C33.3845 60.5294 34.6348 59.2669 34.6348 57.7225V41.1014C34.6348 39.5447 33.3723 38.2945 31.8278 38.2945Z"
            fill="#696667"
          />
          <path
            d="M61.4912 46.63C61.5647 45.0365 60.2899 43.725 58.7087 43.725H59.1745C60.6944 43.725 62.0673 42.5851 62.1286 41.0651C62.2021 39.4717 60.9273 38.1601 59.3461 38.1601H47.2113C47.2113 38.1601 47.1745 38.1356 47.199 38.1111C49.9202 35.6596 51.6117 34.4216 51.673 29.3716C51.6975 27.6065 50.6311 25.3389 48.8292 25.3389C46.0468 25.3389 45.9365 27.4839 45.912 29.3716C44.2695 34.8016 36.6699 34.7403 36.6699 43.5289V55.5166C36.6699 58.2133 38.8517 60.3951 41.5483 60.3951H57.2378C58.7087 60.3951 60.1306 59.2061 60.1919 57.7352C60.2654 56.1418 58.9907 54.8302 57.4094 54.8302H57.8752C59.3461 54.8302 60.768 53.6413 60.8293 52.1704C60.9028 50.5769 59.628 49.2654 58.0468 49.2654H58.5126C59.9835 49.2654 61.4054 48.0764 61.4666 46.6055L61.4912 46.63ZM31.7424 38.1601H29.1683C27.6116 38.1601 26.3491 39.4227 26.3491 40.9671V57.5882C26.3491 59.1449 27.6116 60.3951 29.1683 60.3951H31.7424C33.2991 60.3951 34.5493 59.1326 34.5493 57.5882V40.9671C34.5493 39.4104 33.2868 38.1601 31.7424 38.1601Z"
            fill="#686566"
          />
          <path
            d="M61.4047 46.4943C61.4783 44.9008 60.2035 43.5893 58.6223 43.5893H59.0881C60.608 43.5893 61.9808 42.4493 62.0421 40.9294C62.1157 39.3359 60.8409 38.0244 59.2597 38.0244H47.1248C47.1248 38.0244 47.0881 37.9999 47.1126 37.9754C49.8337 35.5239 51.5253 34.2859 51.5865 29.2358C51.6111 27.4708 50.5447 25.2031 48.7428 25.2031C45.9604 25.2031 45.8501 27.3482 45.8256 29.2358C44.1831 34.6659 36.5834 34.6046 36.5834 43.3931V55.3809C36.5834 58.0775 38.7653 60.2594 41.4619 60.2594H57.1514C58.6223 60.2594 60.0442 59.0704 60.1055 57.5995C60.179 56.006 58.9042 54.6945 57.323 54.6945H57.7888C59.2597 54.6945 60.6816 53.5055 60.7428 52.0346C60.8164 50.4412 59.5416 49.1296 57.9604 49.1296H58.4262C59.8971 49.1296 61.3189 47.9407 61.3802 46.4698L61.4047 46.4943ZM31.656 38.0367H29.0819C27.5252 38.0367 26.2627 39.2992 26.2627 40.8436V57.4647C26.2627 59.0214 27.5252 60.2716 29.0819 60.2716H31.656C33.2127 60.2716 34.4629 59.0091 34.4629 57.4647V40.8436C34.4629 39.2869 33.2004 38.0367 31.656 38.0367Z"
            fill="#676465"
          />
          <path
            d="M61.3188 46.3722C61.3923 44.7787 60.1175 43.4672 58.5363 43.4672H59.0021C60.522 43.4672 61.8948 42.3272 61.9561 40.8073C62.0297 39.2139 60.7549 37.9023 59.1737 37.9023H47.0388C47.0388 37.9023 47.0021 37.8778 47.0266 37.8533C49.7477 35.4018 51.4393 34.1638 51.5006 29.1137C51.5251 27.3487 50.4587 25.0811 48.6568 25.0811C45.8744 25.0811 45.7641 27.2261 45.7396 29.1137C44.0971 34.5438 36.4975 34.4825 36.4975 43.2711V55.2588C36.4975 57.9555 38.6793 60.1373 41.3759 60.1373H57.0654C58.5363 60.1373 59.9582 58.9483 60.0195 57.4774C60.093 55.884 58.8182 54.5724 57.237 54.5724H57.7028C59.1737 54.5724 60.5956 53.3835 60.6569 51.9126C60.7304 50.3191 59.4556 49.0076 57.8744 49.0076H58.3402C59.8111 49.0076 61.233 47.8186 61.2942 46.3477L61.3188 46.3722ZM31.5822 37.9023H29.0082C27.4515 37.9023 26.189 39.1648 26.189 40.7093V57.3303C26.189 58.887 27.4515 60.1373 29.0082 60.1373H31.5822C33.1389 60.1373 34.3892 58.8748 34.3892 57.3303V40.7093C34.3892 39.1526 33.1267 37.9023 31.5822 37.9023Z"
            fill="#666364"
          />
          <path
            d="M61.2456 46.2374C61.3191 44.644 60.0443 43.3324 58.4631 43.3324H58.9289C60.4488 43.3324 61.8217 42.1925 61.8829 40.6726C61.9565 39.0791 60.6817 37.7676 59.1005 37.7676H46.9657C46.9657 37.7676 46.9289 37.743 46.9534 37.7185C49.6746 35.267 51.3661 34.029 51.4274 28.979C51.4519 27.2139 50.3855 24.9463 48.5836 24.9463C45.8012 24.9463 45.6909 27.0913 45.6664 28.979C44.0239 34.409 36.4243 34.3477 36.4243 43.1363V55.1241C36.4243 57.8207 38.6061 60.0025 41.3027 60.0025H56.9922C58.4631 60.0025 59.885 58.8136 59.9463 57.3427C60.0198 55.7492 58.745 54.4377 57.1638 54.4377H57.6296C59.1005 54.4377 60.5224 53.2487 60.5837 51.7778C60.6572 50.1843 59.3824 48.8728 57.8012 48.8728H58.267C59.7379 48.8728 61.1598 47.6838 61.221 46.2129L61.2456 46.2374ZM31.4968 37.7676H28.9227C27.366 37.7676 26.1035 39.0301 26.1035 40.5745V57.1956C26.1035 58.7523 27.366 60.0025 28.9227 60.0025H31.4968C33.0535 60.0025 34.3037 58.74 34.3037 57.1956V40.5745C34.3037 39.0178 33.0412 37.7676 31.4968 37.7676Z"
            fill="#656263"
          />
          <path
            d="M61.1601 46.1036C61.2337 44.5102 59.9589 43.1986 58.3777 43.1986H58.8435C60.3634 43.1986 61.7362 42.0587 61.7975 40.5388C61.871 38.9453 60.5963 37.6338 59.0151 37.6338H46.8802C46.8802 37.6338 46.8434 37.6093 46.868 37.5847C49.5891 35.1333 51.2806 33.8953 51.3419 28.8452C51.3664 27.0801 50.3 24.8125 48.4982 24.8125C45.7158 24.8125 45.6054 26.9576 45.5809 28.8452C43.9384 34.2752 36.3388 34.2139 36.3388 43.0025V54.9903C36.3388 57.6869 38.5206 59.8687 41.2173 59.8687H56.9068C58.3777 59.8687 59.7995 58.6798 59.8608 57.2089C59.9344 55.6154 58.6596 54.3039 57.0784 54.3039H57.5442C59.0151 54.3039 60.4369 53.1149 60.4982 51.644C60.5718 50.0505 59.297 48.739 57.7158 48.739H58.1816C59.6525 48.739 61.0743 47.55 61.1356 46.0791L61.1601 46.1036ZM31.4113 37.646H28.8373C27.2806 37.646 26.0181 38.9085 26.0181 40.453V57.0741C26.0181 58.6307 27.2806 59.881 28.8373 59.881H31.4113C32.968 59.881 34.2183 58.6185 34.2183 57.0741V40.453C34.2183 38.8963 32.9558 37.646 31.4113 37.646Z"
            fill="#646162"
          />
          <path
            d="M61.0741 45.9796C61.1477 44.3862 59.8729 43.0746 58.2917 43.0746H58.7575C60.2774 43.0746 61.6502 41.9347 61.7115 40.4148C61.7851 38.8213 60.5103 37.5098 58.9291 37.5098H46.7942C46.7942 37.5098 46.7574 37.4852 46.782 37.4607C49.5031 35.0092 51.1946 33.7712 51.2559 28.7212C51.2804 26.9561 50.214 24.6885 48.4122 24.6885C45.6298 24.6885 45.5194 26.8335 45.4949 28.7212C43.8524 34.1512 36.2528 34.0899 36.2528 42.8785V54.8663C36.2528 57.5629 38.4347 59.7447 41.1313 59.7447H56.8208C58.2917 59.7447 59.7136 58.5558 59.7748 57.0849C59.8484 55.4914 58.5736 54.1799 56.9924 54.1799H57.4582C58.9291 54.1799 60.3509 52.9909 60.4122 51.52C60.4858 49.9265 59.211 48.615 57.6298 48.615H58.0956C59.5665 48.615 60.9883 47.426 61.0496 45.9551L61.0741 45.9796ZM31.3376 37.5098H28.7635C27.2069 37.5098 25.9443 38.7723 25.9443 40.3167V56.9378C25.9443 58.4945 27.2069 59.7447 28.7635 59.7447H31.3376C32.8943 59.7447 34.1446 58.4822 34.1446 56.9378V40.3167C34.1446 38.76 32.882 37.5098 31.3376 37.5098Z"
            fill="#636061"
          />
          <path
            d="M60.9882 45.8449C61.0617 44.2514 59.787 42.9399 58.2057 42.9399H58.6715C60.1915 42.9399 61.5643 41.7999 61.6256 40.28C61.6991 38.6865 60.4243 37.375 58.8431 37.375H46.7083C46.7083 37.375 46.6715 37.3505 46.696 37.326C49.4172 34.8745 51.1087 33.6365 51.17 28.5864C51.1945 26.8213 50.1281 24.5537 48.3263 24.5537C45.5438 24.5537 45.4335 26.6988 45.409 28.5864C43.7665 34.0165 36.1669 33.9552 36.1669 42.7437V54.7315C36.1669 57.4281 38.3487 59.61 41.0454 59.61H56.7349C58.2057 59.61 59.6276 58.421 59.6889 56.9501C59.7624 55.3566 58.4877 54.0451 56.9065 54.0451H57.3722C58.8431 54.0451 60.265 52.8561 60.3263 51.3852C60.3998 49.7918 59.1251 48.4802 57.5438 48.4802H58.0096C59.4805 48.4802 60.9024 47.2912 60.9637 45.8204L60.9882 45.8449ZM31.2517 37.3872H28.6776C27.1209 37.3872 25.8584 38.6498 25.8584 40.1942V56.8153C25.8584 58.372 27.1209 59.6222 28.6776 59.6222H31.2517C32.8084 59.6222 34.0586 58.3597 34.0586 56.8153V40.1942C34.0586 38.6375 32.7961 37.3872 31.2517 37.3872Z"
            fill="#625F60"
          />
          <path
            d="M60.9145 45.7228C60.9881 44.1293 59.7133 42.8178 58.1321 42.8178H58.5979C60.1178 42.8178 61.4906 41.6778 61.5519 40.1579C61.6254 38.5645 60.3507 37.2529 58.7695 37.2529H46.6346C46.6346 37.2529 46.5978 37.2284 46.6223 37.2039C49.3435 34.7524 51.035 33.5144 51.0963 28.4643C51.1208 26.6993 50.0544 24.4316 48.2526 24.4316C45.4701 24.4316 45.3598 26.5767 45.3353 28.4643C43.6928 33.8944 36.0932 33.8331 36.0932 42.6217V54.6094C36.0932 57.3061 38.275 59.4879 40.9717 59.4879H56.6612C58.1321 59.4879 59.5539 58.2989 59.6152 56.828C59.6888 55.2346 58.414 53.923 56.8328 53.923H57.2986C58.7695 53.923 60.1913 52.734 60.2526 51.2632C60.3262 49.6697 59.0514 48.3581 57.4702 48.3581H57.936C59.4068 48.3581 60.8287 47.1692 60.89 45.6983L60.9145 45.7228ZM31.1657 37.2529H28.5917C27.035 37.2529 25.7725 38.5154 25.7725 40.0599V56.6809C25.7725 58.2376 27.035 59.4879 28.5917 59.4879H31.1657C32.7224 59.4879 33.9727 58.2254 33.9727 56.6809V40.0599C33.9727 38.5032 32.7102 37.2529 31.1657 37.2529Z"
            fill="#615E5F"
          />
          <path
            d="M60.829 45.5871C60.9026 43.9936 59.6278 42.682 58.0466 42.682H58.5124C60.0323 42.682 61.4051 41.5421 61.4664 40.0222C61.5399 38.4287 60.2652 37.1172 58.684 37.1172H46.5491C46.5491 37.1172 46.5123 37.0927 46.5368 37.0681C49.258 34.6167 50.9495 33.3786 51.0108 28.3286C51.0353 26.5635 49.9689 24.2959 48.1671 24.2959C45.3847 24.2959 45.2743 26.4409 45.2498 28.3286C43.6073 33.7586 36.0077 33.6973 36.0077 42.4859V54.4737C36.0077 57.1703 38.1895 59.3521 40.8862 59.3521H56.5757C58.0466 59.3521 59.4684 58.1632 59.5297 56.6923C59.6033 55.0988 58.3285 53.7873 56.7473 53.7873H57.2131C58.684 53.7873 60.1058 52.5983 60.1671 51.1274C60.2407 49.5339 58.9659 48.2224 57.3847 48.2224H57.8505C59.3213 48.2224 60.7432 47.0334 60.8045 45.5625L60.829 45.5871ZM31.0925 37.1172H28.5184C26.9617 37.1172 25.6992 38.3797 25.6992 39.9241V56.5452C25.6992 58.1019 26.9617 59.3521 28.5184 59.3521H31.0925C32.6492 59.3521 33.8994 58.0896 33.8994 56.5452V39.9241C33.8994 38.3674 32.6369 37.1172 31.0925 37.1172Z"
            fill="#605D5E"
          />
          <path
            d="M60.7431 45.465C60.8166 43.8715 59.5418 42.56 57.9606 42.56H58.4264C59.9463 42.56 61.3192 41.42 61.3805 39.9001C61.454 38.3066 60.1792 36.9951 58.598 36.9951H46.4632C46.4632 36.9951 46.4264 36.9706 46.4509 36.9461C49.1721 34.4946 50.8636 33.2566 50.9249 28.2065C50.9494 26.4414 49.883 24.1738 48.0812 24.1738C45.2987 24.1738 45.1884 26.3189 45.1639 28.2065C43.5214 33.6366 35.9218 33.5753 35.9218 42.3639V54.3516C35.9218 57.0483 38.1036 59.2301 40.8002 59.2301H56.4897C57.9606 59.2301 59.3825 58.0411 59.4438 56.5702C59.5173 54.9767 58.2426 53.6652 56.6613 53.6652H57.1271C58.598 53.6652 60.0199 52.4762 60.0812 51.0053C60.1547 49.4119 58.8799 48.1003 57.2987 48.1003H57.7645C59.2354 48.1003 60.6573 46.9114 60.7186 45.4405L60.7431 45.465ZM31.0066 36.9951H28.4325C26.8758 36.9951 25.6133 38.2576 25.6133 39.802V56.4231C25.6133 57.9798 26.8758 59.2301 28.4325 59.2301H31.0066C32.5632 59.2301 33.8135 57.9676 33.8135 56.4231V39.802C33.8135 38.2454 32.551 36.9951 31.0066 36.9951Z"
            fill="#5F5C5D"
          />
          <path
            d="M60.6694 45.3302C60.7429 43.7367 59.4682 42.4252 57.887 42.4252H58.3527C59.8727 42.4252 61.2455 41.2853 61.3068 39.7653C61.3803 38.1719 60.1056 36.8603 58.5243 36.8603H46.3895C46.3895 36.8603 46.3527 36.8358 46.3772 36.8113C49.0984 34.3598 50.7899 33.1218 50.8512 28.0718C50.8757 26.3067 49.8093 24.0391 48.0075 24.0391C45.225 24.0391 45.1147 26.1841 45.0902 28.0718C43.4477 33.5018 35.8481 33.4405 35.8481 42.2291V54.2169C35.8481 56.9135 38.0299 59.0953 40.7266 59.0953H56.4161C57.887 59.0953 59.3088 57.9063 59.3701 56.4354C59.4436 54.842 58.1689 53.5304 56.5877 53.5304H57.0534C58.5243 53.5304 59.9462 52.3415 60.0075 50.8706C60.081 49.2771 58.8063 47.9656 57.2251 47.9656H57.6908C59.1617 47.9656 60.5836 46.7766 60.6449 45.3057L60.6694 45.3302ZM30.9206 36.8603H28.3466C26.7899 36.8603 25.5273 38.1228 25.5273 39.6673V56.2884C25.5273 57.8451 26.7899 59.0953 28.3466 59.0953H30.9206C32.4773 59.0953 33.7276 57.8328 33.7276 56.2884V39.6673C33.7276 38.1106 32.465 36.8603 30.9206 36.8603Z"
            fill="#5E5B5C"
          />
          <path
            d="M60.5839 45.1954C60.6575 43.602 59.3827 42.2904 57.8015 42.2904H58.2673C59.7872 42.2904 61.16 41.1505 61.2213 39.6306C61.2949 38.0371 60.0201 36.7256 58.4389 36.7256H46.304C46.304 36.7256 46.2673 36.7011 46.2918 36.6765C49.0129 34.2251 50.7045 32.987 50.7657 27.937C50.7903 26.1719 49.7239 23.9043 47.922 23.9043C45.1396 23.9043 45.0293 26.0493 45.0048 27.937C43.3623 33.367 35.7627 33.3057 35.7627 42.0943V54.0821C35.7627 56.7787 37.9445 58.9605 40.6411 58.9605H56.3306C57.8015 58.9605 59.2234 57.7716 59.2847 56.3007C59.3582 54.7072 58.0834 53.3957 56.5022 53.3957H56.968C58.4389 53.3957 59.8608 52.2067 59.922 50.7358C59.9956 49.1423 58.7208 47.8308 57.1396 47.8308H57.6054C59.0763 47.8308 60.4981 46.6418 60.5594 45.1709L60.5839 45.1954ZM30.8352 36.7378H28.2611C26.7044 36.7378 25.4419 38.0003 25.4419 39.5448V56.1659C25.4419 57.7225 26.7044 58.9728 28.2611 58.9728H30.8352C32.3919 58.9728 33.6421 57.7103 33.6421 56.1659V39.5448C33.6421 37.9881 32.3796 36.7378 30.8352 36.7378Z"
            fill="#5D5A5B"
          />
          <path
            d="M60.498 45.0724C60.5715 43.4789 59.2967 42.1674 57.7155 42.1674H58.1813C59.7012 42.1674 61.0741 41.0275 61.1353 39.5075C61.2089 37.9141 59.9341 36.6025 58.3529 36.6025H46.2181C46.2181 36.6025 46.1813 36.578 46.2058 36.5535C48.9269 34.102 50.6185 32.864 50.6798 27.8139C50.7043 26.0489 49.6379 23.7812 47.836 23.7812C45.0536 23.7812 44.9433 25.9263 44.9188 27.8139C43.2763 33.244 35.6767 33.1827 35.6767 41.9713V53.959C35.6767 56.6557 37.8585 58.8375 40.5551 58.8375H56.2446C57.7155 58.8375 59.1374 57.6485 59.1987 56.1776C59.2722 54.5842 57.9974 53.2726 56.4162 53.2726H56.882C58.3529 53.2726 59.7748 52.0836 59.8361 50.6128C59.9096 49.0193 58.6348 47.7078 57.0536 47.7078H57.5194C58.9903 47.7078 60.4122 46.5188 60.4734 45.0479L60.498 45.0724ZM30.7614 36.6025H28.1874C26.6307 36.6025 25.3682 37.865 25.3682 39.4095V56.0305C25.3682 57.5872 26.6307 58.8498 28.1874 58.8498H30.7614C32.3181 58.8498 33.5684 57.5872 33.5684 56.0305V39.4095C33.5684 37.8528 32.3059 36.6025 30.7614 36.6025Z"
            fill="#5C595A"
          />
          <path
            d="M60.4243 44.9386C60.4978 43.3451 59.223 42.0336 57.6418 42.0336H58.1076C59.6275 42.0336 61.0004 40.8937 61.0617 39.3737C61.1352 37.7803 59.8604 36.4687 58.2792 36.4687H46.1444C46.1444 36.4687 46.1076 36.4442 46.1321 36.4197C48.8533 33.9682 50.5448 32.7302 50.6061 27.6802C50.6306 25.9151 49.5642 23.6475 47.7624 23.6475C44.9799 23.6475 44.8696 25.7925 44.8451 27.6802C43.2026 33.1102 35.603 33.0489 35.603 41.8375V53.8253C35.603 56.5219 37.7848 58.7037 40.4814 58.7037H56.1709C57.6418 58.7037 59.0637 57.5147 59.125 56.0438C59.1985 54.4504 57.9238 53.1388 56.3426 53.1388H56.8083C58.2792 53.1388 59.7011 51.9499 59.7624 50.479C59.8359 48.8855 58.5611 47.574 56.9799 47.574H57.4457C58.9166 47.574 60.3385 46.385 60.3998 44.9141L60.4243 44.9386ZM30.6755 36.4687H28.1014C26.5447 36.4687 25.2822 37.7312 25.2822 39.2757V55.8968C25.2822 57.4535 26.5447 58.7037 28.1014 58.7037H30.6755C32.2322 58.7037 33.4824 57.4412 33.4824 55.8968V39.2757C33.4824 37.719 32.2199 36.4687 30.6755 36.4687Z"
            fill="#5B5859"
          />
          <path
            d="M60.3388 44.8156C60.4123 43.2221 59.1375 41.9106 57.5563 41.9106H58.0221C59.542 41.9106 60.9149 40.7706 60.9762 39.2507C61.0497 37.6572 59.7749 36.3457 58.1937 36.3457H46.0589C46.0589 36.3457 46.0221 36.3212 46.0466 36.2967C48.7678 33.8452 50.4593 32.6072 50.5206 27.5571C50.5451 25.792 49.4787 23.5244 47.6768 23.5244C44.8944 23.5244 44.7841 25.6695 44.7596 27.5571C43.1171 32.9871 35.5175 32.9259 35.5175 41.7144V53.7022C35.5175 56.3988 37.6993 58.5807 40.3959 58.5807H56.0854C57.5563 58.5807 58.9782 57.3917 59.0395 55.9208C59.113 54.3273 57.8383 53.0158 56.257 53.0158H56.7228C58.1937 53.0158 59.6156 51.8268 59.6769 50.3559C59.7504 48.7625 58.4756 47.4509 56.8944 47.4509H57.3602C58.8311 47.4509 60.253 46.2619 60.3143 44.791L60.3388 44.8156ZM30.59 36.3457H28.0159C26.4592 36.3457 25.209 37.6082 25.209 39.1526V55.7737C25.209 57.3304 26.4715 58.5807 28.0159 58.5807H30.59C32.1467 58.5807 33.3969 57.3181 33.3969 55.7737V39.1526C33.3969 37.5959 32.1344 36.3457 30.59 36.3457Z"
            fill="#5A5758"
          />
          <path
            d="M60.2528 44.6808C60.3263 43.0873 59.0516 41.7758 57.4704 41.7758H57.9361C59.4561 41.7758 60.8289 40.6359 60.8902 39.1159C60.9637 37.5225 59.6889 36.2109 58.1077 36.2109H45.9729C45.9729 36.2109 45.9361 36.1864 45.9606 36.1619C48.6818 33.7104 50.3733 32.4724 50.4346 27.4223C50.4591 25.6573 49.3927 23.3896 47.5909 23.3896C44.8084 23.3896 44.6981 25.5347 44.6736 27.4223C43.0311 32.8524 35.4315 32.7911 35.4315 41.5797V53.5674C35.4315 56.2641 37.6133 58.4459 40.31 58.4459H55.9995C57.4704 58.4459 58.8922 57.2569 58.9535 55.786C59.027 54.1926 57.7523 52.881 56.1711 52.881H56.6368C58.1077 52.881 59.5296 51.692 59.5909 50.2212C59.6644 48.6277 58.3897 47.3162 56.8085 47.3162H57.2742C58.7451 47.3162 60.167 46.1272 60.2283 44.6563L60.2528 44.6808ZM30.5163 36.2109H27.9422C26.3855 36.2109 25.1353 37.4734 25.1353 39.0301V55.6512C25.1353 57.2079 26.3978 58.4582 27.9422 58.4582H30.5163C32.073 58.4582 33.3232 57.1956 33.3232 55.6512V39.0301C33.3232 37.4734 32.0607 36.2109 30.5163 36.2109Z"
            fill="#595657"
          />
          <path
            d="M60.1673 44.546C60.2409 42.9526 58.9661 41.641 57.3849 41.641H57.8507C59.3706 41.641 60.7434 40.5011 60.8047 38.9812C60.8783 37.3877 59.6035 36.0761 58.0223 36.0761H45.8874C45.8874 36.0761 45.8507 36.0516 45.8752 36.0271C48.5963 33.5756 50.2879 32.3376 50.3491 27.2876C50.3737 25.5225 49.3073 23.2549 47.5054 23.2549C44.723 23.2549 44.6127 25.3999 44.5881 27.2876C42.9457 32.7176 35.346 32.6563 35.346 41.4449V53.4327C35.346 56.1293 37.5279 58.3111 40.2245 58.3111H55.914C57.3849 58.3111 58.8068 57.1222 58.8681 55.6513C58.9416 54.0578 57.6668 52.7463 56.0856 52.7463H56.5514C58.0223 52.7463 59.4442 51.5573 59.5054 50.0864C59.579 48.4929 58.3042 47.1814 56.723 47.1814H57.1888C58.6597 47.1814 60.0815 45.9924 60.1428 44.5215L60.1673 44.546ZM30.4308 36.0884H27.8568C26.3001 36.0884 25.0498 37.3509 25.0498 38.8954V55.5164C25.0498 57.0731 26.3123 58.3234 27.8568 58.3234H30.4308C31.9875 58.3234 33.2378 57.0609 33.2378 55.5164V38.8954C33.2378 37.3387 31.9753 36.0884 30.4308 36.0884Z"
            fill="#585556"
          />
          <path
            d="M60.0932 44.424C60.1667 42.8305 58.8919 41.519 57.3107 41.519H57.7765C59.2964 41.519 60.6693 40.379 60.7306 38.8591C60.8041 37.2656 59.5293 35.9541 57.9481 35.9541H45.8133C45.8133 35.9541 45.7765 35.9296 45.801 35.9051C48.5222 33.4536 50.2137 32.2156 50.275 27.1655C50.2995 25.4004 49.2331 23.1328 47.4312 23.1328C44.6488 23.1328 44.5385 25.2779 44.514 27.1655C42.8715 32.5955 35.2719 32.5343 35.2719 41.3228V53.3106C35.2719 56.0072 37.4537 58.1891 40.1503 58.1891H55.8398C57.3107 58.1891 58.7326 57.0001 58.7939 55.5292C58.8674 53.9357 57.5927 52.6242 56.0114 52.6242H56.4772C57.9481 52.6242 59.37 51.4352 59.4313 49.9643C59.5048 48.3709 58.23 47.0593 56.6488 47.0593H57.1146C58.5855 47.0593 60.0074 45.8703 60.0687 44.3994L60.0932 44.424ZM30.3444 35.9541H27.7703C26.2136 35.9541 24.9634 37.2166 24.9634 38.761V55.3821C24.9634 56.9388 26.2259 58.1891 27.7703 58.1891H30.3444C31.9011 58.1891 33.1513 56.9265 33.1513 55.3821V38.761C33.1513 37.2043 31.8888 35.9541 30.3444 35.9541Z"
            fill="#575455"
          />
          <path
            d="M60.0082 44.2882C60.0817 42.6948 58.8069 41.3832 57.2257 41.3832H57.6915C59.2114 41.3832 60.5843 40.2433 60.6455 38.7234C60.7191 37.1299 59.4443 35.8183 57.8631 35.8183H45.7283C45.7283 35.8183 45.6915 35.7938 45.716 35.7693C48.4371 33.3178 50.1287 32.0798 50.19 27.0298C50.2145 25.2647 49.1481 22.9971 47.3462 22.9971C44.5638 22.9971 44.4535 25.1421 44.429 27.0298C42.7865 32.4598 35.1869 32.3985 35.1869 41.1871V53.1749C35.1869 55.8715 37.3687 58.0533 40.0653 58.0533H55.7548C57.2257 58.0533 58.6476 56.8643 58.7089 55.3935C58.7824 53.8 57.5076 52.4884 55.9264 52.4884H56.3922C57.8631 52.4884 59.285 51.2995 59.3463 49.8286C59.4198 48.2351 58.145 46.9236 56.5638 46.9236H57.0296C58.5005 46.9236 59.9224 45.7346 59.9836 44.2637L60.0082 44.2882ZM30.2716 35.8306H27.6976C26.1409 35.8306 24.8906 37.0931 24.8906 38.6376V55.2586C24.8906 56.8153 26.1531 58.0656 27.6976 58.0656H30.2716C31.8283 58.0656 33.0786 56.8031 33.0786 55.2586V38.6376C33.0786 37.0809 31.8161 35.8306 30.2716 35.8306Z"
            fill="#565354"
          />
          <path
            d="M59.9217 44.1662C59.9953 42.5727 58.7205 41.2611 57.1393 41.2611H57.6051C59.125 41.2611 60.4978 40.1212 60.5591 38.6013C60.6327 37.0078 59.3579 35.6963 57.7767 35.6963H45.6418C45.6418 35.6963 45.6051 35.6718 45.6296 35.6472C48.3507 33.1958 50.0422 31.9578 50.1035 26.9077C50.128 25.1426 49.0617 22.875 47.2598 22.875C44.4774 22.875 44.3671 25.0201 44.3425 26.9077C42.7 32.3377 35.1004 32.2764 35.1004 41.065V53.0528C35.1004 55.7494 37.2823 57.9312 39.9789 57.9312H55.6684C57.1393 57.9312 58.5612 56.7423 58.6224 55.2714C58.696 53.6779 57.4212 52.3664 55.84 52.3664H56.3058C57.7767 52.3664 59.1985 51.1774 59.2598 49.7065C59.3334 48.113 58.0586 46.8015 56.4774 46.8015H56.9432C58.4141 46.8015 59.8359 45.6125 59.8972 44.1416L59.9217 44.1662ZM30.1852 35.6963H27.6112C26.0545 35.6963 24.8042 36.9588 24.8042 38.5032V55.1243C24.8042 56.681 26.0667 57.9312 27.6112 57.9312H30.1852C31.7419 57.9312 33.0044 56.6687 33.0044 55.1243V38.5032C33.0044 36.9465 31.7419 35.6963 30.1852 35.6963Z"
            fill="#555253"
          />
          <path
            d="M59.8485 44.0314C59.9221 42.4379 58.6473 41.1264 57.0661 41.1264H57.5319C59.0518 41.1264 60.4246 39.9864 60.4859 38.4665C60.5595 36.8731 59.2847 35.5615 57.7035 35.5615H45.5686C45.5686 35.5615 45.5319 35.537 45.5564 35.5125C48.2775 33.061 49.9691 31.823 50.0303 26.7729C50.0549 25.0079 48.9885 22.7402 47.1866 22.7402C44.4042 22.7402 44.2939 24.8853 44.2693 26.7729C42.6269 32.203 35.0272 32.1417 35.0272 40.9303V52.918C35.0272 55.6147 37.2091 57.7965 39.9057 57.7965H55.5952C57.0661 57.7965 58.488 56.6075 58.5493 55.1366C58.6228 53.5432 57.348 52.2316 55.7668 52.2316H56.2326C57.7035 52.2316 59.1254 51.0426 59.1866 49.5717C59.2602 47.9783 57.9854 46.6667 56.4042 46.6667H56.87C58.3409 46.6667 59.7627 45.4778 59.824 44.0069L59.8485 44.0314ZM30.0998 35.5615H27.5257C25.969 35.5615 24.7188 36.824 24.7188 38.3685V54.9895C24.7188 56.5462 25.9813 57.7965 27.5257 57.7965H30.0998C31.6565 57.7965 32.919 56.534 32.919 54.9895V38.3685C32.919 36.8118 31.6565 35.5615 30.0998 35.5615Z"
            fill="#545152"
          />
          <path
            d="M59.7621 43.9093C59.8357 42.3159 58.5609 41.0043 56.9797 41.0043H57.4455C58.9654 41.0043 60.3382 39.8644 60.3995 38.3444C60.473 36.751 59.1983 35.4394 57.6171 35.4394H45.4822C45.4822 35.4394 45.4454 35.4149 45.47 35.3904C48.1911 32.9389 49.8826 31.7009 49.9439 26.6509C49.9684 24.8858 48.902 22.6182 47.1002 22.6182C44.3178 22.6182 44.2074 24.7632 44.1829 26.6509C42.5404 32.0809 34.9408 32.0196 34.9408 40.8082V52.796C34.9408 55.4926 37.1226 57.6744 39.8193 57.6744H55.5088C56.9797 57.6744 58.4015 56.4854 58.4628 55.0145C58.5364 53.4211 57.2616 52.1095 55.6804 52.1095H56.1462C57.6171 52.1095 59.0389 50.9206 59.1002 49.4497C59.1738 47.8562 57.899 46.5447 56.3178 46.5447H56.7836C58.2544 46.5447 59.6763 45.3557 59.7376 43.8848L59.7621 43.9093ZM30.0133 35.4394H27.4393C25.8826 35.4394 24.6323 36.7019 24.6323 38.2464V54.8675C24.6323 56.4241 25.8948 57.6744 27.4393 57.6744H30.0133C31.57 57.6744 32.8325 56.4119 32.8325 54.8675V38.2464C32.8325 36.6897 31.57 35.4394 30.0133 35.4394Z"
            fill="#535051"
          />
          <path
            d="M59.6771 43.7736C59.7507 42.1801 58.4759 40.8686 56.8947 40.8686H57.3605C58.8804 40.8686 60.2532 39.7286 60.3145 38.2087C60.388 36.6152 59.1133 35.3037 57.5321 35.3037H45.3972C45.3972 35.3037 45.3604 35.2792 45.3849 35.2547C48.1061 32.8032 49.7976 31.5652 49.8589 26.5151C49.8834 24.75 48.817 22.4824 47.0152 22.4824C44.2327 22.4824 44.1224 24.6275 44.0979 26.5151C42.4554 31.9452 34.8558 31.8839 34.8558 40.6724V52.6602C34.8558 55.3568 37.0376 57.5387 39.7343 57.5387H55.4238C56.8947 57.5387 58.3165 56.3497 58.3778 54.8788C58.4514 53.2853 57.1766 51.9738 55.5954 51.9738H56.0612C57.5321 51.9738 58.9539 50.7848 59.0152 49.3139C59.0887 47.7205 57.814 46.4089 56.2328 46.4089H56.6986C58.1694 46.4089 59.5913 45.2199 59.6526 43.7491L59.6771 43.7736ZM29.9406 35.3037H27.3665C25.8098 35.3037 24.5596 36.5662 24.5596 38.1106V54.7317C24.5596 56.2884 25.8221 57.5387 27.3665 57.5387H29.9406C31.4973 57.5387 32.7598 56.2761 32.7598 54.7317V38.1106C32.7598 36.5539 31.4973 35.3037 29.9406 35.3037Z"
            fill="#524F50"
          />
          <path
            d="M59.6029 43.6388C59.6765 42.0453 58.4017 40.7338 56.8205 40.7338H57.2863C58.8062 40.7338 60.179 39.5939 60.2403 38.0739C60.3139 36.4805 59.0391 35.1689 57.4579 35.1689H45.323C45.323 35.1689 45.2863 35.1444 45.3108 35.1199C48.0319 32.6684 49.7234 31.4304 49.7847 26.3804C49.8092 24.6153 48.7429 22.3477 46.941 22.3477C44.1586 22.3477 44.0483 24.4927 44.0237 26.3804C42.3812 31.8104 34.7816 31.7491 34.7816 40.5377V52.5254C34.7816 55.2221 36.9635 57.4039 39.6601 57.4039H55.3496C56.8205 57.4039 58.2424 56.2149 58.3036 54.744C58.3772 53.1506 57.1024 51.839 55.5212 51.839H55.987C57.4579 51.839 58.8797 50.6501 58.941 49.1792C59.0146 47.5857 57.7398 46.2742 56.1586 46.2742H56.6244C58.0953 46.2742 59.5171 45.0852 59.5784 43.6143L59.6029 43.6388ZM29.8542 35.1812H27.2801C25.7234 35.1812 24.4731 36.4437 24.4731 37.9881V54.6092C24.4731 56.1659 25.7357 57.4162 27.2801 57.4162H29.8542C31.4109 57.4162 32.6734 56.1536 32.6734 54.6092V37.9881C32.6734 36.4314 31.4109 35.1812 29.8542 35.1812Z"
            fill="#514E4F"
          />
          <path
            d="M59.5175 43.5167C59.591 41.9233 58.3163 40.6117 56.735 40.6117H57.2008C58.7208 40.6117 60.0936 39.4718 60.1549 37.9519C60.2284 36.3584 58.9536 35.0469 57.3724 35.0469H45.2376C45.2376 35.0469 45.2008 35.0223 45.2253 34.9978C47.9465 32.5463 49.638 31.3083 49.6993 26.2583C49.7238 24.4932 48.6574 22.2256 46.8556 22.2256C44.0731 22.2256 43.9628 24.3706 43.9383 26.2583C42.2958 31.6883 34.6962 31.627 34.6962 40.4156V52.4034C34.6962 55.1 36.878 57.2818 39.5746 57.2818H55.2642C56.7351 57.2818 58.1569 56.0929 58.2182 54.622C58.2917 53.0285 57.017 51.717 55.4358 51.717H55.9015C57.3724 51.717 58.7943 50.528 58.8556 49.0571C58.9291 47.4636 57.6544 46.1521 56.0732 46.1521H56.5389C58.0098 46.1521 59.4317 44.9631 59.493 43.4922L59.5175 43.5167ZM29.7687 35.0469H27.1946C25.638 35.0469 24.3877 36.3094 24.3877 37.8538V54.4749C24.3877 56.0316 25.6502 57.2818 27.1946 57.2818H29.7687C31.3254 57.2818 32.5879 56.0193 32.5879 54.4749V37.8538C32.5879 36.2971 31.3254 35.0469 29.7687 35.0469Z"
            fill="#504D4E"
          />
          <path
            d="M59.4315 43.381C59.505 41.7875 58.2303 40.476 56.6491 40.476H57.1148C58.6348 40.476 60.0076 39.336 60.0689 37.8161C60.1424 36.2227 58.8677 34.9111 57.2864 34.9111H45.1516C45.1516 34.9111 45.1148 34.8866 45.1393 34.8621C47.8605 32.4106 49.552 31.1726 49.6133 26.1225C49.6378 24.3575 48.5714 22.0898 46.7696 22.0898C43.9871 22.0898 43.8768 24.2349 43.8523 26.1225C42.2098 31.5526 34.6102 31.4913 34.6102 40.2799V52.2676C34.6102 54.9643 36.792 57.1461 39.4887 57.1461H55.1782C56.6491 57.1461 58.0709 55.9571 58.1322 54.4862C58.2058 52.8928 56.931 51.5812 55.3498 51.5812H55.8156C57.2864 51.5812 58.7083 50.3922 58.7696 48.9214C58.8431 47.3279 57.5684 46.0163 55.9872 46.0163H56.4529C57.9238 46.0163 59.3457 44.8274 59.407 43.3565L59.4315 43.381ZM29.695 34.9111H27.1209C25.5642 34.9111 24.314 36.1736 24.314 37.7181V54.3391C24.314 55.8958 25.5765 57.1461 27.1209 57.1461H29.695C31.2517 57.1461 32.5142 55.8836 32.5142 54.3391V37.7181C32.5142 36.1614 31.2517 34.9111 29.695 34.9111Z"
            fill="#4F4C4D"
          />
          <path
            d="M59.346 43.2589C59.4196 41.6655 58.1448 40.3539 56.5636 40.3539H57.0294C58.5493 40.3539 59.9221 39.214 59.9834 37.6941C60.057 36.1006 58.7822 34.789 57.201 34.789H45.0661C45.0661 34.789 45.0294 34.7645 45.0539 34.74C47.775 32.2885 49.4666 31.0505 49.5278 26.0005C49.5524 24.2354 48.486 21.9678 46.6841 21.9678C43.9017 21.9678 43.7914 24.1128 43.7669 26.0005C42.1244 31.4305 34.5248 31.3692 34.5248 40.1578V52.1456C34.5248 54.8422 36.7066 57.024 39.4032 57.024H55.0927C56.5636 57.024 57.9855 55.8351 58.0468 54.3642C58.1203 52.7707 56.8455 51.4592 55.2643 51.4592H55.7301C57.201 51.4592 58.6229 50.2702 58.6841 48.7993C58.7577 47.2058 57.4829 45.8943 55.9017 45.8943H56.3675C57.8384 45.8943 59.2602 44.7053 59.3215 43.2344L59.346 43.2589ZM29.6095 34.789H27.0355C25.4788 34.789 24.2285 36.0516 24.2285 37.596V54.2171C24.2285 55.7738 25.491 57.024 27.0355 57.024H29.6095C31.1662 57.024 32.4287 55.7615 32.4287 54.2171V37.596C32.4287 36.0393 31.1662 34.789 29.6095 34.789Z"
            fill="#4E4B4C"
          />
          <path
            d="M59.2719 43.1242C59.3454 41.5307 58.0707 40.2192 56.4894 40.2192H56.9552C58.4751 40.2192 59.848 39.0792 59.9093 37.5593C59.9828 35.9658 58.708 34.6543 57.1268 34.6543H44.992C44.992 34.6543 44.9552 34.6298 44.9797 34.6052C47.7009 32.1538 49.3924 30.9158 49.4537 25.8657C49.4782 24.1006 48.4118 21.833 46.61 21.833C43.8275 21.833 43.7172 23.9781 43.6927 25.8657C42.0502 31.2957 34.4506 31.2345 34.4506 40.023V52.0108C34.4506 54.7074 36.6324 56.8893 39.329 56.8893H55.0186C56.4894 56.8893 57.9113 55.7003 57.9726 54.2294C58.0461 52.6359 56.7714 51.3244 55.1902 51.3244H55.6559C57.1268 51.3244 58.5487 50.1354 58.61 48.6645C58.6835 47.0711 57.4088 45.7595 55.8275 45.7595H56.2933C57.7642 45.7595 59.1861 44.5705 59.2474 43.0996L59.2719 43.1242ZM29.5231 34.6543H26.949C25.3923 34.6543 24.1421 35.9168 24.1421 37.4612V54.0823C24.1421 55.639 25.4046 56.8893 26.949 56.8893H29.5231C31.0798 56.8893 32.3423 55.6267 32.3423 54.0823V37.4612C32.3423 35.9045 31.0798 34.6543 29.5231 34.6543Z"
            fill="#4D4A4B"
          />
          <path
            d="M59.1864 42.9894C59.2599 41.3959 57.9852 40.0844 56.4039 40.0844H56.8697C58.3896 40.0844 59.7625 38.9444 59.8238 37.4245C59.8973 35.8311 58.6225 34.5195 57.0413 34.5195H44.9065C44.9065 34.5195 44.8697 34.495 44.8942 34.4705C47.6154 32.019 49.3069 30.781 49.3682 25.7309C49.3927 23.9659 48.3263 21.6982 46.5245 21.6982C43.742 21.6982 43.6317 23.8433 43.6072 25.7309C41.9647 31.161 34.3651 31.0997 34.3651 39.8883V51.876C34.3651 54.5727 36.5469 56.7545 39.2435 56.7545H54.9331C56.4039 56.7545 57.8258 55.5655 57.8871 54.0946C57.9606 52.5012 56.6859 51.1896 55.1047 51.1896H55.5704C57.0413 51.1896 58.4632 50.0006 58.5245 48.5298C58.598 46.9363 57.3233 45.6247 55.742 45.6247H56.2078C57.6787 45.6247 59.1006 44.4358 59.1619 42.9649L59.1864 42.9894ZM29.4499 34.5318H26.8758C25.3191 34.5318 24.0688 35.7943 24.0688 37.3387V53.9598C24.0688 55.5165 25.3314 56.7667 26.8758 56.7667H29.4499C31.0066 56.7667 32.2691 55.5042 32.2691 53.9598V37.3387C32.2691 35.782 31.0066 34.5318 29.4499 34.5318Z"
            fill="#4C494A"
          />
          <path
            d="M59.1004 42.8663C59.174 41.2729 57.8992 39.9613 56.318 39.9613H56.7838C58.3037 39.9613 59.6765 38.8214 59.7378 37.3015C59.8114 35.708 58.5366 34.3965 56.9554 34.3965H44.8205C44.8205 34.3965 44.7838 34.372 44.8083 34.3474C47.5294 31.896 49.221 30.658 49.2822 25.6079C49.3068 23.8428 48.2404 21.5752 46.4385 21.5752C43.6561 21.5752 43.5458 23.7203 43.5213 25.6079C41.8788 31.0379 34.2791 30.9766 34.2791 39.7652V51.753C34.2791 54.4496 36.461 56.6314 39.1576 56.6314H54.8471C56.318 56.6314 57.7399 55.4425 57.8012 53.9716C57.8747 52.3781 56.5999 51.0666 55.0187 51.0666H55.4845C56.9554 51.0666 58.3773 49.8776 58.4385 48.4067C58.5121 46.8132 57.2373 45.5017 55.6561 45.5017H56.1219C57.5928 45.5017 59.0146 44.3127 59.0759 42.8418L59.1004 42.8663ZM29.3639 34.3965H26.7899C25.2332 34.3965 23.9829 35.659 23.9829 37.2034V53.8245C23.9829 55.3812 25.2454 56.6314 26.7899 56.6314H29.3639C30.9206 56.6314 32.1831 55.3689 32.1831 53.8245V37.2034C32.1831 35.6467 30.9206 34.3965 29.3639 34.3965Z"
            fill="#4B4849"
          />
          <path
            d="M59.0272 42.7326C59.1008 41.1391 57.826 39.8275 56.2448 39.8275H56.7106C58.2305 39.8275 59.6033 38.6876 59.6646 37.1677C59.7382 35.5742 58.4634 34.2627 56.8822 34.2627H44.7473C44.7473 34.2627 44.7106 34.2382 44.7351 34.2136C47.4562 31.7622 49.1478 30.5242 49.209 25.4741C49.2336 23.709 48.1672 21.4414 46.3653 21.4414C43.5829 21.4414 43.4726 23.5865 43.4481 25.4741C41.8056 30.9041 34.206 30.8428 34.206 39.6314V51.6192C34.206 54.3158 36.3878 56.4977 39.0844 56.4977H54.7739C56.2448 56.4977 57.6667 55.3087 57.728 53.8378C57.8015 52.2443 56.5267 50.9328 54.9455 50.9328H55.4113C56.8822 50.9328 58.3041 49.7438 58.3653 48.2729C58.4389 46.6795 57.1641 45.3679 55.5829 45.3679H56.0487C57.5196 45.3679 58.9414 44.1789 59.0027 42.708L59.0272 42.7326ZM29.2785 34.2749H26.7044C25.1477 34.2749 23.8975 35.5374 23.8975 37.0819V53.703C23.8975 55.2597 25.16 56.5099 26.7044 56.5099H29.2785C30.8352 56.5099 32.0977 55.2474 32.0977 53.703V37.0819C32.0977 35.5252 30.8352 34.2749 29.2785 34.2749Z"
            fill="#4A4748"
          />
          <path
            d="M58.9413 42.6095C59.0149 41.016 57.7401 39.7045 56.1589 39.7045H56.6247C58.1446 39.7045 59.5174 38.5646 59.5787 37.0446C59.6522 35.4512 58.3775 34.1396 56.7963 34.1396H44.6614C44.6614 34.1396 44.6246 34.1151 44.6492 34.0906C47.3703 31.6391 49.0618 30.4011 49.1231 25.351C49.1476 23.586 48.0812 21.3184 46.2794 21.3184C43.497 21.3184 43.3866 23.4634 43.3621 25.351C41.7196 30.7811 34.12 30.7198 34.12 39.5084V51.4961C34.12 54.1928 36.3018 56.3746 38.9985 56.3746H54.688C56.1589 56.3746 57.5807 55.1856 57.642 53.7147C57.7156 52.1213 56.4408 50.8097 54.8596 50.8097H55.3254C56.7963 50.8097 58.2181 49.6208 58.2794 48.1499C58.353 46.5564 57.0782 45.2449 55.497 45.2449H55.9628C57.4336 45.2449 58.8555 44.0559 58.9168 42.585L58.9413 42.6095ZM29.1925 34.1396H26.6185C25.0618 34.1396 23.8115 35.4021 23.8115 36.9588V53.5799C23.8115 55.1366 25.074 56.3869 26.6185 56.3869H29.1925C30.7492 56.3869 32.0117 55.1244 32.0117 53.5799V36.9588C32.0117 35.4021 30.7492 34.1396 29.1925 34.1396Z"
            fill="#494647"
          />
          <path
            d="M58.8553 42.4738C58.9289 40.8803 57.6541 39.5688 56.0729 39.5688H56.5387C58.0586 39.5688 59.4314 38.4288 59.4927 36.9089C59.5663 35.3154 58.2915 34.0039 56.7103 34.0039H44.5754C44.5754 34.0039 44.5387 33.9794 44.5632 33.9549C47.2843 31.5034 48.9758 30.2654 49.0371 25.2153C49.0616 23.4502 47.9952 21.1826 46.1934 21.1826C43.411 21.1826 43.3006 23.3277 43.2761 25.2153C41.6336 30.6454 34.034 30.5841 34.034 39.3726V51.3604C34.034 54.057 36.2159 56.2389 38.9125 56.2389H54.602C56.0729 56.2389 57.4948 55.0499 57.556 53.579C57.6296 51.9855 56.3548 50.674 54.7736 50.674H55.2394C56.7103 50.674 58.1321 49.485 58.1934 48.0141C58.267 46.4207 56.9922 45.1091 55.411 45.1091H55.8768C57.3477 45.1091 58.7695 43.9202 58.8308 42.4493L58.8553 42.4738ZM29.1188 34.0039H26.5447C24.9881 34.0039 23.7378 35.2664 23.7378 36.8108V53.4319C23.7378 54.9886 25.0003 56.2389 26.5447 56.2389H29.1188C30.6755 56.2389 31.938 54.9764 31.938 53.4319V36.8108C31.938 35.2542 30.6755 34.0039 29.1188 34.0039Z"
            fill="#484546"
          />
          <path
            d="M58.7821 42.3517C58.8557 40.7582 57.5809 39.4467 55.9997 39.4467H56.4655C57.9854 39.4467 59.3582 38.3067 59.4195 36.7868C59.4931 35.1934 58.2183 33.8818 56.6371 33.8818H44.5022C44.5022 33.8818 44.4655 33.8573 44.49 33.8328C47.2111 31.3813 48.9026 30.1433 48.9639 25.0932C48.9884 23.3282 47.922 21.0605 46.1202 21.0605C43.3378 21.0605 43.2275 23.2056 43.2029 25.0932C41.5604 30.5233 33.9608 30.462 33.9608 39.2506V51.2383C33.9608 53.935 36.1427 56.1168 38.8393 56.1168H54.5288C55.9997 56.1168 57.4216 54.9278 57.4828 53.4569C57.5564 51.8635 56.2816 50.5519 54.7004 50.5519H55.1662C56.6371 50.5519 58.0589 49.363 58.1202 47.8921C58.1938 46.2986 56.919 44.987 55.3378 44.987H55.8036C57.2745 44.987 58.6963 43.7981 58.7576 42.3272L58.7821 42.3517ZM29.0334 33.8818H26.4593C24.9026 33.8818 23.6523 35.1443 23.6523 36.6888V53.3098C23.6523 54.8665 24.9149 56.1168 26.4593 56.1168H29.0334C30.59 56.1168 31.8526 54.8543 31.8526 53.3098V36.6888C31.8526 35.1321 30.59 33.8818 29.0334 33.8818Z"
            fill="#474445"
          />
          <path
            d="M58.6962 42.2169C58.7697 40.6235 57.495 39.3119 55.9138 39.3119H56.3795C57.8995 39.3119 59.2723 38.172 59.3336 36.6521C59.4071 35.0586 58.1324 33.7471 56.5511 33.7471H44.4163C44.4163 33.7471 44.3795 33.7225 44.404 33.698C47.1252 31.2465 48.8167 30.0085 48.878 24.9585C48.9025 23.1934 47.8361 20.9258 46.0343 20.9258C43.2518 20.9258 43.1415 23.0708 43.117 24.9585C41.4745 30.3885 33.8749 30.3272 33.8749 39.1158V51.1036C33.8749 53.8002 36.0567 55.982 38.7534 55.982H54.4429C55.9138 55.982 57.3356 54.7931 57.3969 53.3222C57.4705 51.7287 56.1957 50.4172 54.6145 50.4172H55.0803C56.5511 50.4172 57.973 49.2282 58.0343 47.7573C58.1078 46.1638 56.8331 44.8523 55.2519 44.8523H55.7176C57.1885 44.8523 58.6104 43.6633 58.6717 42.1924L58.6962 42.2169ZM28.9474 33.7471H26.3734C24.8167 33.7471 23.5664 35.0096 23.5664 36.554V53.1751C23.5664 54.7318 24.8289 55.982 26.3734 55.982H28.9474C30.5041 55.982 31.7544 54.7195 31.7544 53.1751V36.554C31.7544 34.9973 30.4919 33.7471 28.9474 33.7471Z"
            fill="#464344"
          />
          <path
            d="M58.6107 42.0822C58.6842 40.4887 57.4095 39.1772 55.8283 39.1772H56.294C57.814 39.1772 59.1868 38.0372 59.2481 36.5173C59.3216 34.9238 58.0469 33.6123 56.4657 33.6123H44.3308C44.3308 33.6123 44.294 33.5878 44.3185 33.5633C47.0397 31.1118 48.7312 29.8738 48.7925 24.8237C48.817 23.0586 47.7506 20.791 45.9488 20.791C43.1663 20.791 43.056 22.9361 43.0315 24.8237C41.389 30.2538 33.7894 30.1925 33.7894 38.981V50.9688C33.7894 53.6654 35.9712 55.8473 38.6679 55.8473H54.3574C55.8283 55.8473 57.2501 54.6583 57.3114 53.1874C57.385 51.5939 56.1102 50.2824 54.529 50.2824H54.9948C56.4656 50.2824 57.8875 49.0934 57.9488 47.6225C58.0223 46.0291 56.7476 44.7175 55.1664 44.7175H55.6321C57.103 44.7175 58.5249 43.5285 58.5862 42.0577L58.6107 42.0822ZM28.8742 33.6245H26.3001C24.7434 33.6245 23.4932 34.8871 23.4932 36.4315V53.0526C23.4932 54.6093 24.7557 55.8718 26.3001 55.8718H28.8742C30.4309 55.8718 31.6811 54.6093 31.6811 53.0526V36.4315C31.6811 34.8748 30.4186 33.6245 28.8742 33.6245Z"
            fill="#454243"
          />
          <path
            d="M58.5243 41.9591C58.5978 40.3657 57.323 39.0541 55.7418 39.0541H56.2076C57.7275 39.0541 59.1004 37.9142 59.1617 36.3942C59.2352 34.8008 57.9604 33.4892 56.3792 33.4892H44.2444C44.2444 33.4892 44.2076 33.4647 44.2321 33.4402C46.9533 30.9887 48.6448 29.7507 48.7061 24.7007C48.7306 22.9356 47.6642 20.668 45.8623 20.668C43.0799 20.668 42.9696 22.813 42.9451 24.7007C41.3026 30.1307 33.703 30.0694 33.703 38.858V50.8458C33.703 53.5424 35.8848 55.7242 38.5814 55.7242H54.2709C55.7418 55.7242 57.1637 54.5352 57.225 53.0644C57.2985 51.4709 56.0238 50.1593 54.4425 50.1593H54.9083C56.3792 50.1593 57.8011 48.9704 57.8624 47.4995C57.9359 45.906 56.6611 44.5945 55.0799 44.5945H55.5457C57.0166 44.5945 58.4385 43.4055 58.4998 41.9346L58.5243 41.9591ZM28.7877 33.4892H26.2137C24.657 33.4892 23.4067 34.7517 23.4067 36.2962V52.9173C23.4067 54.474 24.6693 55.7242 26.2137 55.7242H28.7877C30.3444 55.7242 31.5947 54.4617 31.5947 52.9173V36.2962C31.5947 34.7395 30.3322 33.4892 28.7877 33.4892Z"
            fill="#444142"
          />
          <path
            d="M58.4511 41.8253C58.5247 40.2319 57.2499 38.9203 55.6687 38.9203H56.1345C57.6544 38.9203 59.0272 37.7804 59.0885 36.2605C59.1621 34.667 57.8873 33.3555 56.3061 33.3555H44.1712C44.1712 33.3555 44.1345 33.3309 44.159 33.3064C46.8801 30.8549 48.5716 29.6169 48.6329 24.5669C48.6574 22.8018 47.591 20.5342 45.7892 20.5342C43.0068 20.5342 42.8965 22.6792 42.8719 24.5669C41.2294 29.9969 33.6298 29.9356 33.6298 38.7242V50.712C33.6298 53.4086 35.8117 55.5904 38.5083 55.5904H54.1978C55.6687 55.5904 57.0906 54.4015 57.1518 52.9306C57.2254 51.3371 55.9506 50.0256 54.3694 50.0256H54.8352C56.3061 50.0256 57.7279 48.8366 57.7892 47.3657C57.8628 45.7722 56.588 44.4607 55.0068 44.4607H55.4726C56.9435 44.4607 58.3653 43.2717 58.4266 41.8008L58.4511 41.8253ZM28.7023 33.3555H26.1283C24.5716 33.3555 23.3091 34.618 23.3091 36.1624V52.7835C23.3091 54.3402 24.5716 55.5904 26.1283 55.5904H28.7023C30.259 55.5904 31.5093 54.3279 31.5093 52.7835V36.1624C31.5093 34.6057 30.2468 33.3555 28.7023 33.3555Z"
            fill="#434041"
          />
          <path
            d="M58.3651 41.7023C58.4387 40.1088 57.1639 38.7973 55.5827 38.7973H56.0485C57.5684 38.7973 58.9412 37.6573 59.0025 36.1374C59.0761 34.5439 57.8013 33.2324 56.2201 33.2324H44.0852C44.0852 33.2324 44.0485 33.2079 44.073 33.1834C46.7941 30.7319 48.4857 29.4939 48.5469 24.4438C48.5715 22.6788 47.5051 20.4111 45.7032 20.4111C42.9208 20.4111 42.8105 22.5562 42.7859 24.4438C41.1435 29.8739 33.5438 29.8126 33.5438 38.6012V50.5889C33.5438 53.2856 35.7257 55.4674 38.4223 55.4674H54.1118C55.5827 55.4674 57.0046 54.2784 57.0659 52.8075C57.1394 51.214 55.8646 49.9025 54.2834 49.9025H54.7492C56.2201 49.9025 57.642 48.7135 57.7032 47.2426C57.7768 45.6492 56.502 44.3376 54.9208 44.3376H55.3866C56.8575 44.3376 58.2793 43.1487 58.3406 41.6778L58.3651 41.7023ZM28.6286 33.2324H26.0546C24.4979 33.2324 23.2354 34.4949 23.2354 36.0516V52.6727C23.2354 54.2294 24.4979 55.4796 26.0546 55.4796H28.6286C30.1853 55.4796 31.4356 54.2171 31.4356 52.6727V36.0516C31.4356 34.4949 30.1731 33.2324 28.6286 33.2324Z"
            fill="#423F40"
          />
          <path
            d="M58.2792 41.5675C58.3527 39.9741 57.078 38.6625 55.4968 38.6625H55.9626C57.4825 38.6625 58.8553 37.5226 58.9166 36.0026C58.9901 34.4092 57.7154 33.0976 56.1342 33.0976H43.9993C43.9993 33.0976 43.9625 33.0731 43.987 33.0486C46.7082 30.5971 48.3997 29.3591 48.461 24.3091C48.4855 22.544 47.4191 20.2764 45.6173 20.2764C42.8348 20.2764 42.7245 22.4214 42.7 24.3091C41.0575 29.7391 33.4579 29.6778 33.4579 38.4664V50.4542C33.4579 53.1508 35.6397 55.3326 38.3364 55.3326H54.0259C55.4968 55.3326 56.9186 54.1436 56.9799 52.6727C57.0535 51.0793 55.7787 49.7677 54.1975 49.7677H54.6633C56.1342 49.7677 57.556 48.5788 57.6173 47.1079C57.6908 45.5144 56.4161 44.2029 54.8349 44.2029H55.3006C56.7715 44.2029 58.1934 43.0139 58.2547 41.543L58.2792 41.5675ZM28.5427 33.0976H25.9686C24.4119 33.0976 23.1494 34.3601 23.1494 35.9046V52.5257C23.1494 54.0824 24.4119 55.3326 25.9686 55.3326H28.5427C30.0994 55.3326 31.3496 54.0701 31.3496 52.5257V35.9046C31.3496 34.3479 30.0871 33.0976 28.5427 33.0976Z"
            fill="#413E3F"
          />
          <path
            d="M58.206 41.4328C58.2796 39.8393 57.0048 38.5277 55.4236 38.5277H55.8894C57.4093 38.5277 58.7821 37.3878 58.8434 35.8679C58.9169 34.2744 57.6422 32.9629 56.061 32.9629H43.9261C43.9261 32.9629 43.8893 32.9384 43.9139 32.9138C46.635 30.4624 48.3265 29.2244 48.3878 24.1743C48.4123 22.4092 47.3459 20.1416 45.5441 20.1416C42.7617 20.1416 42.6513 22.2867 42.6268 24.1743C40.9843 29.6043 33.3847 29.543 33.3847 38.3316V50.3194C33.3847 53.016 35.5665 55.1979 38.2632 55.1979H53.9527C55.4236 55.1979 56.8454 54.0089 56.9067 52.538C56.9803 50.9445 55.7055 49.633 54.1243 49.633H54.5901C56.061 49.633 57.4828 48.444 57.5441 46.9731C57.6177 45.3797 56.3429 44.0681 54.7617 44.0681H55.2275C56.6983 44.0681 58.1202 42.8791 58.1815 41.4082L58.206 41.4328ZM28.4572 32.9751H25.8832C24.3265 32.9751 23.064 34.2376 23.064 35.7821V52.4032C23.064 53.9598 24.3265 55.2101 25.8832 55.2101H28.4572C30.0139 55.2101 31.2642 53.9476 31.2642 52.4032V35.7821C31.2642 34.2254 30.0017 32.9751 28.4572 32.9751Z"
            fill="#403D3E"
          />
          <path
            d="M58.1201 41.3107C58.1936 39.7172 56.9188 38.4057 55.3376 38.4057H55.8034C57.3233 38.4057 58.6962 37.2657 58.7575 35.7458C58.831 34.1523 57.5562 32.8408 55.975 32.8408H43.8402C43.8402 32.8408 43.8034 32.8163 43.8279 32.7918C46.5491 30.3403 48.2406 29.1023 48.3019 24.0522C48.3264 22.2872 47.26 20.0195 45.4582 20.0195C42.6757 20.0195 42.5654 22.1646 42.5409 24.0522C40.8984 29.4823 33.2988 29.421 33.2988 38.2096V50.1973C33.2988 52.894 35.4806 55.0758 38.1772 55.0758H53.8667C55.3376 55.0758 56.7595 53.8868 56.8208 52.4159C56.8943 50.8224 55.6196 49.5109 54.0384 49.5109H54.5041C55.975 49.5109 57.3969 48.3219 57.4582 46.851C57.5317 45.2576 56.257 43.946 54.6757 43.946H55.1415C56.6124 43.946 58.0343 42.7571 58.0956 41.2862L58.1201 41.3107ZM28.3713 32.8408H25.7972C24.2405 32.8408 22.978 34.1033 22.978 35.6478V52.2688C22.978 53.8255 24.2405 55.0758 25.7972 55.0758H28.3713C29.928 55.0758 31.1782 53.8133 31.1782 52.2688V35.6478C31.1782 34.0911 29.9157 32.8408 28.3713 32.8408Z"
            fill="#3F3C3D"
          />
          <path
            d="M58.0346 41.1749C58.1081 39.5815 56.8333 38.2699 55.2521 38.2699H55.7179C57.2378 38.2699 58.6107 37.13 58.672 35.6101C58.7455 34.0166 57.4707 32.7051 55.8895 32.7051H43.7547C43.7547 32.7051 43.7179 32.6805 43.7424 32.656C46.4636 30.2045 48.1551 28.9665 48.2164 23.9165C48.2409 22.1514 47.1745 19.8838 45.3727 19.8838C42.5902 19.8838 42.4799 22.0288 42.4554 23.9165C40.8129 29.3465 33.2133 29.2852 33.2133 38.0738V50.0616C33.2133 52.7582 35.3951 54.94 38.0917 54.94H53.7812C55.2521 54.94 56.674 53.7511 56.7353 52.2802C56.8088 50.6867 55.5341 49.3752 53.9529 49.3752H54.4186C55.8895 49.3752 57.3114 48.1862 57.3727 46.7153C57.4462 45.1218 56.1714 43.8103 54.5902 43.8103H55.056C56.5269 43.8103 57.9488 42.6213 58.0101 41.1504L58.0346 41.1749ZM28.2981 32.7173H25.724C24.1673 32.7173 22.9048 33.9798 22.9048 35.5243V52.1453C22.9048 53.702 24.1673 54.9523 25.724 54.9523H28.2981C29.8547 54.9523 31.105 53.6898 31.105 52.1453V35.5243C31.105 33.9676 29.8425 32.7173 28.2981 32.7173Z"
            fill="#3F3B3C"
          />
          <path
            d="M57.9604 41.0529C58.034 39.4594 56.7592 38.1479 55.178 38.1479H55.6438C57.1637 38.1479 58.5365 37.0079 58.5978 35.488C58.6713 33.8945 57.3966 32.583 55.8154 32.583H43.6805C43.6805 32.583 43.6437 32.5585 43.6682 32.534C46.3894 30.0825 48.0809 28.8445 48.1422 23.7944C48.1667 22.0293 47.1003 19.7617 45.2985 19.7617C42.516 19.7617 42.4057 21.9068 42.3812 23.7944C40.7387 29.2245 33.1391 29.1632 33.1391 37.9517V49.9395C33.1391 52.6361 35.3209 54.818 38.0176 54.818H53.7071C55.178 54.818 56.5998 53.629 56.6611 52.1581C56.7347 50.5646 55.4599 49.2531 53.8787 49.2531H54.3445C55.8154 49.2531 57.2372 48.0641 57.2985 46.5932C57.3721 44.9998 56.0973 43.6882 54.5161 43.6882H54.9819C56.4527 43.6882 57.8746 42.4992 57.9359 41.0284L57.9604 41.0529ZM28.2116 32.583H25.6376C24.0809 32.583 22.8184 33.8455 22.8184 35.3899V52.011C22.8184 53.5677 24.0809 54.818 25.6376 54.818H28.2116C29.7683 54.818 31.0186 53.5554 31.0186 52.011V35.3899C31.0186 33.8332 29.7561 32.583 28.2116 32.583Z"
            fill="#3E3A3B"
          />
          <path
            d="M57.875 40.9181C57.9485 39.3246 56.6737 38.0131 55.0925 38.0131H55.5583C57.0782 38.0131 58.4511 36.8732 58.5123 35.3532C58.5859 33.7598 57.3111 32.4482 55.7299 32.4482H43.5951C43.5951 32.4482 43.5583 32.4237 43.5828 32.3992C46.3039 29.9477 47.9955 28.7097 48.0568 23.6596C48.0813 21.8946 47.0149 19.627 45.213 19.627C42.4306 19.627 42.3203 21.772 42.2958 23.6596C40.6533 29.0897 33.0537 29.0284 33.0537 37.817V49.8047C33.0537 52.5014 35.2355 54.6832 37.9321 54.6832H53.6216C55.0925 54.6832 56.5144 53.4942 56.5757 52.0233C56.6492 50.4299 55.3744 49.1183 53.7932 49.1183H54.259C55.7299 49.1183 57.1518 47.9294 57.2131 46.4585C57.2866 44.865 56.0118 43.5535 54.4306 43.5535H54.8964C56.3673 43.5535 57.7892 42.3645 57.8504 40.8936L57.875 40.9181ZM28.1262 32.4482H25.5521C23.9954 32.4482 22.7329 33.7107 22.7329 35.2552V51.8763C22.7329 53.4329 23.9954 54.6832 25.5521 54.6832H28.1262C29.6829 54.6832 30.9331 53.4207 30.9331 51.8763V35.2552C30.9331 33.6985 29.6706 32.4482 28.1262 32.4482Z"
            fill="#3D393A"
          />
          <path
            d="M57.789 40.796C57.8625 39.2026 56.5877 37.891 55.0065 37.891H55.4723C56.9922 37.891 58.3651 36.7511 58.4264 35.2312C58.4999 33.6377 57.2251 32.3262 55.6439 32.3262H43.5091C43.5091 32.3262 43.4723 32.3016 43.4968 32.2771C46.218 29.8256 47.9095 28.5876 47.9708 23.5376C47.9953 21.7725 46.9289 19.5049 45.127 19.5049C42.3446 19.5049 42.2343 21.6499 42.2098 23.5376C40.5673 28.9676 32.9677 28.9063 32.9677 37.6949V49.6827C32.9677 52.3793 35.1495 54.5611 37.8461 54.5611H53.5356C55.0065 54.5611 56.4284 53.3722 56.4897 51.9013C56.5632 50.3078 55.2885 48.9963 53.7072 48.9963H54.173C55.6439 48.9963 57.0658 47.8073 57.1271 46.3364C57.2006 44.7429 55.9258 43.4314 54.3446 43.4314H54.8104C56.2813 43.4314 57.7032 42.2424 57.7645 40.7715L57.789 40.796ZM28.0525 32.3262H25.4784C23.9217 32.3262 22.6592 33.5887 22.6592 35.1331V51.7542C22.6592 53.3109 23.9217 54.5611 25.4784 54.5611H28.0525C29.6091 54.5611 30.8594 53.2986 30.8594 51.7542V35.1331C30.8594 33.5764 29.5969 32.3262 28.0525 32.3262Z"
            fill="#3C3839"
          />
          <path
            d="M57.7035 40.6603C57.7771 39.0668 56.5023 37.7553 54.9211 37.7553H55.3869C56.9068 37.7553 58.2796 36.6153 58.3409 35.0954C58.4145 33.502 57.1397 32.1904 55.5585 32.1904H43.4236C43.4236 32.1904 43.3868 32.1659 43.4114 32.1414C46.1325 29.6899 47.824 28.4519 47.8853 23.4018C47.9098 21.6368 46.8434 19.3691 45.0416 19.3691C42.2592 19.3691 42.1488 21.5142 42.1243 23.4018C40.4818 28.8319 32.8822 28.7706 32.8822 37.5592V49.5469C32.8822 52.2436 35.064 54.4254 37.7607 54.4254H53.4502C54.9211 54.4254 56.3429 53.2364 56.4042 51.7655C56.4778 50.1721 55.203 48.8605 53.6218 48.8605H54.0876C55.5585 48.8605 56.9803 47.6715 57.0416 46.2006C57.1152 44.6072 55.8404 43.2956 54.2592 43.2956H54.725C56.1959 43.2956 57.6177 42.1067 57.679 40.6358L57.7035 40.6603ZM27.967 32.1904H25.3929C23.8362 32.1904 22.5737 33.4529 22.5737 34.9974V51.6184C22.5737 53.1751 23.8362 54.4254 25.3929 54.4254H27.967C29.5237 54.4254 30.774 53.1629 30.774 51.6184V34.9974C30.774 33.4407 29.5114 32.1904 27.967 32.1904Z"
            fill="#3B3738"
          />
          <path
            d="M57.6294 40.5255C57.7029 38.9321 56.4281 37.6205 54.8469 37.6205H55.3127C56.8326 37.6205 58.2055 36.4806 58.2667 34.9607C58.3403 33.3672 57.0655 32.0556 55.4843 32.0556H43.3494C43.3494 32.0556 43.3127 32.0311 43.3372 32.0066C46.0583 29.5551 47.7499 28.3171 47.8112 23.2671C47.8357 21.502 46.7693 19.2344 44.9674 19.2344C42.185 19.2344 42.0747 21.3794 42.0502 23.2671C40.4077 28.6971 32.8081 28.6358 32.8081 37.4244V49.4122C32.8081 52.1088 34.9899 54.2906 37.6865 54.2906H53.376C54.8469 54.2906 56.2688 53.1017 56.3301 51.6308C56.4036 50.0373 55.1288 48.7257 53.5476 48.7257H54.0134C55.4843 48.7257 56.9062 47.5368 56.9675 46.0659C57.041 44.4724 55.7662 43.1609 54.185 43.1609H54.6508C56.1217 43.1609 57.5436 41.9719 57.6048 40.501L57.6294 40.5255ZM27.8806 32.0679H25.3065C23.7498 32.0679 22.4873 33.3304 22.4873 34.8749V51.4959C22.4873 53.0526 23.7498 54.3029 25.3065 54.3029H27.8806C29.4373 54.3029 30.6875 53.0404 30.6875 51.4959V34.8749C30.6875 33.3182 29.425 32.0679 27.8806 32.0679Z"
            fill="#3A3637"
          />
          <path
            d="M57.5443 40.4035C57.6179 38.81 56.3431 37.4984 54.7619 37.4984H55.2277C56.7476 37.4984 58.1204 36.3585 58.1817 34.8386C58.2553 33.2451 56.9805 31.9336 55.3993 31.9336H43.2644C43.2644 31.9336 43.2277 31.9091 43.2522 31.8845C45.9733 29.4331 47.6649 28.1951 47.7261 23.145C47.7507 21.3799 46.6843 19.1123 44.8824 19.1123C42.1 19.1123 41.9897 21.2574 41.9651 23.145C40.3227 28.575 32.723 28.5137 32.723 37.3023V49.2901C32.723 51.9867 34.9049 54.1685 37.6015 54.1685H53.291C54.7619 54.1685 56.1838 52.9796 56.2451 51.5087C56.3186 49.9152 55.0438 48.6037 53.4626 48.6037H53.9284C55.3993 48.6037 56.8212 47.4147 56.8824 45.9438C56.956 44.3503 55.6812 43.0388 54.1 43.0388H54.5658C56.0367 43.0388 57.4585 41.8498 57.5198 40.3789L57.5443 40.4035ZM27.8078 31.9336H25.2338C23.6771 31.9336 22.4146 33.1961 22.4146 34.7405V51.3616C22.4146 52.9183 23.6771 54.1685 25.2338 54.1685H27.8078C29.3645 54.1685 30.6148 52.906 30.6148 51.3616V34.7405C30.6148 33.1838 29.3523 31.9336 27.8078 31.9336Z"
            fill="#393536"
          />
          <path
            d="M57.4579 40.2677C57.5315 38.6743 56.2567 37.3627 54.6755 37.3627H55.1413C56.6612 37.3627 58.034 36.2228 58.0953 34.7028C58.1688 33.1094 56.8941 31.7978 55.3129 31.7978H43.178C43.178 31.7978 43.1412 31.7733 43.1658 31.7488C45.8869 29.2973 47.5784 28.0593 47.6397 23.0093C47.6642 21.2442 46.5978 18.9766 44.796 18.9766C42.0136 18.9766 41.9032 21.1216 41.8787 23.0093C40.2362 28.4393 32.6366 28.378 32.6366 37.1666V49.1544C32.6366 51.851 34.8184 54.0328 37.5151 54.0328H53.2046C54.6755 54.0328 56.0973 52.8438 56.1586 51.3729C56.2322 49.7795 54.9574 48.4679 53.3762 48.4679H53.842C55.3129 48.4679 56.7347 47.279 56.796 45.8081C56.8696 44.2146 55.5948 42.9031 54.0136 42.9031H54.4794C55.9503 42.9031 57.3721 41.7141 57.4334 40.2432L57.4579 40.2677ZM27.7214 31.7978H25.1473C23.5906 31.7978 22.3281 33.0603 22.3281 34.6048V51.2259C22.3281 52.7825 23.5906 54.0328 25.1473 54.0328H27.7214C29.2781 54.0328 30.5283 52.7703 30.5283 51.2259V34.6048C30.5283 33.0481 29.2658 31.7978 27.7214 31.7978Z"
            fill="#383435"
          />
          <path
            d="M57.3847 40.1456C57.4583 38.5522 56.1835 37.2406 54.6023 37.2406H55.0681C56.588 37.2406 57.9608 36.1007 58.0221 34.5808C58.0957 32.9873 56.8209 31.6758 55.2397 31.6758H43.1048C43.1048 31.6758 43.068 31.6512 43.0926 31.6267C45.8137 29.1752 47.5052 27.9372 47.5665 22.8872C47.591 21.1221 46.5246 18.8545 44.7228 18.8545C41.9404 18.8545 41.83 20.9995 41.8055 22.8872C40.163 28.3172 32.5634 28.2559 32.5634 37.0445V49.0323C32.5634 51.7289 34.7453 53.9107 37.4419 53.9107H53.1314C54.6023 53.9107 56.0242 52.7218 56.0854 51.2509C56.159 49.6574 54.8842 48.3459 53.303 48.3459H53.7688C55.2397 48.3459 56.6615 47.1569 56.7228 45.686C56.7964 44.0925 55.5216 42.781 53.9404 42.781H54.4062C55.8771 42.781 57.2989 41.592 57.3602 40.1211L57.3847 40.1456ZM27.6359 31.6758H25.0619C23.5052 31.6758 22.2427 32.9383 22.2427 34.4827V51.1038C22.2427 52.6605 23.5052 53.9107 25.0619 53.9107H27.6359C29.1926 53.9107 30.4429 52.6482 30.4429 51.1038V34.4827C30.4429 32.926 29.1804 31.6758 27.6359 31.6758Z"
            fill="#373334"
          />
          <path
            d="M57.2983 40.0109C57.3718 38.4174 56.0971 37.1059 54.5159 37.1059H54.9816C56.5016 37.1059 57.8744 35.9659 57.9357 34.446C58.0092 32.8525 56.7345 31.541 55.1532 31.541H43.0184C43.0184 31.541 42.9816 31.5165 43.0061 31.492C45.7273 29.0405 47.4188 27.8025 47.4801 22.7524C47.5046 20.9874 46.4382 18.7197 44.6364 18.7197C41.8539 18.7197 41.7436 20.8648 41.7191 22.7524C40.0766 28.1825 32.477 28.1212 32.477 36.9097V48.8975C32.477 51.5942 34.6588 53.776 37.3555 53.776H53.045C54.5159 53.776 55.9377 52.587 55.999 51.1161C56.0726 49.5226 54.7978 48.2111 53.2166 48.2111H53.6824C55.1532 48.2111 56.5751 47.0221 56.6364 45.5512C56.7099 43.9578 55.4352 42.6462 53.854 42.6462H54.3197C55.7906 42.6462 57.2125 41.4573 57.2738 39.9864L57.2983 40.0109ZM27.5495 31.541H24.9755C23.4188 31.541 22.1562 32.8035 22.1562 34.3479V50.969C22.1562 52.5257 23.4188 53.7882 24.9755 53.7882H27.5495C29.1062 53.7882 30.3565 52.5257 30.3565 50.969V34.3479C30.3565 32.7913 29.094 31.541 27.5495 31.541Z"
            fill="#363233"
          />
          <path
            d="M57.2133 39.8761C57.2868 38.2827 56.0121 36.9711 54.4309 36.9711H54.8966C56.4166 36.9711 57.7894 35.8312 57.8507 34.3112C57.9242 32.7178 56.6494 31.4062 55.0682 31.4062H42.9334C42.9334 31.4062 42.8966 31.3817 42.9211 31.3572C45.6423 28.9057 47.3338 27.6677 47.3951 22.6177C47.4196 20.8526 46.3532 18.585 44.5514 18.585C41.7689 18.585 41.6586 20.73 41.6341 22.6177C39.9916 28.0477 32.392 27.9864 32.392 36.775V48.7628C32.392 51.4594 34.5738 53.6412 37.2705 53.6412H52.96C54.4309 53.6412 55.8527 52.4522 55.914 50.9813C55.9875 49.3879 54.7128 48.0763 53.1316 48.0763H53.5973C55.0682 48.0763 56.4901 46.8874 56.5514 45.4165C56.6249 43.823 55.3502 42.5115 53.769 42.5115H54.2347C55.7056 42.5115 57.1275 41.3225 57.1888 39.8516L57.2133 39.8761ZM27.4768 31.4185H24.9027C23.346 31.4185 22.0835 32.681 22.0835 34.2254V50.8465C22.0835 52.4032 23.346 53.6535 24.9027 53.6535H27.4768C29.0335 53.6535 30.2837 52.3909 30.2837 50.8465V34.2254C30.2837 32.6687 29.0212 31.4185 27.4768 31.4185Z"
            fill="#353132"
          />
          <path
            d="M57.1391 39.7531C57.2127 38.1596 55.9379 36.8481 54.3567 36.8481H54.8225C56.3424 36.8481 57.7152 35.7081 57.7765 34.1882C57.8501 32.5947 56.5753 31.2832 54.9941 31.2832H42.8592C42.8592 31.2832 42.8224 31.2587 42.847 31.2342C45.5681 28.7827 47.2596 27.5447 47.3209 22.4946C47.3454 20.7295 46.279 18.4619 44.4772 18.4619C41.6948 18.4619 41.5844 20.607 41.5599 22.4946C39.9174 27.9246 32.3178 27.8634 32.3178 36.6519V48.6397C32.3178 51.3363 34.4996 53.5182 37.1963 53.5182H52.8858C54.3567 53.5182 55.7785 52.3292 55.8398 50.8583C55.9134 49.2648 54.6386 47.9533 53.0574 47.9533H53.5232C54.9941 47.9533 56.4159 46.7643 56.4772 45.2934C56.5508 43.7 55.276 42.3884 53.6948 42.3884H54.1606C55.6315 42.3884 57.0533 41.1994 57.1146 39.7285L57.1391 39.7531ZM27.3903 31.2832H24.8163C23.2596 31.2832 21.9971 32.5457 21.9971 34.0901V50.7112C21.9971 52.2679 23.2596 53.5182 24.8163 53.5182H27.3903C28.947 53.5182 30.1973 52.2556 30.1973 50.7112V34.0901C30.1973 32.5334 28.9348 31.2832 27.3903 31.2832Z"
            fill="#343031"
          />
          <path
            d="M57.0537 39.6193C57.1272 38.0258 55.8524 36.7143 54.2712 36.7143H54.737C56.2569 36.7143 57.6298 35.5743 57.6911 34.0544C57.7646 32.4609 56.4898 31.1494 54.9086 31.1494H42.7738C42.7738 31.1494 42.737 31.1249 42.7615 31.1004C45.4827 28.6489 47.1742 27.4109 47.2355 22.3608C47.26 20.5958 46.1936 18.3281 44.3917 18.3281C41.6093 18.3281 41.499 20.4732 41.4745 22.3608C39.832 27.7909 32.2324 27.7296 32.2324 36.5181V48.5059C32.2324 51.2026 34.4142 53.3844 37.1108 53.3844H52.8003C54.2712 53.3844 55.6931 52.1954 55.7544 50.7245C55.8279 49.131 54.5532 47.8195 52.9719 47.8195H53.4377C54.9086 47.8195 56.3305 46.6305 56.3918 45.1596C56.4653 43.5662 55.1905 42.2546 53.6093 42.2546H54.0751C55.546 42.2546 56.9679 41.0657 57.0292 39.5948L57.0537 39.6193ZM27.3049 31.1617H24.7308C23.1741 31.1617 21.9116 32.4242 21.9116 33.9809V50.6019C21.9116 52.1586 23.1741 53.4089 24.7308 53.4089H27.3049C28.8616 53.4089 30.1118 52.1464 30.1118 50.6019V33.9809C30.1118 32.4242 28.8493 31.1617 27.3049 31.1617Z"
            fill="#332F30"
          />
          <path
            d="M56.9677 39.4962C57.0412 37.9028 55.7665 36.5912 54.1852 36.5912H54.651C56.1709 36.5912 57.5438 35.4513 57.6051 33.9314C57.6786 32.3379 56.4038 31.0264 54.8226 31.0264H42.6878C42.6878 31.0264 42.651 31.0018 42.6755 30.9773C45.3967 28.5258 47.0882 27.2878 47.1495 22.2378C47.174 20.4727 46.1076 18.2051 44.3058 18.2051C41.5233 18.2051 41.413 20.3501 41.3885 22.2378C39.746 27.6678 32.1464 27.6065 32.1464 36.3951V48.3829C32.1464 51.0795 34.3282 53.2613 37.0248 53.2613H52.7143C54.1852 53.2613 55.6071 52.0724 55.6684 50.6015C55.7419 49.008 54.4672 47.6965 52.886 47.6965H53.3517C54.8226 47.6965 56.2445 46.5075 56.3058 45.0366C56.3793 43.4431 55.1045 42.1316 53.5233 42.1316H53.9891C55.46 42.1316 56.8819 40.9426 56.9432 39.4717L56.9677 39.4962ZM27.2312 31.0264H24.6571C23.1004 31.0264 21.8379 32.2889 21.8379 33.8333V50.4544C21.8379 52.0111 23.1004 53.2613 24.6571 53.2613H27.2312C28.7878 53.2613 30.0381 51.9988 30.0381 50.4544V33.8333C30.0381 32.2766 28.7756 31.0264 27.2312 31.0264Z"
            fill="#322E2F"
          />
          <path
            d="M56.8822 39.3615C56.9557 37.768 55.681 36.4565 54.0997 36.4565H54.5655C56.0855 36.4565 57.4583 35.3165 57.5196 33.7966C57.5931 32.2031 56.3183 30.8916 54.7371 30.8916H42.6023C42.6023 30.8916 42.5655 30.8671 42.59 30.8426C45.3112 28.3911 47.0027 27.1531 47.064 22.103C47.0885 20.3379 46.0221 18.0703 44.2203 18.0703C41.4378 18.0703 41.3275 20.2154 41.303 22.103C39.6605 27.533 32.0609 27.4718 32.0609 36.2603V48.2481C32.0609 50.9447 34.2427 53.1266 36.9393 53.1266H52.6289C54.0997 53.1266 55.5216 51.9376 55.5829 50.4667C55.6564 48.8732 54.3817 47.5617 52.8005 47.5617H53.2662C54.7371 47.5617 56.159 46.3727 56.2203 44.9018C56.2938 43.3084 55.0191 41.9968 53.4378 41.9968H53.9036C55.3745 41.9968 56.7964 40.8078 56.8577 39.3369L56.8822 39.3615ZM27.1457 30.8916H24.5716C23.0149 30.8916 21.7646 32.1541 21.7646 33.6985V50.3196C21.7646 51.8763 23.0272 53.1266 24.5716 53.1266H27.1457C28.7024 53.1266 29.9526 51.864 29.9526 50.3196V33.6985C29.9526 32.1418 28.6901 30.8916 27.1457 30.8916Z"
            fill="#312D2E"
          />
          <path
            d="M56.8085 39.2384C56.882 37.645 55.6073 36.3334 54.0261 36.3334H54.4918C56.0118 36.3334 57.3846 35.1935 57.4459 33.6735C57.5194 32.0801 56.2447 30.7685 54.6634 30.7685H42.5286C42.5286 30.7685 42.4918 30.744 42.5163 30.7195C45.2375 28.268 46.929 27.03 46.9903 21.98C47.0148 20.2149 45.9484 17.9473 44.1466 17.9473C41.3641 17.9473 41.2538 20.0923 41.2293 21.98C39.5868 27.41 31.9872 27.3487 31.9872 36.1373V48.1251C31.9872 50.8217 34.169 53.0035 36.8657 53.0035H52.5552C54.0261 53.0035 55.4479 51.8145 55.5092 50.3437C55.5828 48.7502 54.308 47.4386 52.7268 47.4386H53.1926C54.6634 47.4386 56.0853 46.2497 56.1466 44.7788C56.2201 43.1853 54.9454 41.8738 53.3642 41.8738H53.8299C55.3008 41.8738 56.7227 40.6848 56.784 39.2139L56.8085 39.2384ZM27.0597 30.7685H24.4857C22.929 30.7685 21.6787 32.0311 21.6787 33.5755V50.1966C21.6787 51.7533 22.9412 53.0035 24.4857 53.0035H27.0597C28.6164 53.0035 29.8667 51.741 29.8667 50.1966V33.5755C29.8667 32.0188 28.6042 30.7685 27.0597 30.7685Z"
            fill="#302C2D"
          />
          <path
            d="M56.723 39.1027C56.7965 37.5092 55.5218 36.1977 53.9406 36.1977H54.4063C55.9263 36.1977 57.2991 35.0577 57.3604 33.5378C57.4339 31.9443 56.1592 30.6328 54.578 30.6328H42.4431C42.4431 30.6328 42.4063 30.6083 42.4308 30.5838C45.152 28.1323 46.8435 26.8943 46.9048 21.8442C46.9293 20.0791 45.8629 17.8115 44.0611 17.8115C41.2786 17.8115 41.1683 19.9566 41.1438 21.8442C39.5013 27.2743 31.9017 27.213 31.9017 36.0015V47.9893C31.9017 50.6859 34.0835 52.8678 36.7802 52.8678H52.4697C53.9406 52.8678 55.3624 51.6788 55.4237 50.2079C55.4973 48.6144 54.2225 47.3029 52.6413 47.3029H53.1071C54.578 47.3029 55.9998 46.1139 56.0611 44.643C56.1346 43.0496 54.8599 41.738 53.2787 41.738H53.7444C55.2153 41.738 56.6372 40.5491 56.6985 39.0782L56.723 39.1027ZM26.9865 30.6328H24.4124C22.8557 30.6328 21.6055 31.8953 21.6055 33.4397V50.0608C21.6055 51.6175 22.868 52.88 24.4124 52.88H26.9865C28.5432 52.88 29.7934 51.6175 29.7934 50.0608V33.4397C29.7934 31.8831 28.5309 30.6328 26.9865 30.6328Z"
            fill="#2F2B2C"
          />
          <path
            d="M56.6371 38.9689C56.7106 37.3754 55.4358 36.0639 53.8546 36.0639H54.3204C55.8403 36.0639 57.2132 34.9239 57.2745 33.404C57.348 31.8105 56.0732 30.499 54.492 30.499H42.3572C42.3572 30.499 42.3204 30.4745 42.3449 30.45C45.0661 27.9985 46.7576 26.7605 46.8189 21.7104C46.8434 19.9454 45.777 17.6777 43.9751 17.6777C41.1927 17.6777 41.0824 19.8228 41.0579 21.7104C39.4154 27.1405 31.8158 27.0792 31.8158 35.8678V47.8555C31.8158 50.5522 33.9976 52.734 36.6942 52.734H52.3837C53.8546 52.734 55.2765 51.545 55.3378 50.0741C55.4113 48.4807 54.1365 47.1691 52.5553 47.1691H53.0211C54.492 47.1691 55.9139 45.9801 55.9752 44.5092C56.0487 42.9158 54.7739 41.6042 53.1927 41.6042H53.6585C55.1294 41.6042 56.5513 40.4153 56.6125 38.9444L56.6371 38.9689ZM26.9005 30.5113H24.3265C22.7698 30.5113 21.5195 31.7738 21.5195 33.3182V49.9393C21.5195 51.496 22.782 52.7462 24.3265 52.7462H26.9005C28.4572 52.7462 29.7075 51.4837 29.7075 49.9393V33.3182C29.7075 31.7615 28.445 30.5113 26.9005 30.5113Z"
            fill="#2E2A2B"
          />
          <path
            d="M56.5634 38.8458C56.6369 37.2524 55.3622 35.9408 53.7809 35.9408H54.2467C55.7667 35.9408 57.1395 34.8009 57.2008 33.281C57.2743 31.6875 55.9995 30.376 54.4183 30.376H42.2835C42.2835 30.376 42.2467 30.3514 42.2712 30.3269C44.9924 27.8754 46.6839 26.6374 46.7452 21.5874C46.7697 19.8223 45.7033 17.5547 43.9015 17.5547C41.119 17.5547 41.0087 19.6997 40.9842 21.5874C39.3417 27.0174 31.7421 26.9561 31.7421 35.7447V47.7325C31.7421 50.4291 33.9239 52.6109 36.6205 52.6109H52.3101C53.7809 52.6109 55.2028 51.422 55.2641 49.9511C55.3376 48.3576 54.0629 47.0461 52.4817 47.0461H52.9474C54.4183 47.0461 55.8402 45.8571 55.9015 44.3862C55.975 42.7927 54.7003 41.4812 53.119 41.4812H53.5848C55.0557 41.4812 56.4776 40.2922 56.5389 38.8213L56.5634 38.8458ZM26.8146 30.376H24.2405C22.6839 30.376 21.4336 31.6385 21.4336 33.1829V49.804C21.4336 51.3607 22.6961 52.6109 24.2405 52.6109H26.8146C28.3713 52.6109 29.6216 51.3484 29.6216 49.804V33.1829C29.6216 31.6262 28.359 30.376 26.8146 30.376Z"
            fill="#2D292A"
          />
          <path
            d="M56.4774 38.712C56.551 37.1186 55.2762 35.807 53.695 35.807H54.1608C55.6807 35.807 57.0535 34.6671 57.1148 33.1472C57.1884 31.5537 55.9136 30.2422 54.3324 30.2422H42.1975C42.1975 30.2422 42.1608 30.2177 42.1853 30.1931C44.9064 27.7417 46.598 26.5037 46.6592 21.4536C46.6838 19.6885 45.6174 17.4209 43.8155 17.4209C41.0331 17.4209 40.9228 19.5659 40.8983 21.4536C39.2558 26.8836 31.6562 26.8223 31.6562 35.6109V47.5987C31.6562 50.2953 33.838 52.4771 36.5346 52.4771H52.2241C53.695 52.4771 55.1169 51.2882 55.1782 49.8173C55.2517 48.2238 53.9769 46.9123 52.3957 46.9123H52.8615C54.3324 46.9123 55.7543 45.7233 55.8155 44.2524C55.8891 42.6589 54.6143 41.3474 53.0331 41.3474H53.4989C54.9698 41.3474 56.3916 40.1584 56.4529 38.6875L56.4774 38.712ZM26.7287 30.2422H24.1546C22.5979 30.2422 21.3477 31.5047 21.3477 33.0614V49.6825C21.3477 51.2391 22.6102 52.4894 24.1546 52.4894H26.7287C28.2854 52.4894 29.5356 51.2269 29.5356 49.6825V33.0614C29.5356 31.5047 28.2731 30.2422 26.7287 30.2422Z"
            fill="#2C2829"
          />
          <path
            d="M56.3919 38.588C56.4655 36.9946 55.1907 35.683 53.6095 35.683H54.0753C55.5952 35.683 56.968 34.5431 57.0293 33.0232C57.1029 31.4297 55.8281 30.1181 54.2469 30.1181H42.112C42.112 30.1181 42.0753 30.0936 42.0998 30.0691C44.8209 27.6176 46.5125 26.3796 46.5737 21.3296C46.5983 19.5645 45.5319 17.2969 43.73 17.2969C40.9476 17.2969 40.8373 19.4419 40.8128 21.3296C39.1703 26.7596 31.5707 26.6983 31.5707 35.4869V47.4747C31.5707 50.1713 33.7525 52.3531 36.4491 52.3531H52.1386C53.6095 52.3531 55.0314 51.1641 55.0927 49.6933C55.1662 48.0998 53.8914 46.7883 52.3102 46.7883H52.776C54.2469 46.7883 55.6688 45.5993 55.73 44.1284C55.8036 42.5349 54.5288 41.2234 52.9476 41.2234H53.4134C54.8843 41.2234 56.3061 40.0344 56.3674 38.5635L56.3919 38.588ZM26.6554 30.1181H24.0814C22.5247 30.1181 21.2744 31.3807 21.2744 32.9251V49.5462C21.2744 51.1029 22.5369 52.3531 24.0814 52.3531H26.6554C28.2121 52.3531 29.4624 51.0906 29.4624 49.5462V32.9251C29.4624 31.3684 28.1999 30.1181 26.6554 30.1181Z"
            fill="#2B2728"
          />
          <path
            d="M56.3183 38.4542C56.3918 36.8608 55.117 35.5492 53.5358 35.5492H54.0016C55.5215 35.5492 56.8944 34.4093 56.9557 32.8894C57.0292 31.2959 55.7544 29.9844 54.1732 29.9844H42.0384C42.0384 29.9844 42.0016 29.9598 42.0261 29.9353C44.7473 27.4838 46.4388 26.2458 46.5001 21.1958C46.5246 19.4307 45.4582 17.1631 43.6563 17.1631C40.8739 17.1631 40.7636 19.3081 40.7391 21.1958C39.0966 26.6258 31.497 26.5645 31.497 35.3531V47.3409C31.497 50.0375 33.6788 52.2193 36.3754 52.2193H52.0649C53.5358 52.2193 54.9577 51.0304 55.019 49.5595C55.0925 47.966 53.8178 46.6545 52.2365 46.6545H52.7023C54.1732 46.6545 55.5951 45.4655 55.6564 43.9946C55.7299 42.4011 54.4551 41.0896 52.8739 41.0896H53.3397C54.8106 41.0896 56.2325 39.9006 56.2938 38.4297L56.3183 38.4542ZM26.5695 29.9844H23.9954C22.4387 29.9844 21.1885 31.2469 21.1885 32.7913V49.4124C21.1885 50.9691 22.451 52.2193 23.9954 52.2193H26.5695C28.1262 52.2193 29.3764 50.9568 29.3764 49.4124V32.7913C29.3764 31.2346 28.1139 29.9844 26.5695 29.9844Z"
            fill="#2A2627"
          />
          <path
            d="M56.2323 38.3195C56.3059 36.726 55.0311 35.4145 53.4499 35.4145H53.9157C55.4356 35.4145 56.8084 34.2745 56.8697 32.7546C56.9433 31.1611 55.6685 29.8496 54.0873 29.8496H41.9524C41.9524 29.8496 41.9157 29.8251 41.9402 29.8006C44.6613 27.3491 46.3528 26.1111 46.4141 21.061C46.4386 19.2959 45.3722 17.0283 43.5704 17.0283C40.788 17.0283 40.6777 19.1734 40.6531 21.061C39.0106 26.4911 31.411 26.4298 31.411 35.2183V47.2061C31.411 49.9027 33.5929 52.0846 36.2895 52.0846H51.979C53.4499 52.0846 54.8718 50.8956 54.933 49.4247C55.0066 47.8312 53.7318 46.5197 52.1506 46.5197H52.6164C54.0873 46.5197 55.5091 45.3307 55.5704 43.8598C55.644 42.2664 54.3692 40.9548 52.788 40.9548H53.2538C54.7247 40.9548 56.1465 39.7659 56.2078 38.295L56.2323 38.3195ZM26.4836 29.8618H23.9095C22.3528 29.8618 21.1025 31.1244 21.1025 32.6688V49.2899C21.1025 50.8466 22.3651 52.0968 23.9095 52.0968H26.4836C28.0402 52.0968 29.2905 50.8343 29.2905 49.2899V32.6688C29.2905 31.1121 28.028 29.8618 26.4836 29.8618Z"
            fill="#292526"
          />
          <path
            d="M56.1463 38.1974C56.2199 36.6039 54.9451 35.2924 53.3639 35.2924H53.8297C55.3496 35.2924 56.7224 34.1525 56.7837 32.6325C56.8573 31.0391 55.5825 29.7275 54.0013 29.7275H41.8664C41.8664 29.7275 41.8297 29.703 41.8542 29.6785C44.5753 27.227 46.2669 25.989 46.3281 20.9389C46.3527 19.1739 45.2863 16.9062 43.4844 16.9062C40.702 16.9062 40.5917 19.0513 40.5672 20.9389C38.9247 26.369 31.325 26.3077 31.325 35.0963V47.084C31.325 49.7807 33.5069 51.9625 36.2035 51.9625H51.893C53.3639 51.9625 54.7858 50.7735 54.8471 49.3026C54.9206 47.7092 53.6458 46.3976 52.0646 46.3976H52.5304C54.0013 46.3976 55.4232 45.2086 55.4844 43.7378C55.558 42.1443 54.2832 40.8328 52.702 40.8328H53.1678C54.6387 40.8328 56.0605 39.6438 56.1218 38.1729L56.1463 38.1974ZM26.4098 29.7275H23.8358C22.2791 29.7275 21.0288 30.99 21.0288 32.5345V49.1555C21.0288 50.7122 22.2913 51.9625 23.8358 51.9625H26.4098C27.9665 51.9625 29.2168 50.7 29.2168 49.1555V32.5345C29.2168 30.9778 27.9543 29.7275 26.4098 29.7275Z"
            fill="#282425"
          />
          <path
            d="M56.0609 38.0617C56.1344 36.4682 54.8597 35.1566 53.2785 35.1566H53.7442C55.2642 35.1566 56.637 34.0167 56.6983 32.4968C56.7718 30.9033 55.4971 29.5918 53.9158 29.5918H41.781C41.781 29.5918 41.7442 29.5673 41.7687 29.5428C44.4899 27.0913 46.1814 25.8533 46.2427 20.8032C46.2672 19.0381 45.2008 16.7705 43.399 16.7705C40.6165 16.7705 40.5062 18.9156 40.4817 20.8032C38.8392 26.2332 31.2396 26.172 31.2396 34.9605V46.9483C31.2396 49.6449 33.4214 51.8268 36.1181 51.8268H51.8076C53.2785 51.8268 54.7003 50.6378 54.7616 49.1669C54.8352 47.5734 53.5604 46.2619 51.9792 46.2619H52.445C53.9158 46.2619 55.3377 45.0729 55.399 43.602C55.4725 42.0086 54.1978 40.697 52.6166 40.697H53.0823C54.5532 40.697 55.9751 39.508 56.0364 38.0372L56.0609 38.0617ZM26.3244 29.604H23.7503C22.1936 29.604 20.9434 30.8665 20.9434 32.411V49.0321C20.9434 50.5887 22.2059 51.839 23.7503 51.839H26.3244C27.8811 51.839 29.1313 50.5765 29.1313 49.0321V32.411C29.1313 30.8543 27.8688 29.604 26.3244 29.604Z"
            fill="#272324"
          />
          <path
            d="M55.9877 37.9396C56.0612 36.3461 54.7865 35.0346 53.2053 35.0346H53.671C55.191 35.0346 56.5638 33.8946 56.6251 32.3747C56.6986 30.7813 55.4239 29.4697 53.8427 29.4697H41.7078C41.7078 29.4697 41.671 29.4452 41.6955 29.4207C44.4167 26.9692 46.1082 25.7312 46.1695 20.6811C46.194 18.9161 45.1276 16.6484 43.3258 16.6484C40.5433 16.6484 40.433 18.7935 40.4085 20.6811C38.766 26.1112 31.1664 26.0499 31.1664 34.8385V46.8262C31.1664 49.5229 33.3482 51.7047 36.0449 51.7047H51.7344C53.2053 51.7047 54.6271 50.5157 54.6884 49.0448C54.762 47.4514 53.4872 46.1398 51.906 46.1398H52.3718C53.8427 46.1398 55.2645 44.9508 55.3258 43.4799C55.3993 41.8865 54.1246 40.5749 52.5434 40.5749H53.0091C54.48 40.5749 55.9019 39.386 55.9632 37.9151L55.9877 37.9396ZM26.2389 29.4697H23.6649C22.1082 29.4697 20.8579 30.7322 20.8579 32.2767V48.8977C20.8579 50.4544 22.1204 51.7047 23.6649 51.7047H26.2389C27.7956 51.7047 29.0459 50.4422 29.0459 48.8977V32.2767C29.0459 30.72 27.7834 29.4697 26.2389 29.4697Z"
            fill="#262223"
          />
          <path
            d="M55.9017 37.8048C55.9753 36.2114 54.7005 34.8998 53.1193 34.8998H53.5851C55.105 34.8998 56.4778 33.7599 56.5391 32.24C56.6126 30.6465 55.3379 29.3349 53.7567 29.3349H41.6218C41.6218 29.3349 41.585 29.3104 41.6096 29.2859C44.3307 26.8344 46.0222 25.5964 46.0835 20.5464C46.108 18.7813 45.0416 16.5137 43.2398 16.5137C40.4574 16.5137 40.347 18.6587 40.3225 20.5464C38.68 25.9764 31.0804 25.9151 31.0804 34.7037V46.6915C31.0804 49.3881 33.2622 51.5699 35.9589 51.5699H51.6484C53.1193 51.5699 54.5411 50.3809 54.6024 48.9101C54.676 47.3166 53.4012 46.005 51.82 46.005H52.2858C53.7567 46.005 55.1785 44.8161 55.2398 43.3452C55.3134 41.7517 54.0386 40.4402 52.4574 40.4402H52.9232C54.3941 40.4402 55.8159 39.2512 55.8772 37.7803L55.9017 37.8048ZM26.1652 29.3349H23.5911C22.0344 29.3349 20.7842 30.5975 20.7842 32.1419V48.763C20.7842 50.3197 22.0467 51.5699 23.5911 51.5699H26.1652C27.7219 51.5699 28.9721 50.3074 28.9721 48.763V32.1419C28.9721 30.5852 27.7096 29.3349 26.1652 29.3349Z"
            fill="#252122"
          />
          <path
            d="M55.8153 37.6691C55.8888 36.0756 54.6141 34.7641 53.0329 34.7641H53.4986C55.0186 34.7641 56.3914 33.6241 56.4527 32.1042C56.5262 30.5107 55.2514 29.1992 53.6702 29.1992H41.5354C41.5354 29.1992 41.4986 29.1747 41.5231 29.1502C44.2443 26.6987 45.9358 25.4607 45.9971 20.4106C46.0216 18.6456 44.9552 16.3779 43.1534 16.3779C40.3709 16.3779 40.2606 18.523 40.2361 20.4106C38.5936 25.8407 30.994 25.7794 30.994 34.568V46.5557C30.994 49.2524 33.1758 51.4342 35.8725 51.4342H51.562C53.0329 51.4342 54.4547 50.2452 54.516 48.7743C54.5895 47.1808 53.3148 45.8693 51.7336 45.8693H52.1993C53.6702 45.8693 55.0921 44.6803 55.1534 43.2094C55.2269 41.616 53.9522 40.3044 52.371 40.3044H52.8367C54.3076 40.3044 55.7295 39.1155 55.7908 37.6446L55.8153 37.6691ZM26.0788 29.2115H23.5047C21.948 29.2115 20.6978 30.474 20.6978 32.0184V48.6395C20.6978 50.1962 21.9603 51.4464 23.5047 51.4464H26.0788C27.6355 51.4464 28.8857 50.1839 28.8857 48.6395V32.0184C28.8857 30.4617 27.6232 29.2115 26.0788 29.2115Z"
            fill="#242021"
          />
          <path
            d="M55.7421 37.547C55.8156 35.9536 54.5409 34.642 52.9597 34.642H53.4254C54.9454 34.642 56.3182 33.5021 56.3795 31.9821C56.453 30.3887 55.1783 29.0771 53.597 29.0771H41.4622C41.4622 29.0771 41.4254 29.0526 41.4499 29.0281C44.1711 26.5766 45.8626 25.3386 45.9239 20.2886C45.9484 18.5235 44.882 16.2559 43.0802 16.2559C40.2977 16.2559 40.1874 18.4009 40.1629 20.2886C38.5204 25.7186 30.9208 25.6573 30.9208 34.4459V46.4337C30.9208 49.1303 33.1026 51.3121 35.7993 51.3121H51.4888C52.9597 51.3121 54.3815 50.1231 54.4428 48.6522C54.5163 47.0588 53.2416 45.7472 51.6604 45.7472H52.1262C53.597 45.7472 55.0189 44.5583 55.0802 43.0874C55.1537 41.4939 53.879 40.1824 52.2978 40.1824H52.7635C54.2344 40.1824 55.6563 38.9934 55.7176 37.5225L55.7421 37.547ZM25.9933 29.0771H23.4193C21.8626 29.0771 20.6123 30.3396 20.6123 31.8841V48.5052C20.6123 50.0619 21.8748 51.3121 23.4193 51.3121H25.9933C27.55 51.3121 28.8003 50.0496 28.8003 48.5052V31.8841C28.8003 30.3274 27.5378 29.0771 25.9933 29.0771Z"
            fill="#231F20"
          />
        </g>
      </g>
      <path
        d="M25.9928 29.0762H23.4188C21.8621 29.0762 20.6118 30.3387 20.6118 31.8831V48.5042C20.6118 50.0609 21.8743 51.3111 23.4188 51.3111H25.9928C27.5495 51.3111 28.7998 50.0486 28.7998 48.5042V31.8831C28.7998 30.3264 27.5373 29.0762 25.9928 29.0762ZM55.7416 37.546C55.8152 35.9526 54.5404 34.641 52.9592 34.641H53.425C54.9449 34.641 56.3177 33.5011 56.379 31.9812C56.4525 30.3877 55.1778 29.0762 53.5966 29.0762H41.4617C41.4617 29.0762 41.4249 29.0516 41.4494 29.0271C44.1706 26.5756 45.8621 25.3376 45.9234 20.2876C45.9479 18.5225 44.8815 16.2549 43.0797 16.2549C40.2972 16.2549 40.1869 18.3999 40.1624 20.2876C38.5199 25.7176 30.9203 25.6563 30.9203 34.4449V46.4327C30.9203 49.1293 33.1021 51.3111 35.7988 51.3111H51.4883C52.9592 51.3111 54.381 50.1222 54.4423 48.6513C54.5159 47.0578 53.2411 45.7463 51.6599 45.7463H52.1257C53.5966 45.7463 55.0184 44.5573 55.0797 43.0864C55.1532 41.4929 53.8785 40.1814 52.2973 40.1814H52.7631C54.2339 40.1814 55.6558 38.9924 55.7171 37.5215L55.7416 37.546Z"
        fill="white"
      />
    </svg>
  );
};
