import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

interface CustomProps {
  summary: any;
  showBackground?: boolean;
  referralAmount?: any;
  tokenBalanceAmount?: number;
}

class PaymentSummary extends Component<CustomProps> {
  translate = (key: string) => {
    const { t } = this.props as any; // t comes from withTranslation HOC
    return t(key);
  };
  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <h5 className="text-lg font-semibold pb-5">
        {this.translate('myaccount.PaymentDetails')}
      </h5>
    );
  }

  renderSummarySubtotal() {
    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('common.lblTotalWODiscount')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left">
          AED {this.props.summary.drivingCenter.totalAmount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryDiscount() {
    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('dashboard.Discount')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left">
          {this.props.summary.totalDiscount > 0 ? '' : ''} AED{' '}
          {this.props.summary.totalDiscount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryRtaAmount() {
    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('common.lblRTAAmount')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left">
          AED {this.props.summary.rta.totalAmount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryNetAmount() {
    let netPay = 0;
    if (
      this.props.summary &&
      this.props.summary.drivingCenter &&
      this.props.summary.drivingCenter.totalAmount
    ) {
      netPay = this.props.summary.drivingCenter.totalAmount;
    }
    if (this.props.summary && this.props.summary.totalDiscount) {
      netPay -= this.props.summary.totalDiscount;
    }
    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('dashboard.Net_Amount')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left">
          AED {netPay.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryWithOutVat() {
    let netPay = 0;
    if (this.props.summary && this.props.summary.totalAmount) {
      netPay =
        this.props.summary.totalAmount - this.props.summary.totalDiscount;
    }
    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('common.lblSubotalWithoutVAT')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left">
          AED {netPay.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryTax() {
    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('common.lblVATIsApplicableFor')}{' '}
          {process.env.REACT_APP_COMPANYNAME} {this.translate('common.lblFees')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left">
          AED {this.props.summary.totalTax.toFixed(2)}
        </span>
      </li>
    );
  }

  renderTokenAmount() {
    const tokenAmount = this.props.tokenBalanceAmount || 0;
    if (tokenAmount <= 0) return null;
    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('common.lblTokenAmount')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left text-secondary">
          -AED {tokenAmount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryAmountPayable() {
    let netPay =
      this.props.summary.totalAmount +
      this.props.summary.totalTax -
      this.props.summary.totalDiscount;
    if (this.props.summary && this.props.referralAmount) {
      netPay -= this.props.referralAmount;
    }

    if (
      this.props.summary &&
      this.props?.tokenBalanceAmount &&
      this.props.tokenBalanceAmount > 0
    ) {
      netPay -= this.props.tokenBalanceAmount;
    }

    return (
      <li className="grid grid-cols-12 text-base py-2.5 border-t md:border-b mt-4">
        <span className="col-span-6 py-px">
          {this.translate('common.lblAmountPayable')}
        </span>
        <span className="col-span-6 py-px font-semibold ltr:text-right rtl:text-left">
          AED {netPay.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryReferral() {
    let netReferral = 0;
    if (this.props.referralAmount) {
      netReferral = this.props.referralAmount;
    }

    return (
      <li className="grid grid-cols-12 gap-x-2.5 py-2 font-medium ">
        <span className="col-span-6 text-sm text-muted">
          {this.translate('common.lblReferralAmount')}
        </span>
        <span className="col-span-6 text-sm ltr:text-right rtl:text-left text-danger">
          - {this.props.summary && netReferral > 0 ? '' : ''} AED{' '}
          {this.props.summary ? netReferral.toFixed(2) : 0}
        </span>
      </li>
    );
  }

  render() {
    const tokenBalanceAmount = this.props?.tokenBalanceAmount || 0;
    return (
      <div
        className={`pt-2 ${
          this.props.showBackground ? 'bg-white p-lg-4 p-3 rounded' : ''
        }`}
      >
        <ul>
          {this.renderHeader()}
          {this.renderSummarySubtotal()}
          {this.renderSummaryDiscount()}
          {this.renderSummaryNetAmount()}
          {this.renderSummaryRtaAmount()}
          {this.renderSummaryWithOutVat()}
          {this.renderSummaryTax()}
          {this.props?.referralAmount > 0 && this.renderSummaryReferral()}
          {tokenBalanceAmount > 0 && this.renderTokenAmount()}
          {this.renderSummaryAmountPayable()}
        </ul>
      </div>
    );
  }
}

// PaymentSummary.propTypes = {
//   summary: PropTypes.shape({
//     totalAmount: PropTypes.number.isRequired,
//     totalTax: PropTypes.number.isRequired,
//     totalDiscount: PropTypes.number.isRequired,
//     rta: PropTypes.shape({
//       totalAmount: PropTypes.number.isRequired,
//     }).isRequired,
//     drivingCenter: PropTypes.shape({
//       totalAmount: PropTypes.number.isRequired,
//     }).isRequired,
//   }).isRequired,
//   showBackground: PropTypes.bool,
// };

// PaymentSummary.defaultProps = {
//   showBackground: false,
// };

export default withTranslation()(PaymentSummary);
