import React from 'react';

export const ArrowIcon = (props: any) => {
  return (
    <svg
      width="3"
      height="5"
      viewBox="0 0 3 5"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 2.5L-2.03916e-07 4.66506L-1.46405e-08 0.334936L3 2.5Z" />
    </svg>
  );
};
