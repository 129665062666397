// core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { actions as authUserActions } from '../../store/reducers/authUser';
// components
import {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  DateInput,
  SelectPhoneInput,
} from '../../components/form-group';
import { CrudDetailsRequestModel } from '../../api/models';
// helpers
import { validateFields, serializeFields, validators } from '../helpers';
import SelectInput from '../../components/form-group/SelectInput/SelectInput';
import countryCodes from '../../models/country-dialcodes.json';
import prepareData from './prepareData';
import { Dict } from '../../models/interfaces';
import { State } from '../../store/interfaces';
import { useGTagConversionTracker } from '../../hooks/gtag';
import { GtagSendTo } from '../../utils/gtag';
import { RouteKeys } from '../route-keys';
import loaderImg from '../../assets/images/loader-white.svg';
import moment from 'moment';
import { CloseIcon } from '../../components/icons/close';
import { ErrorResponse } from '../../store/reducers/error';
import SelectMultipleInput from '../../components/form-group/SelectMultipleInput';
import { ScanIcon, UploadIcon } from '../../components/icons';
const UAECountryName = 'United Arab Emirates';
const noVisaCountries = [
  'Saudi Arabia',
  'Kuwait',
  'United Arab Emirates',
  'Qatar',
  'Bahrain',
  'Oman',
];

const countryCodeList: Array<{ label: string; value: string }> = [];
const countryCodesList = countryCodes as { [key: string]: string };
Object.keys(countryCodes).forEach((codeKey) =>
  countryCodeList.push({
    label: countryCodesList[codeKey],
    value: countryCodesList[codeKey],
  })
);

const StepForm: React.SFC = () => {
  const location = useLocation();
  useGTagConversionTracker({
    allow_custom_scripts: true,
    u1: location.pathname,
    send_to: GtagSendTo.Signup,
  });

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onSubmit = (data: any) => dispatch(authUserActions.signup(data));
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  const { isLoading } = useSelector((state: State) => state.authUser);
  const [options, setOptions] = useState([
    {
      label: t('sign_up.gear_type_options.label_automatic'),
      value: 'automatic',
    },
    { label: t('sign_up.gear_type_options.label_manual'), value: 'manual' },
  ]);
  const [genderOptions, setGenderOptions] = useState([
    { label: t('sign_up.gender_options.label_male'), value: 'male' },
    { label: t('sign_up.gender_options.label_female'), value: 'female' },
  ]);
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEidDetailsModalOpen, setIsEidDetailsModalOpen] = useState(false);
  const [isUploadEidModalOpen, setIsUploadEidModalOpen] = useState(false);
  const actionModalPopup = (open: boolean) => {
    setIsModalOpen(true);
    setIsUploadEidModalOpen(true);
    document.body.classList.toggle('overflow-hidden');
    document.body.classList.toggle('lg:pr-[17px]');
  };
  const [nationalityValue, setNationality] = useState<any>(null);
  const [readEIDLoading, setReadEIDLoading] = useState<boolean>(false);
  const [genderValue, setGender] = useState<any>(null);
  const [nameValue, setNameValue] = useState<any>(null);
  const [dateOfBirth, setDateOfBirth] = useState<any>(null);
  const [eidNumber, setEidNumber] = useState<any>(null);
  const closeModalPopup = () => {
    setNationality(null);
    setGender(null);
    setNameValue(null);
    setDateOfBirth(null);
    setNameValue(null);
    setIsModalOpen(false);
    setIsEidDetailsModalOpen(false);
    setIsUploadEidModalOpen(false);
    document.body.classList.toggle('overflow-hidden');
    document.body.classList.toggle('lg:pr-[17px]');
  };

  const handleImageUpload = async (event: any) => {
    try {
      setReadEIDLoading(true);
      const file = event.target.files[0];
      const isFileValid = isImage(file);

      if (!isFileValid) {
        throw new Error('Use an image file');
      }

      const request: CrudDetailsRequestModel = {
        resource: 'read-emirate-id',
        resourceId: '',
      };

      const response = await api.media.uploadFile(file, request);

      extractDataFromEIDText(response.data);
    } catch (error) {
      if (error) {
        const str = '2 UNKNOWN:';
        const typedError = error as ErrorResponse;

        let message = typedError?.response?.data?.message
          ? typedError?.response?.data?.message
          : typedError.message;

        const isUnKnowError = `${message}`.startsWith(str);

        if (isUnKnowError) {
          message = message.replace(str, '');
          toastr.error(message, '');
        } else {
          toastr.error(message, '');
        }
        resetFields();
      }

      setReadEIDLoading(false);
      // nothing
    } finally {
      setReadEIDLoading(false);
    }
  };
  

  async function resetFields() {
    setEidNumber(null);
    setFields({
      ...fields,
      name: {
        ...fields.name,
        value: '',
      },
      dob: {
        ...fields.dob,
        value: '',
      },
      nationality: {
        ...fields.nationality,
        value: '',
      },
      gender: {
        ...fields.gender,
        value: '',
      },
    });
  }

  function isImage(file: any) {
    const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const typeProperty = 'type';
    return file && acceptedImageTypes.includes(file[typeProperty]);
  }

  const extractDataFromEIDText = (extractedData: any) => {
    if (extractedData) {
      setIsUploadEidModalOpen(false);
      setIsEidDetailsModalOpen(true);

      for (const [key, value] of Object.entries(extractedData)) {
        if (key === 'nationality') {
          setNationality(value ? `${value}` : '');
        } else if (key === 'gender') {
          setGender(value ? (value === 'M' ? 'male' : 'female') : '');
        } else if (key === 'name') {
          setNameValue(value ? `${value}` : '');
        } else if (key === 'dob') {
          setDateOfBirth(value ? `${value}` : '');
        } else if (key === 'eidNumber') {
          setEidNumber(value ? `${value}` : '');
        }
      }
    }
    if (
      !extractedData?.dob &&
      !extractedData?.name &&
      !extractedData?.nationality &&
      !extractedData?.gender
    ) {
      toastr.error('Invalid Id ,', `Id can't be read , please try again`);

      closeModalPopup();
    }
  };

  const onProceed = () => {
    try {
      const datedob = new Date(`${dateOfBirth}`).toString();

      setFields({
        ...fields,
        name: {
          ...fields.name,
          value: nameValue,
        },
        dob: {
          ...fields.dob,
          value:
            datedob !== 'Invalid Date' && dateOfBirth
              ? new Date(moment(dateOfBirth).format('DD/MM/YYYY'))
              : '',
        },
        nationality: {
          ...fields.nationality,
          value: nationalityValue,
        },
        gender: {
          ...fields.gender,
          value: genderValue,
        },
      });

      closeModalPopup();
    } catch (error) {
      toastr.error('error occured ,', `please try again`);
    }
  };

  const [fields, setFields] = useState<Dict>({
    name: {
      value: '',
      name: 'Full Name',
      label: 'Full Name *',
      error: '',
      placeholder: 'Enter full name',
      validators: [
        validators.required,
        validators.regexPattern(/^[a-zA-Z ]+$/),
      ],
    },
    countryCode: {
      value: '971',
    },
    startingCode: {
      value: '50',
    },
    number: {
      value: '',
      name: 'Phone Number',
      label: 'Phone Number *',
      error: '',
      placeholder: 'Enter your phone number',
      validators: [validators.required],
    },
    email: {
      value: '',
      name: 'Email Address',
      label: 'Email Address *',
      error: '',
      placeholder: 'Enter your email',
      validators: [validators.required, validators.email],
    },
    password: {
      value: '',
      name: 'Password',
      label: 'Password *',
      error: '',
      placeholder: 'Your password should be 6 characters long',
      validators: [
        validators.required,
        validators.minLength(
          6,
          'Password should be at least 6 characters long'
        ),
      ],
    },
    confirmPassword: {
      value: '',
      name: 'Confirm Password',
      label: 'Confirm Password *',
      error: '',
      placeholder: 'Re-enter password to confirm',
      validators: [validators.required],
    },

    licenseTypeId: {
      value: '',
      name: 'Driving License Type',
      label: 'Driving License Type *',
      placeholder: 'Select License Type',
      error: '',
      validators: [validators.required],
    },
    licenseTypeCategory: {
      value: '',
    },
    gearType: {
      value: '',
      name: 'Gear Type',
      label: 'Gear Type *',
      error: '',
      validators: [validators.required],
    },
    gender: {
      value: 'male',
      name: 'Gender',
      label: 'Gender *',
      error: '',
      validators: [validators.required],
    },

    dob: {
      value: '',
      name: 'Date Of Birth',
      label: 'Date Of Birth *',
      error: '',
      placeholder: 'DD / MM / YYYY',
      validators: [validators.required, validators.validDOB],
    },
    language: {
      value: '',
      name: 'Language',
      label: 'Language *',
      error: '',
      placeholder: 'Select your language',
      validators: [validators.required],
    },
    hasResidencyVisa: {
      value: undefined,
      name: 'Residency Visa Status',
      label: 'Do you have a residency visa in UAE? *',
      error: '',
      validators: [
        validators.required,
        // validators.equals(
        //   true,
        //   'You need to have residency Visa in UAE to register!'
        // ),
      ],
    },
    nationality: {
      value: '',
      name: 'Nationality',
      label: 'Nationality *',
      error: '',
      placeholder: 'Select your nationality',
      validators: [validators.required],
    },
    trainingBranch: {
      value: '',
      name: 'Training Branch',
      label: 'Training Branch *',
      error: '',
      placeholder: 'Select your Training Branch',
      validators: [validators.required],
    },
  });
  const [queryValues, setQueryValues] = useState<{
    code: string;
    startingCode: string;
    number: string;
  }>();

  const queryNumber = useLocation<any>();

  useEffect(() => {
    if (queryNumber) {
      const valAr: any = {};
      queryNumber.search
        .replace('?', '')
        .split('&')
        .map((queries) => {
          const query = queries.split('=');
          return (valAr[query[0]] = query[1]);
        });
      if (valAr) {
        setQueryValues(valAr);
      }
    }
  }, [queryNumber]);

  const getInput = (inputField: string, value: any) => {
    if (inputField === 'nationality' && value === UAECountryName) {
      const fieldName = 'hasResidencyVisa';
      setFields({
        ...fields,
        [fieldName]: {
          ...fields.hasResidencyVisa,
          value: false,
        },
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    } else {
      setFields({
        ...fields,
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    }
  };

  const loadGearType = async (licenseTypeId: any) => {
    const request: CrudDetailsRequestModel = {
      resource: 'entity/v1/license-type',
      resourceId: licenseTypeId,
    };
    const gearTypeoptionsOptions: any = [];
    const { data } = await api.crud.get(request);
    if (data && data.gearTypes) {
      data.gearTypes.forEach((item: string) => {
        gearTypeoptionsOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setOptions(gearTypeoptionsOptions);
    }
    const genderTypeOptions: any = [];
    if (data && data.genders) {
      data.genders.forEach((item: string) => {
        genderTypeOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setGenderOptions(genderTypeOptions);
    }
  };

  const validateCustomFields = (customeFields: Dict) => {
    let areCustomFieldsValid = true;
    if (
      !customeFields.number.value ||
      !customeFields.countryCode.value ||
      !customeFields.startingCode.value
    ) {
      return { areCustomFieldsValid: false, updatedCustomFields: {} };
    }

    const validatorMessage = validators.phone(
      'number',
      customeFields.countryCode.value +
        customeFields.startingCode.value +
        customeFields.number.value,
      customeFields.number.name || customeFields.number.label
    );
    customeFields.number.error = validatorMessage;

    if (validatorMessage.length) {
      areCustomFieldsValid = false;
    }

    return { areCustomFieldsValid, updatedCustomFields: customeFields };
  };

  const validatePasswordFields = (customeFields: Dict) => {
    const { password, confirmPassword } = customeFields;
    if (password.value === confirmPassword.value) {
      return undefined;
    }
    return 'Password Mismatch! Both passwords must be same!';
  };

  const validateHasResidencyVisaField = (customeFields: Dict) => {
    const { hasResidencyVisa, nationality } = customeFields;
    if (
      hasResidencyVisa.value === false &&
      !noVisaCountries.includes(nationality.value)
    ) {
      return 'You need to have residency Visa in UAE to register!';
    }
    return undefined;
  };

  const submitForm = async (readyFields: Dict) => {
    const data: any = prepareData(readyFields);

    if (eidNumber) {
      data.studentDetails.emiratesId = eidNumber;
      data.studentDetails.emiratesIdDetails = { id: eidNumber };
    }
    try {
      await onSubmit(data);
      history.replace(`/${selectedLanguage}${RouteKeys.SignupOtp}`);
    } catch (error) {
      const typedError = error as ErrorResponse;

      if (typedError && typedError.response && typedError.response.data) {
        if (typedError.response.data.message === 'Emirates Id already exists') {
          resetFields();
        }
        formatErrors(typedError?.response?.data?.errors);
      }
    }
  };

  const formatErrors = (errors: any) => {
    const errorFields: Dict = {};
    if (Array.isArray(errors)) {
      for (const error of errors) {
        const field = error?.context?.key;
        const formField = field === 'phone' ? 'number' : field;
        const message = `Invalid ${fields[formField]?.name ||
          fields[formField].label}`;
        if (fields[formField]) {
          errorFields[formField] = {
            ...fields[formField],
            error: message,
          };
        } else {
          toastr.error(message, '');
        }
      }
    } else {
      if (errors) {
        const errorkeys = Object.keys(errors);
        for (const field of errorkeys) {
          const formField = field === 'phone' ? 'number' : field;

          const message =
            errors[field].message ||
            errors[field].errorMessage ||
            `Invalid ${fields[formField].name || fields[formField].label}`;
          if (fields[formField]) {
            errorFields[formField] = {
              ...fields[formField],
              error: message,
            };
          } else {
            toastr.error(message, '');
          }
        }
      }
    }

    setFields({
      ...fields,
      ...errorFields,
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const validateOptions = {
      fieldsToValidate: fields,
    };
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    const validatedFields = { ...fields, ...updatedFields };
    if (!areFieldsValid) {
      setFields(validatedFields);
      return;
    }
    const { areCustomFieldsValid, updatedCustomFields } = validateCustomFields(
      validatedFields
    );
    // show error messages (if any)
    const updatedValidatedFields = {
      ...validatedFields,
      ...updatedCustomFields,
    };
    if (!areCustomFieldsValid) {
      setFields(updatedValidatedFields);
      return;
    }
    const passwordMatchErrorMessage = validatePasswordFields(
      updatedValidatedFields
    );
    if (passwordMatchErrorMessage) {
      setFields({
        ...updatedValidatedFields,
        confirmPassword: {
          ...updatedValidatedFields.confirmPassword,
          error: passwordMatchErrorMessage,
        },
      });
      return;
    }
    const residencyVisaErrorMessage = validateHasResidencyVisaField(
      updatedValidatedFields
    );
    if (residencyVisaErrorMessage) {
      setFields({
        ...updatedValidatedFields,
        hasResidencyVisa: {
          ...updatedValidatedFields.hasResidencyVisa,
          error: residencyVisaErrorMessage,
        },
      });
      return;
    }
    const readyFields = serializeFields(fields);
    submitForm(readyFields);
  };

  const renderFieldGroupOne = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/license-type"
            label={t('sign_up.license_type.label')}
            placeholder={t('sign_up.license_type.placeholder')}
            optionLabel="name"
            optionValue="_id"
            onChange={(value, selectedOption) => {
              setFields({
                ...fields,
                licenseTypeId: {
                  ...fields.licenseTypeId,
                  value,
                },
                licenseTypeCategory: {
                  ...fields.licenseTypeCategory,
                  value: selectedOption.name,
                },
                gearType: {
                  ...fields.gearType,
                  value: '',
                },
              });
              loadGearType(value);
            }}
            error={fields.licenseTypeId.error}
            isSearchable={false}
            default={fields.licenseTypeId.value}
            className="form-control-select"
          />
        </div>
        {/* {fields.licenseTypeId.value && ( */}
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            name="gearType"
            key="gearType"
            label={t('sign_up.gear_type.label')}
            options={options}
            onChange={(value) => getInput('gearType', value)}
            error={fields.gearType.error}
            default={fields.gearType.value}
          />
        </div>
        {/* )} */}
      </>
    );
  };

  const renderFieldGroupTwo = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <TextInput
            label={t('sign_up.name.label')}
            default={fields.name.value}
            onChange={(value) => {
              getInput('name', value);
            }}
            placeholder={t('sign_up.name.placeholder')}
            error={fields.name.error}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <TextInput
            label={t('sign_up.email.label')}
            onChange={(value) => getInput('email', value)}
            placeholder={t('sign_up.email.placeholder')}
            error={fields.email.error}
            default={fields.email.value}
          />
        </div>
      </>
    );
  };

  const renderFieldGroupThree = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            name="gender"
            key="gender"
            label={t('sign_up.gender.label')}
            options={genderOptions}
            onChange={(value) => getInput('gender', value)}
            error={fields.gender.error}
            default={fields.gender.value}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <SelectPhoneInput
            type="number"
            label={t('sign_up.number.label')}
            data={countryCodeList}
            defaultCode={fields.countryCode.value}
            defaultSelected={
              queryValues?.startingCode || fields.startingCode.value
            }
            defaultEntered={queryValues?.number || fields.number.value}
            onChange={(value) => {
              setFields({
                ...fields,
                countryCode: {
                  ...fields.countryCode,
                  value: value.code,
                },
                startingCode: {
                  ...fields.startingCode,
                  value: value.selected,
                },
                number: {
                  ...fields.number,
                  value: value.entered,
                },
              });
            }}
            placeholder={t('sign_up.number.placeholder')}
            error={fields.number.error}
            enableSelectSearch
            disableNumberScrolls
          />
        </div>
      </>
    );
  };

  const renderFieldGroupFour = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <DateInput
            label={t('sign_up.dob.label')}
            onChange={(value) => getInput('dob', value)}
            placeholder={fields.dob.placeholder}
            error={fields.dob.error}
            default={fields.dob.value}
            maxDate={new Date()}
            className="form-control"
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <SelectMultipleInput
            api="entity/v1/languages"
            label={t('sign_up.language.label')}
            placeholder={t('sign_up.language.placeholder')}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('language', value)}
            error={fields.language.error}
            className="form-control-select"
          />
        </div>
      </>
    );
  };

  const renderFieldGroupFive = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/countries"
            label={t('sign_up.nationality.label')}
            placeholder={t('sign_up.nationality.placeholder')}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('nationality', value)}
            error={fields.nationality.error}
            className="form-control-select"
            default={fields.nationality.value}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            type="boolean"
            name="hasResidencyVisa"
            key="hasResidencyVisa"
            label={t('sign_up.has_residency_visa.label')}
            options={[
              {
                label: t('sign_up.has_residency_visa_options.yes'),
                value: true,
              },
              {
                label: t('sign_up.has_residency_visa_options.no'),
                value: false,
              },
            ]}
            onChange={(value) => getInput('hasResidencyVisa', value)}
            error={fields.hasResidencyVisa.error}
            default={fields.hasResidencyVisa.value}
          />
        </div>
      </>
    );
  };
  const renderFieldGroupSix = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <PasswordInput
            label={t('sign_up.password.label')}
            onChange={(value) => getInput('password', value)}
            placeholder={t('sign_up.password.placeholder')}
            error={fields.password.error}
            gap={'no'}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <PasswordInput
            label={t('sign_up.confirm_password.label')}
            onChange={(value) => getInput('confirmPassword', value)}
            placeholder={t('sign_up.confirm_password.placeholder')}
            error={fields.confirmPassword.error}
            gap={'no'}
          />
        </div>
      </>
    );
  };
  const renderFieldGroupSeven = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/training-branch"
            label={fields.trainingBranch.label}
            placeholder={fields.trainingBranch.placeholder}
            optionLabel="name"
            optionValue="_id"
            onChange={(value) => getInput('trainingBranch', value)}
            error={fields.trainingBranch.error}
            className="form-control-select"
            default={fields.trainingBranch.value}
            filterFunc={(item) =>
              item.type !== 'main_branch' || item.allowTrainingSchedule
            }
          />
        </div>
        <div className="col-span-2 md:col-span-1 hidden md:block"></div>
      </>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="col-span-2 pt-2 pb-3">
        <div className="grid grid-cols-2 gap-x-4 gap-y-5 lg:gap-x-10 lg:gap-y-7">
          <div className="col-span-2 md:col-span-1">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary w-full"
            >
              <span className="relative">
                {isLoading && (
                  <span className="absolute top-[-3px] ltr:-left-7 rtl:-right-7">
                    <img src={loaderImg} width={20} />
                  </span>
                )}
                {t('sign_up.next')}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };
  // Any functionality added to this page needs to also be updated on the pre-registration step one page.
  return (
    <div className="bg-white py-11 lg:py-24">
      <div className="container">
        <div className="text-center pb-8">
          <h1 className="text-3xl md:text-4xl leading-tight text-center font-semibold">
            {t('sign_up.register_now')}
          </h1>
          <p className="text-muted text-sm pt-1 lg:pt-2.5">
            {t('sign_up.have_account')}
            <Link to={`/${selectedLanguage}${RouteKeys.Login}`}>
              <a className="text-black ltr:ml-1 rtl:mr-1">
                {t('sign_up.login')}
              </a>
            </Link>
          </p>
        </div>
        <form onSubmit={submitHandler}>
          <div className="max-w-[920px] mx-auto">
            {isModalOpen && (
              <>
                {/* file upload */}
                {isUploadEidModalOpen && !readEIDLoading && (
                  <div className="max-w-[95%] sm:max-w-[450px] w-full bg-white rounded-2xl overflow-hidden fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-50">
                    <div className="bg-primary text-center p-4 font-bold text-white text-lg leading-4 relative">
                      {t('sign_up.upload_eid')}
                      <div
                        className="absolute top-3 ltr:right-5 rtl:left-5 p-1.5 "
                        onClick={() => closeModalPopup()}
                      >
                        <CloseIcon width={12} height={12} stroke="white" />
                      </div>
                    </div>
                    <div className="px-8 pt-8 pb-12 sm:mb-5">
                      <div className="grid grid-cols-2 gap-y-16 sm:gap-y-0 sm:gap-x-16 relative">
                        <div
                          className="col-span-2 sm:col-span-1 lg:opacity-50 lg:cursor-not-allowed"
                          aria-disabled
                        >
                          <p className="text-sm">
                            {t('sign_up.for_mobile_user')}
                          </p>
                          <div className="border-primary rounded-md flex items-center justify-center p-3 mt-2 border-2">
                            <ScanIcon className="ltr:mr-2 rtl:ml-2" />
                            {t('sign_up.scan')}
                            <input
                              type={'file'}
                              capture={'camera'}
                              accept="image/*"
                              onChange={handleImageUpload}
                              className="absolute bottom-0 left-0 top-0 w-full z-40 opacity-0 "
                              // disabled={readEIDLoading}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <p className="text-sm	">
                            {t('sign_up.upload_from_computer')}
                          </p>
                          <div className="cursor-pointer border-primary rounded-md flex items-center justify-center p-3 mt-2 border-2 relative text-sm font-medium">
                            <UploadIcon className="ltr:mr-2 rtl:ml-2" />
                            {t('sign_up.upload')}
                            <input
                              type={'file'}
                              accept="image/*"
                              onChange={handleImageUpload}
                              className="absolute bottom-0 left-0 top-0 w-full z-40 opacity-0 cursor-pointer"
                              disabled={readEIDLoading}
                            />
                          </div>
                        </div>
                        <div className="absolute left-1/2 -translate-y-2/4 -translate-x-2/4 text-xs	bg-white text-[#B7B7B7] p-1 z-10 sm:mt-3 top-1/2">
                          {t('sign_up.or')}
                        </div>
                        <span className="w-[100%] h-[1px] sm:w-[1px] sm:h-[100%] bg-[#B7B7B7] absolute sm:left-1/2	top-1/2 sm:top-0"></span>
                      </div>
                    </div>
                  </div>
                )}
                {/* file upload */}
                {readEIDLoading && (
                  <div className="fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-50">
                    <img src={loaderImg} width={45} className="mr-2" />
                  </div>
                )}
                {/* details */}
                {isEidDetailsModalOpen && !readEIDLoading && (
                  <div className="max-w-[95%] sm:max-w-[410px] w-full bg-white rounded-3xl fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-50">
                    <div className="bg-primary rounded-tr-3xl  rounded-tl-3xl text-center p-4 font-bold text-white text-lg leading-4">
                      {t('sign_up.details_from_EID')}
                    </div>
                    <div className="px-12 pt-8">
                      <div className="grid grid-cols-2 gap-y-3 sm:gap-x-12 relative text-sm">
                        <div className="col-span-2 sm:col-span-1">
                          <label className="text-[#8A8C8B]">Name:</label>
                          <p>{nameValue}</p>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <label className="text-[#8A8C8B]">Gender:</label>
                          <p>{genderValue}</p>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <label className="text-[#8A8C8B]">
                            Date of Birth:
                          </label>
                          <p>{dateOfBirth}</p>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <label className="text-[#8A8C8B]">Nationality:</label>
                          <p>{nationalityValue}</p>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <label className="text-[#8A8C8B]">Emirate Id:</label>
                          <p>{eidNumber}</p>
                        </div>
                      </div>
                      <div className="gap-3 grid grid-cols-2 mt-2">
                        <div className="col-span-1 mt-3">
                          <button
                            onClick={() => closeModalPopup()}
                            type="button"
                            className="btn btn-outline-primary w-full "
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-span-1 mt-3">
                          <button
                            type="button"
                            onClick={() => onProceed()}
                            className="btn btn-primary w-full "
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex text-sm px-4 pt-4 pb-5">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          className="px-[6px] w-[24px]"
                        >
                          <path
                            d="M7 4.6V7.6M7 13C10.3 13 13 10.3 13 7C13 3.7 10.3 1 7 1C3.7 1 1 3.7 1 7C1 10.3 3.7 13 7 13Z"
                            stroke="#6F7171"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <p className="text-[#6F7171] text-xs">
                        In the event of any discrepancies or incorrect data, you
                        may manually make the necessary corrections.
                      </p>
                    </div>
                  </div>
                )}
                {/* details */}
                <span
                  className="fixed bg-black/60 z-40 top-0 left-0 right-0 bottom-0"
                  // onClick={() => closeModalPopup()}
                ></span>
              </>
            )}
            <div
              className="upload bg-slate-50 rounded-[10px] px-4 lg:px-6 py-3.5 cursor-pointer mb-9"
              onClick={() => actionModalPopup(true)}
            >
              <div className="flex items-center justify-between -mx-2.5">
                <div className="px-2.5">
                  <h4 className="text-lg font-bold mb-1">
                    {t('sign_up.upload_eid')}
                  </h4>
                  <p className="text-sm text-muted">{t('sign_up.click_on')}</p>
                </div>
                <div className="px-2.5">
                  <UploadIcon />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4 lg:gap-x-10 md:gap-y-7 gap-5">
              {renderFieldGroupOne()}
              {renderFieldGroupTwo()}
              {renderFieldGroupThree()}
              {renderFieldGroupFour()}
              {renderFieldGroupFive()}
              {renderFieldGroupSeven()}
              {renderFieldGroupSix()}
              {renderSubmitButton()}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepForm;
