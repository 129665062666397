import React from 'react';

export const GridIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      {...props}
    >
      <path d="M1.99997 7.99998C1.59101 7.99998 1.23877 7.85223 0.943275 7.55673C0.647758 7.26121 0.5 6.90897 0.5 6.5V1.99998C0.5 1.59101 0.647758 1.23878 0.943275 0.943275C1.23877 0.647759 1.59101 0.5 1.99997 0.5H6.5C6.90897 0.5 7.26121 0.647759 7.55673 0.943275C7.85223 1.23878 7.99998 1.59101 7.99998 1.99998V6.5C7.99998 6.90897 7.85223 7.26121 7.55673 7.55673C7.26121 7.85223 6.90897 7.99998 6.5 7.99998H1.99997ZM1.99997 17.5C1.59101 17.5 1.23877 17.3522 0.943275 17.0567C0.647758 16.7612 0.5 16.4089 0.5 16V11.5C0.5 11.091 0.647758 10.7387 0.943275 10.4432C1.23877 10.1477 1.59101 9.99998 1.99997 9.99998H6.5C6.90897 9.99998 7.26121 10.1477 7.55673 10.4432C7.85223 10.7387 7.99998 11.091 7.99998 11.5V16C7.99998 16.4089 7.85223 16.7612 7.55673 17.0567C7.26121 17.3522 6.90897 17.5 6.5 17.5H1.99997ZM11.5 7.99998C11.091 7.99998 10.7387 7.85223 10.4432 7.55673C10.1477 7.26121 9.99998 6.90897 9.99998 6.5V1.99998C9.99998 1.59101 10.1477 1.23878 10.4432 0.943275C10.7387 0.647759 11.091 0.5 11.5 0.5H16C16.4089 0.5 16.7612 0.647759 17.0567 0.943275C17.3522 1.23878 17.5 1.59101 17.5 1.99998V6.5C17.5 6.90897 17.3522 7.26121 17.0567 7.55673C16.7612 7.85223 16.4089 7.99998 16 7.99998H11.5ZM11.5 17.5C11.091 17.5 10.7387 17.3522 10.4432 17.0567C10.1477 16.7612 9.99998 16.4089 9.99998 16V11.5C9.99998 11.091 10.1477 10.7387 10.4432 10.4432C10.7387 10.1477 11.091 9.99998 11.5 9.99998H16C16.4089 9.99998 16.7612 10.1477 17.0567 10.4432C17.3522 10.7387 17.5 11.091 17.5 11.5V16C17.5 16.4089 17.3522 16.7612 17.0567 17.0567C16.7612 17.3522 16.4089 17.5 16 17.5H11.5ZM1.99997 6.5H6.5V1.99998H1.99997V6.5ZM11.5 6.5H16V1.99998H11.5V6.5ZM11.5 16H16V11.5H11.5V16ZM1.99997 16H6.5V11.5H1.99997V16Z" />
    </svg>
  );
};
