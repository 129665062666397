/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect, useRef } from 'react';
import './UserHeader.scss';
import LogoutButton from '../../components/app/logout-button';
import User from '../../models/user';
import { State } from '../../store/interfaces';
import { Link } from 'react-router-dom';
import LogoImage from '../../assets/images/logo.svg';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../LanguageContext';
import i18n from '../../i18n';
import Avatar from '../../components/avatar';

interface CustomProps {
  innerHeader?: boolean;
}

const cmsDomain = process.env.REACT_APP_CMS_DOMAIN;

const UserHeaderCanvas: React.SFC<CustomProps> = (props: any) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [accountDropdown, setAccountDropdown] = useState<boolean>(false);
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  const {
    userProfileLoaded,
    user,
  }: { user?: User; userProfileLoaded: boolean } = useSelector(
    (store: State) => store.authUser
  );
  const profileImageClick = () => {
    history.replace(`/${selectedLanguage}/dashboard/course`);
  };
  const history = useHistory();
  const baseUrl = `/${selectedLanguage}/dashboard`;

  const userName = (name: string) => {
    const getNameLen = name.split(' ');
    if (getNameLen.length > 2) {
      return getNameLen[0] + ' ' + getNameLen[1] + '...';
    }
    return name;
  };

  const { t } = useTranslation();
  const { setSelectedLanguage } = useLanguage();
  const [lang, setLang] = React.useState<'en' | 'ar'>(selectedLanguage);

  const changeLanguage = (lng: 'en' | 'ar') => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    setLang(lng);
    // Get the current pathname and search params
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
  
    // Replace the language segment in the pathname
    const newPath = currentPath.replace(/^\/(en|ar)/, `/${lng}`);
  
    // Construct the new URL with the updated pathname and existing search params
    const newUrl = `${newPath}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
  
    // Push the new URL to history
    history.push(newUrl);
  };
  useEffect(() => {
    const urlLanguage = window.location.pathname.split('/')[1];
    if (urlLanguage && (urlLanguage === 'en' || urlLanguage === 'ar')) {
      changeLanguage(urlLanguage);
    }
  }, [selectedLanguage]);
  useEffect(() => {
    const dir = lang === 'en' ? 'ltr' : 'rtl';
    const language = lang === 'en' ? 'en' : 'ar';
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.setAttribute('dir', dir);
      htmlElement.setAttribute('lang', language);
    }
  }, [lang]);

  const accountDropdownRef = useRef<HTMLDivElement>(null);

  const toggleAccountDropdown = () => {
    setAccountDropdown((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accountDropdownRef.current &&
      !accountDropdownRef.current.contains(event.target as Node)
    ) {
      setAccountDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="relative bg-white shadow px-4 lg:px-10 py-3.5">
      <div className="flex items-center justify-between -mx-4">
        <div className="px-4">
          <Link to={`${baseUrl}`} className="cursor-pointer flex">
            <span className="cursor-pointer flex h-16 lg:h-auto w-auto">
              <img src={LogoImage} alt="" />
            </span>
          </Link>
        </div>
        <div className="px-4">
          <div
            ref={accountDropdownRef}
            onMouseEnter={() => setAccountDropdown(true)}
            onMouseLeave={() => setAccountDropdown(false)}
            className="relative"
          >
            <button
              className="cursor-pointer flex items-center py-3.5 lg:py-0 font-semibold"
              onClick={toggleAccountDropdown}
            >
              {/* {user?.name ? userName(user?.name) : user?.name} */}
              {user?.name}
              <div className="border-2 border-secondary rounded-full p-0.5 inline-flex ltr:ml-3 rtl:mr-3">
                <Avatar src={user?.avatar} size="small" />
              </div>
            </button>
            {accountDropdown && (
              <div className="absolute ltr:right-0 rtl:left-0 w-[253px] z-10 pt-1">
                <div className="bg-white border border-[#E0E0E0] rounded-[10px] shadow-sm px-4 lg:px-5 pt-4 pb-5 text-xs text-muted font-semibold">
                  <h6 className="text-sm leading-snug capitalize pb-px text-black">
                    {user?.name}
                  </h6>
                  <p>
                    LPN:
                    <span className="text-black">
                      {user?.studentDetails?.studentId}
                    </span>
                  </p>
                  <p className="font-medium">{user?.email}</p>
                  <div className="grid grid-cols-2 gap-x-1 mt-4">
                    <a
                      href={`${baseUrl}/my-account`}
                      className="btn btn-primary p-1.5 text-xs"
                    >
                      {t('header.account')}
                    </a>
                    <LogoutButton />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default UserHeaderCanvas;
