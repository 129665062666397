// core
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
// action creators
import { actions as authUserActions } from '../../store/reducers/authUser';
// components
import { OTPInput } from '../../components/form-group';
// helpers
import { validateFields, serializeFields, validators } from '../helpers';
import './StepForm.scss';
import { Dict } from '../../models/interfaces';
import { State } from '../../store/interfaces';
import { useGTagConversionTracker } from '../../hooks/gtag';
import { GtagSendTo } from '../../utils/gtag';
import loaderImg from '../../assets/images/loader-white.svg';
import { ErrorResponse } from '../../store/reducers/error';
import { useTranslation } from 'react-i18next';

const StepForm: React.SFC = () => {
  useGTagConversionTracker({
    allow_custom_scripts: true,
    send_to: GtagSendTo.SignupOTP,
  });

  const { t } = useTranslation();

  const { isLoading, verify } = useSelector((state: State) => state.authUser);
  const [timer, setTimer] = useState<number>(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  useEffect(() => {
    const storedTimer = localStorage.getItem('otpTimer');
    const storedStartTime = localStorage.getItem('otpStartTime');

    if (storedTimer && storedStartTime) {
      const timerValue = parseInt(storedTimer, 10);
      const startTimeValue = parseInt(storedStartTime, 10);
      const elapsed = Math.floor((Date.now() - startTimeValue) / 1000);
      const remainingTime = Math.max(0, timerValue - elapsed);

      if (remainingTime > 0) {
        setTimer(remainingTime);
        setIsTimerRunning(true);
        startTimer(remainingTime);
      } else {
        setIsTimerRunning(false);
      }
    }
    /*else {
      setTimer(180);
      setIsTimerRunning(true);
      localStorage.setItem('otpTimer', '180');
      localStorage.setItem('otpStartTime', `${Date.now()}`);
      startTimer(180);
    }*/
    return () => {
      localStorage.removeItem('otpTimer');
      localStorage.removeItem('otpStartTime');
    };
  }, []);

  const startTimer = (duration: number) => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(intervalId);
          setIsTimerRunning(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const [fields, setFields] = useState<Dict>({
    otp: {
      value: '',
      label: 'OTP',
      error: '',
      validators: [
        validators.minLength(6, t('common.lblOTPShouldHave6Letters')),
      ],
    },
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  const onSubmit = (credentials: any) =>
    dispatch(
      authUserActions.loginWithOTP(credentials, () =>
        dispatch(authUserActions.fetchProfile())
      )
    );
  const sendOTPAction = (id: string) => dispatch(authUserActions.sendOTP(id));

  const getInput = (inputField: string, value: any) => {
    setFields({
      ...fields,
      [inputField]: {
        ...fields[inputField],
        value,
      },
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const validateOptions = {
      fieldsToValidate: fields,
    };
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    // show error messages (if any)
    setFields({
      ...fields,
      ...updatedFields,
    });

    if (areFieldsValid) {
      const readyFields = serializeFields(fields);
      submitForm(readyFields);
    }
  };

  const submitForm = async (readyFields: Dict) => {
    try {
      await onSubmit({
        id: (verify && verify.id) || '',
        otp: readyFields.otp,
      });
      history.replace(`/${selectedLanguage}/signup/onboard`);
    } catch (error) {
      const typedError = error as ErrorResponse;

      // console.log('Login error:', error);
      if (
        typedError &&
        typedError.response &&
        typedError.response.data &&
        typedError.response.data.message
      ) {
        setFields({
          ...fields,
          otp: {
            ...fields.otp,
            error: typedError.response.data.message,
          },
        });
      }
    }
  };

  const sendOTP = async () => {
    if (!verify) {
      toastr.error(
        t('common.lblError'),
        t('common.lblUnableToSendPleaseTryAgain')
      );
      return;
    }
    try {
      // console.log('sending otp to : ', this.props.verify.id);
      await sendOTPAction((verify && verify.id) || '');
      setTimer(180);
      setIsTimerRunning(true);
      localStorage.setItem('otpTimer', '180');
      localStorage.setItem('otpStartTime', `${Date.now()}`);
      startTimer(180);
      toastr.success(
        t('common.lblResentOTP'),
        t('common.lblPleaseEnterTheNewOTPToVerify')
      );
    } catch (error) {
      toastr.error(
        t('common.lblErrorSendingOTP'),
        t('common.lblPleaseResendiAgain')
      );
      // console.log('rend otp failed: ', error);
    }
  };

  const otpTimerDisplay = () => {
    if (timer >= 60) {
      const minutes = Math.floor(timer / 60);
      const seconds = timer % 60;
      return `${minutes} : ${seconds.toString().padStart(2, '0')} minute${
        minutes !== 1 ? 's' : ''
      }`;
    } else {
      return `${timer} second${timer !== 1 ? 's' : ''}`;
    }
  };

  return (
    <div className="max-w-md mx-auto py-9 lg:py-12">
      <form onSubmit={submitHandler}>
        <div className="mb-8">
          <h4 className="mb-2 font-semibold">
            {t('common.lblVerifyYourMobileNumber')}
          </h4>
          {verify && (
            <p className="text-muted text-sm">
              {t('common.lblWeSentASixDigitCodeTo')}{' '}
              <span className="Verification__number font-medium">
                {verify.phoneNumber}
              </span>
            </p>
          )}
          <p className="text-muted text-sm">
            {t('common.lblEnterTheCodeBelowToConfirmYourMobileNumber')}
          </p>
        </div>
        <OTPInput
          onChange={(value) => getInput('otp', value)}
          error={fields.otp.error}
        />
        <div className="flex justify-content-lg-start justify-content-center text-sm py-5 mb-1 font-medium">
          {/* <span className="ltr:mr-1 rtl:ml-1 text-muted">
            Did not receive OTP?
          </span> */}
          <span>
            {isTimerRunning &&
              `${t('common.lblResendOTPIn')} ${otpTimerDisplay()}`}
          </span>
          {!isTimerRunning && (
            <span onClick={() => sendOTP()} className="text-sm cursor-pointer">
              {t('common.lblResendOTP')}
            </span>
          )}
        </div>
        <div className="row justify-content-lg-start justify-content-center">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary w-full"
          >
            <span className="relative">
              {isLoading && (
                <span className="absolute top-[-3px] ltr:-left-7 rtl:-right-7">
                  <img src={loaderImg} width={20} />
                </span>
              )}
              {t('common.lblVerifyAndProceed')}
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepForm;
