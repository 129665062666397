import React from 'react';

export const GetDirectionIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.18747 10.0625H6.31247V7.81247H9.31247V9.42066L11.4832 7.24997L9.31247 5.07216V6.68747H5.18747V10.0625ZM7.99997 15.5663L0.433594 7.99997L7.99997 0.433594L15.5663 7.99997L7.99997 15.5663ZM7.99997 14L14 7.99997L7.99997 1.99997L1.99997 7.99997L7.99997 14Z" />
    </svg>
  );
};
