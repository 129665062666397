import React from 'react';

const ClockIcon = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_2830_18978)">
    <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#EF3935" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 4.5V9L12 10.5" stroke="#EF3935" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_2830_18978">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

export default ClockIcon;
