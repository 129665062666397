import React from 'react';

export const PaymentIcon = (props: any) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      stroke={'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#F8F8F8" stroke="none" />
      <g clip-path="url(#clip0_2766_46517)">
        <path
          d="M9.75 16.166C9.75 15.3041 10.0924 14.4774 10.7019 13.8679C11.3114 13.2584 12.138 12.916 13 12.916H26C26.862 12.916 27.6886 13.2584 28.2981 13.8679C28.9076 14.4774 29.25 15.3041 29.25 16.166V24.8327C29.25 25.6946 28.9076 26.5213 28.2981 27.1308C27.6886 27.7403 26.862 28.0827 26 28.0827H13C12.138 28.0827 11.3114 27.7403 10.7019 27.1308C10.0924 26.5213 9.75 25.6946 9.75 24.8327V16.166Z"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.75 18.334H29.25"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.0833 23.75H14.0941"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.4167 23.75H20.5834"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
