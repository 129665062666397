import React from 'react';

export const CopyIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="currentColor"
      {...props}
    >
      <path d="M8.8421 0H1.26316C0.565263 0 0 0.4475 0 1V8H1.26316V1H8.8421V0ZM10.7368 2H3.78947C3.09158 2 2.52632 2.4475 2.52632 3V10C2.52632 10.5525 3.09158 11 3.78947 11H10.7368C11.4347 11 12 10.5525 12 10V3C12 2.4475 11.4347 2 10.7368 2ZM10.7368 10H3.78947V3H10.7368V10Z" />
    </svg>
  );
};
