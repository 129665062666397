import React from 'react';

export const LectureIcon = (props: any) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#F8F8F8" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8386 25.7695H32.735C33.1056 25.7695 33.4061 26.2249 33.4061 26.5955C33.4061 26.5955 33.4061 28.3891 33.4061 30.3286C33.4061 30.6993 33.1056 30.9997 32.735 30.9997H10.8386C10.4679 30.9997 10.1675 30.6993 10.1675 30.3286V26.5955C10.1675 26.3189 10.4121 25.7695 10.8386 25.7695ZM11.5097 28.9318V29.6575H32.0639C32.0639 28.4528 32.0639 28.8069 32.0639 27.1117H11.5097C11.5097 28.2173 11.5097 28.9318 11.5097 28.9318Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.1919 12.8705H18.5479V11.5283H30.863C31.2336 11.5283 31.5341 11.8288 31.5341 12.1994V23.8819C31.5341 24.2526 31.2336 24.553 30.863 24.553H28.0034V23.2108H30.1919V12.8705Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3817 23.2113V18.8291H12.0396V23.8824C12.0396 24.253 12.34 24.5535 12.7106 24.5535H22.0959V23.2113H13.3817Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1033 15.8519C24.6358 15.8519 24.2577 16.2306 24.2577 16.6939C24.2577 17.1593 24.6379 17.5395 25.1033 17.5395C25.5629 17.5395 25.9453 17.1577 25.9453 16.6939C25.9453 16.2311 25.5677 15.8519 25.1033 15.8519ZM22.9155 16.6939C22.9155 15.4851 23.8988 14.5098 25.1033 14.5098C26.3111 14.5098 27.2875 15.492 27.2875 16.6939C27.2875 17.8947 26.3085 18.8817 25.1033 18.8817C23.8967 18.8817 22.9155 17.9006 22.9155 16.6939Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.9823 18.5393C24.132 18.5226 24.2829 18.5568 24.4108 18.6364L25.1027 19.0667L25.7912 18.6369C25.9193 18.557 26.0706 18.5226 26.2206 18.5393C27.4062 18.671 27.9866 19.6052 27.9866 20.4677V22.58C27.9866 23.0829 27.7654 23.5345 27.4169 23.8502V26.4385H26.0747V23.5086C26.0747 23.2615 26.2104 23.0345 26.428 22.9175C26.5706 22.8409 26.6444 22.7074 26.6444 22.58V20.4677C26.6444 20.3235 26.5969 20.1853 26.5131 20.0838C26.4621 20.0221 26.3871 19.9604 26.2728 19.9185L25.4586 20.4266C25.2415 20.5621 24.9662 20.5623 24.7489 20.4272L23.9338 19.9203C23.6801 20.0194 23.5585 20.2449 23.5585 20.4862V22.5837C23.5585 22.7111 23.6324 22.8446 23.7749 22.9212C23.9925 23.0382 24.1282 23.2652 24.1282 23.5123V26.4422H22.786V23.8539C22.4375 23.5382 22.2163 23.0866 22.2163 22.5837V20.4862C22.2163 19.5846 22.8387 18.6663 23.9823 18.5393Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7057 10.6918C12.0414 10.6918 10.6923 12.0409 10.6923 13.7052C10.6923 15.3695 12.0414 16.7187 13.7057 16.7187C15.37 16.7187 16.7192 15.3695 16.7192 13.7052C16.7192 12.0409 15.37 10.6918 13.7057 10.6918ZM9.3501 13.7052C9.3501 11.2997 11.3002 9.34961 13.7057 9.34961C16.1113 9.34961 18.0614 11.2997 18.0614 13.7052C18.0614 16.1108 16.1113 18.0609 13.7057 18.0609C11.3002 18.0609 9.3501 16.1108 9.3501 13.7052Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1998 12.0374C13.3219 11.9743 13.4668 11.992 13.5726 12.083L14.9326 13.2519C15.0162 13.3237 15.0648 13.4325 15.0649 13.5474C15.0649 13.6624 15.0163 13.7712 14.9328 13.8431L13.5729 15.0141C13.4671 15.1052 13.3221 15.123 13.1999 15.06C13.0777 14.997 13 14.8643 13 14.7186V12.3787C13 12.2331 13.0777 12.1004 13.1998 12.0374Z"
      />
    </svg>
  );
};
