export enum RouteKeys {
  Auth = '/auth',
  Login = '/auth/login',
  Signup = '/signup',
  SignupOtp = '/signup/otp',
  SignupOnboard = '/signup/onboard',
  SignupThankYou = '/signup/thank-you',
  Home = '/',
  Dashboard = '/dashboard',
  ForgotPassword = '/auth/forgot-password',
  ResetPasswordWithOTP = '/auth/forgot-password/otp',
  Payment = '/dashboard/payment',
  PaymentCallback = '/payment/redirect',
  NotFound = '/page-not-found',
  LectureVideo = '/lecture-video/:lessonId/:topicId/:subTopicId',
  ScheduleDetails = '/schedules/:scheduleId',
  QuestionAnswer = '/question-answer/:lessonId/:topicId/quiz',
  PdfInvoice = '/invoice/:id/pdf/',
  LumpsumPdfInvoice = '/lumpsum-invoice/pdf/',
  PdfContract = '/contract/:id/pdf/',
  Dev = '/dev/',
  PdfRefundDocument = '/refund-document/:id/pdf/',
  PdfClearanceCertificate = '/clearance-certificate/:id/pdf/',
  BookAppointment = '/book-an-appointment',
  BookAppointmentThankYou = '/book-an-appointment/thank-you',
  PaymentThankYou = '/dashboard/payment/thank-you',
  CustomerFeedback = '/customer-feedback',
  CustomerFeedbackThankYou = '/customer-feedback-thank-you',
  CorporateLumpsumPdfInvoice = '/corporate-lumpsum-invoice/pdf/',
  CorporatePdfInvoice = '/corporate-invoice/pdf/',
  PdfRefundDocumentCorporate = '/corporate-refund-document/:id/pdf/',
  PreregistrationPdfInvoice = '/preregistration-invoice/:id/pdf/',
  MyReferrals = '/dashboard/my-referral-report/:id/',
  EditProfile = '/dashboard/profile/edit-profile',
  SalesmanCollectionReport = '/saleman-collection-report/pdf/',
  SalesmanCollectingReport = '/saleman-collecting-report/pdf/',
  CollectionReportPdf = '/collection-report/pdf/',
  QuickSignup = '/quick-signup',
  PaymentError = '/dashboard/payment/error',
  OnlinePaymentRedirect = '/payment/redirect',
  SignupPaymentError = '/signup/payment-error',
  SalesmanAllCollectionReport = '/saleman-all-collection-report/pdf/',
  RegAdditionalPaymentCallback = '/payment/v1/web-hook/payment-handler',
  AdditionalPaymentCallback='/payment/v1/web-hook/additional-payment-handler',
  MockTestOtp = '/mock-test/otp',
  MockTestInstructions = '/mock-test/instructions',
  MockTestChoseLanguage = '/mock-test/choose-language',
  MockTestQuestion = '/mock-test/question',
  MockTestSummary = '/mock-test/summary',
  MockTestVideoMessage = '/mock-test/video-message',
  MockTestVideoConfirmation = '/mock-test/video-confirmation',
  MockTestVideoQuestion = '/mock-test/video-question',
  MockTestThankyou = '/mock-test/thank-you',
}
