import React, { ReactElement, useState, useEffect } from 'react';
import api from '../../api';

interface CustomProps {
  src?: string;
  defaultSrc?: string;
  className?: string;
  alt?: string;
  title?: string;
}

const SignedImage: React.SFC<CustomProps> = (
  props: CustomProps
): ReactElement => {
  const isValidUrl = (url: string) => {
    try {
      // tslint:disable-next-line: no-unused-expression
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const getSignedUrl = async (docPath: string) => {
    try {
      const response = await api.media.getSignedDocumentUrl(docPath);
      return response.data;
    } catch (e) {
      return docPath;
    }
  };
  const { src, defaultSrc = '', className, alt, title } = props;

  const [signedUrl, setSignedUrl] = useState<string>(defaultSrc);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded && signedUrl === defaultSrc && src) {
      if (isValidUrl(src) || src.startsWith('data:image')) {
        setLoaded(true);
        setSignedUrl(src);
      } else {
        getSignedUrl(src).then((url) => {
          setLoaded(true);
          setSignedUrl(url);
        });
      }
    }
  }, [signedUrl, setSignedUrl, getSignedUrl, defaultSrc, src]);

  return (
    <img
      className={className}
      src={signedUrl}
      alt={alt}
      title={title}
      onError={(e) => {
        if (signedUrl !== defaultSrc) {
          setSignedUrl(defaultSrc);
        }
      }}
    />
  );
};

export default SignedImage;
