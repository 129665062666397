import React from 'react';

export const DocumentsIcon = (props: any) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      stroke={'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#F8F8F8" stroke="none" />
      <g clip-path="url(#clip0_2766_46531)">
        <g filter="url(#filter0_d_2766_46531)">
          <rect
            x="-12.8222"
            y="-11.6662"
            width="230.533"
            height="129.422"
            rx="3.17778"
            stroke="#ECEBEB"
            stroke-width="0.577778"
            shape-rendering="crispEdges"
          />
        </g>
        <path
          d="M21.7778 12.002V15.5575C21.7778 15.7933 21.8715 16.0193 22.0382 16.186C22.2049 16.3527 22.431 16.4464 22.6667 16.4464H26.2223"
          stroke-width="1.44444"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.4445 28.002H15.5556C15.0841 28.002 14.6319 27.8147 14.2985 27.4813C13.9651 27.1479 13.7778 26.6957 13.7778 26.2242V13.7797C13.7778 13.3082 13.9651 12.8561 14.2985 12.5227C14.6319 12.1893 15.0841 12.002 15.5556 12.002H21.7778L26.2223 16.4464V26.2242C26.2223 26.6957 26.035 27.1479 25.7016 27.4813C25.3682 27.8147 24.916 28.002 24.4445 28.002Z"
          stroke-width="1.44444"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.3335 22.6684L19.1113 24.4462L22.6668 20.8906"
          stroke-width="1.44444"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
