import React from 'react';

const CorrectAnswerIcon = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
<path d="M27.9997 56C43.4635 56 55.9994 43.464 55.9994 28C55.9994 12.536 43.4635 0 27.9997 0C12.5359 0 0 12.536 0 28C0 43.464 12.5359 56 27.9997 56Z" fill="#B8E082"/>
<path d="M18.9348 25.5142C21.0173 25.5142 22.7055 23.826 22.7055 21.7434C22.7055 19.6609 21.0173 17.9727 18.9348 17.9727C16.8523 17.9727 15.1641 19.6609 15.1641 21.7434C15.1641 23.826 16.8523 25.5142 18.9348 25.5142Z" fill="#3E8144"/>
<path d="M37.0657 25.5142C39.1482 25.5142 40.8364 23.826 40.8364 21.7434C40.8364 19.6609 39.1482 17.9727 37.0657 17.9727C34.9831 17.9727 33.2949 19.6609 33.2949 21.7434C33.2949 23.826 34.9831 25.5142 37.0657 25.5142Z" fill="#3E8144"/>
<path d="M39.3923 35.2207C39.3923 35.2207 36.6645 41.7995 27.7592 41.7995C18.8538 41.7995 16.6074 35.2207 16.6074 35.2207C16.6074 35.2207 27.358 39.7938 39.3923 35.2207Z" fill="#3E8144"/>
</svg>
  );
};

export default CorrectAnswerIcon;
