import React, { lazy, Suspense, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import { RouteKeys } from './route-keys';
import AuthenticateRoute from './auth/AuthenticateRoute';
import ErrorBoundary from './ErrorBoundary';

// import Auth from './auth';
// import Home from './home';
// import NotFound from './notfound';
// import DashboardPage from './dashboard';
import RegisterStep1 from './register-step1';
import QuickRegisterStep1 from './preregister-step1';
import RegisterStep2 from './register-step2';
import RegisterStep3 from './register-step3';
import RegisterThankYou from './register-thank-you';
import { PaymentCallBackPage } from './payment';
// import LectureVideo from './lecture-video';
// import QuestionAnswer from './question-answer';
// import PdfInvoicePage from './pdf-invoice';
// import ScheduleDetailsPage from './schedule-details';
import VerifyUserEmail from './verify-email';
import RedirectIfAuthenticatedRoute from './auth/RedirectIfAuthenticatedRoute';
import { ContainerLoader } from '../components/loader';
import ForgotPasswordPage from './auth/forgot-password';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AuthLogin from './auth/AuthLogin';
import ResetPasswordWithOTPPage from './auth/reset-password-otp';
import i18n from '../i18n';
import { useLanguage } from '../LanguageContext';
import RedirectIfPreRegistrationAuthenticatedRoute from './auth/RedirectIfPreRegistrationAuthenticatedRoute';
import PaymentRedirect from './dashboard/payments-tab/PaymentRedirect';
import OnlinePaymentDetailsPage from './dashboard/payments-tab/PaymentThankYouOnline'; 
// import PdfContractPage from './pdf-contract';
// import Dev from './dev';

// lazy loaded components (components should have a default export to lazy load)
const Auth = lazy(() => import('./auth'));
const Home = lazy(() => import('./home'));
const NotFound = lazy(() => import('./notfound'));
const DashboardPage = lazy(() => import('./dashboard'));

const LectureVideo = lazy(() => import('./lecture-video'));
const QuestionAnswer = lazy(() => import('./question-answer'));
const PdfInvoicePage = lazy(() => import('./pdf-invoice'));
const LumpsumPdfInvoicePage = lazy(() => import('./lumpsum-pdf-invoice'));
const ScheduleDetailsPage = lazy(() => import('./schedule-details'));
const PdfContractPage = lazy(() => import('./pdf-contract'));
const PdfRefundDocument = lazy(() => import('./pdf-refund-document'));
const PdfRefundDocumentCorporate = lazy(() =>
  import('./pdf-refund-document-corporate')
);
const PdfClearanceCertificate = lazy(() =>
  import('./pdf-clearance-certificate')
);
const BookAppointment = lazy(() => import('./book-an-appointment'));
const BookAppointmentThankYou = lazy(() =>
  import('./book-an-appointment-thank-you')
);
const CustomerFeedback = lazy(() => import('./customer-feedback'));
const CustomerFeedbackThankYou = lazy(() =>
  import('./customer-feedback-thank-you')
);
const CorporateLumpsumPdfInvoicePage = lazy(() =>
  import('./corporate-lumpsum-pdf-invoice')
);

const CorporatePdfInvoice = lazy(() => import('./pdf-invoice-corporate'));
const PreregistrationPdfInvoicePage = lazy(() =>
  import('./preregistration-pdf-invoice')
);
const SalesmanCollectionReportPdfPage = lazy(() =>
  import('./salesman-collection-report-pdf')
);
const SalesmanCollectingReportPdfPage = lazy(() =>
  import('./salesman-collecting-report-pdf')
);

const CollectionReportPdfPage = lazy(() =>
  import('./collection-report-pdf-invoice')
);
const PaymentError = lazy(() => import('./register-payment-error'));
const SalesmanAllCollectionReportPdfPage = lazy(() => import('./salesman-all-collection-report-pdf'));
const MockTestOtp = lazy(() => import('./mock-test/otp'));
const MockTestInstructions = lazy(() => import('./mock-test/instructions'));
const MockTestChoseLanguage = lazy(() => import('./mock-test/choose-language'));
const MockTestQuestion = lazy(() => import('./mock-test/questions'));
const SummaryQuestion = lazy(() => import('./mock-test/summary'));
const VideoMessage = lazy(() => import('./mock-test/video-message'));
const VideoConfirmation = lazy(() => import('./mock-test/video-confirmation'));
const VideoQuestionMockTest = lazy(() => import('./mock-test/video-question'));
const MockTestThankyou = lazy(() => import('./mock-test/mock-test-thank-you'));

// const MyReferralTab = lazy(() => import('./'));
const Routes: React.FunctionComponent<any> = (props) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          {/* <Route exact path="/" component={Auth} /> */}
          <Route path={`/:lang${RouteKeys.Auth}`} component={Auth} />
          {/* <RedirectIfAuthenticatedRoute
            // path={`${baseUrl}/forgot-password`}
            path={`/:lang${RouteKeys.ForgotPassword}`}
            component={ForgotPasswordPage}
          />
          <RedirectIfAuthenticatedRoute
            // path={`${baseUrl}/forgot-password`}
            path={`/:lang${RouteKeys.Login}`}
            component={AuthLogin}
          />
          <Route
            // path={`${baseUrl}/forgot-password/otp`}
            path={`/:lang${RouteKeys.ResetPasswordWithOTP}`}
            component={ResetPasswordWithOTPPage}
          /> */}

          {/* <Route path={RouteKeys.Dev} component={Dev} /> */}
          <Route
            path={`/:lang${RouteKeys.PdfInvoice}`}
            component={PdfInvoicePage}
          />
          <Route
            path={`/:lang${RouteKeys.LumpsumPdfInvoice}`}
            component={LumpsumPdfInvoicePage}
          />
          <Route
            path={`/:lang${RouteKeys.CorporatePdfInvoice}`}
            component={CorporatePdfInvoice}
          />
          <Route
            path={`/:lang${RouteKeys.CorporateLumpsumPdfInvoice}`}
            component={CorporateLumpsumPdfInvoicePage}
          />
          <Route
            path={`/:lang${RouteKeys.PdfContract}`}
            component={PdfContractPage}
          />
          <Route
            path={`/:lang${RouteKeys.PaymentCallback}`}
            component={PaymentCallBackPage}
          />

          <Route
            path={`${RouteKeys.OnlinePaymentRedirect}`}
            component={PaymentRedirect}
          /> 
         
          <RedirectIfPreRegistrationAuthenticatedRoute
            exact
            path={`/:lang${RouteKeys.QuickSignup}`}
            component={QuickRegisterStep1}
          />
          <RedirectIfAuthenticatedRoute
            exact
            path={`/:lang${RouteKeys.Signup}`}
            component={RegisterStep1}
          />
          <Route
            exact
            path={`/:lang${RouteKeys.SignupOtp}`}
            component={RegisterStep2}
          />
          <AuthenticateRoute
            exact
            path={`/:lang${RouteKeys.SignupOnboard}`}
            component={RegisterStep3}
          />
          <AuthenticateRoute
            exact
            path={`/:lang${RouteKeys.SignupThankYou}`}
            component={RegisterThankYou}
          />
          <AuthenticateRoute
            // exact
            path={`/:lang${RouteKeys.Dashboard}`}
            component={DashboardPage}
          />
          <AuthenticateRoute
            path={`/:lang${RouteKeys.LectureVideo}`}
            component={LectureVideo}
          />
          <AuthenticateRoute
            path={`/:lang${RouteKeys.ScheduleDetails}`}
            component={ScheduleDetailsPage}
          />
          <AuthenticateRoute
            path={`/:lang${RouteKeys.QuestionAnswer}`}
            component={QuestionAnswer}
          />
          <Route
            path={`/:lang${RouteKeys.PdfRefundDocument}`}
            component={PdfRefundDocument}
          />
          <Route
            path={`/:lang${RouteKeys.PdfRefundDocumentCorporate}`}
            component={PdfRefundDocumentCorporate}
          />
          <Route
            path={`/:lang${RouteKeys.PdfClearanceCertificate}`}
            component={PdfClearanceCertificate}
          />
          <Route
            exact
            path={`/:lang${RouteKeys.BookAppointmentThankYou}`}
            component={BookAppointmentThankYou}
          />
          <Route
            path={`/:lang${RouteKeys.BookAppointment}`}
            component={BookAppointment}
          />
          <Route
            path={`/:lang${RouteKeys.CustomerFeedback}`}
            component={CustomerFeedback}
          />
          <Route
            path={`/:lang${RouteKeys.CustomerFeedbackThankYou}`}
            component={CustomerFeedbackThankYou}
          />

          <Route exact path={`${RouteKeys.Home}`} component={Home} />
          <Route exact path="/verify-email/:id" component={VerifyUserEmail} />
          <Route
            path="/theorytest"
            component={() => {
              window.location.href =
                'https://stg70.oorjit.net/firstdrive/theorytest';
              return null;
            }}
          />
          <Route
            path={`/:lang${RouteKeys.PreregistrationPdfInvoice}`}
            component={PreregistrationPdfInvoicePage}
          />
          <Route
            path={RouteKeys.SalesmanCollectionReport}
            component={SalesmanCollectionReportPdfPage}
          />
          <Route
            path={RouteKeys.SalesmanCollectingReport}
            component={SalesmanCollectingReportPdfPage}
          />
          <Route
            path={RouteKeys.SalesmanAllCollectionReport}
            component={SalesmanAllCollectionReportPdfPage}
          />
          <Route
            path={RouteKeys.CollectionReportPdf}
            component={CollectionReportPdfPage}
          />
          <AuthenticateRoute
            // exact
            path={`/:lang${RouteKeys.PaymentThankYou}`}
            component={OnlinePaymentDetailsPage}
          />
           <AuthenticateRoute
            exact
            path={`${RouteKeys.PaymentError}`}
            component={PaymentError}
          />
        
          <Route
            exact
            path={`/:lang${RouteKeys.NotFound}`}
            component={NotFound}
          />

          <Route
            path={`/:lang${RouteKeys.MockTestOtp}`}
            component={MockTestOtp}
          />
     
          <Route
            path={`/:lang${RouteKeys.MockTestInstructions}`}
            component={MockTestInstructions}
          />
          <Route
            path={`/:lang${RouteKeys.MockTestChoseLanguage}`}
            component={MockTestChoseLanguage}
          />
          <Route
            path={`/:lang${RouteKeys.MockTestQuestion}`}
            component={MockTestQuestion}
          />
          <Route
            path={`/:lang${RouteKeys.MockTestSummary}`}
            component={SummaryQuestion}
          />
          <Route
            path={`/:lang${RouteKeys.MockTestVideoMessage}`}
            component={VideoMessage}
          />
          <Route
            path={`/:lang${RouteKeys.MockTestVideoConfirmation}`}
            component={VideoConfirmation}
          />
          <Route
            path={`/:lang${RouteKeys.MockTestVideoQuestion}`}
            component={VideoQuestionMockTest}
          />
          <Route
            path={`/:lang${RouteKeys.MockTestThankyou}`}
            component={MockTestThankyou}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
