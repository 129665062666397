import React from 'react';

const WrongAnswerIcon = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
    <path d="M28 56.0006C43.464 56.0006 56 43.4645 56 28.0003C56 12.5362 43.464 0 28 0C12.536 0 0 12.5362 0 28.0003C0 43.4645 12.536 56.0006 28 56.0006Z" fill="#FF565E"/>
    <path d="M16.6074 41.7214C16.6074 41.7214 19.3352 35.1426 28.2407 35.1426C37.1461 35.1426 39.3925 41.7214 39.3925 41.7214C39.3925 41.7214 28.6418 37.1483 16.6074 41.7214Z" fill="#B0312F"/>
    <path d="M15.8047 18.6934L21.9823 24.8711" stroke="#B0312F" stroke-width="2.43176" stroke-miterlimit="10"/>
    <path d="M21.9823 18.6934L15.8047 24.8711" stroke="#B0312F" stroke-width="2.43176" stroke-miterlimit="10"/>
    <path d="M33.9375 18.6934L40.1152 24.8711" stroke="#B0312F" stroke-width="2.43176" stroke-miterlimit="10"/>
    <path d="M40.1152 18.6934L33.9375 24.8711" stroke="#B0312F" stroke-width="2.43176" stroke-miterlimit="10"/>
    </svg>
  );
};

export default WrongAnswerIcon;
