import React, { Component } from 'react';

import HeaderCanvas from './HeaderCanvas';
import UserHeaderCanvas from './UserHeaderCanvas';

interface CustomProps {
  headerType?: 'logged-in' | 'pre-login';
  innerHeader?: boolean;
  noBorder?: boolean;
}
class Header extends Component<CustomProps> {
  render() {
    return this.props.headerType !== 'logged-in' ? (
      <HeaderCanvas
        innerHeader={this.props.innerHeader}
        noBorder={this.props.noBorder}
      />
    ) : (
      <UserHeaderCanvas innerHeader={this.props.innerHeader} />
    );
  }
}

export default Header;
