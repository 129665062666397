/* eslint-disable react/jsx-no-target-blank */
/* eslint jsx-a11y/anchor-is-valid: "off" */
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CustomProps {
  avoidFooterResponsive?: boolean;
}

const Footer = (props: CustomProps) => {
  const { t } = useTranslation();

  return (
    <footer
      className={`bg-black text-white text-center text-sm p-4 ${
        props.avoidFooterResponsive ? 'hidden lg:block' : ''
      }`}
    >
      {t('common.lblCopyRight')}
    </footer>
  );
};

export default Footer;
