import React from 'react';

export const CalendarIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      stroke="currentColor"
      {...props}
    >
      <g clip-path="url(#clip0_2899_15420)">
        <path
          d="M3.6665 6.41536C3.6665 5.92913 3.85966 5.46282 4.20347 5.119C4.54729 4.77519 5.01361 4.58203 5.49984 4.58203H16.4998C16.9861 4.58203 17.4524 4.77519 17.7962 5.119C18.14 5.46282 18.3332 5.92913 18.3332 6.41536V17.4154C18.3332 17.9016 18.14 18.3679 17.7962 18.7117C17.4524 19.0555 16.9861 19.2487 16.4998 19.2487H5.49984C5.01361 19.2487 4.54729 19.0555 4.20347 18.7117C3.85966 18.3679 3.6665 17.9016 3.6665 17.4154V6.41536Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6665 2.75V6.41667"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3335 2.75V6.41667"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.6665 10.082H18.3332"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
