import React from 'react';
import { LogoutButtonProps } from './props';
import Button from '../../button';
import { useDispatch, useSelector } from 'react-redux';

import { actions as userActions } from '../../../store/reducers/authUser';
import { actions as commonActions } from '../../../store/reducers/common';
import { useHistory } from 'react-router';
import { RouteKeys } from '../../../containers/route-keys';
import { useTranslation } from 'react-i18next';

const LogoutButton: React.SFC<LogoutButtonProps> = (
  props: LogoutButtonProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );

  const logout = () => {
    dispatch(userActions.logout());
    dispatch(commonActions.clearData());
    // history.replace(RouteKeys.Login);
    history.replace(`/${selectedLanguage}${RouteKeys.Dashboard}`);
  };

  return (
    <button
      type="button"
      className="btn btn-outline-primary p-1.5 text-xs"
      onClick={logout}
    >
      {t('header.Logout')}
    </button>
  );
};

export default LogoutButton;
