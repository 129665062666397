import React, { ReactElement } from 'react';
// import PropTypes, { InferProps } from 'prop-types';

import './Avatar.scss';
import defaultAvatar from './default-avatar.png';
import SignedImage from '../signed-image';

interface CustomProps {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  src?: string;
  name?: string;
  rounded?: boolean;
}
const Avatar: React.SFC<CustomProps> = (props: CustomProps): ReactElement => {
  const { src = defaultAvatar, name, size, rounded } = props;
  return (
    <div className="Avatar">
      <SignedImage
        className={`Avatar__image Avatar__image--${size} ${
          rounded ? 'rounded-full' : ''
        }`}
        src={src}
        alt={name}
        title={name}
        defaultSrc={defaultAvatar}
      />
    </div>
  );
};

// Avatar.propTypes = {
//   size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
//   src: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
// };

Avatar.defaultProps = {
  size: 'small',
  src: defaultAvatar,
};

export default Avatar;
