import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouteKeys } from '../route-keys';
import { actions as authUserActions } from '../../store/reducers/authUser';
import Header from '../header';
import Footer from '../footer';
import { ThankyouIcon } from '../../components/icons';

const ThankYouPage: React.SFC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchProfile = (onSuccess?: () => void) =>
    dispatch(authUserActions.fetchProfile(onSuccess));

  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );

  const goBackHome = async () => {
    setLoading(true);
    await fetchProfile(() => {
      history.replace(`/${selectedLanguage}${RouteKeys.Dashboard}`);
      setLoading(false);
    });
  };

  const renderBody = () => {
    return (
      <div className="bg-slate-50 py-11 lg:py-24 min-h-[calc(100svh-144px)] lg:min-h-[calc(100svh-162px)] text-center flex items-center">
        <div className="container">
          <ThankyouIcon className="mx-auto mb-3" />
          <h3 className="text-3xl md:text-[32px] font-semibold mb-1.5">
            {t('common.lblCongratulations')}
          </h3>
          <p className="text-base md:text-lg mb-7">
            {t('common.lblYourAccountHasBeenRegisteredSuccessfully')}
          </p>
          <button
            type="button"
            className="btn btn-primary py-2.5 mx-auto"
            onClick={goBackHome}
            disabled={loading}
          >
            {loading ? t('common.lblPleaseWait') : t('common.lblBackToHome')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header noBorder />
      {renderBody()}
      <Footer />
    </>
  );
};

export default ThankYouPage;
